import WebServiceWrapper from "./WebServiceWrapper";

export const EligibilityService = {
    GetEligibilityData,
    SaveEligibilityData,
};


function GetEligibilityData(pId) {
    return WebServiceWrapper.Get(`/eligibilityCriteria/GetEligibilityData?patientId=${pId}`);
  }
  

function SaveEligibilityData(payload) {
    return WebServiceWrapper.PostWithHeader("/eligibilityCriteria/SaveEligibilityData", payload);
}