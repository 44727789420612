import WebServiceWrapper from "./WebServiceWrapper";

export const TLSMonitoringService = {
  LoadTLSMonitoringData,
  SaveTLSMonitoringDetails,
  LoadTLSMonitoringWeekData,
  SaveTLSMonitoringWeekDetails,
  LoadTLSMonitoringLabTestData,
  SaveTLSMonitoringLabTestDetails
};

function LoadTLSMonitoringData(patientId) {
  return WebServiceWrapper.Get(`/tlsMonitoring/LoadTLSMonitoringData?patientId=${patientId}`); 
}

function SaveTLSMonitoringDetails(payload) {
  return WebServiceWrapper.PostWithHeader("/tlsMonitoring/SaveTLSMonitoringData", payload);
}


function LoadTLSMonitoringWeekData(patientId, weekNum) {
  return WebServiceWrapper.Get(`/tlsMonitoring/LoadTLSMonitoringWeekData?patientId=${patientId}&weekNum=${weekNum}`); 
}

function SaveTLSMonitoringWeekDetails(payload) {
  return WebServiceWrapper.PostWithHeader("/tlsMonitoring/SaveTLSMonitoringWeekData", payload);
}


function LoadTLSMonitoringLabTestData(patientId, weekNum, labtestnum) {
  return WebServiceWrapper.Get(`/tlsMonitoring/LoadTLSMonitoringLabTestData?patientId=${patientId}&weekNum=${weekNum}&labTestNum=${labtestnum}`); 
}

function SaveTLSMonitoringLabTestDetails(payload) {
  return WebServiceWrapper.PostWithHeader("/tlsMonitoring/SaveTLSMonitoringLabTestData", payload);
}



