import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./custom.css";
import PrivateRoute from "./Components/PrivateRoute";
import Login from "./Pages/Login";
import Error from "./Pages/Error";
import AppContextProvider from "./Contexts/AppContextProvider";
import Dashboard from "./Pages/Patient/Dashboard";
import ManageAbstractors from "./Pages/Abstractor/ManageAbstractors";
import { ManageCenters } from "./Pages/Center/ManageCenters";
import ResetPassword from "./Pages/ResetPassword";
import PasswordRecovery from "./Pages/PasswordRecovery";
import { AddLocalStorageData, GetLocalStorageData } from "./Helpers/Utilities";
import packageJson from "../package.json";
import ClinicalCharacteristics from "./Pages/Patient/ClinicalCharacteristics";
import HealthCareVisits from "./Pages/Patient/HealthCareVisits";
import VenetoclaxRampUp from "./Pages/Patient/VenetoclaxRampUp";
import EligibilityCriteria from "./Pages/Patient/EligibilityCriteria";
import TLSMonitoring from "./Pages/Patient/TlsMonitoring";
import TreatmentInterruptions from "./Pages/Patient/TreatmentInterruptions";
import ValidateSubmit from "./Pages/Patient/ValidateSubmit";
import './custom.css';
import Introduction from "./Pages/Patient/Introduction";
import Appendix from './Components/Appendix';
import Demographics from "./Pages/Patient/Demographics";
import VenetoclaxRampUpWeek from "./Pages/Patient/VenetoclaxRampUpWeek";
import TlsWeekMonitoring from "./Pages/Patient/TlsWeekMonitoring";
import TlsLabTestMonitoring from "./Pages/Patient/TlsLabTestMonitoring";
const App = () => {
  const location = useLocation();
 /* <summary>
 date: 06-04-2024
 Name: AP
 description: Effect to clear the cache
 <summary>*/
    useEffect(() => {
        const Caching = () => {
            const version = GetLocalStorageData("version");
            if (version !== packageJson.version) {
                AddLocalStorageData("version", packageJson.version);
                if ("caches" in window) {
                    caches.keys().then((names) => {
                        // Delete all the cache files
                        names.forEach((name) => {
                            caches.delete(name);
                        });
                    });
                }

                // Makes sure the page reloads. Changes are only visible after you refresh.
                window.location.replace(window.location.href);
            }
        };

        Caching();

        return () => { };
    }, []);
  return (
    <AppContextProvider>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Login />} />
        <Route path="/ResetPassword" element={<ResetPassword />} />
        <Route path="/PasswordRecovery" element={<PasswordRecovery />} />
        <Route path="/" element={<PrivateRoute />}> 
          <Route path="/ManagePatients" element={<Dashboard />} />
          <Route path="/ManageAbstractors" element={<ManageAbstractors />} />
          <Route path="/ManageCenters" element={<ManageCenters />} />
          <Route path="/EligibilityCriteria" element={<EligibilityCriteria />} />
          <Route path="/ClinicalCharacteristics" element={<ClinicalCharacteristics />} />
          <Route path="/HealthCareVisits" element={<HealthCareVisits />} />
          <Route path="/VenetoclaxRamUp" element={<VenetoclaxRampUp />} />
          <Route path="/VenetoclaxRampUpWeek/:week" element={<VenetoclaxRampUpWeek />} />
          <Route path="/TLSMonitoring" element={<TLSMonitoring />} />
          <Route path="/TlsWeekMonitoring/:week" element={<TlsWeekMonitoring />} />
          <Route path="/TlsLabTestMonitoring/:weekNum/:testNum" element={<TlsLabTestMonitoring />} />
          <Route path="/TreatmentInterruptions" element={<TreatmentInterruptions />} />
          <Route path="/ValidateSubmit" element={<ValidateSubmit />} />
          <Route path="/Introduction" element={<Introduction />} />
          <Route path="/Appendix" element={<Appendix />} />
          <Route path="/Demographics" element={<Demographics />} />

        </Route>
        <Route path="*" element={<Error />} />
      </Routes>
    </AppContextProvider>
  );
};
export default App;
