import React, { useContext, useEffect, useState } from "react";
import { HTTPResponse, SideMenuItems, StrongCYP3Agents, ToastMessages, ToastMessageType, Values, WeeklyModifiedDoseSchedule, WeeklyRegularDoseSchedule } from "../../Helpers/Enums";
import SideBarMenu from "../../Components/SideBarMenu";
import PatientDetails from "../../Components/PatientInformation";
import SaveAndNavigate from "../../Components/SaveAndNavigate";
import DateControl from "../../Components/DateControl";
import VenetoclaxDosingPopUp from "../../Components/VenetoclaxDosingPopUp";
import dayjs from "dayjs";
import {
  AllowOnlyIntegers,
  GetLocalStorageData,
  GetLoggedInUserID,
  IsDateBefore,
  IsDtValid,
  isStringEmpty,
  MatchesAnyWord,
  ParseDate,
  ValueInRange
} from "../../Helpers/Utilities";
import { AppContext } from "../../Contexts/AppContextProvider";
import { CommonError_Msgs, VenRampUp_Msgs } from "../../Helpers/HelperText";
import ErrorField from "../../Components/ErrorField";
import { VenetoclaxRampUpDataService } from "../../WebApiServices/VenetoclaxRampUp.service";
import { useParams } from "react-router-dom";

let pid = 0;
const VenetoclaxRampUpWeek = () => {
  const { week } = useParams();
  const { ShowToast, ToggleLoader, HandleSessionTimeout, appState } = useContext(AppContext);


  const [navigatePaths, setNavPaths] = useState({
    prevPage: "",
    currPage: `/VenetoclaxRampUpWeek/${week}`,
    nextPage: ""
  });

  const [showHidePopUp, setShowHidePopUp] = useState(false);

  const [weekDetails, setWeekDetails] = useState({
    weekId: null,//Pk of bridge tbl
    // venetoclaxCharacteristicsId: null, //FK of ven ramp-up tbl
    startDate: "",
    prevWkDoseDate: "",
    rampUpDoseSelect: null,
    weekCount: null,
    //26 soft errors
    first_veneto_DateSoftErr: false,
    first_veneto_DateLessThanPriorSoftErr: false,
    //27 soft err
    strongCyp3aSoftErr: false,
    //26
    first_veneto_week_date: null,
    first_veneto_week_Date_Value: null,
    first_veneto_week_Time: null,
    first_veneto_week_Hour_value: "",
    first_veneto_week_Mins_value: "",
    //27
    medications_Ciprofloxacin: null,
    medications_Diltiazem: null,
    medications_Erythromycin: null,
    medications_Fluconazole: null,
    medications_Verapamil: null,
    medications_CYP3A_Other_txt_value: "",
    medications_CYP3A_Other: null,
    //28
    veneto_dose_received_beforeSave: null,
    veneto_dose_received: null,
    veneto_dose_received_Other_txt: "",
    //29
    veneto_Admin_Inpatient: false,
    veneto_Admin_Outpatient: false,
    veneto_Admin_Other: false,
    veneto_Admin_Other_value: "",
    veneto_Admin_Unknown: false,
    //30
    veneto_Hydration_Preventative: false,
    veneto_Hydration_Other: false,
    veneto_Hydration: null,
  });

  const InitialHardErrList = {
    //26
    first_veneto_weekEmpty: false,
    first_veneto_week_Date_Invalid: false,
    first_veneto_week_Date_ValueEmpty: false,
    first_veneto_week_TimeEmpty: false,
    first_veneto_week_Hour_valueEmpty: false,
    first_veneto_week_Mins_valueEmpty: false,
    first_veneto_week_HrsMins_Empty: false,
    //27
    medications_CiprofloxacinEmpty: false,
    medications_DiltiazemEmpty: false,
    medications_ErythromycinEmpty: false,
    medications_FluconazoleEmpty: false,
    medications_VerapamilEmpty: false,
    medications_CYP3A_OtherEmpty: false,
    medications_CYP3A_Other_txt_valueEmpty: false,
    //28
    veneto_dose_receivedEmpty: false,
    veneto_dose_received_Other_txtEmpty: false,

    veneto_dose_received: null,
    veneto_dose_received_Other_txt: "",
    //29
    veneto_AdminEmpty: false,
    veneto_Admin_Other_valueEmpty: false,
    //30  
    veneto_HydrationEmpty: false
  };
  const [hardErrList, setHardErrList] = useState(InitialHardErrList);
  const [showTopErrMsg, setShowTopErrMsg] = useState(false);

  useEffect(() => {
    async function FetchData() {
      const { venRampUpWeekCnt } = appState ?? 0;
      const prevWk = Number(week) - 1;
      const nextWk = Number(week) + 1;
      const isFirstWk = week == 1;
      const isLastWk = venRampUpWeekCnt == week;

      let newPaths = { ...navigatePaths };

      newPaths.prevPage = isFirstWk ? "/VenetoclaxRamUp" : `/VenetoclaxRampUpWeek/${prevWk}`;
      newPaths.nextPage = isLastWk ? "/TlsMonitoring" : `/VenetoclaxRampUpWeek/${nextWk}`;
      setNavPaths(newPaths);

      pid = GetLocalStorageData("patientId");
      if (pid > 0 && week != 0) {
        await LoadData();
      }
    }
    FetchData();
  }, [week]);

  async function LoadData() {
    try {
      const response = await VenetoclaxRampUpDataService.GetVenRampUpWeekData(pid, week);
      if (response?.status !== HTTPResponse.OK) {
        if (response?.status === HTTPResponse.Unauthorized)
          HandleSessionTimeout();
        else if (response?.status === HTTPResponse.BadRequest)
          throw response.error;
      } else {
        if (response != null) {
          const { data } = response;

          const startDate = data.venStartDate;
          // Extracting hours and minutes from TimeOnly (assuming it's formatted as "HH:mm")
          const first_veneto_week_Hour_value = data.rcvdTime
            ? data.rcvdTime.split(":")[0]
            : "";
          const first_veneto_week_Mins_value = data.rcvdTime
            ? data.rcvdTime.split(":")[1]
            : "";
          let rcvdDate = data.rcvdDate || "";
          if (!data.rcvdDateUnk && rcvdDate == "" && week == 1) {
            rcvdDate = startDate;
          }
          let veneto_dose_received = data.doseReceived;
          if (veneto_dose_received == null && isStringEmpty(data.doseReceivedOthTxt)) {
            if (data.rampupDosing === 1 && data.weeks === 5) {
              //any YES
              if (data.medicationCiprofloxacin === 1 ||
                data.medicationDiltiazem === 1 ||
                data.medicationErythromycin === 1 ||
                data.medicationFluconazole === 1 ||
                data.medicationVerapamil === 1 ||
                data.medicationOth === 1) {
                veneto_dose_received = WeeklyModifiedDoseSchedule[week];
              }
              // ALL no/unk 
              else if (data.medicationCiprofloxacin !== null &&
                data.medicationDiltiazem !== null &&
                data.medicationErythromycin !== null &&
                data.medicationFluconazole !== null &&
                data.medicationVerapamil !== null &&
                data.medicationOth !== null) {
                veneto_dose_received = WeeklyRegularDoseSchedule[week];
              }
            }
          }
          setWeekDetails(prev => ({
            ...prev,
            weekId: data.venetoclaxRampUpBridgeId,//Pk of bridge tbl
            startDate,
            prevWkDoseDate: data.previousWeekDoseDate,
            rampUpDoseSelect: data.rampupDosing,
            weekCount: data.weeks,
            //26
            first_veneto_week_date: data.rcvdDateUnk ? Values.value99 : !isStringEmpty(rcvdDate) ? 1 : null,
            first_veneto_week_Date_Value: rcvdDate,
            first_veneto_week_Time: data.rcvdTimeUnk ? Values.value99 : (first_veneto_week_Hour_value || first_veneto_week_Mins_value) ? 1 : null,
            first_veneto_week_Hour_value,
            first_veneto_week_Mins_value,
            //27
            medications_Ciprofloxacin: data.medicationCiprofloxacin,
            medications_Diltiazem: data.medicationDiltiazem,
            medications_Erythromycin: data.medicationErythromycin,
            medications_Fluconazole: data.medicationFluconazole,
            medications_Verapamil: data.medicationVerapamil,
            medications_CYP3A_Other: data.medicationOth,
            medications_CYP3A_Other_txt_value: data.medicationOthTxt || "",
            //28
            veneto_dose_received_beforeSave: veneto_dose_received,
            veneto_dose_received,
            veneto_dose_received_Other_txt: data.doseReceivedOthTxt || "",
            //29
            veneto_Admin_Inpatient: data.settingInpatient,
            veneto_Admin_Outpatient: data.settingOutpatient,
            veneto_Admin_Other: data.settingOth,
            veneto_Admin_Other_value: data.settingOthTxt,
            veneto_Admin_Unknown: data.settingUnk,
            //30
            veneto_Hydration_Preventative: data.hydrationPreventative,
            veneto_Hydration_Other: data.hydrationOth,
            veneto_Hydration: data.hydrationNo ? 1 : data.hydrationUnk ? Values.value99 : null
          }));
        }
      }
    } catch (error) {
      ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
    }
  }

  const SavePage = async (validate) => {
    try {
      setHardErrList(InitialHardErrList);
      setWeekDetails(prev => ({
        ...prev,
        first_veneto_DateSoftErr: false,
        first_veneto_DateLessThanPriorSoftErr: false,
        strongCyp3aSoftErr: false
      }));

      let isValid = validate ? ValidateForm() : true;
      if (isValid) {
        setShowTopErrMsg(false);

        const hours = Number(weekDetails.first_veneto_week_Hour_value);
        const minutes = Number(weekDetails.first_veneto_week_Mins_value);

        // Check if hours and minutes are entered
        const RcvdTime = (weekDetails.first_veneto_week_Hour_value || weekDetails.first_veneto_week_Mins_value)
          ? `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`
          : ""; // If both are not entered, return an empty string

        let payload = {
          PatientId: pid,
          LoggedInUserId: GetLoggedInUserID(),
          IsValidated: validate,
          Week: week,
          VenetoclaxRampUpBridgeId: weekDetails.weekId,
          // VenetoclaxCharacteristicsId: weekDetails.venetoclaxCharacteristicsId,

          //26
          RcvdDate: IsDtValid(weekDetails.first_veneto_week_Date_Value) ? weekDetails.first_veneto_week_Date_Value : "",
          RcvdDateUnk: weekDetails.first_veneto_week_date == Values.value99,
          RcvdTime,
          RcvdTimeUnk: weekDetails.first_veneto_week_Time === Values.value99,
          //27
          MedicationCiprofloxacin: weekDetails.medications_Ciprofloxacin,
          MedicationDiltiazem: weekDetails.medications_Diltiazem,
          MedicationErythromycin: weekDetails.medications_Erythromycin,
          MedicationFluconazole: weekDetails.medications_Fluconazole,
          MedicationVerapamil: weekDetails.medications_Verapamil,
          MedicationOth: weekDetails.medications_CYP3A_Other,
          MedicationOthTxt: weekDetails.medications_CYP3A_Other_txt_value,
          //28
          DoseReceived: weekDetails.veneto_dose_received,
          DoseReceivedOthTxt: weekDetails.veneto_dose_received_Other_txt,
          //29
          SettingInpatient: weekDetails.veneto_Admin_Inpatient,
          SettingOutpatient: weekDetails.veneto_Admin_Outpatient,
          SettingOth: weekDetails.veneto_Admin_Other,
          SettingUnk: weekDetails.veneto_Admin_Unknown,
          SettingOthTxt: weekDetails.veneto_Admin_Other_value,
          //30
          HydrationPreventative: weekDetails.veneto_Hydration_Preventative,
          HydrationOth: weekDetails.veneto_Hydration_Other,
          HydrationNo: weekDetails.veneto_Hydration === 1,
          HydrationUnk: weekDetails.veneto_Hydration === Values.value99,

        };
        if (pid > 0) {
          ToggleLoader(true);
          const response = await VenetoclaxRampUpDataService.SaveVenRampUpWeekData(payload);
          ToggleLoader(false);
          if (response.status !== HTTPResponse.OK) {
            if (response?.status === HTTPResponse.Unauthorized) {
              HandleSessionTimeout();
            }
            else
              throw response.error;
          }
          else {
            ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
            await LoadData();
          }
        }
        return true;
      } else {
        setShowTopErrMsg(true);
        return false;
      }
    } catch (error) {
      ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed);
    }
  };

  const ValidateForm = () => {
    let result = true;
    const hr_empty = weekDetails.first_veneto_week_Hour_value == "";
    const min_empty = weekDetails.first_veneto_week_Mins_value == "";

    //26
    const first_veneto_weekEmpty = weekDetails.first_veneto_week_date == null;
    const first_veneto_week_Date_ValueEmpty = weekDetails.first_veneto_week_date == 1 && isStringEmpty(weekDetails.first_veneto_week_Date_Value);
    const first_veneto_week_Date_Invalid = weekDetails.first_veneto_week_date == 1 && !first_veneto_week_Date_ValueEmpty && !IsDtValid(weekDetails.first_veneto_week_Date_Value);

    const first_veneto_week_TimeEmpty = weekDetails.first_veneto_week_Time == null;
    const first_veneto_week_Hour_valueEmpty = weekDetails.first_veneto_week_Time == 1 && hr_empty && !min_empty;
    const first_veneto_week_Mins_valueEmpty = weekDetails.first_veneto_week_Time == 1 && min_empty && !hr_empty;
    const first_veneto_week_HrsMins_Empty = weekDetails.first_veneto_week_Time == 1 && hr_empty && min_empty;
    //27     
    const medications_CiprofloxacinEmpty = weekDetails.medications_Ciprofloxacin == null;
    const medications_DiltiazemEmpty = weekDetails.medications_Diltiazem == null;
    const medications_ErythromycinEmpty = weekDetails.medications_Erythromycin == null;
    const medications_FluconazoleEmpty = weekDetails.medications_Fluconazole == null;
    const medications_VerapamilEmpty = weekDetails.medications_Verapamil == null;
    const medications_CYP3A_OtherEmpty = weekDetails.medications_CYP3A_Other == null;
    const medications_CYP3A_Other_txt_valueEmpty = weekDetails.medications_CYP3A_Other === 1 && weekDetails.medications_CYP3A_Other_txt_value == "";
    //28
    const veneto_dose_receivedEmpty = weekDetails.veneto_dose_received == null;
    const veneto_dose_received_Other_txtEmpty = weekDetails.veneto_dose_received == 7 && weekDetails.veneto_dose_received_Other_txt == "";
    //29
    const veneto_AdminEmpty = !weekDetails.veneto_Admin_Inpatient && !weekDetails.veneto_Admin_Outpatient && !weekDetails.veneto_Admin_Other && !weekDetails.veneto_Admin_Unknown;
    const veneto_Admin_Other_valueEmpty = weekDetails.veneto_Admin_Other && weekDetails.veneto_Admin_Other_value == "";
    //30  
    const veneto_HydrationEmpty = !weekDetails.veneto_Hydration_Preventative && !weekDetails.veneto_Hydration_Other && weekDetails.veneto_Hydration == null;


    setHardErrList(prev => ({
      ...prev,
      first_veneto_weekEmpty,
      first_veneto_week_Date_ValueEmpty,
      first_veneto_week_Date_Invalid,
      first_veneto_week_TimeEmpty,
      first_veneto_week_Hour_valueEmpty,
      first_veneto_week_Mins_valueEmpty,
      first_veneto_week_HrsMins_Empty,
      medications_CiprofloxacinEmpty,
      medications_DiltiazemEmpty,
      medications_ErythromycinEmpty,
      medications_FluconazoleEmpty,
      medications_VerapamilEmpty,
      medications_CYP3A_OtherEmpty,
      medications_CYP3A_Other_txt_valueEmpty,
      veneto_dose_receivedEmpty,
      veneto_dose_received_Other_txtEmpty,
      veneto_AdminEmpty,
      veneto_Admin_Other_valueEmpty,
      veneto_HydrationEmpty,
    }));
    if (first_veneto_weekEmpty || first_veneto_week_Date_ValueEmpty || first_veneto_week_Date_Invalid || first_veneto_week_TimeEmpty || first_veneto_week_Hour_valueEmpty ||
      first_veneto_week_Mins_valueEmpty || first_veneto_week_HrsMins_Empty || medications_CiprofloxacinEmpty ||
      medications_DiltiazemEmpty || medications_ErythromycinEmpty || medications_FluconazoleEmpty || medications_VerapamilEmpty ||
      medications_CYP3A_OtherEmpty || medications_CYP3A_Other_txt_valueEmpty || veneto_dose_receivedEmpty || veneto_dose_received_Other_txtEmpty ||
      veneto_AdminEmpty || veneto_Admin_Other_valueEmpty || veneto_HydrationEmpty) {
      result = false;
    }
    return result;
  };

  const HandleFieldChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      //26
      case "first_veneto_week_date":
        {
          setWeekDetails(prev => ({
            ...prev,
            first_veneto_week_date: Number(value),
            first_veneto_week_Date_Value: null,
            first_veneto_DateSoftErr: false,
            first_veneto_DateLessThanPriorSoftErr: false
          }));
          setHardErrList(prev => ({
            ...prev,
            first_veneto_weekEmpty: false,
            first_veneto_week_Date_ValueEmpty: false,
            first_veneto_week_Date_Invalid: false
          }));
        }
        break;
      case "first_veneto_week_Time":
        {
          setWeekDetails(prev => ({
            ...prev,
            first_veneto_week_Time: Number(value),
            first_veneto_week_Hour_value: "",
            first_veneto_week_Mins_value: "",
          }));
          setHardErrList(prev => ({
            ...prev,
            first_veneto_week_TimeEmpty: false,
            first_veneto_week_Hour_valueEmpty: false,
            first_veneto_week_Mins_valueEmpty: false,
            first_veneto_week_HrsMins_Empty: false
          }));
        }
        break;
      case "first_veneto_week_Hour_value":
        {
          if (value !== "" && !AllowOnlyIntegers(value)) {
            break;
          }
          setWeekDetails(prev => ({
            ...prev,
            first_veneto_week_Time: 1,
            first_veneto_week_Hour_value: value > 23 ? "23" : value,
            first_veneto_week_Mins_value: value === "23" && prev.first_veneto_week_Mins_value > "59" ? "59" : prev.first_veneto_week_Mins_value

          }));
          setHardErrList(prev => ({
            ...prev,
            first_veneto_week_TimeEmpty: false,
            first_veneto_week_Hour_valueEmpty: false,
            first_veneto_week_HrsMins_Empty: false
          }));
        }
        break;
      case "first_veneto_week_Mins_value":
        {
          if (value !== "" && !AllowOnlyIntegers(value)) {
            break;
          }
          setWeekDetails(prev => ({
            ...prev,
            first_veneto_week_Time: 1,
            first_veneto_week_Mins_value: value > 59 ? "59" : value,

          }));
          setHardErrList(prev => ({
            ...prev,
            first_veneto_week_TimeEmpty: false,
            first_veneto_week_Mins_valueEmpty: false,
            first_veneto_week_HrsMins_Empty: false
          }));
        }
        break;
      //27
      case "medications_Ciprofloxacin":
        {
          setWeekDetails(prev => ({
            ...prev,
            [name]: Number(value),
          }));
          setHardErrList(prev => ({
            ...prev,
            medications_CiprofloxacinEmpty: false,
          }));
          PrePopulateDose(name, Number(value));
        }
        break;
      case "medications_Diltiazem":
        {
          setWeekDetails(prev => ({
            ...prev,
            [name]: Number(value),
          }));
          setHardErrList(prev => ({
            ...prev,
            medications_DiltiazemEmpty: false,
          }));
          PrePopulateDose(name, Number(value));
        }
        break;
      case "medications_Erythromycin":
        {
          setWeekDetails(prev => ({
            ...prev,
            [name]: Number(value),
          }));
          setHardErrList(prev => ({
            ...prev,
            medications_ErythromycinEmpty: false,
          }));
          PrePopulateDose(name, Number(value));
        }
        break;
      case "medications_Fluconazole":
        {
          setWeekDetails(prev => ({
            ...prev,
            [name]: Number(value),
          }));
          setHardErrList(prev => ({
            ...prev,
            medications_FluconazoleEmpty: false,
          }));
          PrePopulateDose(name, Number(value));
        }
        break;
      case "medications_Verapamil":
        {
          setWeekDetails(prev => ({
            ...prev,
            [name]: Number(value),
          }));
          setHardErrList(prev => ({
            ...prev,
            medications_VerapamilEmpty: false,
          }));
          PrePopulateDose(name, Number(value));
        }
        break;
      case "medications_CYP3A_Other":
        {
          setWeekDetails(prev => ({
            ...prev,
            [name]: Number(value),
          }));
          setHardErrList(prev => ({
            ...prev,
            medications_CYP3A_OtherEmpty: false,
            medications_CYP3A_Other_txt_valueEmpty: false
          }));
          PrePopulateDose(name, Number(value));
        }
        break;
      case "medications_CYP3A_Other_txt_value":
        {
          const strongCyp3aSoftErr = MatchesAnyWord(value, StrongCYP3Agents)
          setWeekDetails(prev => ({
            ...prev,
            [name]: value,
            strongCyp3aSoftErr
          }));
          setHardErrList(prev => ({
            ...prev,
            medications_CYP3A_Other_txt_valueEmpty: false,
          }));
        }
        break;
      //28
      case "veneto_dose_received":
        {
          setWeekDetails(prev => ({
            ...prev,
            [name]: Number(value),
            veneto_dose_received_Other_txt: "",
          }));
          setHardErrList(prev => ({
            ...prev,
            veneto_dose_receivedEmpty: false,
            veneto_dose_received_Other_txtEmpty: false,
          }));
        }
        break;
      case "veneto_dose_received_Other_txt":
        {
          if (value !== "" && !AllowOnlyIntegers(value)) {
            break;
          }
          setWeekDetails(prev => ({
            ...prev,
            [name]: value,
            veneto_dose_received: 7
          }));
          setHardErrList(prev => ({
            ...prev,
            veneto_dose_receivedEmpty: false,
            veneto_dose_received_Other_txtEmpty: value != "" && !ValueInRange(Number(value), 10, 1200)
          }));
        }
        break;
      //29
      case "veneto_Admin_Unknown":
        {
          setWeekDetails(prev => ({
            ...prev,
            [name]: !prev[name],
            veneto_Admin_Inpatient: false,
            veneto_Admin_Outpatient: false,
            veneto_Admin_Other: false,
            veneto_Admin_Other_value: ""
          }));
          setHardErrList(prev => ({
            ...prev,
            veneto_AdminEmpty: false,
            veneto_Admin_Other_valueEmpty: false,
          }));
        }
        break;
      case "veneto_Admin_Inpatient":
      case "veneto_Admin_Outpatient":
      case "veneto_Admin_Other":
        {
          setWeekDetails(prev => ({
            ...prev,
            [name]: !prev[name],
            veneto_Admin_Unknown: false,
            veneto_Admin_Other_value: name === "veneto_Admin_Other" ? "" : prev.veneto_Admin_Other_value
          }));
          setHardErrList(prev => ({
            ...prev,
            veneto_AdminEmpty: false,
            veneto_Admin_Other_valueEmpty: name === "veneto_Admin_Other" ? false : prev.veneto_Admin_Other_valueEmpty,
          }));
        }
        break;
      case "veneto_Admin_Other_value":
        {
          setWeekDetails(prev => ({
            ...prev,
            [name]: value,
            veneto_Admin_Other: true,
            veneto_Admin_Unknown: false,
          }));
          setHardErrList(prev => ({
            ...prev,
            veneto_AdminEmpty: false,
            veneto_Admin_Other_valueEmpty: false,
          }));
        }
        break;
      //30
      case "veneto_Hydration":
        {
          setWeekDetails(prev => ({
            ...prev,
            [name]: Number(value),
            veneto_Hydration_Preventative: false,
            veneto_Hydration_Other: false
          }));
          setHardErrList(prev => ({
            ...prev,
            veneto_HydrationEmpty: false
          }));
        }
        break;
      case "veneto_Hydration_Preventative":
      case "veneto_Hydration_Other":
        {
          setWeekDetails(prev => ({
            ...prev,
            [name]: !prev[name],
            veneto_Hydration: null,
          }));
          setHardErrList(prev => ({
            ...prev,
            veneto_HydrationEmpty: false
          }));
        }
        break;
      default: break;

    }
  };

  const PrePopulateDose = (name, value) => {//updatedWeekDetails
    if (weekDetails.veneto_dose_received_beforeSave == null) {
      if (weekDetails.rampUpDoseSelect === 1 && weekDetails.weekCount === 5) {
        let { medications_Ciprofloxacin, medications_Diltiazem, medications_Erythromycin,
          medications_Fluconazole, medications_Verapamil, medications_CYP3A_Other
        } = weekDetails;
        // const { medications_Ciprofloxacin, medications_Diltiazem, medications_Erythromycin, medications_Fluconazole, medications_Verapamil, medications_CYP3A_Other }
        //   = updatedWeekDetails;
        if (name === "medications_Ciprofloxacin") {
          medications_Ciprofloxacin = value;
        }
        else if (name === "medications_Diltiazem") {
          medications_Diltiazem = value;
        }
        else if (name === "medications_Erythromycin") {
          medications_Erythromycin = value;
        }
        else if (name === "medications_Fluconazole") {
          medications_Fluconazole = value;
        }
        else if (name === "medications_Verapamil") {
          medications_Verapamil = value;
        }
        else if (name === "medications_CYP3A_Other") {
          medications_CYP3A_Other = value;
        }
        //any YES
        if (medications_Ciprofloxacin === 1 ||
          medications_Diltiazem === 1 ||
          medications_Erythromycin === 1 ||
          medications_Fluconazole === 1 ||
          medications_Verapamil === 1 ||
          medications_CYP3A_Other === 1) {
          setWeekDetails(prev => ({
            ...prev,
            veneto_dose_received: WeeklyModifiedDoseSchedule[week],
            veneto_dose_received_Other_txt: "",
          }));
          setHardErrList(prev => ({
            ...prev,
            veneto_dose_receivedEmpty: false,
            veneto_dose_received_Other_txtEmpty: false,
          }));
        }
        // ALL no/unk 
        else if (medications_Ciprofloxacin !== null &&
          medications_Diltiazem !== null &&
          medications_Erythromycin !== null &&
          medications_Fluconazole !== null &&
          medications_Verapamil !== null &&
          medications_CYP3A_Other !== null) {
          setWeekDetails(prev => ({
            ...prev,
            veneto_dose_received: WeeklyRegularDoseSchedule[week],
            veneto_dose_received_Other_txt: "",
          }));
          setHardErrList(prev => ({
            ...prev,
            veneto_dose_receivedEmpty: false,
            veneto_dose_received_Other_txtEmpty: false,
          }));
        } else {
          setWeekDetails(prev => ({
            ...prev,
            veneto_dose_received: null,
            veneto_dose_received_Other_txt: "",
          }));
          setHardErrList(prev => ({
            ...prev,
            // veneto_dose_receivedEmpty: false,
            veneto_dose_received_Other_txtEmpty: false,
          }));
        }
      }
    }
  };
  const HandleDateChange = (name, date, id) => {
      switch (name) {
      case "first_veneto_week_Date_Value":
        {
          const first_veneto_DateSoftErr = week == 1 && IsDtValid(date) && date != weekDetails.startDate;
          let first_veneto_DateLessThanPriorSoftErr = false;
          if (!isStringEmpty(weekDetails.prevWkDoseDate) && date != "" && IsDtValid(date) ) {
              if (IsDateBefore(ParseDate(date), ParseDate(weekDetails.prevWkDoseDate))){
              first_veneto_DateLessThanPriorSoftErr = true;
            }
          }
          setWeekDetails(prev => ({
            ...prev,
            first_veneto_week_date: 1,
            first_veneto_week_Date_Value: date,
            first_veneto_DateSoftErr,
            first_veneto_DateLessThanPriorSoftErr
          }));
          setHardErrList(prev => ({
            ...prev,
            first_veneto_weekEmpty: false,
            first_veneto_week_Date_ValueEmpty: false,
            first_veneto_week_Date_Invalid: false
          }));
        }
        break;
      default: break;
    }
  }

  return (
    <>
      <section className="survey-patient-details-section">
        <div className="row">
          <PatientDetails />
          <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
            <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
          </div>
        </div>
      </section>

      <section className="main-survey-section">
        <div className="row">
          <SideBarMenu activePageId={SideMenuItems.VenetoclaxRampupWeek.toString() + week.toString()} />

          <div className="col-md-9 col-pad-left">
            <div className="survey-section-content">
              <div className="survey-section-content-header">
                <h4>VENETOCLAX RAMP-UP WEEK {week}</h4>
              </div>
              {showTopErrMsg && <div className="clean-error ">
                <ErrorField show={showTopErrMsg} message={CommonError_Msgs.Hard_Empty} />
              </div>
              }
              <div className="survey-question-section">
                <div className="survey-question-content">
                  <div className="question-answer">
                    <div className="question-bot-sapce single-dig-quest">



                      {/* Loop Start */}
                      <div className="custom-loop-back">
                        <div className="question-bot-sapce">
                          <div className="question question-weight">
                            <span>26.</span>
                            <span className="quest-text-pad">
                              When was the first venetoclax ramp-up dose received
                              for week {week}?
                            </span>
                          </div>
                          <table className="modal-popup-table-large custom-modal-width">
                            <thead className="modal-popup-table-header">
                              <tr>
                                <th className="modal-popup-table-header-cell custom-width-veneto_26">
                                  Date
                                </th>
                                <th className="modal-popup-table-header-cell custom-width-veneto_26">
                                  Time
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="modal-popup-table-cell" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                                  <div className="date-field-padding option-fbox">
                                    <input
                                      type="radio"
                                      id="first_veneto_week_date_1"
                                      name="first_veneto_week_date"
                                      value={1}
                                      checked={weekDetails.first_veneto_week_date === 1}
                                      onChange={HandleFieldChange}
                                    />
                                    {/* <span className="radio-text-padding"> */}
                                    <DateControl
                                      ctrlId={"first_veneto_week_Date_Value"}
                                      HandleDateChange={HandleDateChange}
                                      date={weekDetails.first_veneto_week_Date_Value}
                                      locale="en"
                                      maxDate={dayjs()}
                                      minDate={dayjs("01-01-1920")}
                                    />
                                  </div>
                                  {/*<br />*/}
                                  <div className="option-without-input">
                                    <label>
                                      <input
                                        type="radio"
                                        id="first_veneto_week_date_99"
                                        name="first_veneto_week_date"
                                        value={99}
                                        checked={weekDetails.first_veneto_week_date === Values.value99}
                                        onChange={HandleFieldChange}
                                      />
                                      <span className="radio-text-padding">
                                        Unknown
                                      </span>
                                    </label>
                                    <ErrorField show={weekDetails.first_veneto_DateSoftErr}
                                      message={VenRampUp_Msgs.wk1DoseDate.replace("{venStartDt}", weekDetails.startDate)
                                        .replace("{doseDt}", weekDetails.first_veneto_week_Date_Value)}
                                    />
                                    <ErrorField show={weekDetails.first_veneto_DateLessThanPriorSoftErr}
                                      message={VenRampUp_Msgs.doseDtLessThanPrior.replace("{currWk}", week)
                                        .replace("{prevWk}", Number(week) - 1)}
                                    />
                                    <ErrorField show={hardErrList.first_veneto_weekEmpty} message={VenRampUp_Msgs.Select} />
                                    <ErrorField show={hardErrList.first_veneto_week_Date_ValueEmpty} message={VenRampUp_Msgs.rcvdDateEmpty} />
                                    <ErrorField show={hardErrList.first_veneto_week_Date_Invalid} message={CommonError_Msgs.DateNotValid} />
                                  </div>
                                </td>
                                <td className="modal-popup-table-cell">
                                  <div className="option-without-input option-fbox custom-gap"  style={{marginTop:"4px"}}>
                                    <input
                                      type="radio"
                                      id="first_veneto_week_Time_1"
                                      name="first_veneto_week_Time"
                                      value={1}
                                      checked={weekDetails.first_veneto_week_Time === 1}
                                      onChange={HandleFieldChange}
                                    />
                                    <input
                                      className="input-dash input_custome-space_sm"
                                      name="first_veneto_week_Hour_value"
                                      type="text"
                                      maxLength={2}
                                      value={weekDetails.first_veneto_week_Hour_value}
                                      onChange={HandleFieldChange}
                                    />
                                    :
                                    <input
                                      className="input-dash input_custome-space_sm"
                                      name="first_veneto_week_Mins_value"
                                      type="text"
                                      maxLength={2}
                                      value={weekDetails.first_veneto_week_Mins_value}
                                      onChange={HandleFieldChange}
                                    />
                                    <span>
                                      (hour/min){" "}
                                    </span>
                                  </div>

                                  <span><i>[Please use the 24-hour clock format]</i></span>

                                  {/*<br />*/}
                                  <div className="option-without-input option-fbox custom-gap">
                                    <label className="option-fbox custom-gap">
                                      <input
                                        type="radio"
                                        id="first_veneto_week_Time_99"
                                        name="first_veneto_week_Time"
                                        value={99}
                                        checked={weekDetails.first_veneto_week_Time === Values.value99}
                                        onChange={HandleFieldChange}  
                                        style={{marginTop:"14px"}}
                                      />
                                      <span className="custom-radio-padding"  style={{marginTop:"8px"}}>
                                        Unknown
                                      </span>
                                    </label>
                                  </div>
                                  <ErrorField show={hardErrList.first_veneto_week_TimeEmpty} message={VenRampUp_Msgs.Select} />
                                  <ErrorField show={hardErrList.first_veneto_week_Hour_valueEmpty} message={VenRampUp_Msgs.HrsEmpty} />
                                  <ErrorField show={hardErrList.first_veneto_week_Mins_valueEmpty} message={VenRampUp_Msgs.MinsEmpty} />
                                  <ErrorField show={hardErrList.first_veneto_week_HrsMins_Empty} message={VenRampUp_Msgs.HrsMinsEmpty} />
                                </td>
                                <br />
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div className="question-bot-sapce">
                          <div className="question question-weight">
                            <span>27.</span>
                            <span className="quest-text-pad">
                              Please indicate whether the patient received any of
                              the following medications during week {week} of
                              venetoclax ramp-up.
                            </span>
                          </div>
                          <table className="modal-popup-table-large custom-modal-width">
                            <thead className="modal-popup-table-header">
                              <tr>
                                <th className="modal-popup-table-header-cell">
                                  Agent
                                </th>
                                <th className="modal-popup-table-header-cell custom-width-veneto_27">
                                  Yes
                                </th>
                                <th className="modal-popup-table-header-cell custom-width-veneto_27">
                                  No
                                </th>
                                <th className="modal-popup-table-header-cell custom-width-veneto_27">
                                  Unknown
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="modal-popup-table-cell custom-venetoclax-tr" colSpan={4}>
                                  <span className=" question-weight">
                                    Moderate CYP3A inhibitors
                                  </span>
                                </td>
                              </tr>

                              <tr>
                                <td className="modal-popup-table-cell custom-venetoclax-tr">
                                  <span>Ciprofloxacin</span>
                                  <ErrorField show={hardErrList.medications_CiprofloxacinEmpty} message={VenRampUp_Msgs.Select} />
                                </td>
                                <td className="modal-popup-table-cell custom-venetoclax custom-venetoclax-tr">
                                  <input
                                    type="radio"
                                    id="medications_Ciprofloxacin_1"
                                    name="medications_Ciprofloxacin"
                                    value={1}
                                    checked={weekDetails.medications_Ciprofloxacin === 1}
                                    onChange={HandleFieldChange}
                                  />
                                </td>
                                <td className="modal-popup-table-cell custom-venetoclax custom-venetoclax-tr">
                                  <input
                                    type="radio"
                                    id="medications_Ciprofloxacin_2"
                                    name="medications_Ciprofloxacin"
                                    value={2}
                                    checked={weekDetails.medications_Ciprofloxacin === Values.value2}
                                    onChange={HandleFieldChange}
                                  />
                                </td>
                                <td className="modal-popup-table-cell custom-venetoclax custom-venetoclax-tr">
                                  <input
                                    type="radio"
                                    id="medications_Ciprofloxacin_99"
                                    name="medications_Ciprofloxacin"
                                    value={99}
                                    checked={weekDetails.medications_Ciprofloxacin === Values.value99}
                                    onChange={HandleFieldChange}
                                  />
                                </td>
                                <br />
                              </tr>

                              <tr>
                                <td className="modal-popup-table-cell custom-venetoclax-tr">
                                  <span>Diltiazem</span>
                                  <ErrorField show={hardErrList.medications_DiltiazemEmpty} message={VenRampUp_Msgs.Select} />
                                </td>
                                <td className="modal-popup-table-cell custom-venetoclax custom-venetoclax-tr">
                                  <input
                                    type="radio"
                                    id="medications_Diltiazem_1"
                                    name="medications_Diltiazem"
                                    value={1}
                                    checked={weekDetails.medications_Diltiazem === 1}
                                    onChange={HandleFieldChange}
                                  />
                                </td>
                                <td className="modal-popup-table-cell custom-venetoclax custom-venetoclax-tr">
                                  <input
                                    type="radio"
                                    id="medications_Diltiazem_2"
                                    name="medications_Diltiazem"
                                    value={2}
                                    checked={weekDetails.medications_Diltiazem === Values.value2}
                                    onChange={HandleFieldChange}
                                  />
                                </td>
                                <td className="modal-popup-table-cell custom-venetoclax custom-venetoclax-tr">
                                  <input
                                    type="radio"
                                    id="medications_Diltiazem_99"
                                    name="medications_Diltiazem"
                                    value={99}
                                    checked={weekDetails.medications_Diltiazem === Values.value99}
                                    onChange={HandleFieldChange}
                                  />
                                </td>
                                <br />
                              </tr>

                              <tr>
                                <td className="modal-popup-table-cell custom-venetoclax-tr">
                                  <span>Erythromycin</span>
                                  <ErrorField show={hardErrList.medications_ErythromycinEmpty} message={VenRampUp_Msgs.Select} />
                                </td>
                                <td className="modal-popup-table-cell custom-venetoclax custom-venetoclax-tr">
                                  <input
                                    type="radio"
                                    id="medications_Erythromycin_1"
                                    name="medications_Erythromycin"
                                    value={1}
                                    checked={weekDetails.medications_Erythromycin === 1}
                                    onChange={HandleFieldChange}
                                  />
                                </td>
                                <td className="modal-popup-table-cell custom-venetoclax custom-venetoclax-tr">
                                  <input
                                    type="radio"
                                    id="medications_Erythromycin_2"
                                    name="medications_Erythromycin"
                                    value={2}
                                    checked={weekDetails.medications_Erythromycin === Values.value2}
                                    onChange={HandleFieldChange}
                                  />
                                </td>
                                <td className="modal-popup-table-cell custom-venetoclax custom-venetoclax-tr">
                                  <input
                                    type="radio"
                                    id="medications_Erythromycin_99"
                                    name="medications_Erythromycin"
                                    value={99}
                                    checked={weekDetails.medications_Erythromycin === Values.value99}
                                    onChange={HandleFieldChange}
                                  />
                                </td>
                                <br />
                              </tr>

                              <tr>
                                <td className="modal-popup-table-cell custom-venetoclax-tr">
                                  <span>Fluconazole</span>
                                  <ErrorField show={hardErrList.medications_FluconazoleEmpty} message={VenRampUp_Msgs.Select} />
                                </td>
                                <td className="modal-popup-table-cell custom-venetoclax custom-venetoclax-tr">
                                  <input
                                    type="radio"
                                    id="medications_Fluconazole_1"
                                    name="medications_Fluconazole"
                                    value={1}
                                    checked={weekDetails.medications_Fluconazole === 1}
                                    onChange={HandleFieldChange}
                                  />
                                </td>
                                <td className="modal-popup-table-cell custom-venetoclax custom-venetoclax-tr">
                                  <input
                                    type="radio"
                                    id="medications_Diltiazem_2"
                                    name="medications_Fluconazole"
                                    value={2}
                                    checked={weekDetails.medications_Fluconazole === Values.value2}
                                    onChange={HandleFieldChange}
                                  />
                                </td>
                                <td className="modal-popup-table-cell custom-venetoclax custom-venetoclax-tr">
                                  <input
                                    type="radio"
                                    id="medications_Diltiazem_99"
                                    name="medications_Fluconazole"
                                    value={99}
                                    checked={weekDetails.medications_Fluconazole === Values.value99}
                                    onChange={HandleFieldChange}
                                  />
                                </td>
                                <br />
                              </tr>

                              <tr>
                                <td className="modal-popup-table-cell custom-venetoclax-tr">
                                  <span>Verapamil</span>
                                  <ErrorField show={hardErrList.medications_VerapamilEmpty} message={VenRampUp_Msgs.Select} />
                                </td>
                                <td className="modal-popup-table-cell custom-venetoclax custom-venetoclax-tr">
                                  <input
                                    type="radio"
                                    id="medications_Verapamil_1"
                                    name="medications_Verapamil"
                                    value={1}
                                    checked={weekDetails.medications_Verapamil === 1}
                                    onChange={HandleFieldChange}
                                  />
                                </td>
                                <td className="modal-popup-table-cell custom-venetoclax custom-venetoclax-tr">
                                  <input
                                    type="radio"
                                    id="medications_Verapamil_2"
                                    name="medications_Verapamil"
                                    value={2}
                                    checked={weekDetails.medications_Verapamil === Values.value2}
                                    onChange={HandleFieldChange}
                                  />
                                </td>
                                <td className="modal-popup-table-cell custom-venetoclax custom-venetoclax-tr">
                                  <input
                                    type="radio"
                                    id="medications_Verapamil_99"
                                    name="medications_Verapamil"
                                    value={99}
                                    checked={weekDetails.medications_Verapamil === Values.value99}
                                    onChange={HandleFieldChange}
                                  />
                                </td>
                                <br />
                              </tr>

                              <tr>
                                <td className="modal-popup-table-cell custom-venetoclax-tr">
                                  <i>Please note that patients who received strong CYP3A inhibitors are not eligible for this study</i> <br />
                                  <span>Other, specify</span>
                                  <input
                                    className="input-dash input_custome-space"
                                    name="medications_CYP3A_Other_txt_value"
                                    type="text"
                                    maxLength={500}
                                    value={weekDetails.medications_CYP3A_Other_txt_value}
                                    onChange={HandleFieldChange}
                                  />
                                  <ErrorField show={hardErrList.medications_CYP3A_OtherEmpty} message={VenRampUp_Msgs.Select} />
                                  <ErrorField show={hardErrList.medications_CYP3A_Other_txt_valueEmpty} message={VenRampUp_Msgs.medTxtEmpty} />
                                  <ErrorField show={weekDetails.strongCyp3aSoftErr} message={VenRampUp_Msgs.strongCyp3a} style={{ paddingLeft: '0px' }} />
                                </td>
                                <td className="modal-popup-table-cell custom-venetoclax custom-venetoclax-tr">
                                  <input
                                    type="radio"
                                    id="medications_CYP3A_Other_1"
                                    name="medications_CYP3A_Other"
                                    value={1}
                                    checked={weekDetails.medications_CYP3A_Other === 1}
                                    onChange={HandleFieldChange}
                                  />
                                </td>
                                <td className="modal-popup-table-cell custom-venetoclax custom-venetoclax-tr">
                                  <input
                                    type="radio"
                                    id="medications_CYP3A_Other_2"
                                    name="medications_CYP3A_Other"
                                    value={2}
                                    checked={weekDetails.medications_CYP3A_Other === Values.value2}
                                    onChange={HandleFieldChange}
                                  />
                                </td>
                                <td className="modal-popup-table-cell custom-venetoclax custom-venetoclax-tr">
                                  <input
                                    type="radio"
                                    id="medications_CYP3A_Other_99"
                                    name="medications_CYP3A_Other"
                                    value={99}
                                    checked={weekDetails.medications_CYP3A_Other === Values.value99}
                                    onChange={HandleFieldChange}
                                  />
                                </td>
                                <br />
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div className="question-bot-sapce">
                          <div className="question question-weight">
                            <span>28.</span>
                            <span className="quest-text-pad">
                              Please enter venetoclax ramp-up dose received for
                              week {week}.
                            </span>
                          </div>
                          <div className="double-dig-answer">
                            <div className="option-without-input">
                              <label>
                                <input
                                  type="radio"
                                  id="veneto_dose_received_1"
                                  name="veneto_dose_received"
                                  value={1}
                                  checked={weekDetails.veneto_dose_received === 1}
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  10 mg per day
                                </span>
                              </label>
                            </div>
                            <div className="option-without-input">
                              <label>
                                <input
                                  type="radio"
                                  id="veneto_dose_received_2"
                                  name="veneto_dose_received"
                                  value={2}
                                  checked={weekDetails.veneto_dose_received === Values.value2}
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  20 mg per day
                                </span>
                              </label>
                            </div>
                            <div className="option-without-input">
                              <label>
                                <input
                                  type="radio"
                                  id="veneto_dose_received_3"
                                  name="veneto_dose_received"
                                  value={3}
                                  checked={weekDetails.veneto_dose_received === 3}
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  50 mg per day
                                </span>
                              </label>
                            </div>
                            <div className="option-without-input">
                              <label>
                                <input
                                  type="radio"
                                  id="veneto_dose_received_4"
                                  name="veneto_dose_received"
                                  value={4}
                                  checked={weekDetails.veneto_dose_received === 4}
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  100 mg per day
                                </span>
                              </label>
                            </div>
                            <div className="option-without-input">
                              <label>
                                <input
                                  type="radio"
                                  id="veneto_dose_received_5"
                                  name="veneto_dose_received"
                                  value={5}
                                  checked={weekDetails.veneto_dose_received === 5}
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  200 mg per day
                                </span>
                              </label>
                            </div>
                            <div className="option-without-input">
                              <label>
                                <input
                                  type="radio"
                                  id="veneto_dose_received_6"
                                  name="veneto_dose_received"
                                  value={6}
                                  checked={weekDetails.veneto_dose_received === 6}
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  400 mg per day
                                </span>
                              </label>
                            </div>
                            <div className="option-without-input">
                              <label>
                                <input
                                  type="radio"
                                  id="veneto_dose_received_7"
                                  name="veneto_dose_received"
                                  value={7}
                                  checked={weekDetails.veneto_dose_received === 7}
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Other, specify
                                  <input
                                    className="input-dash input_custome-space"
                                    name="veneto_dose_received_Other_txt"
                                    type="text"
                                    maxLength={4}
                                    value={weekDetails.veneto_dose_received_Other_txt}
                                    onChange={HandleFieldChange}
                                  />
                                  &nbsp;&nbsp;mg per day
                                </span>
                              </label>
                            </div>
                            <div className="option-without-input">
                              <label>
                                <input
                                  type="radio"
                                  id="veneto_dose_received_99"
                                  name="veneto_dose_received"
                                  value={99}
                                  checked={weekDetails.veneto_dose_received === Values.value99}
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Unknown
                                </span>
                              </label>
                            </div>
                          </div>
                          <ErrorField show={hardErrList.veneto_dose_receivedEmpty} message={VenRampUp_Msgs.Select} />
                          <ErrorField show={hardErrList.veneto_dose_received_Other_txtEmpty} message={
                            weekDetails.veneto_dose_received_Other_txt == ""
                              ? VenRampUp_Msgs.doseReceivedTxtEmpty
                              : CommonError_Msgs.NotInRange.replace('[value]', weekDetails.veneto_dose_received_Other_txt).replace('[value units]', 'mg per day')
                          } />
                        </div>

                        <div className="question-bot-sapce">
                          <div className="question question-weight">
                            <span>29.</span>
                            <span className="quest-text-pad">
                              Please enter the administration setting of
                              venetoclax ramp-up during week {week}.
                            </span>
                          </div>
                          <div className="double-dig-answer">
                            <div className="option-without-input">
                              <label>
                                <input
                                  type="checkbox"
                                  id="veneto_Admin_Inpatient"
                                  name="veneto_Admin_Inpatient"
                                  checked={weekDetails.veneto_Admin_Inpatient}
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Inpatient
                                </span>
                              </label>
                            </div>
                            <div className="option-without-input">
                              <label>
                                <input
                                  type="checkbox"
                                  id="veneto_Admin_Outpatient"
                                  name="veneto_Admin_Outpatient"
                                  checked={weekDetails.veneto_Admin_Outpatient}
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Outpatient
                                </span>
                              </label>
                            </div>

                            <div className="option-without-input">
                              <label>
                                <input
                                  type="checkbox"
                                  id="veneto_Admin_Other"
                                  name="veneto_Admin_Other"
                                  checked={weekDetails.veneto_Admin_Other}
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Other, specify
                                  <input
                                    className="input-dash input_custome-space"
                                    name="veneto_Admin_Other_value"
                                    type="text"
                                    maxLength={500}
                                    value={weekDetails.veneto_Admin_Other_value}
                                    onChange={HandleFieldChange}
                                  />
                                </span>
                              </label>
                            </div>
                            <div className="option-without-input">
                              <label>
                                <input
                                  type="radio"
                                  id="veneto_Admin_Unknown"
                                  name="veneto_Admin_Unknown"
                                  checked={weekDetails.veneto_Admin_Unknown}
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Unknown
                                </span>
                              </label>
                            </div>
                          </div>
                          <ErrorField show={hardErrList.veneto_AdminEmpty} message={VenRampUp_Msgs.Select} />
                          <ErrorField show={hardErrList.veneto_Admin_Other_valueEmpty} message={VenRampUp_Msgs.adminTxtEmpty} />
                        </div>

                        <div className="question-bot-sapce">
                          <div className="question question-weight">
                            <span>30.</span>
                            <span className="quest-text-pad">
                              Did the patient receive IV hydration (either
                              preventive or unplanned) in the 2 days prior to the
                              initiation of venetoclax ramp-up week {week}?
                            </span>
                          </div>
                          <div className="double-dig-answer">
                            <div className="option-without-input">
                              <label>
                                <input
                                  type="checkbox"
                                  id="veneto_Hydration_Preventative"
                                  name="veneto_Hydration_Preventative"
                                  checked={weekDetails.veneto_Hydration_Preventative}
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Yes, via preventative IV hydration
                                </span>
                              </label>
                            </div>
                            <div className="option-without-input">
                              <label>
                                <input
                                  type="checkbox"
                                  id="veneto_Hydration_Other"
                                  name="veneto_Hydration_Other"
                                  checked={weekDetails.veneto_Hydration_Other}
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Yes, via other IV hydration
                                </span>
                              </label>
                            </div>

                            <div className="option-without-input">
                              <label>
                                <input
                                  type="radio"
                                  id="veneto_Hydration1"
                                  name="veneto_Hydration"
                                  value={1}
                                  checked={weekDetails.veneto_Hydration === 1}
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">No</span>
                              </label>
                            </div>
                            <div className="option-without-input">
                              <label>
                                <input
                                  type="radio"
                                  id="veneto_Hydration_99"
                                  name="veneto_Hydration"
                                  value={99}
                                  checked={weekDetails.veneto_Hydration === Values.value99}
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Unknown
                                </span>
                              </label>
                            </div>
                          </div>
                          <ErrorField show={hardErrList.veneto_HydrationEmpty} message={VenRampUp_Msgs.Select} />
                        </div>

                        <div className="question-bot-sapce custom-loop-back-white"></div>
                        {/* Loop End */}
                      </div>


                    </div>
                  </div>
                </div>
              </div>
              <div className="survey-section-content-foot">
                <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
              </div>
            </div>
          </div>
        </div>
      </section>
      {showHidePopUp && (
        <VenetoclaxDosingPopUp OkClick={() => setShowHidePopUp(false)} />
      )}
    </>
  );
};

export default VenetoclaxRampUpWeek;
