import React, { useContext, useEffect, useState } from "react";
import { HTTPResponse, SideMenuItems, ToastMessageType, ToastMessages, Values } from "../../Helpers/Enums";
import SideBarMenu from "../../Components/SideBarMenu";
import PatientDetails from "../../Components/PatientInformation";
import SaveAndNavigate from "../../Components/SaveAndNavigate";
import DateControl from "../../Components/DateControl";
import InfoPopUp from "../../Components/InfoPopUp";
import { CommonError_Msgs, TLSMonitoring_Msgs, TLS_Cairo, TLS_Table } from "../../Helpers/HelperText";
import ErrorField from "../../Components/ErrorField";
import { AppContext } from "../../Contexts/AppContextProvider";
import { PatientService } from "../../WebApiServices/Patient.service";
import { TLSMonitoringService } from "../../WebApiServices/TLSMonitoring.service";
import {
    GetLocalStorageData,
    GetLoggedInUserID,
    ParseDate,
    IsDateBefore,
    IsDateAfter,
    SubtractMonths,
    DateDisplayFormat,
    getOrdinalSuffix,
} from "../../Helpers/Utilities";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

const TlsLabTestMonitoring = () => {
    const { weekNum, testNum } = useParams();
    const [navigatePaths, setNavPaths] = useState({
        prevPage: "/",
        currPage: `/TlsLabTestMonitoring?week=${weekNum}&testid=${testNum}`,
        nextPage: "",
    });
    const pid = GetLocalStorageData("patientId") ?? 0;
    const {
        ShowToast,
        ToggleLoader,
        HandleSessionTimeout,
        appState
    } = useContext(AppContext);

    const [currWeekNo, setCurrWeekNo] = useState(weekNum);

    const currentUrl = window.location.href;
    const [showHideModalPopup, setShowHideModalPopup] = useState(false);
    const [showHideModalPopupLink, setShowHideModalPopupLink] = useState(false);
    const [showTopErrMsg, setShowTopErrMsg] = useState(false);
    const [q36ResultAlcOutOfRange, setQ36ResultAlcOutOfRange] = useState(TLSMonitoring_Msgs.TestCompleteTxtEmptyMsg);
    const [q36ResultCalOutOfRange, setQ36ResultCalOutOfRange] = useState(TLSMonitoring_Msgs.TestCompleteTxtEmptyMsg);
    const [q36ResultScOutOfRange, setQ36ResultScOutOfRange] = useState(TLSMonitoring_Msgs.TestCompleteTxtEmptyMsg);
    const [q36ResultCrclOutOfRange, setQ36ResultCrclOutOfRange] = useState(TLSMonitoring_Msgs.TestCompleteTxtEmptyMsg);
    const [q36ResultLdhOutOfRange, setQ36ResultLdhOutOfRange] = useState(TLSMonitoring_Msgs.TestCompleteTxtEmptyMsg);
    const [q36ResultPhoOutOfRange, setQ36ResultPhoOutOfRange] = useState(TLSMonitoring_Msgs.TestCompleteTxtEmptyMsg);
    const [q36ResultPotOutOfRange, setQ36ResultPotOutOfRange] = useState(TLSMonitoring_Msgs.TestCompleteTxtEmptyMsg);
    const [q36ResultUricOutOfRange, setQ36ResultUricOutOfRange] = useState(TLSMonitoring_Msgs.TestCompleteTxtEmptyMsg);

    const [indexDate, setIndexDate] = useState('[Index Date]');
    const [index_dt_minus_2mo, setIndexDateMinus2Months] = useState('[index_date] - 2 months');
    const [venetoclaxStartDate, setVenetoclaxStartDate] = useState('[Venetoclax Start Date]');
    const [venetoclaxEndDate, setVenetoclaxEndDate] = useState('[Venetoclax End Date]');



    const InitialSoftErrList = {
        radiographic_evaluationsSoft: false,
    };

    const InitialHardErrList = {
        radiographic_evaluationsEmptyHard: false,
        radiographic_evaluationsTxtEmptyHard: false,

        testCompleteEmptyHard: false,
        testCompleteTxtEmptyHard: false,
        testCompleteTxtEmpty: false,

    };

    const [softErrList, setSoftErrList] = useState(InitialSoftErrList);
    const [hardErrList, setHardErrList] = useState(InitialHardErrList);

    const [previousTestDate, setPreviousTestDate] = useState("");
    const newBloodTestDetails = {
        //Q36
        tlsbloodtestdetailsbridgeid: 0,

        //T1 - col A
        blooddraw_date_checked: null,
        blooddraw_date: null,
        blooddraw_date_unk: null,
        q36T1AEmpty: false,
        q36T1ADateEmpty: false,
        q36T1ADateCheckError: false,
        q36T1ADateVenCheckError: false,
        q36T1AInvalidDtError: false,
        //T1 - col B
        reasonrepeattest: null,
        confirm_lab_hemolyzed: null,
        post_hemolyzed: null,
        inconclusive_hemolyzed: null,
        q36T1BEmpty: false,
        q36T1B1SubValueEmpty: false,
        q36T1B2SubValueEmpty: false,
        q36T1B3SubValueEmpty: false,
        q36T1ColBCSoft: false,
        //T1 - col C
        timeofblooddraw: null,
        blooddraw_oth_time_hr: "",
        blooddraw_oth_time_min: "",
        blooddraw_oth_time: "",
        q36T1CEmpty: false,
        q36T1CTimeEmpty: false,
        q36T1CHrsEmpty: false,
        q36T1CMinsEmpty: false,
        //T1 - col D
        testsetting: null,
        q36T1DEmpty: false,
        //T1 - col E
        tls_laboratory: null,
        tls_clinical: null,
        tls_laboratory_cairo: null,
        tls_clinical_cairo: null,
        tls_neither: null,
        tls_unk: null,
        q36T1EEmpty: false,
        //T1 - col F
        intervention_given: null,
        yes_dosehold: false,
        yes_ivhydration: false,
        yes_metabolic: false,
        yes_oth: false,
        yes_oth_txt: null,
        q36T1FEmpty: false,
        q36T1FChildEmpty: false,
        q36T1FOtherTxtEmpty: false,

        //T2 - Alc
        alc_test_performed: null,
        alc_test_result_checked: null,
        alc_formula_type: null,
        alc_formula_type_oth: null,
        alc_test_result: "",
        alc_test_result_unk: null,
        q36T2BAlcEmpty: false,
        q36T2DAlcEmpty: false,
        q36T2DAlcResultEmpty: false,
        q36T2DAlcAllUnknownEmpty: false,
        q36T2BAlcAllUnkNotTestedEmpty: false,
        q36T2DAlcResultRange: false,

        //T2 - Calcium
        cal_test_performed: null,
        cal_test_result_checked: null,
        cal_formula_type: null,
        cal_formula_type_oth: null,
        cal_test_result: "",
        cal_test_result_unk: null,
        q36T2BCalEmpty: false,
        q36T2DCalEmpty: false,
        q36T2DCalResultEmpty: false,
        q36T2DCalAllUnknownEmpty: false,
        q36T2BCalAllUnkNotTestedEmpty: false,
        q36T2DCalResultRange: false,

        //T2 - Serum creatinine
        sc_test_performed: null,
        sc_test_result_checked: null,
        sc_formula_type: null,
        sc_formula_type_oth: null,
        sc_test_result: "",
        sc_test_result_unk: null,
        q36T2BScEmpty: false,
        q36T2DScEmpty: false,
        q36T2DScResultEmpty: false,
        q36T2DScAllUnknownEmpty: false,
        q36T2BScAllUnkNotTestedEmpty: false,
        q36T2DScResultRange: false,

        //T2 - Creatinine clearance level 
        crcl_test_performed: null,
        crcl_test_result_checked: null,
        crcl_formula_type: null,
        crcl_formula_type_oth: null,
        crcl_test_result: "",
        crcl_test_result_unk: null,
        q36T2BCrclEmpty: false,
        q36T2CCrclEmpty: false,
        q36T2CCrclOthTxtEmpty: false,
        q36T2DCrclEmpty: false,
        q36T2DCrclResultEmpty: false,
        q36T2DCrclAllUnknownEmpty: false,
        q36T2BCrclAllUnkNotTestedEmpty: false,
        q36T2DCrclResultRange: false,

        //T2 - Serum lactate dehydrogenase  
        ldh_test_performed: null,
        ldh_test_result_checked: null,
        ldh_formula_type: null,
        ldh_formula_type_oth: null,
        ldh_test_result: "",
        ldh_test_result_unk: null,
        q36T2BLdhEmpty: false,
        q36T2DLdhEmpty: false,
        q36T2DLdhResultEmpty: false,
        q36T2DLdhAllUnknownEmpty: false,
        q36T2BLdhAllUnkNotTestedEmpty: false,
        q36T2DLdhResultRange: false,

        //T2 - Phosphorus
        pho_test_performed: null,
        pho_test_result_checked: null,
        pho_formula_type: null,
        pho_formula_type_oth: null,
        pho_test_result: "",
        pho_test_result_unk: null,
        q36T2BPhoEmpty: false,
        q36T2DPhoEmpty: false,
        q36T2DPhoResultEmpty: false,
        q36T2DPhoAllUnknownEmpty: false,
        q36T2BPhoAllUnkNotTestedEmpty: false,
        q36T2DPhoResultRange: false,

        //T2 - Potassium
        pot_test_performed: null,
        pot_test_result_checked: null,
        pot_formula_type: null,
        pot_formula_type_oth: null,
        pot_test_result: "",
        pot_test_result_unk: null,
        q36T2BPotEmpty: false,
        q36T2DPotEmpty: false,
        q36T2DPotResultEmpty: false,
        q36T2DPotAllUnknownEmpty: false,
        q36T2BPotAllUnkNotTestedEmpty: false,
        q36T2DPotResultRange: false,

        //T2 - Uric acid
        uric_test_performed: null,
        uric_test_result_checked: null,
        uric_formula_type: null,
        uric_formula_type_oth: null,
        uric_test_result: "",
        uric_test_result_unk: null,
        q36T2BUricEmpty: false,
        q36T2DUricEmpty: false,
        q36T2DUricResultEmpty: false,
        q36T2DUricAllUnknownEmpty: false,
        q36T2BUricAllUnkNotTestedEmpty: false,
        q36T2DUricResultRange: false,

    };

    //question 36 state handling properties
    const BloodTestRow = {
        //Q35 Parent properties
        tlsmonitoringweekbridgeid: 0,
        tlsmonitoringid: 0,
        bloodtest_count_checked: null,
        bloodtest_count: "",
        bloodtest_count_unk: null,
        bloodTestCompleteEmpty: false,
        bloodTestCountEmpty: false,
        bloodTestOutOfRangeError: false,

        // Sublist of blood test details
        bloodTestDetails: {
            //Q36
            tlsbloodtestdetailsbridgeid: 0,

            //T1 - col A
            blooddraw_date_checked: null,
            blooddraw_date: null,
            blooddraw_date_unk: null,
            q36T1AEmpty: false,
            q36T1ADateEmpty: false,
            q36T1ADateCheckError: false,
            q36T1ADateVenCheckError: false,
            q36T1AInvalidDtError: false,
            //T1 - col B
            reasonrepeattest: null,
            confirm_lab_hemolyzed: null,
            post_hemolyzed: null,
            inconclusive_hemolyzed: null,
            q36T1BEmpty: false,
            q36T1B1SubValueEmpty: false,
            q36T1B2SubValueEmpty: false,
            q36T1B3SubValueEmpty: false,
            q36T1ColBCSoft: false,
            //T1 - col C
            timeofblooddraw: null,
            blooddraw_oth_time_hr: "",
            blooddraw_oth_time_min: "",
            blooddraw_oth_time: "",
            q36T1CEmpty: false,
            q36T1CTimeEmpty: false,
            q36T1CHrsEmpty: false,
            q36T1CMinsEmpty: false,
            //T1 - col D
            testsetting: null,
            q36T1DEmpty: false,
            //T1 - col E
            tls_laboratory: null,
            tls_clinical: null,
            tls_laboratory_cairo: null,
            tls_clinical_cairo: null,
            tls_neither: null,
            tls_unk: null,
            q36T1EEmpty: false,
            //T1 - col F
            intervention_given: null,
            yes_dosehold: false,
            yes_ivhydration: false,
            yes_metabolic: false,
            yes_oth: false,
            yes_oth_txt: null,
            q36T1FEmpty: false,
            q36T1FChildEmpty: false,
            q36T1FOtherTxtEmpty: false,

            //T2 - Alc
            alc_test_performed: null,
            alc_test_result_checked: null,
            alc_formula_type: null,
            alc_formula_type_oth: null,
            alc_test_result: "",
            alc_test_result_unk: null,
            q36T2BAlcEmpty: false,
            q36T2DAlcEmpty: false,
            q36T2DAlcResultEmpty: false,
            q36T2DAlcAllUnknownEmpty: false,
            q36T2BAlcAllUnkNotTestedEmpty: false,
            q36T2DAlcResultRange: false,

            //T2 - Calcium
            cal_test_performed: null,
            cal_test_result_checked: null,
            cal_formula_type: null,
            cal_formula_type_oth: null,
            cal_test_result: "",
            cal_test_result_unk: null,
            q36T2BCalEmpty: false,
            q36T2DCalEmpty: false,
            q36T2DCalResultEmpty: false,
            q36T2DCalAllUnknownEmpty: false,
            q36T2BCalAllUnkNotTestedEmpty: false,
            q36T2DCalResultRange: false,

            //T2 - Serum creatinine
            sc_test_performed: null,
            sc_test_result_checked: null,
            sc_formula_type: null,
            sc_formula_type_oth: null,
            sc_test_result: "",
            sc_test_result_unk: null,
            q36T2BScEmpty: false,
            q36T2DScEmpty: false,
            q36T2DScResultEmpty: false,
            q36T2DScAllUnknownEmpty: false,
            q36T2BScAllUnkNotTestedEmpty: false,
            q36T2DScResultRange: false,

            //T2 - Creatinine clearance level 
            crcl_test_performed: null,
            crcl_test_result_checked: null,
            crcl_formula_type: null,
            crcl_formula_type_oth: null,
            crcl_test_result: "",
            crcl_test_result_unk: null,
            q36T2BCrclEmpty: false,
            q36T2CCrclEmpty: false,
            q36T2CCrclOthTxtEmpty: false,
            q36T2DCrclEmpty: false,
            q36T2DCrclResultEmpty: false,
            q36T2DCrclAllUnknownEmpty: false,
            q36T2BCrclAllUnkNotTestedEmpty: false,
            q36T2DCrclResultRange: false,

            //T2 - Serum lactate dehydrogenase  
            ldh_test_performed: null,
            ldh_test_result_checked: null,
            ldh_formula_type: null,
            ldh_formula_type_oth: null,
            ldh_test_result: "",
            ldh_test_result_unk: null,
            q36T2BLdhEmpty: false,
            q36T2DLdhEmpty: false,
            q36T2DLdhResultEmpty: false,
            q36T2DLdhAllUnknownEmpty: false,
            q36T2BLdhAllUnkNotTestedEmpty: false,
            q36T2DLdhResultRange: false,

            //T2 - Phosphorus
            pho_test_performed: null,
            pho_test_result_checked: null,
            pho_formula_type: null,
            pho_formula_type_oth: null,
            pho_test_result: "",
            pho_test_result_unk: null,
            q36T2BPhoEmpty: false,
            q36T2DPhoEmpty: false,
            q36T2DPhoResultEmpty: false,
            q36T2DPhoAllUnknownEmpty: false,
            q36T2BPhoAllUnkNotTestedEmpty: false,
            q36T2DPhoResultRange: false,

            //T2 - Potassium
            pot_test_performed: null,
            pot_test_result_checked: null,
            pot_formula_type: null,
            pot_formula_type_oth: null,
            pot_test_result: "",
            pot_test_result_unk: null,
            q36T2BPotEmpty: false,
            q36T2DPotEmpty: false,
            q36T2DPotResultEmpty: false,
            q36T2DPotAllUnknownEmpty: false,
            q36T2BPotAllUnkNotTestedEmpty: false,
            q36T2DPotResultRange: false,

            //T2 - Uric acid
            uric_test_performed: null,
            uric_test_result_checked: null,
            uric_formula_type: null,
            uric_formula_type_oth: null,
            uric_test_result: "",
            uric_test_result_unk: null,
            q36T2BUricEmpty: false,
            q36T2DUricEmpty: false,
            q36T2DUricResultEmpty: false,
            q36T2DUricAllUnknownEmpty: false,
            q36T2BUricAllUnkNotTestedEmpty: false,
            q36T2DUricResultRange: false,
        }
    };

    const [bloodTestRowData, setBloodTestRowData] = useState();

    const mapBloodTestRowDataToTlsBloodTestModelSave = (bloodTestRowData) => {
        return bloodTestRowData.map((row) => ({
            // Q35 Parent properties
            Tlsmonitoringweekbridgeid: row.tlsmonitoringweekbridgeid,
            BloodtestCountChecked: row.bloodtest_count_checked,
            BloodtestCount: row.bloodtest_count,
            BloodtestCountUnk: row.bloodtest_count_unk,
            BloodTestCompleteEmpty: false,
            BloodTestCountEmpty: false,
            BloodTestOutOfRangeError: false,
            tlsmonitoringid: row.tlsmonitoringid,

            // Sublist of blood test details
            Tlsbloodtestdetails: Array.isArray(row.bloodTestDetails)
                ? row.bloodTestDetails.map((detail) => {
                    // Parse and format hours and minutes
                    const hours = detail.blooddraw_oth_time_hr ? parseInt(detail.blooddraw_oth_time_hr, 10) : 0;
                    const minutes = detail.blooddraw_oth_time_min ? parseInt(detail.blooddraw_oth_time_min, 10) : 0;

                    // Create time string if hours or minutes are present
                    const blooddraw_oth_time = (detail.blooddraw_oth_time_hr || detail.blooddraw_oth_time_min)
                        ? `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`
                        : ""; // If neither are present, return an empty 
                    return {
                        // Q36
                        Tlsbloodtestdetailsbridgeid: detail.tlsbloodtestdetailsbridgeid,

                        // T1 - col A
                        BlooddrawDateChecked: detail.blooddraw_date_checked,
                        BlooddrawDate: detail.blooddraw_date,
                        BlooddrawDateUnk: detail.blooddraw_date_unk,

                        // T1 - col B
                        Reasonrepeattest: detail.reasonrepeattest,
                        ConfirmLabHemolyzed: detail.confirm_lab_hemolyzed,
                        PostHemolyzed: detail.post_hemolyzed,
                        InconclusiveHemolyzed: detail.inconclusive_hemolyzed || null,

                        // T1 - col C
                        Timeofblooddraw: detail.timeofblooddraw,
                        BlooddrawOthTime: blooddraw_oth_time,

                        // T1 - col D
                        Testsetting: detail.testsetting,

                        // T1 - col E
                        TlsLaboratory: detail.tls_laboratory,
                        TlsClinical: detail.tls_clinical,
                        TlsLaboratoryCairo: detail.tls_laboratory_cairo,
                        TlsClinicalCairo: detail.tls_clinical_cairo,
                        TlsNeither: detail.tls_neither,
                        TlsUnk: detail.tls_unk,

                        // T1 - col F
                        InterventionGiven: detail.intervention_given,
                        YesDosehold: detail.yes_dosehold,
                        YesIvhydration: detail.yes_ivhydration,
                        YesMetabolic: detail.yes_metabolic,
                        YesOth: detail.yes_oth,
                        YesOthTxt: detail.yes_oth_txt,

                        // T2 - Alc
                        ALCTestperformed: detail.alc_test_performed,
                        ALCTestresultint: null,
                        ALCFormulaType: null,
                        ALCFormulaTypeOth: null,
                        ALCTestresult: detail.alc_test_result,
                        ALCResultUnk: detail.alc_test_result_unk,

                        // T2 - Calcium
                        CalTestperformed: detail.cal_test_performed,
                        CalTestresultint: null,
                        CalFormulaType: null,
                        CalFormulaTypeOth: null,
                        CalTestresult: detail.cal_test_result,
                        CalResultUnk: detail.cal_test_result_unk,

                        // T2 - Serum creatinine
                        SCTestperformed: detail.sc_test_performed,
                        SCTestresultint: null,
                        SCFormulaType: null,
                        SCFormulaTypeOth: null,
                        SCTestresult: detail.sc_test_result,
                        SCResultUnk: detail.sc_test_result_unk,

                        // T2 - Creatinine clearance level
                        CrclTestperformed: detail.crcl_test_performed,
                        CrclTestresultint: null,
                        CrclFormulatype: detail.crcl_formula_type,
                        CrclOthTxt: detail.crcl_formula_type_oth,
                        CrclTestresult: detail.crcl_test_result,
                        CrclResultUnk: detail.crcl_test_result_unk,

                        // T2 - Serum lactate dehydrogenase
                        LdhTestperformed: detail.ldh_test_performed,
                        LdhTestresultint: null,
                        LdhFormulaType: null,
                        LdhFormulaTypeOth: null,
                        LdhTestresult: detail.ldh_test_result,
                        LdhResultUnk: detail.ldh_test_result_unk,

                        // T2 - Phosphorus
                        PhoTestperformed: detail.pho_test_performed,
                        PhoTestresultint: null,
                        PhoFormulaType: null,
                        PhoFormulaTypeOth: null,
                        PhoTestresult: detail.pho_test_result,
                        PhoResultUnk: detail.pho_test_result_unk,

                        // T2 - Potassium
                        PotTestperformed: detail.pot_test_performed,
                        PotTestresultint: null,
                        PotFormulaType: null,
                        PotFormulaTypeOth: null,
                        PotTestresult: detail.pot_test_result,
                        PotResultUnk: detail.pot_test_result_unk,

                        // T2 - Uric acid
                        UricTestperformed: detail.uric_test_performed,
                        UricTestresultint: null,
                        UricFormulaType: null,
                        UricFormulaTypeOth: null,
                        UricTestresult: detail.uric_test_result,
                        UricResultUnk: detail.uric_test_result_unk,
                    };
                })
                : [row.bloodTestDetails], // If bloodTestDetails is not an array, return an empty array
        }));
    };

    const mapTlsBloodTestDataToBloodTestRowDataLoad = (tlsBloodTestData) => {
        return tlsBloodTestData.map((data) => ({
            // Q35 Parent properties
            bloodtest_count_checked: data.bloodtestCount != null ? 1 : null,
            bloodtest_count: data.bloodtestCount,
            bloodtest_count_unk: data.bloodtestCountUnk ? Values.value99 : null,
            bloodTestCompleteEmpty: false,
            bloodTestCountEmpty: false,
            bloodTestOutOfRangeError: false,
            tlsmonitoringid: data.tlsmonitoringid,
            tlsmonitoringweekbridgeid: data.tlsmonitoringweekbridgeid,

            bloodTestDetails: Array.isArray(data.tlsbloodtestdetails) && data.tlsbloodtestdetails.length > 0
                ? data.tlsbloodtestdetails.map((detail) => ({
                    // Q36
                    tlsbloodtestdetailsbridgeid: detail.tlsbloodtestdetailsbridgeid,

                    // T1 - col A
                    blooddraw_date_checked: detail.blooddrawDate ? 1 : null,
                    blooddraw_date: detail.blooddrawDate,
                    blooddraw_date_unk: detail.blooddrawDateUnk === true ?Values.value99 : null,
                    q36T1AEmpty: false,
                    q36T1ADateEmpty: false,
                    q36T1ADateCheckError: false,
                    q36T1ADateVenCheckError: false,
                    q36T1AInvalidDtError: false,

                    // T1 - col B
                    reasonrepeattest: detail.reasonrepeattest,
                    confirm_lab_hemolyzed: detail.confirmLabHemolyzed,
                    post_hemolyzed: detail.postHemolyzed,
                    inconclusive_hemolyzed: detail.inconclusiveHemolyzed || null,
                    q36T1BEmpty: false,
                    q36T1B1SubValueEmpty: false,
                    q36T1B2SubValueEmpty: false,
                    q36T1B3SubValueEmpty: false,
                    q36T1ColBCSoft: false,

                    // T1 - col C
                    timeofblooddraw: detail.timeofblooddraw,
                    blooddraw_oth_time_hr: detail.blooddrawOthTime ? detail.blooddrawOthTime.split(":")[0] : "",
                    blooddraw_oth_time_min: detail.blooddrawOthTime ? detail.blooddrawOthTime.split(":")[1] : "",
                    q36T1CEmpty: false,
                    q36T1CTimeEmpty: false,
                    q36T1CHrsEmpty: false,
                    q36T1CMinsEmpty: false,

                    // T1 - col D
                    testsetting: detail.testsetting,
                    q36T1DEmpty: false,

                    // T1 - col E
                    tls_laboratory: detail.tlsLaboratory,
                    tls_clinical: detail.tlsClinical,
                    tls_laboratory_cairo: detail.tlsLaboratoryCairo,
                    tls_clinical_cairo: detail.tlsClinicalCairo,
                    tls_neither: detail.tlsNeither,
                    tls_unk: detail.tlsUnk,
                    q36T1EEmpty: false,

                    // T1 - col F
                    intervention_given: detail.interventionGiven,
                    yes_dosehold: detail.yesDosehold,
                    yes_ivhydration: detail.yesIvhydration,
                    yes_metabolic: detail.yesMetabolic,
                    yes_oth: detail.yesOth,
                    yes_oth_txt: detail.yesOthTxt,
                    q36T1FEmpty: false,
                    q36T1FChildEmpty: false,
                    q36T1FOtherTxtEmpty: false,

                    // // T2 - Alc
                    // alc_test_performed: detail.alcTestperformed,
                    // alc_test_result_checked: detail.alcTestresult ? 1 : null,
                    // alc_test_result: detail.alcTestresult,
                    // alc_test_result_unk: detail.alcResultUnk ?Values.value99 : null,
                    // q36T2BAlcEmpty: false,
                    // q36T2BAlcAllUnkNotTestedEmpty: false,
                    // q36T2DAlcEmpty: false,
                    // q36T2DAlcResultEmpty: false,
                    // q36T2DAlcAllUnknownEmpty: false,
                    // q36T2DAlcResultRange: false,
                    // T2 - Alc
                    alc_test_performed: detail.alcTestperformed,
                    alc_test_result_checked: detail.alcTestresult ? 1 : null,
                    alc_test_result: detail.alcTestresult,
                    alc_test_result_unk: detail.alcResultUnk ?Values.value99 : null,
                    q36T2BAlcEmpty: false,
                    q36T2BAlcAllUnkNotTestedEmpty: false,
                    q36T2DAlcEmpty: false,
                    q36T2DAlcResultEmpty: false,
                    q36T2DAlcAllUnknownEmpty: false,
                    q36T2DAlcResultRange: false,

                    // T2 - Calcium
                    cal_test_performed: detail.calTestperformed,
                    cal_test_result_checked: detail.calTestresult != null ? 1 : null,
                    cal_test_result: detail.calTestresult,
                    cal_test_result_unk: detail.calResultUnk ?Values.value99 : null,
                    q36T2BCalEmpty: false,
                    q36T2BCalAllUnkNotTestedEmpty: false,
                    q36T2DCalEmpty: false,
                    q36T2DCalResultEmpty: false,
                    q36T2DCalAllUnknownEmpty: false,
                    q36T2DCalResultRange: false,

                    // T2 - Serum creatinine
                    sc_test_performed: detail.scTestperformed,
                    sc_test_result_checked: detail.scTestresult != null ? 1 : null,
                    sc_test_result: detail.scTestresult,
                    sc_test_result_unk: detail.scResultUnk ?Values.value99 : null,
                    q36T2BScEmpty: false,
                    q36T2BScAllUnkNotTestedEmpty: false,
                    q36T2DScEmpty: false,
                    q36T2DScResultEmpty: false,
                    q36T2DScAllUnknownEmpty: false,
                    q36T2DScResultRange: false,

                    // T2 - Creatinine clearance level 
                    crcl_test_performed: detail.crclTestperformed,
                    crcl_test_result_checked: detail.crclTestresult != null ? 1 : null,
                    crcl_formula_type: detail.crclFormulatype,
                    crcl_formula_type_oth: detail.crclOthTxt,
                    crcl_test_result: detail.crclTestresult,
                    crcl_test_result_unk: detail.crclResultUnk ?Values.value99 : null,
                    q36T2BCrclEmpty: false,
                    q36T2CCrclEmpty: false,
                    q36T2CCrclOthTxtEmpty: false,
                    q36T2DCrclEmpty: false,
                    q36T2DCrclResultEmpty: false,
                    q36T2DCrclAllUnknownEmpty: false,
                    q36T2BCrclAllUnkNotTestedEmpty: false,
                    q36T2DCrclResultRange: false,

                    // T2 - Serum lactate dehydrogenase  
                    ldh_test_performed: detail.ldhTestperformed,
                    ldh_test_result_checked: detail.ldhTestresult != null ? 1 : null,
                    ldh_test_result: detail.ldhTestresult,
                    ldh_test_result_unk: detail.ldhResultUnk ?Values.value99 : null,
                    q36T2BLdhEmpty: false,
                    q36T2BLdhAllUnkNotTestedEmpty: false,
                    q36T2DLdhEmpty: false,
                    q36T2DLdhResultEmpty: false,
                    q36T2DLdhAllUnknownEmpty: false,
                    q36T2DLdhResultRange: false,

                    // T2 - Phosphorus
                    pho_test_performed: detail.phoTestperformed,
                    pho_test_result_checked: detail.phoTestresult != null ? 1 : null,
                    pho_test_result: detail.phoTestresult,
                    pho_test_result_unk: detail.phoResultUnk ?Values.value99 : null,
                    q36T2BPhoEmpty: false,
                    q36T2BPhoAllUnkNotTestedEmpty: false,
                    q36T2DPhoEmpty: false,
                    q36T2DPhoResultEmpty: false,
                    q36T2DPhoAllUnknownEmpty: false,
                    q36T2DPhoResultRange: false,

                    // T2 - Potassium
                    pot_test_performed: detail.potTestperformed,
                    pot_test_result_checked: detail.potTestresult != null ? 1 : null,
                    pot_test_result: detail.potTestresult,
                    pot_test_result_unk: detail.potResultUnk ?Values.value99 : null,
                    q36T2BPotEmpty: false,
                    q36T2BPotAllUnkNotTestedEmpty: false,
                    q36T2DPotEmpty: false,
                    q36T2DPotResultEmpty: false,
                    q36T2DPotAllUnknownEmpty: false,
                    q36T2DPotResultRange: false,

                    // T2 - Uric acid
                    uric_test_performed: detail.uricTestperformed,
                    uric_test_result_checked: detail.uricTestresult != null ? 1 : null,
                    uric_test_result: detail.uricTestresult,
                    uric_test_result_unk: detail.uricResultUnk ?Values.value99 : null,
                    q36T2BUricEmpty: false,
                    q36T2BUricAllUnkNotTestedEmpty: false,
                    q36T2DUricEmpty: false,
                    q36T2DUricResultEmpty: false,
                    q36T2DUricAllUnknownEmpty: false,
                    q36T2DUricResultRange: false,

                }))
                : [data.tlsbloodtestdetails].map((detail) => ({
                    // Q36
                    tlsbloodtestdetailsbridgeid: detail.tlsbloodtestdetailsbridgeid,

                    // T1 - col A
                    blooddraw_date_checked: detail.blooddrawDate ? 1 : null,
                    blooddraw_date: detail.blooddrawDate,
                    blooddraw_date_unk: detail.blooddrawDateUnk === true ?Values.value99 : null,
                    q36T1AEmpty: false,
                    q36T1ADateEmpty: false,
                    q36T1ADateCheckError: false,
                    q36T1ADateVenCheckError: false,
                    q36T1AInvalidDtError: false,

                    // T1 - col B
                    reasonrepeattest: detail.reasonrepeattest,
                    confirm_lab_hemolyzed: detail.confirmLabHemolyzed,
                    post_hemolyzed: detail.postHemolyzed,
                    inconclusive_hemolyzed: detail.inconclusiveHemolyzed || null,
                    q36T1BEmpty: false,
                    q36T1B1SubValueEmpty: false,
                    q36T1B2SubValueEmpty: false,
                    q36T1B3SubValueEmpty: false,
                    q36T1ColBCSoft: false,

                    // T1 - col C
                    timeofblooddraw: detail.timeofblooddraw,
                    blooddraw_oth_time_hr: detail.blooddrawOthTime ? detail.blooddrawOthTime.split(":")[0] : "",
                    blooddraw_oth_time_min: detail.blooddrawOthTime ? detail.blooddrawOthTime.split(":")[1] : "",
                    q36T1CEmpty: false,
                    q36T1CTimeEmpty: false,
                    q36T1CHrsEmpty: false,
                    q36T1CMinsEmpty: false,

                    // T1 - col D
                    testsetting: detail.testsetting,
                    q36T1DEmpty: false,

                    // T1 - col E
                    tls_laboratory: detail.tlsLaboratory,
                    tls_clinical: detail.tlsClinical,
                    tls_laboratory_cairo: detail.tlsLaboratoryCairo,
                    tls_clinical_cairo: detail.tlsClinicalCairo,
                    tls_neither: detail.tlsNeither,
                    tls_unk: detail.tlsUnk,
                    q36T1EEmpty: false,

                    // T1 - col F
                    intervention_given: detail.interventionGiven,
                    yes_dosehold: detail.yesDosehold,
                    yes_ivhydration: detail.yesIvhydration,
                    yes_metabolic: detail.yesMetabolic,
                    yes_oth: detail.yesOth,
                    yes_oth_txt: detail.yesOthTxt,
                    q36T1FEmpty: false,
                    q36T1FChildEmpty: false,
                    q36T1FOtherTxtEmpty: false,

                    // T2 - Alc
                    alc_test_performed: detail.alcTestperformed,
                    alc_test_result_checked: detail.alcTestresult ? 1 : null,
                    alc_test_result: detail.alcTestresult,
                    alc_test_result_unk: detail.alcResultUnk ?Values.value99 : null,
                    q36T2BAlcEmpty: false,
                    q36T2BAlcAllUnkNotTestedEmpty: false,
                    q36T2DAlcEmpty: false,
                    q36T2DAlcResultEmpty: false,
                    q36T2DAlcAllUnknownEmpty: false,
                    q36T2DAlcResultRange: false,

                    // T2 - Calcium
                    cal_test_performed: detail.calTestperformed,
                    cal_test_result_checked: detail.calTestresult != null ? 1 : null,
                    cal_test_result: detail.calTestresult,
                    cal_test_result_unk: detail.calResultUnk ?Values.value99 : null,
                    q36T2BCalEmpty: false,
                    q36T2BCalAllUnkNotTestedEmpty: false,
                    q36T2DCalEmpty: false,
                    q36T2DCalResultEmpty: false,
                    q36T2DCalAllUnknownEmpty: false,
                    q36T2DCalResultRange: false,

                    // T2 - Serum creatinine
                    sc_test_performed: detail.scTestperformed,
                    sc_test_result_checked: detail.scTestresultint != null ? 1 : null,
                    sc_test_result: detail.scTestresultint,
                    sc_test_result_unk: detail.scResultUnk ?Values.value99 : null,
                    q36T2BScEmpty: false,
                    q36T2BScAllUnkNotTestedEmpty: false,
                    q36T2DScEmpty: false,
                    q36T2DScResultEmpty: false,
                    q36T2DScAllUnknownEmpty: false,
                    q36T2DScResultRange: false,

                    // T2 - Creatinine clearance level 
                    crcl_test_performed: detail.crclTestperformed,
                    crcl_test_result_checked: detail.crclTestresultint != null ? 1 : null,
                    crcl_formula_type: detail.crclFormulatype,
                    crcl_formula_type_oth: detail.crclOthTxt,
                    crcl_test_result: detail.crclTestresultint,
                    crcl_test_result_unk: detail.crclResultUnk ?Values.value99 : null,
                    q36T2BCrclEmpty: false,
                    q36T2CCrclEmpty: false,
                    q36T2CCrclOthTxtEmpty: false,
                    q36T2DCrclEmpty: false,
                    q36T2DCrclResultEmpty: false,
                    q36T2DCrclAllUnknownEmpty: false,
                    q36T2BCrclAllUnkNotTestedEmpty: false,
                    q36T2DCrclResultRange: false,

                    // T2 - Serum lactate dehydrogenase  
                    ldh_test_performed: detail.ldhTestperformed,
                    ldh_test_result_checked: detail.ldhTestresultint != null ? 1 : null,
                    ldh_test_result: detail.ldhTestresultint,
                    ldh_test_result_unk: detail.ldhResultUnk ?Values.value99 : null,
                    q36T2BLdhEmpty: false,
                    q36T2BLdhAllUnkNotTestedEmpty: false,
                    q36T2DLdhEmpty: false,
                    q36T2DLdhResultEmpty: false,
                    q36T2DLdhAllUnknownEmpty: false,
                    q36T2DLdhResultRange: false,

                    // T2 - Phosphorus
                    pho_test_performed: detail.phoTestperformed,
                    pho_test_result_checked: detail.phoTestresultint != null ? 1 : null,
                    pho_test_result: detail.phoTestresultint,
                    pho_test_result_unk: detail.phoResultUnk ?Values.value99 : null,
                    q36T2BPhoEmpty: false,
                    q36T2BPhoAllUnkNotTestedEmpty: false,
                    q36T2DPhoEmpty: false,
                    q36T2DPhoResultEmpty: false,
                    q36T2DPhoAllUnknownEmpty: false,
                    q36T2DPhoResultRange: false,

                    // T2 - Potassium
                    pot_test_performed: detail.potTestperformed,
                    pot_test_result_checked: detail.potTestresultint != null ? 1 : null,
                    pot_test_result: detail.potTestresultint,
                    pot_test_result_unk: detail.potResultUnk ?Values.value99 : null,
                    q36T2BPotEmpty: false,
                    q36T2BPotAllUnkNotTestedEmpty: false,
                    q36T2DPotEmpty: false,
                    q36T2DPotResultEmpty: false,
                    q36T2DPotAllUnknownEmpty: false,
                    q36T2DPotResultRange: false,

                    // T2 - Uric acid
                    uric_test_performed: detail.uricTestperformed,
                    uric_test_result_checked: detail.uricTestresultint != null ? 1 : null,
                    uric_test_result: detail.uricTestresultint,
                    uric_test_result_unk: detail.uricResultUnk ?Values.value99 : null,
                    q36T2BUricEmpty: false,
                    q36T2BUricAllUnkNotTestedEmpty: false,
                    q36T2DUricEmpty: false,
                    q36T2DUricResultEmpty: false,
                    q36T2DUricAllUnknownEmpty: false,
                    q36T2DUricResultRange: false,

                    // Continue with other T2 fields for Calcium, Serum creatinine, Creatinine clearance level, etc...
                }))

        }));
    };

    const LoadData = async () => {
        try {
            if (pid > 0) {
                const response = await TLSMonitoringService.LoadTLSMonitoringLabTestData(
                    pid, currWeekNo, testNum
                );
                if (response?.status === HTTPResponse.OK) {
                    const { data } = response;

                    setPreviousTestDate(data.prevTestDate);
                    setIndexDate(data.indexDate);
                    setVenetoclaxStartDate(data.venetoclaxStartDate || "[Venetoclax Start Date]");
                    setVenetoclaxEndDate(data.venetoclaxEndDate || "[Venetoclax End Date]");
                    const indexDtMinus2MoObj = SubtractMonths(data.indexDate, 2);
                    const indexDtMinus2Mo = DateDisplayFormat(indexDtMinus2MoObj);

                    setIndexDateMinus2Months(indexDtMinus2Mo || '[index_date] - 2 months');

                    if (data.tlsBloodTestData != null &&
                        data.tlsBloodTestData.length > 0) {
                        const { tlsBloodTestData } = data;

                        const bloodTestData = mapTlsBloodTestDataToBloodTestRowDataLoad(tlsBloodTestData);
                        setBloodTestRowData(bloodTestData);

                    }
                    else {
                        const bloodTestData = mapTlsBloodTestDataToBloodTestRowDataLoad(bloodTestRowData);
                        setBloodTestRowData(bloodTestData);
                    }

                } else if (response?.status === HTTPResponse.Unauthorized) {
                    HandleSessionTimeout();
                }
            }
        } catch (error) {
            ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
        }
    };

    const SavePage = async (validate) => {
        try {
            ToggleLoader(true);
            setSoftErrList(InitialSoftErrList);
            setHardErrList(InitialHardErrList);
            let isValid = true;

            isValid = validate ? await ValidateQ35Q36Repeater(bloodTestRowData) : true;

            if (isValid) {
                setShowTopErrMsg(false);

                const bloodTestRowDataToSave = bloodTestRowData.length > 0 ? mapBloodTestRowDataToTlsBloodTestModelSave(bloodTestRowData) : null;
                const tlsLabTestMonitoringModal = {
                    patientId: pid,
                    CurrentWeek: weekNum,
                    CurrentTest: testNum,
                    IsValidated: validate,
                    LoggedInUserId: GetLoggedInUserID(),
                    TlsBloodTestData: bloodTestRowDataToSave,
                };
                if (pid > 0) {
                    const response = await TLSMonitoringService.SaveTLSMonitoringLabTestDetails(
                        tlsLabTestMonitoringModal
                    );
                    ToggleLoader(false);
                    if (response.status !== HTTPResponse.OK) {
                        if (response?.status === HTTPResponse.Unauthorized) {
                            HandleSessionTimeout();
                        } else throw response.error;
                    } else {
                        await LoadData();
                        ToggleLoader(false);
                        ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
                    }
                }
                return true;
            }
            else {
                ToggleLoader(false);
                setShowTopErrMsg(true);
                return false;
            }
        } catch (error) {
            ToggleLoader(false);
            ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed);
        }
    };

    // useEffect(() => {
    //     async function FetchData() {
    //         if (!isNaN(pid) && Number(pid) !== 0) {
    //             setCurrWeekNo(weekNum);
    //             ToggleLoader(true);
    //             await LoadData();
    //             ToggleLoader(false);
    //         }
    //     }
    //     FetchData();
    // }, []);



    useEffect(() => {
        async function FetchData() {
            let tlsWeekTestCnt;
            let tlsWeekCnt;
            if (pid > 0 && parseInt(weekNum, 10) !== 0) {
                const response2 = await PatientService.GetPatientConfigData(pid);
                
                if (response2?.status === HTTPResponse.OK && response2?.data) {
                    const { data } = response2;
                    const { item4, item3 } = data;

                    if (item4?.length > 0) {
                        const labTests = item4
                            .filter(x => x.weeknum === Number(weekNum))
                            .map(x => (Array.isArray(x.tests) ? x.tests.length : 0))[0] || 0;

                        // Set the state for lab test count
                        tlsWeekTestCnt = labTests;
                        tlsWeekCnt = appState?.tlsWeekCnt;
                    } else if (item3?.length > 0) {
                        if (item3?.length > 0) {
                            const labTests = item3
                                .filter(x => x.weeknum === Number(weekNum))
                                .map(x => (Array.isArray(x.tests) ? x.tests.length : 0))[0] || 0;

                            // Set the state for lab test count
                            tlsWeekTestCnt = labTests;
                            tlsWeekCnt = appState?.tlsWeekCnt;
                        }
                    }
                }
            }

            //const { tlsWeekCnt, tlsWeekTestCnt } = appState ?? 0;
            const prevWk = Number(weekNum) - 1;
            const nextWk = Number(weekNum) + 1;
            const isFirstWk = testNum == 1;

            const isLastWkTst = tlsWeekTestCnt == testNum;
            const isLastWeek = tlsWeekCnt == weekNum;

            let currentTestId;
            let nextTestId;
            let prevTestId;
            let newPaths = { ...navigatePaths };
            
            if (tlsWeekTestCnt > 0) {
                if (currentUrl.includes("TlsLabTestMonitoring")) {
                    const matches = currentUrl.match(/(\d+)(?=[^0-9]*$)/);
                    currentTestId = matches ? Number(matches[0]) : 1;
                } else if (currentUrl.includes("TlsWeekMonitoring")) {
                    currentTestId = 1;
                }
                nextTestId = currentTestId + 1;
                prevTestId = currentTestId - 1;  // Increment testid if not at max
            }

            newPaths.prevPage = isFirstWk ? `/TlsWeekMonitoring/${Number(weekNum)}` : `/TlsLabTestMonitoring/${Number(weekNum)}/${prevTestId}`;
            newPaths.nextPage = (isLastWkTst && !isLastWeek) ? `/TlsWeekMonitoring/${Number(weekNum) + 1}` : (isLastWkTst && isLastWeek) ? `/TreatmentInterruptions` : `/TlsLabTestMonitoring/${Number(weekNum)}/${nextTestId}`;

            setNavPaths(newPaths);

            if (pid > 0 && weekNum != 0) {
                await LoadData();
            }
        }
        FetchData();
    }, [weekNum]);




    async function ValidateQ35Q36Repeater(list) {    
        // Your asynchronous processing logic for the list goes here
        const promises = list.map((item, index) => ValidateList(item, index));
        const results = await Promise.all(promises);
        return !results.includes(false);
    }

    async function ValidateList(item, index) {
        // Your asynchronous processing logic for an individual item
        return new Promise((resolve) => {
            setTimeout(() => {
                const isValid = ValidateRepeaterData(item, index);
                resolve(isValid);
            }, 1000); // Simulate async work
        });
    }

    async function ValidateRepeaterData(repeaterarray, index) {
        let result = true;

        //Q36
        // Validate each child item in bloodTestDetails (Q36)

        // Table 1: Col A - Blooddraw Date
        if (repeaterarray.bloodTestDetails[0].blooddraw_date_checked == null && repeaterarray.bloodTestDetails[0].blooddraw_date_unk == null) {
            result = false;
            repeaterarray.bloodTestDetails[0].q36T1AEmpty = true;
        } else {
            repeaterarray.bloodTestDetails[0].q36T1AEmpty = false;
            repeaterarray.bloodTestDetails[0].q36T1ADateCheckError = false;
            repeaterarray.bloodTestDetails[0].q36T1ADateVenCheckError = false;
            repeaterarray.bloodTestDetails[0].q36T1AInvalidDtError = false;
        }

        if (repeaterarray.bloodTestDetails[0].blooddraw_date_checked == 1 && (repeaterarray.bloodTestDetails[0].blooddraw_date != null || repeaterarray.bloodTestDetails[0].blooddraw_date != "") && repeaterarray.bloodTestDetails[0].blooddrawDate == "InValid Date") {
            result = false;
            repeaterarray.bloodTestDetails[0].q36T1AInvalidDtError = true;
        }
        else {
            repeaterarray.bloodTestDetails[0].q36T1AInvalidDtError = false;
        }

        if ((repeaterarray.bloodTestDetails[0].blooddraw_date == null || repeaterarray.bloodTestDetails[0].blooddraw_date == "") && repeaterarray.bloodTestDetails[0].blooddraw_date_checked == 1) {
            result = false;
            repeaterarray.bloodTestDetails[0].q36T1ADateEmpty = true;
        } else {
            repeaterarray.bloodTestDetails[0].q36T1ADateEmpty = false;
            repeaterarray.bloodTestDetails[0].q36T1ADateCheckError = false;
            repeaterarray.bloodTestDetails[0].q36T1ADateVenCheckError = false;
        }


        // Table 1: Col B - Reason Repeat Test, Hemolyzed values
        if (testNum > 1) {
            if (repeaterarray.bloodTestDetails[0].reasonrepeattest == null &&
                (repeaterarray.bloodTestDetails[0].confirm_lab_hemolyzed == null || repeaterarray.bloodTestDetails[0].post_hemolyzed == null || repeaterarray.bloodTestDetails[0].inconclusive_hemolyzed == null)) {
                result = false;
                repeaterarray.bloodTestDetails[0].q36T1BEmpty = true;
            } else {
                repeaterarray.bloodTestDetails[0].q36T1BEmpty = false;
            }

            if (repeaterarray.bloodTestDetails[0].reasonrepeattest == Values.value2 && repeaterarray.bloodTestDetails[0].confirm_lab_hemolyzed == null) {
                result = false;
                repeaterarray.bloodTestDetails[0].q36T1B1SubValueEmpty = true;
            } else {
                repeaterarray.bloodTestDetails[0].q36T1B1SubValueEmpty = false;
            }

            if (repeaterarray.bloodTestDetails[0].reasonrepeattest == Values.value3 && repeaterarray.bloodTestDetails[0].post_hemolyzed == null) {
                result = false;
                repeaterarray.bloodTestDetails[0].q36T1B2SubValueEmpty = true;
            } else {
                repeaterarray.bloodTestDetails[0].q36T1B2SubValueEmpty = false;
            }

            if (repeaterarray.bloodTestDetails[0].reasonrepeattest == Values.value4 && repeaterarray.bloodTestDetails[0].inconclusive_hemolyzed == null) {
                result = false;
                repeaterarray.bloodTestDetails[0].q36T1B3SubValueEmpty = true;
            } else {
                repeaterarray.bloodTestDetails[0].q36T1B3SubValueEmpty = false;
            }
        }
       
        // Table 1: Col C - Time of Blood Draw
        if (repeaterarray.bloodTestDetails[0].timeofblooddraw == null) {
            result = false;
            repeaterarray.bloodTestDetails[0].q36T1CEmpty = true;
        } else {
            repeaterarray.bloodTestDetails[0].q36T1CEmpty = false;
        }

        if (repeaterarray.bloodTestDetails[0].timeofblooddraw === Values.value4 
            // && (repeaterarray.bloodTestDetails[0].blooddraw_oth_time === null || repeaterarray.bloodTestDetails[0].blooddraw_oth_time === "")
            && ((repeaterarray.bloodTestDetails[0].blooddraw_oth_time_hr == null || repeaterarray.bloodTestDetails[0].blooddraw_oth_time_hr == "") 
                && (repeaterarray.bloodTestDetails[0].blooddraw_oth_time_min == null || repeaterarray.bloodTestDetails[0].blooddraw_oth_time_min == "")
            )
        ) {
            result = false;
            repeaterarray.bloodTestDetails[0].q36T1CTimeEmpty = true;
        } else {
            repeaterarray.bloodTestDetails[0].q36T1CTimeEmpty = false;
        }

        // Table 1: Col D - Test Setting
        if (repeaterarray.bloodTestDetails[0].testsetting == null) {
            result = false;
            repeaterarray.bloodTestDetails[0].q36T1DEmpty = true;
        } else {
            repeaterarray.bloodTestDetails[0].q36T1DEmpty = false;
        }

        // Table 1: Col E - Laboratory/Clinical/Neither
        if (
            (repeaterarray.bloodTestDetails[0].tls_laboratory == null || repeaterarray.bloodTestDetails[0].tls_laboratory == false) &&
            (repeaterarray.bloodTestDetails[0].tls_clinical == null || repeaterarray.bloodTestDetails[0].tls_clinical == false) &&
            (repeaterarray.bloodTestDetails[0].tls_neither == null || repeaterarray.bloodTestDetails[0].tls_neither == false) &&
            (repeaterarray.bloodTestDetails[0].tls_unk == null || repeaterarray.bloodTestDetails[0].tls_unk == false) &&
            (repeaterarray.bloodTestDetails[0].tls_laboratory_cairo == null || repeaterarray.bloodTestDetails[0].tls_laboratory_cairo == false) &&
            (repeaterarray.bloodTestDetails[0].tls_clinical_cairo == null || repeaterarray.bloodTestDetails[0].tls_clinical_cairo == false)
        )
        {
            result = false;
            repeaterarray.bloodTestDetails[0].q36T1EEmpty = true;
        } else {
            repeaterarray.bloodTestDetails[0].q36T1EEmpty = false;
        }

        // Table 1: Col F - Intervention Given
        if (repeaterarray.bloodTestDetails[0].intervention_given == null) {
            result = false;
            repeaterarray.bloodTestDetails[0].q36T1FEmpty = true;
        } else {
            repeaterarray.bloodTestDetails[0].q36T1FEmpty = false;
        }

        if (repeaterarray.bloodTestDetails[0].intervention_given == 1 && (repeaterarray.bloodTestDetails[0].yes_dosehold == null || !repeaterarray.bloodTestDetails[0].yes_dosehold) && (repeaterarray.bloodTestDetails[0].yes_ivhydration == null || !repeaterarray.bloodTestDetails[0].yes_ivhydration) && (repeaterarray.bloodTestDetails[0].yes_metabolic == null || !repeaterarray.bloodTestDetails[0].yes_metabolic) && (repeaterarray.bloodTestDetails[0].yes_oth == null || !repeaterarray.bloodTestDetails[0].yes_oth)) {
            result = false;
            repeaterarray.bloodTestDetails[0].q36T1FChildEmpty = true;
        } else {
            repeaterarray.bloodTestDetails[0].q36T1FChildEmpty = false;
        }

        if (repeaterarray.bloodTestDetails[0].yes_oth == true && ((repeaterarray.bloodTestDetails[0].yes_oth_txt == null || !repeaterarray.bloodTestDetails[0].yes_oth_txt) || repeaterarray.bloodTestDetails[0].yes_oth_txt == "")) {
            result = false;
            repeaterarray.bloodTestDetails[0].q36T1FOtherTxtEmpty = true;
        } else {
            repeaterarray.bloodTestDetails[0].q36T1FOtherTxtEmpty = false;
        }

        // Table 2 Col B
        if (repeaterarray.bloodTestDetails[0].alc_test_performed == null || repeaterarray.bloodTestDetails[0].cal_test_performed == null || repeaterarray.bloodTestDetails[0].sc_test_performed == null ||
            repeaterarray.bloodTestDetails[0].crcl_test_performed == null || repeaterarray.bloodTestDetails[0].ldh_test_performed == null || repeaterarray.bloodTestDetails[0].pho_test_performed == null ||
            repeaterarray.bloodTestDetails[0].pot_test_performed == null || repeaterarray.bloodTestDetails[0].uric_test_performed == null) {
            result = false;
            repeaterarray.bloodTestDetails[0].q36T2BAlcEmpty = repeaterarray.bloodTestDetails[0].alc_test_performed == null;
            repeaterarray.bloodTestDetails[0].q36T2BCalEmpty = repeaterarray.bloodTestDetails[0].cal_test_performed == null;
            repeaterarray.bloodTestDetails[0].q36T2BScEmpty = repeaterarray.bloodTestDetails[0].sc_test_performed == null;
            repeaterarray.bloodTestDetails[0].q36T2BCrclEmpty = repeaterarray.bloodTestDetails[0].crcl_test_performed == null;
            repeaterarray.bloodTestDetails[0].q36T2BLdhEmpty = repeaterarray.bloodTestDetails[0].ldh_test_performed == null;
            repeaterarray.bloodTestDetails[0].q36T2BPhoEmpty = repeaterarray.bloodTestDetails[0].pho_test_performed == null;
            repeaterarray.bloodTestDetails[0].q36T2BPotEmpty = repeaterarray.bloodTestDetails[0].pot_test_performed == null;
            repeaterarray.bloodTestDetails[0].q36T2BUricEmpty = repeaterarray.bloodTestDetails[0].uric_test_performed == null;
        } else {
            repeaterarray.bloodTestDetails[0].q36T2BAlcEmpty = false;
            repeaterarray.bloodTestDetails[0].q36T2BCalEmpty = false;
            repeaterarray.bloodTestDetails[0].q36T2BScEmpty = false;
            repeaterarray.bloodTestDetails[0].q36T2BCrclEmpty = false;
            repeaterarray.bloodTestDetails[0].q36T2BLdhEmpty = false;
            repeaterarray.bloodTestDetails[0].q36T2BPhoEmpty = false;
            repeaterarray.bloodTestDetails[0].q36T2BPotEmpty = false;
            repeaterarray.bloodTestDetails[0].q36T2BUricEmpty = false;
            repeaterarray.bloodTestDetails[0].q36T2BAllUnkNotTestedEmpty = false;
        }

        if (repeaterarray.bloodTestDetails[0].crcl_test_performed == 1) {
            // Table 2 Col C
            if (repeaterarray.bloodTestDetails[0].crcl_formula_type == null) {
                result = false;
                repeaterarray.bloodTestDetails[0].q36T2CCrclEmpty = true;
            } else {
                repeaterarray.bloodTestDetails[0].q36T2CCrclEmpty = false;
            }

            if (repeaterarray.bloodTestDetails[0].crcl_formula_type == Values.value2 && (repeaterarray.bloodTestDetails[0].crcl_formula_type_oth == null || repeaterarray.bloodTestDetails[0].crcl_formula_type_oth == "")) {
                result = false;
                repeaterarray.bloodTestDetails[0].q36T2CCrclOthTxtEmpty = true;
            } else {
                repeaterarray.bloodTestDetails[0].q36T2CCrclOthTxtEmpty = false;
            }
        }

        // Table 2 Col D
        //Table 2: Col D Radio is checked but result is empty
        if ((repeaterarray.bloodTestDetails[0].alc_test_performed == 1 && repeaterarray.bloodTestDetails[0].alc_test_result_checked == null && repeaterarray.bloodTestDetails[0].alc_test_result_unk == null) ||
        (repeaterarray.bloodTestDetails[0].cal_test_performed == 1 && repeaterarray.bloodTestDetails[0].cal_test_result_checked == null && repeaterarray.bloodTestDetails[0].cal_test_result_unk == null) ||
        (repeaterarray.bloodTestDetails[0].sc_test_performed == 1 && repeaterarray.bloodTestDetails[0].sc_test_result_checked == null && repeaterarray.bloodTestDetails[0].sc_test_result_unk == null) ||
        (repeaterarray.bloodTestDetails[0].crcl_test_performed == 1 && repeaterarray.bloodTestDetails[0].crcl_test_result_checked == null && repeaterarray.bloodTestDetails[0].crcl_test_result_unk == null) ||
        (repeaterarray.bloodTestDetails[0].ldh_test_performed == 1 && repeaterarray.bloodTestDetails[0].ldh_test_result_checked == null && repeaterarray.bloodTestDetails[0].ldh_test_result_unk == null) ||
        (repeaterarray.bloodTestDetails[0].pho_test_performed == 1 && repeaterarray.bloodTestDetails[0].pho_test_result_checked == null && repeaterarray.bloodTestDetails[0].pho_test_result_unk == null) ||
        (repeaterarray.bloodTestDetails[0].pot_test_performed == 1 && repeaterarray.bloodTestDetails[0].pot_test_result_checked == null && repeaterarray.bloodTestDetails[0].pot_test_result_unk == null) ||
        (repeaterarray.bloodTestDetails[0].uric_test_performed == 1 && repeaterarray.bloodTestDetails[0].uric_test_result_checked == null && repeaterarray.bloodTestDetails[0].uric_test_result_unk == null)) {
        result = false;
            repeaterarray.bloodTestDetails[0].q36T2DAlcEmpty = repeaterarray.bloodTestDetails[0].alc_test_performed == 1 && repeaterarray.bloodTestDetails[0].alc_test_result_checked == null && repeaterarray.bloodTestDetails[0].alc_test_result_unk == null;
            repeaterarray.bloodTestDetails[0].q36T2DCalEmpty = repeaterarray.bloodTestDetails[0].cal_test_performed == 1 && repeaterarray.bloodTestDetails[0].cal_test_result_checked == null && repeaterarray.bloodTestDetails[0].cal_test_result_unk == null;
            repeaterarray.bloodTestDetails[0].q36T2DScEmpty = repeaterarray.bloodTestDetails[0].sc_test_performed == 1 && repeaterarray.bloodTestDetails[0].sc_test_result_checked == null && repeaterarray.bloodTestDetails[0].sc_test_result_unk == null;
            repeaterarray.bloodTestDetails[0].q36T2DCrclEmpty = repeaterarray.bloodTestDetails[0].crcl_test_performed == 1 && repeaterarray.bloodTestDetails[0].crcl_test_result_checked == null && repeaterarray.bloodTestDetails[0].crcl_test_result_unk == null;
            repeaterarray.bloodTestDetails[0].q36T2DLdhEmpty = repeaterarray.bloodTestDetails[0].ldh_test_performed == 1 && repeaterarray.bloodTestDetails[0].ldh_test_result_checked == null && repeaterarray.bloodTestDetails[0].ldh_test_result_unk == null;
            repeaterarray.bloodTestDetails[0].q36T2DPhoEmpty = repeaterarray.bloodTestDetails[0].pho_test_performed == 1 && repeaterarray.bloodTestDetails[0].pho_test_result_checked == null && repeaterarray.bloodTestDetails[0].pho_test_result_unk == null;
            repeaterarray.bloodTestDetails[0].q36T2DPotEmpty = repeaterarray.bloodTestDetails[0].pot_test_performed == 1 && repeaterarray.bloodTestDetails[0].pot_test_result_checked == null && repeaterarray.bloodTestDetails[0].pot_test_result_unk == null;
            repeaterarray.bloodTestDetails[0].q36T2DUricEmpty = repeaterarray.bloodTestDetails[0].uric_test_performed == 1 && repeaterarray.bloodTestDetails[0].uric_test_result_checked == null && repeaterarray.bloodTestDetails[0].uric_test_result_unk == null;
        } else {
            repeaterarray.bloodTestDetails[0].q36T2DAlcEmpty = false;
            repeaterarray.bloodTestDetails[0].q36T2DCalEmpty = false;
            repeaterarray.bloodTestDetails[0].q36T2DScEmpty = false;
            repeaterarray.bloodTestDetails[0].q36T2DCrclEmpty = false;
            repeaterarray.bloodTestDetails[0].q36T2DLdhEmpty = false;
            repeaterarray.bloodTestDetails[0].q36T2DPhoEmpty = false;
            repeaterarray.bloodTestDetails[0].q36T2DPotEmpty = false;
            repeaterarray.bloodTestDetails[0].q36T2DUricEmpty = false;
            repeaterarray.bloodTestDetails[0].q36T2DAllUnknownEmpty = false;
            repeaterarray.bloodTestDetails[0].q36T2DAlcResultRange = false;
            repeaterarray.bloodTestDetails[0].q36T2BCalResultRange = false;
            repeaterarray.bloodTestDetails[0].q36T2BSCResultRange = false;
            repeaterarray.bloodTestDetails[0].q36T2BCrclResultRange = false;
            repeaterarray.bloodTestDetails[0].q36T2BLdhResultRange = false;
            repeaterarray.bloodTestDetails[0].q36T2BPhoResultRange = false;
            repeaterarray.bloodTestDetails[0].q36T2BPotResultRange = false;
            repeaterarray.bloodTestDetails[0].q36T2BUricResultRange = false;
        }

        //Table 2: Col D Radio is checked but result is empty
        if ((repeaterarray.bloodTestDetails[0].alc_test_performed == 1 && repeaterarray.bloodTestDetails[0].alc_test_result_checked == 1 && repeaterarray.bloodTestDetails[0].alc_test_result == "") ||
            (repeaterarray.bloodTestDetails[0].cal_test_performed == 1 && repeaterarray.bloodTestDetails[0].cal_test_result_checked == 1 && repeaterarray.bloodTestDetails[0].cal_test_result == "") ||
            (repeaterarray.bloodTestDetails[0].sc_test_performed == 1 && repeaterarray.bloodTestDetails[0].sc_test_result_checked == 1 && repeaterarray.bloodTestDetails[0].sc_test_result == "") ||
            (repeaterarray.bloodTestDetails[0].crcl_test_performed == 1 && repeaterarray.bloodTestDetails[0].crcl_test_result_checked == 1 && repeaterarray.bloodTestDetails[0].crcl_test_resul == "") ||
            (repeaterarray.bloodTestDetails[0].ldh_test_performed == 1 && repeaterarray.bloodTestDetails[0].ldh_test_result_checked == 1 && repeaterarray.bloodTestDetails[0].ldh_test_resul == "") ||
            (repeaterarray.bloodTestDetails[0].pho_test_performed == 1 && repeaterarray.bloodTestDetails[0].pho_test_result_checked == 1 && repeaterarray.bloodTestDetails[0].pho_test_result == "") ||
            (repeaterarray.bloodTestDetails[0].pot_test_performed == 1 && repeaterarray.bloodTestDetails[0].pot_test_result_checked == 1 && repeaterarray.bloodTestDetails[0].pot_test_result == "") ||
            (repeaterarray.bloodTestDetails[0].uric_test_performed == 1 && repeaterarray.bloodTestDetails[0].uric_test_result_checked == 1 && repeaterarray.bloodTestDetails[0].uric_test_result == "")) {
            result = false;
            repeaterarray.bloodTestDetails[0].q36T2DAlcResultEmpty = repeaterarray.bloodTestDetails[0].alc_test_performed == 1 && repeaterarray.bloodTestDetails[0].alc_test_result_checked == 1 && repeaterarray.bloodTestDetails[0].alc_test_result == "";
            repeaterarray.bloodTestDetails[0].q36T2DCalResultEmpty = repeaterarray.bloodTestDetails[0].cal_test_performed == 1 && repeaterarray.bloodTestDetails[0].cal_test_result_checked == 1 && repeaterarray.bloodTestDetails[0].cal_test_result == "";
            repeaterarray.bloodTestDetails[0].q36T2DScResultEmpty = repeaterarray.bloodTestDetails[0].sc_test_performed == 1 && repeaterarray.bloodTestDetails[0].sc_test_result_checked == 1 && repeaterarray.bloodTestDetails[0].sc_test_result == "";
            repeaterarray.bloodTestDetails[0].q36T2DCrclResultEmpty = repeaterarray.bloodTestDetails[0].crcl_test_performed == 1 && repeaterarray.bloodTestDetails[0].crcl_test_result_checked == 1 && repeaterarray.bloodTestDetails[0].crcl_test_result == "";
            repeaterarray.bloodTestDetails[0].q36T2DLdhResultEmpty = repeaterarray.bloodTestDetails[0].ldh_test_performed == 1 && repeaterarray.bloodTestDetails[0].ldh_test_result_checked == 1 && repeaterarray.bloodTestDetails[0].ldh_test_result == "";
            repeaterarray.bloodTestDetails[0].q36T2DPhoResultEmpty = repeaterarray.bloodTestDetails[0].pho_test_performed == 1 && repeaterarray.bloodTestDetails[0].pho_test_result_checked == 1 && repeaterarray.bloodTestDetails[0].pho_test_result == "";
            repeaterarray.bloodTestDetails[0].q36T2DPotResultEmpty = repeaterarray.bloodTestDetails[0].pot_test_performed == 1 && repeaterarray.bloodTestDetails[0].pot_test_result_checked == 1 && repeaterarray.bloodTestDetails[0].pot_test_result == "";
            repeaterarray.bloodTestDetails[0].q36T2DUricResultEmpty = repeaterarray.bloodTestDetails[0].uric_test_performed == 1 && repeaterarray.bloodTestDetails[0].uric_test_result_checked == 1 && repeaterarray.bloodTestDetails[0].uric_test_result == "";
        } else {
            repeaterarray.bloodTestDetails[0].q36T2DAlcResultEmpty = false;
            repeaterarray.bloodTestDetails[0].q36T2DCalResultEmpty = false;
            repeaterarray.bloodTestDetails[0].q36T2DSCResultEmpty = false;
            repeaterarray.bloodTestDetails[0].q36T2DCrclResultEmpty = false;
            repeaterarray.bloodTestDetails[0].q36T2DLdhResultEmpty = false;
            repeaterarray.bloodTestDetails[0].q36T2DPhoResultEmpty = false;
            repeaterarray.bloodTestDetails[0].q36T2DBPotResultEmpty = false;
            repeaterarray.bloodTestDetails[0].q36T2DUricResultEmpty = false;
            repeaterarray.bloodTestDetails[0].q36T2DAllUnknownEmpty = false;
            repeaterarray.bloodTestDetails[0].q36T2DAlcResultRange = false;
            repeaterarray.bloodTestDetails[0].q36T2BCalResultRange = false;
            repeaterarray.bloodTestDetails[0].q36T2BSCResultRange = false;
            repeaterarray.bloodTestDetails[0].q36T2BCrclResultRange = false;
            repeaterarray.bloodTestDetails[0].q36T2BLdhResultRange = false;
            repeaterarray.bloodTestDetails[0].q36T2BPhoResultRange = false;
            repeaterarray.bloodTestDetails[0].q36T2BPotResultRange = false;
            repeaterarray.bloodTestDetails[0].q36T2BUricResultRange = false;
        }
        //     }
        // );

        return result;
    }


    //Q36
    const HandleQ36RepeaterFieldChange = (e, parentIdx) => {
        const { name, id, value } = e.target;
        const value_int = Number(value);
        let updatedArray = [...bloodTestRowData];

        switch (name) {
            //Radio Q36 colA
            case `blood_draw_date_checked_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].blooddraw_date_checked = value_int;
                updatedArray[parentIdx].bloodTestDetails[0].blooddraw_date_unk = null;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1AEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1ADateEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1ADateCheckError = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1ADateVenCheckError = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1AInvalidDtError = false;

            }
                break;
            case `blood_draw_date_unk_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].blooddraw_date_unk = value_int;
                updatedArray[parentIdx].bloodTestDetails[0].blooddraw_date_checked = null;
                updatedArray[parentIdx].bloodTestDetails[0].blooddraw_date = null;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1AEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1ADateEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1ADateCheckError = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1ADateVenCheckError = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1AInvalidDtError = false;
            }
                break;

            //Radio Q36 colB
            case `repeat_labtest_1_${parentIdx}`:
            case `repeat_labtest_2_${parentIdx}`:
            case `repeat_labtest_3_${parentIdx}`:
            case `repeat_labtest_4_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].reasonrepeattest = value_int;
                updatedArray[parentIdx].bloodTestDetails[0].confirm_lab_hemolyzed = null;
                updatedArray[parentIdx].bloodTestDetails[0].post_hemolyzed = null;
                updatedArray[parentIdx].bloodTestDetails[0].inconclusive_hemolyzed = null;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1BEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1B1SubValueEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1B2SubValueEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1B3SubValueEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1ColBCSoft = false;
            }
                break;
            case `repeat_labtest_labhemmo_1_${parentIdx}`:
            case `repeat_labtest_labhemmo_2_${parentIdx}`:
            case `repeat_labtest_labhemmo_99_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].reasonrepeattest = Values.value2;
                updatedArray[parentIdx].bloodTestDetails[0].confirm_lab_hemolyzed = value_int;
                updatedArray[parentIdx].bloodTestDetails[0].post_hemolyzed = null;
                updatedArray[parentIdx].bloodTestDetails[0].inconclusive_hemolyzed = null;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1BEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1B1SubValueEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1B2SubValueEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1B3SubValueEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1ColBCSoft = false;
            }
                break;
            case `repeat_labtest_posthemmo_1_${parentIdx}`:
            case `repeat_labtest_posthemmo_2_${parentIdx}`:
            case `repeat_labtest_posthemmo_99_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].reasonrepeattest = 3;
                updatedArray[parentIdx].bloodTestDetails[0].confirm_lab_hemolyzed = null;
                updatedArray[parentIdx].bloodTestDetails[0].post_hemolyzed = value_int;
                updatedArray[parentIdx].bloodTestDetails[0].inconclusive_hemolyzed = null;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1BEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1B1SubValueEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1B2SubValueEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1B3SubValueEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1ColBCSoft = false;
            }
                break;
            case `repeat_labtest_inhemmo_1_${parentIdx}`:
            case `repeat_labtest_inhemmo_2_${parentIdx}`:
            case `repeat_labtest_inhemmo_99_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].reasonrepeattest = 4;
                updatedArray[parentIdx].bloodTestDetails[0].confirm_lab_hemolyzed = null;
                updatedArray[parentIdx].bloodTestDetails[0].post_hemolyzed = null;
                updatedArray[parentIdx].bloodTestDetails[0].inconclusive_hemolyzed = value_int;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1BEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1B1SubValueEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1B2SubValueEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1B3SubValueEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1ColBCSoft = false;
            }
                break;



            //Radio Q36 colC
            case `blooddraw_time_1_${parentIdx}`:
            case `blooddraw_time_2_${parentIdx}`:
            case `blooddraw_time_3_${parentIdx}`:
            case `blooddraw_time_4_${parentIdx}`:
            case `blooddraw_time_99_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].timeofblooddraw = value_int;
                updatedArray[parentIdx].bloodTestDetails[0].blooddraw_oth_time = null;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1CEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1CTimeEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1CHrsEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1CMinsEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1ColBCSoft = false;

                updatedArray[parentIdx].bloodTestDetails[0].blooddraw_oth_time_hr = "";
                updatedArray[parentIdx].bloodTestDetails[0].blooddraw_oth_time_min = "";
            }
                break;

            case `blooddraw_oth_time_hr_${parentIdx}`: {
                // const hoursValue = value === "" ? "" : Math.min(value, 24).toString();

                updatedArray[parentIdx].bloodTestDetails[0].blooddraw_oth_time_hr = value > 23 ? "23" : value;;
                updatedArray[parentIdx].bloodTestDetails[0].timeofblooddraw = 4;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1CEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1CTimeEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1CHrsEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1CMinsEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1ColBCSoft = false;

                updatedArray[parentIdx].bloodTestDetails[0].blooddraw_oth_time_min = value === "23" &&
                    updatedArray[parentIdx].bloodTestDetails[0].blooddraw_oth_time_min > "59" ? "59" :
                    updatedArray[parentIdx].bloodTestDetails[0].blooddraw_oth_time_min;
            }
                break;

            case `blooddraw_oth_time_min_${parentIdx}`: {
                // const minutesValue = value === "" ? "" : Math.min(value, 59).toString();

                updatedArray[parentIdx].bloodTestDetails[0].blooddraw_oth_time_min = value > 59 ? "59" : value;
                updatedArray[parentIdx].bloodTestDetails[0].timeofblooddraw = 4;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1CEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1CTimeEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1CHrsEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1ColBCSoft = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1CMinsEmpty = false;
            }
                break;


            //Radio Q36 colD
            case `tls_settings_1_${parentIdx}`:
            case `tls_settings_2_${parentIdx}`:
            case `tls_settings_99_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].testsetting = value_int;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1DEmpty = false;
            }
                break;

            //Radio Q36 colE
            case `tls_laboratory_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].tls_laboratory = !updatedArray[parentIdx].bloodTestDetails[0].tls_laboratory;
                updatedArray[parentIdx].bloodTestDetails[0].tls_neither = false;
                updatedArray[parentIdx].bloodTestDetails[0].tls_unk = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1EEmpty = false;
            }
                break;
            case `tls_clinical_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].tls_clinical = !updatedArray[parentIdx].bloodTestDetails[0].tls_clinical;
                updatedArray[parentIdx].bloodTestDetails[0].tls_neither = false;
                updatedArray[parentIdx].bloodTestDetails[0].tls_unk = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1EEmpty = false;
            }
                break;
            case `tls_laboratory_cairo_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].tls_laboratory_cairo = !updatedArray[parentIdx].bloodTestDetails[0].tls_laboratory_cairo;
                updatedArray[parentIdx].bloodTestDetails[0].tls_neither = false;
                updatedArray[parentIdx].bloodTestDetails[0].tls_unk = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1EEmpty = false;
            }
                break;
            case `tls_clinical_cairo_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].tls_clinical_cairo = !updatedArray[parentIdx].bloodTestDetails[0].tls_clinical_cairo;
                updatedArray[parentIdx].bloodTestDetails[0].tls_neither = false;
                updatedArray[parentIdx].bloodTestDetails[0].tls_unk = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1EEmpty = false;
            }
                break;
            case `tls_neither_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].tls_neither = true;
                updatedArray[parentIdx].bloodTestDetails[0].tls_laboratory = false;
                updatedArray[parentIdx].bloodTestDetails[0].tls_clinical = false;
                updatedArray[parentIdx].bloodTestDetails[0].tls_laboratory_cairo = false;
                updatedArray[parentIdx].bloodTestDetails[0].tls_clinical_cairo = false;
                updatedArray[parentIdx].bloodTestDetails[0].tls_unk = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1EEmpty = false;
            }
                break;
            case `tls_unk_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].tls_unk = true;
                updatedArray[parentIdx].bloodTestDetails[0].tls_laboratory = false;
                updatedArray[parentIdx].bloodTestDetails[0].tls_clinical = false;
                updatedArray[parentIdx].bloodTestDetails[0].tls_laboratory_cairo = false;
                updatedArray[parentIdx].bloodTestDetails[0].tls_clinical_cairo = false;
                updatedArray[parentIdx].bloodTestDetails[0].tls_neither = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1EEmpty = false;
            }
                break;

            //Radio Q36 colF
            case `intervention_given_1_${parentIdx}`:
            case `intervention_given_2_${parentIdx}`:
            case `intervention_given_99_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].intervention_given = value_int;
                updatedArray[parentIdx].bloodTestDetails[0].yes_dosehold = false;
                updatedArray[parentIdx].bloodTestDetails[0].yes_ivhydration = false;
                updatedArray[parentIdx].bloodTestDetails[0].yes_metabolic = false;
                updatedArray[parentIdx].bloodTestDetails[0].yes_oth = false;
                updatedArray[parentIdx].bloodTestDetails[0].yes_oth_txt = "";
                updatedArray[parentIdx].bloodTestDetails[0].q36T1FEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1FChildEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1FOtherTxtEmpty = false;
            }
                break;
            case `yes_dosehold_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].intervention_given = 1;
                updatedArray[parentIdx].bloodTestDetails[0].yes_dosehold = !updatedArray[parentIdx].bloodTestDetails[0].yes_dosehold;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1FEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1FChildEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1FOtherTxtEmpty = false;
            }
                break;
            case `yes_ivhydration_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].intervention_given = 1;
                updatedArray[parentIdx].bloodTestDetails[0].yes_ivhydration = !updatedArray[parentIdx].bloodTestDetails[0].yes_ivhydration;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1FEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1FChildEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1FOtherTxtEmpty = false;
            }
                break;
            case `yes_metabolic_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].intervention_given = 1;
                updatedArray[parentIdx].bloodTestDetails[0].yes_metabolic = !updatedArray[parentIdx].bloodTestDetails[0].yes_metabolic;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1FEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1FChildEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1FOtherTxtEmpty = false;
            }
                break;
            case `yes_oth_${parentIdx}`: {
                //SYJ - Validation issue for yes_oth_txt not clearing
                if (updatedArray[parentIdx].bloodTestDetails[0].yes_oth) {
                    updatedArray[parentIdx].bloodTestDetails[0].yes_oth = !updatedArray[parentIdx].bloodTestDetails[0].yes_oth;
                    updatedArray[parentIdx].bloodTestDetails[0].yes_oth_txt = "";
                    updatedArray[parentIdx].bloodTestDetails[0].q36T1FEmpty = false;
                    updatedArray[parentIdx].bloodTestDetails[0].q36T1FChildEmpty = false;
                    updatedArray[parentIdx].bloodTestDetails[0].q36T1FOtherTxtEmpty = false;
                }
                else {
                    updatedArray[parentIdx].bloodTestDetails[0].intervention_given = 1;
                    updatedArray[parentIdx].bloodTestDetails[0].yes_oth = !updatedArray[parentIdx].bloodTestDetails[0].yes_oth;
                    updatedArray[parentIdx].bloodTestDetails[0].yes_oth_txt = "";
                    updatedArray[parentIdx].bloodTestDetails[0].q36T1FEmpty = false;
                    updatedArray[parentIdx].bloodTestDetails[0].q36T1FChildEmpty = false;
                    updatedArray[parentIdx].bloodTestDetails[0].q36T1FOtherTxtEmpty = false;
                }
            }
                break;
            case `yes_oth_txt_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].intervention_given = 1;
                updatedArray[parentIdx].bloodTestDetails[0].yes_oth = true;
                updatedArray[parentIdx].bloodTestDetails[0].yes_oth_txt = value;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1FEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1FChildEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T1FOtherTxtEmpty = false;
            }
                break;

            //Radio Q36 Table2 ALC
            case `alc_test_performed_1_${parentIdx}`:
            case `alc_test_performed_2_${parentIdx}`:
            case `alc_test_performed_99_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].alc_test_performed = value_int;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2BAlcEmpty = false;

                updatedArray[parentIdx].bloodTestDetails[0].alc_test_result_checked = null;  
                updatedArray[parentIdx].bloodTestDetails[0].alc_test_result = "";
                updatedArray[parentIdx].bloodTestDetails[0].alc_test_result_unk = null;
                
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DAlcResultRange = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DAlcEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DAlcResultEmpty = false;


            }
                break;
            case `alc_test_result_checked_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].alc_test_result_checked = value_int;
                updatedArray[parentIdx].bloodTestDetails[0].alc_test_result_unk = null;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DAlcEmpty = false;

                updatedArray[parentIdx].bloodTestDetails[0].q36T2DAlcResultRange = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DAlcResultEmpty = false;
            }
                break;
            case `alc_test_result_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].alc_test_result = value;
                updatedArray[parentIdx].bloodTestDetails[0].alc_test_result_checked = 1;
                updatedArray[parentIdx].bloodTestDetails[0].alc_test_result_unk = null;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DAlcEmpty = false;

                updatedArray[parentIdx].bloodTestDetails[0].q36T2DAlcResultEmpty = false;

                if (parseFloat(value) < 0 || parseFloat(value) > 50) {
                    let resultRange = TLSMonitoring_Msgs.TestCompleteTxtEmptyMsg.replace('[value]', value);
                    resultRange = resultRange.replace('[value units]', '10⁹ /L');
                    setQ36ResultAlcOutOfRange(resultRange);
                    updatedArray[parentIdx].bloodTestDetails[0].q36T2DAlcResultRange = true;
                }
                else {
                    updatedArray[parentIdx].bloodTestDetails[0].q36T2DAlcResultRange = false;
                }
            }
                break;
            case `alc_test_result_unk_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].alc_test_result_checked = null;
                updatedArray[parentIdx].bloodTestDetails[0].alc_test_result = "";
                updatedArray[parentIdx].bloodTestDetails[0].alc_test_result_unk = value_int;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DAlcEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DAlcResultRange = false;

                updatedArray[parentIdx].bloodTestDetails[0].q36T2DAlcResultEmpty = false;
            }
                break;

            //Radio Q36 Table2 Calcium
            case `cal_test_performed_1_${parentIdx}`:
            case `cal_test_performed_2_${parentIdx}`:
            case `cal_test_performed_99_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].cal_test_performed = value_int;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2BCalEmpty = false;

                updatedArray[parentIdx].bloodTestDetails[0].cal_test_result_checked = null;  
                updatedArray[parentIdx].bloodTestDetails[0].cal_test_result = "";
                updatedArray[parentIdx].bloodTestDetails[0].cal_test_result_unk = null;
                
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DCalResultRange = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DCalEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DCalResultEmpty = false;


            }
                break;
            case `cal_test_result_checked_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].cal_test_result_checked = value_int;
                updatedArray[parentIdx].bloodTestDetails[0].cal_test_result_unk = null;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DCalEmpty = false;

                updatedArray[parentIdx].bloodTestDetails[0].q36T2DCalResultRange = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DCalResultEmpty = false;
            }
                break;
            case `cal_test_result_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].cal_test_result = value;
                updatedArray[parentIdx].bloodTestDetails[0].cal_test_result_checked = 1;
                updatedArray[parentIdx].bloodTestDetails[0].cal_test_result_unk = null;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DCalEmpty = false;

                updatedArray[parentIdx].bloodTestDetails[0].q36T2DCalResultEmpty = false;
                if (parseFloat(value) < 0 || parseFloat(value) > 14) {
                    let resultRange = TLSMonitoring_Msgs.TestCompleteTxtEmptyMsg.replace('[value]', value);
                    resultRange = resultRange.replace('[value units]', 'mg/dL');
                    setQ36ResultCalOutOfRange(resultRange);
                    updatedArray[parentIdx].bloodTestDetails[0].q36T2DCalResultRange = true;
                }
                else {
                    updatedArray[parentIdx].bloodTestDetails[0].q36T2DCalResultRange = false;
                }
            }
                break;
            case `cal_test_result_unk_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].cal_test_result_checked = null;
                updatedArray[parentIdx].bloodTestDetails[0].cal_test_result = "";
                updatedArray[parentIdx].bloodTestDetails[0].cal_test_result_unk = value_int;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DCalEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DCalResultRange = false;

                updatedArray[parentIdx].bloodTestDetails[0].q36T2DCalResultEmpty = false;
            }
                break;

            //Radio Q36 Table2 Serum creatinine
            case `sc_test_performed_1_${parentIdx}`:
            case `sc_test_performed_2_${parentIdx}`:
            case `sc_test_performed_99_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].sc_test_performed = value_int;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2BScEmpty = false;

                updatedArray[parentIdx].bloodTestDetails[0].sc_test_result_checked = null;  
                updatedArray[parentIdx].bloodTestDetails[0].sc_test_result = "";
                updatedArray[parentIdx].bloodTestDetails[0].sc_test_result_unk = null;

                updatedArray[parentIdx].bloodTestDetails[0].q36T2DScResultRange = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DScEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DScResultEmpty = false;
            }
                break;
            case `sc_test_result_checked_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].sc_test_result_checked = value_int;
                updatedArray[parentIdx].bloodTestDetails[0].sc_test_result_unk = null;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DScEmpty = false;

                updatedArray[parentIdx].bloodTestDetails[0].q36T2DScResultRange = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DScResultEmpty = false;
            }
                break;
            case `sc_test_result_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].sc_test_result = value;
                updatedArray[parentIdx].bloodTestDetails[0].sc_test_result_checked = 1;
                updatedArray[parentIdx].bloodTestDetails[0].sc_test_result_unk = null;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DScEmpty = false;

                updatedArray[parentIdx].bloodTestDetails[0].q36T2DScResultEmpty = false;

                if (parseFloat(value) < 0 || parseFloat(value) > 1.35) {
                    let resultRange = TLSMonitoring_Msgs.TestCompleteTxtEmptyMsg.replace('[value]', value);
                    resultRange = resultRange.replace('[value units]', 'mg/dL');
                    setQ36ResultScOutOfRange(resultRange);
                    updatedArray[parentIdx].bloodTestDetails[0].q36T2DScResultRange = true;
                }
                else {
                    updatedArray[parentIdx].bloodTestDetails[0].q36T2DScResultRange = false;
                }
            }
                break;
            case `sc_test_result_unk_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].sc_test_result_checked = null;
                updatedArray[parentIdx].bloodTestDetails[0].sc_test_result = "";
                updatedArray[parentIdx].bloodTestDetails[0].sc_test_result_unk = value_int;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DScEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DScResultRange = false;

                updatedArray[parentIdx].bloodTestDetails[0].q36T2DScResultEmpty = false;
            }
                break;

            //Radio Q36 Table2 Creatinine clearance level
            case `crcl_test_performed_1_${parentIdx}`:
            case `crcl_test_performed_2_${parentIdx}`:
            case `crcl_test_performed_99_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].crcl_test_performed = value_int;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2BCrclEmpty = false;

                updatedArray[parentIdx].bloodTestDetails[0].crcl_test_result_checked = null;  
                updatedArray[parentIdx].bloodTestDetails[0].crcl_test_result = "";
                updatedArray[parentIdx].bloodTestDetails[0].crcl_test_result_unk = null;

                updatedArray[parentIdx].bloodTestDetails[0].q36T2DCrclResultRange = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DCrclEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DCrclResultEmpty = false;

                updatedArray[parentIdx].bloodTestDetails[0].crcl_formula_type = null;
                updatedArray[parentIdx].bloodTestDetails[0].crcl_formula_type_oth = "";

                updatedArray[parentIdx].bloodTestDetails[0].q36T2CCrclEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2CCrclOthTxtEmpty = false;




            }
                break;
            case `crcl_formula_type_1_${parentIdx}`:
            case `crcl_formula_type_2_${parentIdx}`:
            case `crcl_formula_type_99_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].crcl_formula_type = value_int;
                updatedArray[parentIdx].bloodTestDetails[0].crcl_formula_type_oth = null;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2CCrclEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2CCrclOthTxtEmpty = false;
            }
                break;
            case `crcl_formula_type_oth_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].crcl_formula_type = Values.value2;
                updatedArray[parentIdx].bloodTestDetails[0].crcl_formula_type_oth = value;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2CCrclEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2CCrclOthTxtEmpty = false;

                
            }
                break;
            case `crcl_test_result_checked_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].crcl_test_result_checked = value_int;
                updatedArray[parentIdx].bloodTestDetails[0].crcl_test_result_unk = null;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DCrclEmpty = false;

                updatedArray[parentIdx].bloodTestDetails[0].q36T2DCrclResultRange = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DCrclResultEmpty = false;
            }
                break;
            case `crcl_test_result_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].crcl_test_result = value;
                updatedArray[parentIdx].bloodTestDetails[0].crcl_test_result_checked = 1;
                updatedArray[parentIdx].bloodTestDetails[0].crcl_test_result_unk = null;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DCrclEmpty = false;

                updatedArray[parentIdx].bloodTestDetails[0].q36T2DCrclResultEmpty = false;
                if (parseFloat(value) < 0 || parseFloat(value) > 120) {
                    let resultRange = TLSMonitoring_Msgs.TestCompleteTxtEmptyMsg.replace('[value]', value);
                    resultRange = resultRange.replace('[value units]', 'mL/min');
                    setQ36ResultCrclOutOfRange(resultRange);
                    updatedArray[parentIdx].bloodTestDetails[0].q36T2DCrclResultRange = true;
                }
                else {
                    updatedArray[parentIdx].bloodTestDetails[0].q36T2DCrclResultRange = false;
                }
            }
                break;
            case `crcl_test_result_unk_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].crcl_test_result_checked = null;
                updatedArray[parentIdx].bloodTestDetails[0].crcl_test_result = "";
                updatedArray[parentIdx].bloodTestDetails[0].crcl_test_result_unk = value_int;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DCrclEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DCrclResultRange = false;

                updatedArray[parentIdx].bloodTestDetails[0].q36T2DCrclResultEmpty = false;
            }
                break;

            //Radio Q36 Table2 Serum lactate dehydrogenase 
            case `ldh_test_performed_1_${parentIdx}`:
            case `ldh_test_performed_2_${parentIdx}`:
            case `ldh_test_performed_99_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].ldh_test_performed = value_int;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2BLdhEmpty = false;

                updatedArray[parentIdx].bloodTestDetails[0].ldh_test_result_checked = null;  
                updatedArray[parentIdx].bloodTestDetails[0].ldh_test_result = "";
                updatedArray[parentIdx].bloodTestDetails[0].ldh_test_result_unk = null;

                updatedArray[parentIdx].bloodTestDetails[0].q36T2DLdhResultRange = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DLdhEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DLdhResultEmpty = false;
            }
                break;
            case `ldh_test_result_checked_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].ldh_test_result_checked = value_int;
                updatedArray[parentIdx].bloodTestDetails[0].ldh_test_result_unk = null;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DLdhEmpty = false;

                updatedArray[parentIdx].bloodTestDetails[0].q36T2DLdhResultRange = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DLdhResultEmpty = false;
            }
                break;
            case `ldh_test_result_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].ldh_test_result = value;
                updatedArray[parentIdx].bloodTestDetails[0].ldh_test_result_checked = 1;
                updatedArray[parentIdx].bloodTestDetails[0].ldh_test_result_unk = null;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DLdhEmpty = false;

                updatedArray[parentIdx].bloodTestDetails[0].q36T2DLdhResultEmpty = false;
                if (parseFloat(value) < 0 || parseFloat(value) > 1000) {
                    let resultRange = TLSMonitoring_Msgs.TestCompleteTxtEmptyMsg.replace('[value]', value);
                    resultRange = resultRange.replace('[value units]', 'U/L');
                    setQ36ResultLdhOutOfRange(resultRange);
                    updatedArray[parentIdx].bloodTestDetails[0].q36T2DLdhResultRange = true;
                }
                else {
                    updatedArray[parentIdx].bloodTestDetails[0].q36T2DLdhResultRange = false;
                }
            }
                break;
            case `ldh_test_result_unk_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].ldh_test_result_checked = null;
                updatedArray[parentIdx].bloodTestDetails[0].ldh_test_result = "";
                updatedArray[parentIdx].bloodTestDetails[0].ldh_test_result_unk = value_int;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DLdhEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DLdhResultRange = false;

                updatedArray[parentIdx].bloodTestDetails[0].q36T2DLdhResultEmpty = false;
            }
                break;

            //Radio Q36 Table2 Phosphorus
            case `pho_test_performed_1_${parentIdx}`:
            case `pho_test_performed_2_${parentIdx}`:
            case `pho_test_performed_99_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].pho_test_performed = value_int;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2BPhoEmpty = false;

                updatedArray[parentIdx].bloodTestDetails[0].pho_test_result_checked = null;  
                updatedArray[parentIdx].bloodTestDetails[0].pho_test_result = "";
                updatedArray[parentIdx].bloodTestDetails[0].pho_test_result_unk = null;

                updatedArray[parentIdx].bloodTestDetails[0].q36T2DPhoResultRange = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DPhoEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DPhoResultEmpty = false;
            }
                break;
            case `pho_test_result_checked_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].pho_test_result_checked = value_int;
                updatedArray[parentIdx].bloodTestDetails[0].pho_test_result_unk = null;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DPhoEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DPhoEmpty = false;

                updatedArray[parentIdx].bloodTestDetails[0].q36T2DPhoResultRange = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DPhoResultEmpty = false;
            }
                break;
            case `pho_test_result_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].pho_test_result = value;
                updatedArray[parentIdx].bloodTestDetails[0].pho_test_result_checked = 1;
                updatedArray[parentIdx].bloodTestDetails[0].pho_test_result_unk = null;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DPhoEmpty = false;

                updatedArray[parentIdx].bloodTestDetails[0].q36T2DPhoResultEmpty = false;
                if (parseFloat(value) < 0 || parseFloat(value) > 5) {
                    let resultRange = TLSMonitoring_Msgs.TestCompleteTxtEmptyMsg.replace('[value]', value);
                    resultRange = resultRange.replace('[value units]', 'mg/dL');
                    setQ36ResultPhoOutOfRange(resultRange);
                    updatedArray[parentIdx].bloodTestDetails[0].q36T2DPhoResultRange = true;
                }
                else {
                    updatedArray[parentIdx].bloodTestDetails[0].q36T2DPhoResultRange = false;
                }
            }
                break;
            case `pho_test_result_unk_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].pho_test_result_checked = null;
                updatedArray[parentIdx].bloodTestDetails[0].pho_test_result = "";
                updatedArray[parentIdx].bloodTestDetails[0].pho_test_result_unk = value_int;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DPhoEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DPhoResultRange = false;

                updatedArray[parentIdx].bloodTestDetails[0].q36T2DPhoResultEmpty = false;
            }
                break;

            //Radio Q36 Table2 Potassium
            case `pot_test_performed_1_${parentIdx}`:
            case `pot_test_performed_2_${parentIdx}`:
            case `pot_test_performed_99_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].pot_test_performed = value_int;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2BPotEmpty = false;

                updatedArray[parentIdx].bloodTestDetails[0].pot_test_result_checked = null;  
                updatedArray[parentIdx].bloodTestDetails[0].pot_test_result = "";
                updatedArray[parentIdx].bloodTestDetails[0].pot_test_result_unk = null;

                updatedArray[parentIdx].bloodTestDetails[0].q36T2DPotResultRange = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DPotEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DPotResultEmpty = false;
            }
                break;
            case `pot_test_result_checked_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].pot_test_result_checked = value_int;
                updatedArray[parentIdx].bloodTestDetails[0].pot_test_result_unk = null;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DPotEmpty = false;

                updatedArray[parentIdx].bloodTestDetails[0].q36T2DPotResultRange = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DPotResultEmpty = false;
            }
                break;
            case `pot_test_result_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].pot_test_result = value;
                updatedArray[parentIdx].bloodTestDetails[0].pot_test_result_checked = 1;
                updatedArray[parentIdx].bloodTestDetails[0].pot_test_result_unk = null;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DPotEmpty = false;

                updatedArray[parentIdx].bloodTestDetails[0].q36T2DPotResultEmpty = false;
                if (parseFloat(value) < 0 || parseFloat(value) > 10) {
                    let resultRange = TLSMonitoring_Msgs.TestCompleteTxtEmptyMsg.replace('[value]', value);
                    resultRange = resultRange.replace('[value units]', 'mmol/L');
                    setQ36ResultPotOutOfRange(resultRange);
                    updatedArray[parentIdx].bloodTestDetails[0].q36T2DPotResultRange = true;
                }
                else {
                    updatedArray[parentIdx].bloodTestDetails[0].q36T2DPotResultRange = false;
                }
            }
                break;
            case `pot_test_result_unk_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].pot_test_result_checked = null;
                updatedArray[parentIdx].bloodTestDetails[0].pot_test_result = "";
                updatedArray[parentIdx].bloodTestDetails[0].pot_test_result_unk = value_int;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DPotEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DPotResultRange = false;

                updatedArray[parentIdx].bloodTestDetails[0].q36T2DPotResultEmpty = false;
            }
                break;

            //Radio Q36 Table2 Uric acid
            case `uric_test_performed_1_${parentIdx}`:
            case `uric_test_performed_2_${parentIdx}`:
            case `uric_test_performed_99_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].uric_test_performed = value_int;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2BUricEmpty = false;

                updatedArray[parentIdx].bloodTestDetails[0].uric_test_result_checked = null;  
                updatedArray[parentIdx].bloodTestDetails[0].uric_test_result = "";
                updatedArray[parentIdx].bloodTestDetails[0].uric_test_result_unk = null;

                updatedArray[parentIdx].bloodTestDetails[0].q36T2DuricResultRange = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DuricEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DuricResultEmpty = false;
            }
                break;
            case `uric_test_result_checked_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].uric_test_result_checked = value_int;
                updatedArray[parentIdx].bloodTestDetails[0].uric_test_result_unk = null;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DUricEmpty = false;

                updatedArray[parentIdx].bloodTestDetails[0].q36T2DUricResultRange = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DUricResultEmpty = false;
            }
                break;
            case `uric_test_result_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].uric_test_result = value;
                updatedArray[parentIdx].bloodTestDetails[0].uric_test_result_checked = 1;
                updatedArray[parentIdx].bloodTestDetails[0].uric_test_result_unk = null;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DUricEmpty = false;

                updatedArray[parentIdx].bloodTestDetails[0].q36T2DUricResultEmpty = false;
                if (parseFloat(value) < 0 || parseFloat(value) > 10) {
                    let resultRange = TLSMonitoring_Msgs.TestCompleteTxtEmptyMsg.replace('[value]', value);
                    resultRange = resultRange.replace('[value units]', 'mg/dL');
                    setQ36ResultUricOutOfRange(resultRange);
                    updatedArray[parentIdx].bloodTestDetails[0].q36T2DUricResultRange = true;
                }
                else {
                    updatedArray[parentIdx].bloodTestDetails[0].q36T2DUricResultRange = false;
                }
            }
                break;
            case `uric_test_result_unk_${parentIdx}`: {
                updatedArray[parentIdx].bloodTestDetails[0].uric_test_result_checked = null;
                updatedArray[parentIdx].bloodTestDetails[0].uric_test_result = "";
                updatedArray[parentIdx].bloodTestDetails[0].uric_test_result_unk = value_int;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DUricEmpty = false;
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DUricResultRange = false;

                updatedArray[parentIdx].bloodTestDetails[0].q36T2DUricResultEmpty = false;
            }
                break;
            default:
                break;

        }

        //Col B Soft Error
        if (parentIdx != null) {
            let loopData = updatedArray[parentIdx].bloodTestDetails[0];
            if ((loopData.alc_test_performed === Values.value2 || loopData.alc_test_performed ===Values.value99) &&
                (loopData.cal_test_performed === Values.value2 || loopData.cal_test_performed ===Values.value99) &&
                (loopData.sc_test_performed === Values.value2 || loopData.sc_test_performed ===Values.value99) &&
                (loopData.crcl_test_performed === Values.value2 || loopData.crcl_test_performed ===Values.value99) &&
                (loopData.ldh_test_performed === Values.value2 || loopData.ldh_test_performed ===Values.value99) &&
                (loopData.pho_test_performed === Values.value2 || loopData.pho_test_performed ===Values.value99) &&
                (loopData.pot_test_performed === Values.value2 || loopData.pot_test_performed ===Values.value99) &&
                (loopData.uric_test_performed === Values.value2 || loopData.uric_test_performed ===Values.value99)) {
                updatedArray[parentIdx].bloodTestDetails[0].q36T2BAllUnkNotTestedEmpty = true;
            }
            else {
                updatedArray[parentIdx].bloodTestDetails[0].q36T2BAllUnkNotTestedEmpty = false;
            }

            //Col D Soft Error
            if (loopData.alc_test_result_unk ===Values.value99 && loopData.cal_test_result_unk ===Values.value99 && loopData.sc_test_result_unk ===Values.value99 &&
                loopData.crcl_test_result_unk ===Values.value99 && loopData.ldh_test_result_unk ===Values.value99 && loopData.pho_test_result_unk ===Values.value99 &&
                loopData.pot_test_result_unk ===Values.value99 && loopData.uric_test_result_unk ===Values.value99) {
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DAllUnknownEmpty = true;
            }
            else {
                updatedArray[parentIdx].bloodTestDetails[0].q36T2DAllUnknownEmpty = false;
            }
        }

        if (testNum > 0) {
            var bloodTestColBValue = updatedArray[parentIdx].bloodTestDetails[0].reasonrepeattest;
            var bloodTestColCValue = updatedArray[parentIdx].bloodTestDetails[0].timeofblooddraw;

            var bloodTestColBValuePrev = updatedArray[parentIdx].bloodTestDetails[0].reasonrepeattest;
            var bloodTestColCValuePrev = updatedArray[parentIdx].bloodTestDetails[0].timeofblooddraw;
            if ((bloodTestColBValuePrev === 1 && bloodTestColCValuePrev === 1) && (bloodTestColBValue === 1 && bloodTestColCValue === 1)) {
                updatedArray[parentIdx].bloodTestDetails[0].q36T1ColBCSoft = true;
            }
            else {
                updatedArray[parentIdx].bloodTestDetails[0].q36T1ColBCSoft = false;
            }
        }

        setBloodTestRowData([...updatedArray]);
    };

    //Q36
    const HandleQ36RepeaterDateChange = (e, date, parentIdx) => {
        const { name, id } = e;
        const parsedDate = ParseDate(date);

        let updatedArray = [...bloodTestRowData];
        updatedArray[parentIdx].bloodTestDetails[0].blooddraw_date = date;
        updatedArray[parentIdx].bloodTestDetails[0].blooddraw_date_checked = 1;
        updatedArray[parentIdx].bloodTestDetails[0].blooddraw_date_unk = null;
        updatedArray[parentIdx].bloodTestDetails[0].q36T1AEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[0].q36T1ADateEmpty = false;
        updatedArray[parentIdx].bloodTestDetails[0].q36T1AInvalidDtError = updatedArray[parentIdx].bloodTestDetails[0].blooddraw_date === "InValid Date";

        //[If A (date) for N < A (date) for N – 1, then display the warning message (soft)]
        // const nMinus1DrawDate = id > 0 ? updatedArray[parentIdx].bloodTestDetails[0].testperformed_dt : null;
        // if (nMinus1DrawDate != null) {
        //     const drawDtLTNMinus1 = IsDateBefore(parsedDate, nMinus1DrawDate);
        //     if (drawDtLTNMinus1) {
        //         updatedArray[parentIdx].bloodTestDetails[0].q36T1ADateCheckError = true;
        //     } else {
        //         updatedArray[parentIdx].bloodTestDetails[0].q36T1ADateCheckError = false;
        //     }
        // }

        if (previousTestDate != null || previousTestDate != "") {
            const drawDtLTNMinus1 = IsDateBefore(parsedDate, previousTestDate);
            if (drawDtLTNMinus1) {
                updatedArray[parentIdx].bloodTestDetails[0].q36T1ADateCheckError = true;
            } else {
                updatedArray[parentIdx].bloodTestDetails[0].q36T1ADateCheckError = false;
            }
        }

        //[If A (date) > Venetoclax End Date OR A (date) < Venetoclax Start Date, then display warning (soft)]
        // const drawDtGTVenEDt = IsDateAfter(parsedDate, venetoclaxEndDate);
        // if (drawDtGTVenEDt) {
        //     updatedArray[parentIdx].bloodTestDetails[0].q36T1ADateVenCheckError = true;
        // } else {
        //     updatedArray[parentIdx].bloodTestDetails[0].q36T1ADateVenCheckError = false;
        // }

        // const drawDtLTVenSDt = IsDateBefore(parsedDate, venetoclaxStartDate);
        // if (drawDtLTVenSDt) {
        //     updatedArray[parentIdx].bloodTestDetails[0].q36T1ADateVenCheckError = true;
        // } else {
        //     updatedArray[parentIdx].bloodTestDetails[0].q36T1ADateVenCheckError = false;
        // }
        const drawDtGTVenEDt = IsDateAfter(parsedDate, venetoclaxEndDate);
        const drawDtLTVenSDt = IsDateBefore(parsedDate, venetoclaxStartDate);
        updatedArray[parentIdx].bloodTestDetails[0].q36T1ADateVenCheckError = drawDtGTVenEDt || drawDtLTVenSDt;


        setBloodTestRowData([...updatedArray]);
    };


    function formatInput(e) {
        const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab", "Enter"];
        const invalidChars = /[^0-9.]/; // Only allow numbers and the decimal point

        if (allowedKeys.includes(e.key)) {
            return;
        }
        if (e.key === "." && e.target.value.includes(".") || invalidChars.test(e.key)) {
            // Prevent multiple decimal points or invalid characters
            e.preventDefault();
        }
    }

    function formatWholeNumbers(e) {
        // Allow backspace, arrow keys, and delete
        if (['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'].includes(e.key)) {
            return;
        }
        // Prevent non-numeric characters
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
            return;
        }
        const value = e.target.value;
        const selectionStart = e.target.selectionStart;
        const newValue = value.slice(0, selectionStart) + e.key + value.slice(e.target.selectionEnd);
        // Prevent multiple '.'
        if (e.key === '.' && value.includes('.')) {
            e.preventDefault();
            return;
        }
        // Prevent '.' as the first character
        if (e.key === '.' && value === '') {
            e.preventDefault();
            return;
        }
        // Allow only one digit after decimal point
        if (newValue.includes('.') && newValue.split('.')[1].length > 2) {
            e.preventDefault();
            return;
        }
    }



    return (
        <>
            <section className="survey-patient-details-section">
                <div className="row">
                    <PatientDetails />
                    <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
                        <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
                    </div>
                </div>
            </section>

            <section className="main-survey-section">
                <div className="row">
                    <SideBarMenu activePageId={SideMenuItems.TlsMonitoringLabTest.toString() + weekNum.toString() + testNum.toString()} />

                    <div className="col-md-9 col-pad-left">
                        <div className="survey-section-content">
                            <div className="survey-section-content-header">
                                <h4>TEST - {testNum}</h4>
                            </div>


                            {showTopErrMsg && (
                                <div className="clean-error ">
                                    <ErrorField
                                        show={showTopErrMsg}
                                        message={CommonError_Msgs.Hard_Empty}
                                    />
                                </div>
                            )}


                            <div className="survey-question-section">
                                <div className="survey-question-content">
                                    <div className="question-answer">

                                        <div className="question-bot-sapce single-dig-quest">

                                            {/* Loop 2 Start */}


                                            {bloodTestRowData &&
                                                // bloodTestRowData.length > 0 &&
                                                // bloodTestRowData.some(item => itemParent.bloodTestDetails.tlsmonitoringweekbridgeid > 0) && 
                                                bloodTestRowData?.map((itemParent, itemParentidx) => (
                                                    <div className="dependent-section">

                                                        {itemParent.bloodTestDetails && typeof itemParent.bloodTestDetails === "object" && (
                                                            <div className="dependent-section" key={itemParentidx}>
                                                                <div className="question question-weight">
                                                                    <span>36.</span>
                                                                    <span className="quest-text-pad">
                                                                        Please indicate the following details for the {getOrdinalSuffix(testNum)}{" "}
                                                                        blood test completed.{" "}
                                                                    </span>
                                                                </div>
                                                                <div className="answer-pad-left">
                                                                    <div className="grid-overflow">
                                                                        <table className="modal-popup-table-xlarge">
                                                                            <thead className="modal-popup-table-header">
                                                                                <tr>
                                                                                    <th className="modal-popup-table-header-cell">
                                                                                        Date of blood draw{" "}
                                                                                        <br />
                                                                                        <br />
                                                                                        <i>
                                                                                            Please report tests in chronological order{" "}
                                                                                        </i>
                                                                                    </th>
                                                                                    <th className="modal-popup-table-header-cell column-custom-width-245">
                                                                                        Please indicate the reason for the repeat lab
                                                                                        test
                                                                                    </th>
                                                                                    <th className="modal-popup-table-header-cell">
                                                                                        Time of blood draw
                                                                                    </th>
                                                                                    <th className="modal-popup-table-header-cell">
                                                                                        Test setting
                                                                                    </th>
                                                                                    <th className="modal-popup-table-header-cell column-custom-width-245">
                                                                                        Please indicate whether clinical or laboratory
                                                                                        TLS as per the
                                                                                        {" "}
                                                                                        <span
                                                                                            data-tooltip-id="encounterTip"
                                                                                            className="modal-popup"
                                                                                            onClick={() =>
                                                                                                setShowHideModalPopup(true)
                                                                                            }
                                                                                        >
                                                                                            Cairo-Bishop criteria
                                                                                        </span>
                                                                                        {" "}
                                                                                        or
                                                                                        {" "}
                                                                                        <span
                                                                                            data-tooltip-id="encounterTip"
                                                                                            className="modal-popup"
                                                                                            onClick={() =>
                                                                                                setShowHideModalPopupLink(true)
                                                                                            }
                                                                                        >
                                                                                            Howard criteria
                                                                                        </span>
                                                                                        {" "}
                                                                                        was identified during this
                                                                                        assessment.
                                                                                    </th>
                                                                                    <th className="modal-popup-table-header-cell column-custom-width-400">
                                                                                        Was an intervention given following this lab
                                                                                        result?
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td className="modal-popup-table-cell">
                                                                                        <div className="date-field-padding option-fbox">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={"blood_draw_date_checked_" + itemParentidx}
                                                                                                name={"blood_draw_date_checked_" + itemParentidx}
                                                                                                value={1}
                                                                                                checked={itemParent.bloodTestDetails[0].blooddraw_date_checked === Values.value1}
                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                            />
                                                                                            <span className="">
                                                                                                <DateControl
                                                                                                    ctrlId={"blood_draw_date_" + itemParentidx}
                                                                                                    HandleDateChange={(e, date) => HandleQ36RepeaterDateChange(e, date, itemParentidx)}
                                                                                                    date={itemParent.bloodTestDetails[0].blooddraw_date}
                                                                                                    showUnkInfoTxt={false}
                                                                                                    minDate={dayjs("01-01-1920")}
                                                                                                    maxDate={dayjs()}
                                                                                                />
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className="option-without-input">
                                                                                            <label className="option-fbox">
                                                                                                <input
                                                                                                    type="radio"
                                                                                                    id={"blood_draw_date_unk_" + itemParentidx}
                                                                                                    name={"blood_draw_date_unk_" + itemParentidx}
                                                                                                    checked={itemParent.bloodTestDetails[0].blooddraw_date_unk ===Values.value99}
                                                                                                    value={99}
                                                                                                    onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                />
                                                                                                <span className="">
                                                                                                    Unknown
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <ErrorField
                                                                                            show={itemParent.bloodTestDetails[0].q36T1AEmpty}
                                                                                            message={TLSMonitoring_Msgs.Radio}
                                                                                        />
                                                                                        <ErrorField
                                                                                            show={itemParent.bloodTestDetails[0].q36T1ADateEmpty}
                                                                                            message={TLSMonitoring_Msgs.q36T1ADateEmptyMsg}
                                                                                        />
                                                                                        <ErrorField
                                                                                            show={itemParent.bloodTestDetails[0].q36T1ADateCheckError}
                                                                                            message={
                                                                                                <>
                                                                                                    {TLSMonitoring_Msgs.q36T1ADateCheckErrorMsg.split("[N]")[0]}
                                                                                                    {getOrdinalSuffix(testNum)}
                                                                                                    {TLSMonitoring_Msgs.q36T1ADateCheckErrorMsg.split("[N]")[1].split("[N-1]")[0]}
                                                                                                    {getOrdinalSuffix(testNum - 1)}
                                                                                                    {TLSMonitoring_Msgs.q36T1ADateCheckErrorMsg.split("[N-1]")[1]}
                                                                                                </>
                                                                                            }
                                                                                        />
                                                                                        <ErrorField
                                                                                            show={itemParent.bloodTestDetails[0].q36T1ADateVenCheckError}
                                                                                            message={TLSMonitoring_Msgs.q36T1ADateVenCheckErrorMSg}
                                                                                        />
                                                                                        <ErrorField
                                                                                            show={itemParent.bloodTestDetails[0].q36T1AInvalidDtError}
                                                                                            message={CommonError_Msgs.DateNotValid}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="modal-popup-table-cell">
                                                                                        {testNum > 1 ? (
                                                                                            <div>
                                                                                                <div className="option-without-input option-fbox">
                                                                                                    <input
                                                                                                        type="radio"
                                                                                                        id={"repeat_labtest_1_" + itemParentidx}
                                                                                                        name={"repeat_labtest_1_" + itemParentidx}
                                                                                                        checked={itemParent.bloodTestDetails[0].reasonrepeattest === Values.value1}
                                                                                                        value={1}
                                                                                                        onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                    />
                                                                                                    <label htmlFor={"repeat_labtest_1_" + itemParentidx}>
                                                                                                        <span className="">
                                                                                                            Per-label TLS assessment
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="option-without-input option-fbox">
                                                                                                    <input
                                                                                                        type="radio"
                                                                                                        id={"repeat_labtest_2_" + itemParentidx}
                                                                                                        name={"repeat_labtest_2_" + itemParentidx}
                                                                                                        checked={itemParent.bloodTestDetails[0].reasonrepeattest === Values.value2}
                                                                                                        value={2}
                                                                                                        onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                    />
                                                                                                    <label htmlFor={"repeat_labtest_2_" + itemParentidx}>
                                                                                                        <span className="">
                                                                                                            Yes, to confirm lab abnormality
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="option-without-input question-20-c-sub-option-custom">
                                                                                                    <div className="option-without-input">
                                                                                                        <span>Due to hemolyzed sample  </span>
                                                                                                        <label>
                                                                                                            <input
                                                                                                                type="radio"
                                                                                                                id={"repeat_labtest_labhemmo_1_" + itemParentidx}
                                                                                                                name={"repeat_labtest_labhemmo_1_" + itemParentidx}
                                                                                                                checked={itemParent.bloodTestDetails[0].confirm_lab_hemolyzed === Values.value1}
                                                                                                                value={1}
                                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                            />

                                                                                                            <span className="radio-text-padding">
                                                                                                                Yes
                                                                                                            </span>
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className="option-without-input">
                                                                                                        <label>
                                                                                                            <input
                                                                                                                type="radio"
                                                                                                                id={"repeat_labtest_labhemmo_2_" + itemParentidx}
                                                                                                                name={"repeat_labtest_labhemmo_2_" + itemParentidx}
                                                                                                                checked={itemParent.bloodTestDetails[0].confirm_lab_hemolyzed === Values.value2}
                                                                                                                value={2}
                                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                            />

                                                                                                            <span className="radio-text-padding">
                                                                                                                No
                                                                                                            </span>
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className="option-without-input">
                                                                                                        <label>
                                                                                                            <input
                                                                                                                type="radio"
                                                                                                                id={"repeat_labtest_labhemmo_99_" + itemParentidx}
                                                                                                                name={"repeat_labtest_labhemmo_99_" + itemParentidx}
                                                                                                                checked={itemParent.bloodTestDetails[0].confirm_lab_hemolyzed ===Values.value99}
                                                                                                                value={99}
                                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                            />

                                                                                                            <span className="radio-text-padding">
                                                                                                                Unknown
                                                                                                            </span>
                                                                                                        </label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="option-without-input option-fbox">
                                                                                                    <input
                                                                                                        type="radio"
                                                                                                        id={"repeat_labtest_3_" + itemParentidx}
                                                                                                        name={"repeat_labtest_3_" + itemParentidx}
                                                                                                        checked={itemParent.bloodTestDetails[0].reasonrepeattest === Values.value3}
                                                                                                        value={3}
                                                                                                        onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                    />
                                                                                                    <label htmlFor={"repeat_labtest_3_" + itemParentidx}>
                                                                                                        <span className="">
                                                                                                            Yes, for post-intervention assessment
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="option-without-input question-20-c-sub-option-custom">
                                                                                                    <div className="option-without-input">
                                                                                                        <span>Due to hemolyzed sample  </span>
                                                                                                        <label>
                                                                                                            <input
                                                                                                                type="radio"
                                                                                                                id={"repeat_labtest_posthemmo_1_" + itemParentidx}
                                                                                                                name={"repeat_labtest_posthemmo_1_" + itemParentidx}
                                                                                                                checked={itemParent.bloodTestDetails[0].post_hemolyzed === Values.value1}
                                                                                                                value={1}
                                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                            />

                                                                                                            <span className="radio-text-padding">
                                                                                                                Yes
                                                                                                            </span>
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className="option-without-input">
                                                                                                        <label>
                                                                                                            <input
                                                                                                                type="radio"
                                                                                                                id={"repeat_labtest_posthemmo_2_" + itemParentidx}
                                                                                                                name={"repeat_labtest_posthemmo_2_" + itemParentidx}
                                                                                                                checked={itemParent.bloodTestDetails[0].post_hemolyzed === Values.value2}
                                                                                                                value={2}
                                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                            />

                                                                                                            <span className="radio-text-padding">
                                                                                                                No
                                                                                                            </span>
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className="option-without-input">
                                                                                                        <label>
                                                                                                            <input
                                                                                                                type="radio"
                                                                                                                id={"repeat_labtest_posthemmo_99_" + itemParentidx}
                                                                                                                name={"repeat_labtest_posthemmo_99_" + itemParentidx}
                                                                                                                checked={itemParent.bloodTestDetails[0].post_hemolyzed === Values.value99}
                                                                                                                value={99}
                                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                            />

                                                                                                            <span className="radio-text-padding">
                                                                                                                Unknown
                                                                                                            </span>
                                                                                                        </label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="option-without-input option-fbox">
                                                                                                    <input
                                                                                                        type="radio"
                                                                                                        id={"repeat_labtest_4_" + itemParentidx}
                                                                                                        name={"repeat_labtest_4_" + itemParentidx}
                                                                                                        checked={itemParent.bloodTestDetails[0].reasonrepeattest === Values.value4}
                                                                                                        value={4}
                                                                                                        onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                    />
                                                                                                    <label htmlFor={"repeat_labtest_4_" + itemParentidx}>
                                                                                                        <span className="">
                                                                                                            Yes, due to inconclusive prior lab
                                                                                                            result{" "}
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="option-without-input question-20-c-sub-option-custom">
                                                                                                    <div className="option-without-input">
                                                                                                        <span>Due to hemolyzed sample  </span>
                                                                                                        <label>
                                                                                                            <input
                                                                                                                type="radio"
                                                                                                                id={"repeat_labtest_inhemmo_1_" + itemParentidx}
                                                                                                                name={"repeat_labtest_inhemmo_1_" + itemParentidx}
                                                                                                                checked={itemParent.bloodTestDetails[0].inconclusive_hemolyzed === Values.value1}
                                                                                                                value={1}
                                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                            />

                                                                                                            <span className="radio-text-padding">
                                                                                                                Yes
                                                                                                            </span>
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className="option-without-input">
                                                                                                        <label>
                                                                                                            <input
                                                                                                                type="radio"
                                                                                                                id={"repeat_labtest_inhemmo_2_" + itemParentidx}
                                                                                                                name={"repeat_labtest_inhemmo_2_" + itemParentidx}
                                                                                                                checked={itemParent.bloodTestDetails[0].inconclusive_hemolyzed === Values.value2}
                                                                                                                value={2}
                                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                            />

                                                                                                            <span className="radio-text-padding">
                                                                                                                No
                                                                                                            </span>
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className="option-without-input">
                                                                                                        <label>
                                                                                                            <input
                                                                                                                type="radio"
                                                                                                                id={"repeat_labtest_inhemmo_99_" + itemParentidx}
                                                                                                                name={"repeat_labtest_inhemmo_99_" + itemParentidx}
                                                                                                                checked={itemParent.bloodTestDetails[0].inconclusive_hemolyzed === Values.value99}
                                                                                                                value={99}
                                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                            />

                                                                                                            <span className="radio-text-padding">
                                                                                                                Unknown
                                                                                                            </span>
                                                                                                        </label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <ErrorField
                                                                                                    show={itemParent.bloodTestDetails[0].q36T1BEmpty}
                                                                                                    message={TLSMonitoring_Msgs.Radio}
                                                                                                />
                                                                                                <ErrorField
                                                                                                    show={itemParent.bloodTestDetails[0].q36T1B1SubValueEmpty}
                                                                                                    message={TLSMonitoring_Msgs.q36T1BSubValueEmptyMsg}
                                                                                                />
                                                                                                <ErrorField
                                                                                                    show={itemParent.bloodTestDetails[0].q36T1B2SubValueEmpty}
                                                                                                    message={TLSMonitoring_Msgs.q36T1BSubValueEmptyMsg}
                                                                                                />
                                                                                                <ErrorField
                                                                                                    show={itemParent.bloodTestDetails[0].q36T1B3SubValueEmpty}
                                                                                                    message={TLSMonitoring_Msgs.q36T1BSubValueEmptyMsg}
                                                                                                />
                                                                                                <ErrorField
                                                                                                    show={itemParent.bloodTestDetails[0].q36T1ColBCSoft}
                                                                                                    message={TLSMonitoring_Msgs.q36T1ColBCSoftMsg}
                                                                                                />
                                                                                            </div>) : <div>NA</div>}
                                                                                    </td>
                                                                                    <td className="modal-popup-table-cell">
                                                                                        <div className="option-without-input option-fbox">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={"blooddraw_time_1_" + itemParentidx}
                                                                                                name={"blooddraw_time_1_" + itemParentidx}
                                                                                                checked={itemParent.bloodTestDetails[0].timeofblooddraw === Values.value1}
                                                                                                value={1}
                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                            />
                                                                                            <label htmlFor={"blooddraw_time_1_" + itemParentidx}>
                                                                                                <span className="">
                                                                                                    Pre-dose
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="option-without-input option-fbox">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={"blooddraw_time_2_" + itemParentidx}
                                                                                                name={"blooddraw_time_2_" + itemParentidx}
                                                                                                checked={itemParent.bloodTestDetails[0].timeofblooddraw === Values.value2}
                                                                                                value={2}
                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                            />
                                                                                            <label htmlFor={"blooddraw_time_2_" + itemParentidx}>
                                                                                                <span className="">
                                                                                                    6-8 hours post-dose
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="option-without-input option-fbox">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={"blooddraw_time_3_" + itemParentidx}
                                                                                                name={"blooddraw_time_3_" + itemParentidx}
                                                                                                checked={itemParent.bloodTestDetails[0].timeofblooddraw === Values.value3}
                                                                                                value={3}
                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                            />
                                                                                            <label htmlFor={"blooddraw_time_3_" + itemParentidx}>
                                                                                                <span className="">
                                                                                                    24 hours post-dose
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="option-without-input option-fbox custom-gap">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={"blooddraw_time_4_" + itemParentidx}
                                                                                                name={"blooddraw_time_4_" + itemParentidx}
                                                                                                checked={itemParent.bloodTestDetails[0].timeofblooddraw === Values.value4}
                                                                                                value={4}
                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                            />
                                                                                            <label htmlFor={"blooddraw_time_4_" + itemParentidx} style={{ paddingLeft: "9px" }}>
                                                                                                <span className="">
                                                                                                    Other{" "}
                                                                                                </span>
                                                                                            </label>
                                                                                            <input
                                                                                                className="input-dash input_custome-space_margin"
                                                                                                name={"blooddraw_oth_time_hr_" + itemParentidx}
                                                                                                type="text"
                                                                                                id={"blooddraw_oth_time_hr_" + itemParentidx}
                                                                                                maxLength={2}
                                                                                                value={itemParent.bloodTestDetails[0].blooddraw_oth_time_hr}
                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                onKeyDown={(e) => {
                                                                                                    // Allow backspace, arrow keys, and delete
                                                                                                    if (['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'].includes(e.key)) {
                                                                                                        return;
                                                                                                    }
                                                                                                    // Prevent non-numeric characters
                                                                                                    if (!/[0-9]/.test(e.key)) {
                                                                                                        e.preventDefault();
                                                                                                        return;
                                                                                                    }
                                                                                                    const value = e.target.value;
                                                                                                    const selectionStart = e.target.selectionStart;
                                                                                                    const newValue = value.slice(0, selectionStart) + e.key + value.slice(e.target.selectionEnd);
                                                                                                    // Prevent multiple '.'
                                                                                                    if (e.key === '.' && value.includes('.')) {
                                                                                                        e.preventDefault();
                                                                                                        return;
                                                                                                    }
                                                                                                    // Prevent '.' as the first character
                                                                                                    if (e.key === '.' && value === '') {
                                                                                                        e.preventDefault();
                                                                                                        return;
                                                                                                    }
                                                                                                    // Allow only one digit after decimal point
                                                                                                    if (newValue.includes('.') && newValue.split('.')[1].length > 2) {
                                                                                                        e.preventDefault();
                                                                                                        return;
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                            :
                                                                                            <input
                                                                                                className="input-dash input_custome-space_margin"
                                                                                                name={"blooddraw_oth_time_min_" + itemParentidx}
                                                                                                id={"blooddraw_oth_time_min_" + itemParentidx}
                                                                                                type="text"
                                                                                                maxLength={2}
                                                                                                value={itemParent.bloodTestDetails[0].blooddraw_oth_time_min}
                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                onKeyDown={(e) => {
                                                                                                    // Allow backspace, arrow keys, and delete
                                                                                                    if (['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'].includes(e.key)) {
                                                                                                        return;
                                                                                                    }
                                                                                                    // Prevent non-numeric characters
                                                                                                    if (!/[0-9]/.test(e.key)) {
                                                                                                        e.preventDefault();
                                                                                                        return;
                                                                                                    }
                                                                                                    const value = e.target.value;
                                                                                                    const selectionStart = e.target.selectionStart;
                                                                                                    const newValue = value.slice(0, selectionStart) + e.key + value.slice(e.target.selectionEnd);
                                                                                                    // Prevent multiple '.'
                                                                                                    if (e.key === '.' && value.includes('.')) {
                                                                                                        e.preventDefault();
                                                                                                        return;
                                                                                                    }
                                                                                                    // Prevent '.' as the first character
                                                                                                    if (e.key === '.' && value === '') {
                                                                                                        e.preventDefault();
                                                                                                        return;
                                                                                                    }
                                                                                                    // Allow only one digit after decimal point
                                                                                                    if (newValue.includes('.') && newValue.split('.')[1].length > 2) {
                                                                                                        e.preventDefault();
                                                                                                        return;
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                            <label htmlFor="veneto_RampUp_Dose_1">
                                                                                                <span className="">
                                                                                                    (hour/min)
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>

                                                                                        <div className="option-without-input option-fbox">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={"blooddraw_time_99_" + itemParentidx}
                                                                                                name={"blooddraw_time_99_" + itemParentidx}
                                                                                                checked={itemParent.bloodTestDetails[0].timeofblooddraw === Values.value99}
                                                                                                value={99}
                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                            />
                                                                                            <label htmlFor={"blooddraw_time_99_" + itemParentidx}>
                                                                                                <span className="">
                                                                                                    Unknown
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <ErrorField
                                                                                            show={itemParent.bloodTestDetails[0].q36T1CEmpty}
                                                                                            message={TLSMonitoring_Msgs.Radio}
                                                                                        />
                                                                                        <ErrorField
                                                                                            show={itemParent.bloodTestDetails[0].q36T1CTimeEmpty}
                                                                                            message={TLSMonitoring_Msgs.q36T1CTimeEmptyMsg}
                                                                                        />
                                                                                        <ErrorField
                                                                                            show={itemParent.bloodTestDetails[0].q36T1CHrsEmpty}
                                                                                            message={TLSMonitoring_Msgs.q36T1CHrsEmptyMsg}
                                                                                        />
                                                                                        <ErrorField
                                                                                            show={itemParent.bloodTestDetails[0].q36T1CMinsEmpty}
                                                                                            message={TLSMonitoring_Msgs.q36T1CMinsEmptyMsg}
                                                                                        />
                                                                                        <ErrorField
                                                                                            show={itemParent.bloodTestDetails[0].q36T1ColBCSoft}
                                                                                            message={TLSMonitoring_Msgs.q36T1ColBCSoftMsg}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="modal-popup-table-cell">
                                                                                        <div className="option-without-input option-fbox">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={"tls_settings_1_" + itemParentidx}
                                                                                                name={"tls_settings_1_" + itemParentidx}
                                                                                                checked={itemParent.bloodTestDetails[0].testsetting === Values.value1}
                                                                                                value={1}
                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                            />
                                                                                            <label htmlFor={"tls_settings_1_" + itemParentidx}>
                                                                                                <span className="">
                                                                                                    Onsite
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="option-without-input option-fbox">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={"tls_settings_2_" + itemParentidx}
                                                                                                name={"tls_settings_2_" + itemParentidx}
                                                                                                checked={itemParent.bloodTestDetails[0].testsetting === Values.value2}
                                                                                                value={2}
                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                            />
                                                                                            <label htmlFor={"tls_settings_2_" + itemParentidx}>
                                                                                                <span className="">
                                                                                                    Offsite
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="option-without-input option-fbox">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={"tls_settings_99_" + itemParentidx}
                                                                                                name={"tls_settings_99_" + itemParentidx}
                                                                                                checked={itemParent.bloodTestDetails[0].testsetting === Values.value99}
                                                                                                value={99}
                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                            />
                                                                                            <label htmlFor={"tls_settings_99_" + itemParentidx}>
                                                                                                <span className="">
                                                                                                    Unknown
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <ErrorField
                                                                                            show={itemParent.bloodTestDetails[0].q36T1DEmpty}
                                                                                            message={TLSMonitoring_Msgs.Radio}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="modal-popup-table-cell">
                                                                                        <div className="option-without-input">
                                                                                            <label className="option-fbox">
                                                                                                <input className="option-input-margin"
                                                                                                    type="checkbox"
                                                                                                    id={"tls_laboratory_cairo_" + itemParentidx}
                                                                                                    name={"tls_laboratory_cairo_" + itemParentidx}
                                                                                                    checked={itemParent.bloodTestDetails[0].tls_laboratory_cairo}
                                                                                                    onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                />
                                                                                                <span className="">
                                                                                                  Laboratory TLS only per Cairo-Bishop Criteria
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="option-without-input">
                                                                                            <label className="option-fbox">
                                                                                                <input className="option-input-margin"
                                                                                                    type="checkbox"
                                                                                                    id={"tls_laboratory_" + itemParentidx}
                                                                                                    name={"tls_laboratory_" + itemParentidx}
                                                                                                    checked={itemParent.bloodTestDetails[0].tls_laboratory}
                                                                                                    onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                />
                                                                                                <span className="">
                                                                                                    Laboratory TLS only per Howard Criteria
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="option-without-input">
                                                                                            <label className="option-fbox">
                                                                                                <input className="option-input-margin"
                                                                                                    type="checkbox"
                                                                                                    id={"tls_clinical_cairo_" + itemParentidx}
                                                                                                    name={"tls_clinical_cairo_" + itemParentidx}
                                                                                                    checked={itemParent.bloodTestDetails[0].tls_clinical_cairo}
                                                                                                    onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                />
                                                                                                <span className="">
                                                                                                    Clinical TLS per Cairo-Bishop Criteria
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="option-without-input">
                                                                                            <label className="option-fbox">
                                                                                                <input className="option-input-margin"
                                                                                                    type="checkbox"
                                                                                                    id={"tls_clinical_" + itemParentidx}
                                                                                                    name={"tls_clinical_" + itemParentidx}
                                                                                                    checked={itemParent.bloodTestDetails[0].tls_clinical}
                                                                                                    onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                />
                                                                                                <span className="">
                                                                                                    Clinical TLS per Howard Criteria
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="option-without-input option-fbox">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={"tls_neither_" + itemParentidx}
                                                                                                name={"tls_neither_" + itemParentidx}
                                                                                                checked={itemParent.bloodTestDetails[0].tls_neither}
                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                            />
                                                                                            <label htmlFor={"tls_neither_" + itemParentidx}>
                                                                                                <span className="">
                                                                                                    Neither
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="option-without-input option-fbox">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={"tls_unk_" + itemParentidx}
                                                                                                name={"tls_unk_" + itemParentidx}
                                                                                                checked={itemParent.bloodTestDetails[0].tls_unk}
                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                            />
                                                                                            <label htmlFor={"tls_unk_" + itemParentidx}>
                                                                                                <span className="">
                                                                                                    Unknown
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <ErrorField
                                                                                            show={itemParent.bloodTestDetails[0].q36T1EEmpty}
                                                                                            message={TLSMonitoring_Msgs.Radio}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="modal-popup-table-cell">
                                                                                        <div className="option-without-input option-fbox">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={"intervention_given_1_" + itemParentidx}
                                                                                                name={"intervention_given_1_" + itemParentidx}
                                                                                                checked={itemParent.bloodTestDetails[0].intervention_given === 1}
                                                                                                value={1}
                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                            />
                                                                                            <label htmlFor={"intervention_given_1_" + itemParentidx}>
                                                                                                <span className="">
                                                                                                    Yes
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="option-without-input question-20-c-sub-option-custom">
                                                                                            <div className="option-without-input">

                                                                                                <label className="option-fbox">
                                                                                                    <input className="option-input-margin"
                                                                                                        type="checkbox"
                                                                                                        id={"yes_dosehold_" + itemParentidx}
                                                                                                        name={"yes_dosehold_" + itemParentidx}
                                                                                                        checked={itemParent.bloodTestDetails[0].yes_dosehold}
                                                                                                        onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                    />

                                                                                                    <span className="">
                                                                                                        Treatment interruption/dose hold
                                                                                                    </span>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="option_without_input">

                                                                                                <label className="option-fbox">
                                                                                                    <input className="option-input-margin"
                                                                                                        type="checkbox"
                                                                                                        id={"yes_ivhydration_" + itemParentidx}
                                                                                                        name={"yes_ivhydration_" + itemParentidx}
                                                                                                        checked={itemParent.bloodTestDetails[0].yes_ivhydration}
                                                                                                        onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                    />

                                                                                                    <span className="">
                                                                                                        IV hydration
                                                                                                    </span>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="option-without-input">

                                                                                                <label className="option-fbox">
                                                                                                    <input className="option-input-margin"
                                                                                                        type="checkbox"
                                                                                                        id={"yes_metabolic_" + itemParentidx}
                                                                                                        name={"yes_metabolic_" + itemParentidx}
                                                                                                        checked={itemParent.bloodTestDetails[0].yes_metabolic}
                                                                                                        onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                    />

                                                                                                    <span className="">
                                                                                                        Correction of metabolic abnormalities
                                                                                                    </span>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="option-without-input">

                                                                                                <label className="option-fbox">
                                                                                                    <input className="option-input-margin"
                                                                                                        type="checkbox"
                                                                                                        id={"yes_oth_" + itemParentidx}
                                                                                                        name={"yes_oth_" + itemParentidx}
                                                                                                        checked={itemParent.bloodTestDetails[0].yes_oth}
                                                                                                        onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                    />

                                                                                                    <div>
                                                                                                        <span className="">
                                                                                                            Other, specify:
                                                                                                        </span>
                                                                                                        <input
                                                                                                            className="input-dash input_custome-space"
                                                                                                            name={"yes_oth_txt_" + itemParentidx}
                                                                                                            type="text"
                                                                                                            maxLength={500}
                                                                                                            value={itemParent.bloodTestDetails[0].yes_oth_txt}
                                                                                                            onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                        />
                                                                                                    </div>
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="option-without-input option-fbox">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={"intervention_given_2_" + itemParentidx}
                                                                                                name={"intervention_given_2_" + itemParentidx}
                                                                                                checked={itemParent.bloodTestDetails[0].intervention_given === Values.value2}
                                                                                                value={2}
                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                            />
                                                                                            <label htmlFor={"intervention_given_2_" + itemParentidx}>
                                                                                                <span className="">
                                                                                                    No
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="option-without-input">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={"intervention_given_99_" + itemParentidx}
                                                                                                name={"intervention_given_99_" + itemParentidx}
                                                                                                checked={itemParent.bloodTestDetails[0].intervention_given === Values.value99}
                                                                                                value={99}
                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                            />
                                                                                            <label htmlFor={"intervention_given_99_" + itemParentidx}>
                                                                                                <span className="radio-text-padding">
                                                                                                    Unknown
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <ErrorField
                                                                                            show={itemParent.bloodTestDetails[0].q36T1FEmpty}
                                                                                            message={TLSMonitoring_Msgs.Radio}
                                                                                        />
                                                                                        <ErrorField
                                                                                            show={itemParent.bloodTestDetails[0].q36T1FChildEmpty}
                                                                                            message={TLSMonitoring_Msgs.q36T1FChildEmptyMsg}
                                                                                        />
                                                                                        <ErrorField
                                                                                            show={itemParent.bloodTestDetails[0].q36T1FOtherTxtEmpty}
                                                                                            message={TLSMonitoring_Msgs.q36T1FOtherTxtEmptyMsg}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <br />
                                                                    <i>Please note that the the test results in the table below should correspond with the test dates indicated above </i>
                                                                    <br />
                                                                    <div className="grid-overflow">
                                                                        {/* Sub-Loop 2.1 Start of Q36. Table 2 */}
                                                                        <table className="modal-popup-table-xlarge">
                                                                            <thead className="modal-popup-table-header">
                                                                                <tr>
                                                                                    <th className="modal-popup-table-header-cell column-custom-width-280">
                                                                                        Test{" "}
                                                                                    </th>
                                                                                    <th className="modal-popup-table-header-cell column-custom-width-160">
                                                                                        Test performed
                                                                                    </th>
                                                                                    <th className="modal-popup-table-header-cell">
                                                                                        Formula type
                                                                                    </th>
                                                                                    <th className="modal-popup-table-header-cell column-custom-width-150">
                                                                                        Test result
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td className="modal-popup-table-cell">
                                                                                        Absolute lymphocyte count (ALC)
                                                                                    </td>
                                                                                    <td className="modal-popup-table-cell">
                                                                                        <div className="option-without-input option-fbox">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={"alc_test_performed_1_" + itemParentidx}
                                                                                                name={"alc_test_performed_1_" + itemParentidx}
                                                                                                checked={itemParent.bloodTestDetails[0].alc_test_performed === Values.value1}
                                                                                                value={1}
                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                            />
                                                                                            <label htmlFor={"alc_test_performed_1_" + itemParentidx}>
                                                                                                <span className="">
                                                                                                    Yes
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="option-without-input option-fbox">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={"alc_test_performed_2_" + itemParentidx}
                                                                                                name={"alc_test_performed_2_" + itemParentidx}
                                                                                                checked={itemParent.bloodTestDetails[0].alc_test_performed === Values.value2}
                                                                                                value={2}
                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                            />
                                                                                            <label htmlFor={"alc_test_performed_2_" + itemParentidx}>
                                                                                                <span className="">
                                                                                                    Not tested
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="option-without-input option-fbox">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={"alc_test_performed_99_" + itemParentidx}
                                                                                                name={"alc_test_performed_99_" + itemParentidx}
                                                                                                checked={itemParent.bloodTestDetails[0].alc_test_performed ===Values.value99}
                                                                                                value={99}
                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                            />
                                                                                            <label htmlFor={"alc_test_performed_99_" + itemParentidx}>
                                                                                                <span className="">
                                                                                                    Unknown
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <ErrorField
                                                                                            show={itemParent.bloodTestDetails[0].q36T2BAlcEmpty}
                                                                                            message={TLSMonitoring_Msgs.Radio}
                                                                                        />
                                                                                        <ErrorField
                                                                                            show={itemParent.bloodTestDetails[0].q36T2BAlcAllUnkNotTestedEmpty}
                                                                                            message={TLSMonitoring_Msgs.q36T2BAllUnkNotTestedEmptyMsg}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="modal-popup-table-cell">
                                                                                        {itemParent.bloodTestDetails[0].alc_test_performed === 1 && (
                                                                                            <div>NA</div>
                                                                                        )}
                                                                                    </td>
                                                                                    <td className="modal-popup-table-cell column-custom-width-150">
                                                                                        {itemParent.bloodTestDetails[0].alc_test_performed === 1 && (
                                                                                            <div>
                                                                                                <div className="option-without-input">
                                                                                                    <input
                                                                                                        type="radio"
                                                                                                        id={"alc_test_result_checked_" + itemParentidx}
                                                                                                        name={"alc_test_result_checked_" + itemParentidx}
                                                                                                        checked={itemParent.bloodTestDetails[0].alc_test_result_checked === Values.value1}
                                                                                                        value={1}
                                                                                                        onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                    />
                                                                                                    <input
                                                                                                        className="input-dash input_custome-space_sm"
                                                                                                        name={"alc_test_result_" + itemParentidx}
                                                                                                        type="text"
                                                                                                        maxLength={7}
                                                                                                        value={itemParent.bloodTestDetails[0].alc_test_result}
                                                                                                        onChange={(e) => {
                                                                                                            const value = e.target.value;
                                                                                                            // const regex = /^\d{0,4}(\.\d?)?$/;
                                                                                                            const regex = /^\d{0,4}(\.\d{0,2})?$/;
                                                                                                            if (regex.test(value)) {
                                                                                                                HandleQ36RepeaterFieldChange(e, itemParentidx);
                                                                                                            }
                                                                                                        }}
                                                                                                        onKeyDown={formatInput}
                                                                                                    />

                                                                                                    <label htmlFor={"alc_test_result_checked_" + itemParentidx}>
                                                                                                        <span className="">
                                                                                                            10<sup>9</sup>/L
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="option-without-input option-fbox">
                                                                                                    <label className="option-fbox custom-spacing-lbl">
                                                                                                        <input
                                                                                                            type="radio"
                                                                                                            id={"alc_test_result_unk_" + itemParentidx}
                                                                                                            name={"alc_test_result_unk_" + itemParentidx}
                                                                                                            checked={itemParent.bloodTestDetails[0].alc_test_result_unk ===Values.value99}
                                                                                                            value={99}
                                                                                                            onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                        />
                                                                                                        <span htmlFor={"alc_test_result_unk_" + itemParentidx}>
                                                                                                            Unknown
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </div>
                                                                                                <ErrorField
                                                                                                    show={itemParent.bloodTestDetails[0].q36T2DAlcEmpty}
                                                                                                    message={TLSMonitoring_Msgs.Radio}
                                                                                                />
                                                                                                <ErrorField
                                                                                                    show={itemParent.bloodTestDetails[0].q36T2DAlcResultEmpty}
                                                                                                    message={TLSMonitoring_Msgs.q36T2DResultEmptyMsg}
                                                                                                />
                                                                                                <ErrorField
                                                                                                    show={itemParent.bloodTestDetails[0].q36T2DAlcAllUnknownEmpty}
                                                                                                    message={TLSMonitoring_Msgs.q36T2DAllUnknownEmptyMsg}
                                                                                                />
                                                                                                <ErrorField
                                                                                                    show={itemParent.bloodTestDetails[0].q36T2DAlcResultRange}
                                                                                                    message={q36ResultAlcOutOfRange}
                                                                                                />
                                                                                            </div>)}
                                                                                    </td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td className="modal-popup-table-cell">
                                                                                        Calcium
                                                                                    </td>
                                                                                    <td className="modal-popup-table-cell">
                                                                                        <div className="option-without-input option-fbox">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={"cal_test_performed_1_" + itemParentidx}
                                                                                                name={"cal_test_performed_1_" + itemParentidx}
                                                                                                checked={itemParent.bloodTestDetails[0].cal_test_performed === Values.value1}
                                                                                                value={1}
                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                            />
                                                                                            <label htmlFor={"cal_test_performed_1_" + itemParentidx}>
                                                                                                <span className="">
                                                                                                    Yes
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="option-without-input option-fbox">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={"cal_test_performed_2_" + itemParentidx}
                                                                                                name={"cal_test_performed_2_" + itemParentidx}
                                                                                                checked={itemParent.bloodTestDetails[0].cal_test_performed === Values.value2}
                                                                                                value={2}
                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                            />
                                                                                            <label htmlFor={"cal_test_performed_2_" + itemParentidx}>
                                                                                                <span className="">
                                                                                                    Not tested
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="option-without-input option-fbox">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={"cal_test_performed_99_" + itemParentidx}
                                                                                                name={"cal_test_performed_99_" + itemParentidx}
                                                                                                checked={itemParent.bloodTestDetails[0].cal_test_performed ===Values.value99}
                                                                                                value={99}
                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                            />
                                                                                            <label htmlFor={"cal_test_performed_99_" + itemParentidx}>
                                                                                                <span className="">
                                                                                                    Unknown
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <ErrorField
                                                                                            show={itemParent.bloodTestDetails[0].q36T2BCalEmpty}
                                                                                            message={TLSMonitoring_Msgs.Radio}
                                                                                        />
                                                                                        <ErrorField
                                                                                            show={itemParent.bloodTestDetails[0].q36T2BCalAllUnkNotTestedEmpty}
                                                                                            message={TLSMonitoring_Msgs.q36T2BAllUnkNotTestedEmptyMsg}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="modal-popup-table-cell">
                                                                                        {itemParent.bloodTestDetails[0].cal_test_performed === 1 && (
                                                                                            <div>NA</div>
                                                                                        )}
                                                                                    </td>
                                                                                    <td className="modal-popup-table-cell column-custom-width-150">
                                                                                        {itemParent.bloodTestDetails[0].cal_test_performed === 1 && (
                                                                                            <div>
                                                                                                <div className="option-without-input">
                                                                                                    <input
                                                                                                        type="radio"
                                                                                                        id={"cal_test_result_checked_" + itemParentidx}
                                                                                                        name={"cal_test_result_checked_" + itemParentidx}
                                                                                                        checked={itemParent.bloodTestDetails[0].cal_test_result_checked === Values.value1}
                                                                                                        value={1}
                                                                                                        onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                    />
                                                                                                    <input
                                                                                                        className="input-dash input_custome-space_sm"
                                                                                                        name={"cal_test_result_" + itemParentidx}
                                                                                                        type="text"
                                                                                                        maxLength={7}
                                                                                                        value={itemParent.bloodTestDetails[0].cal_test_result}
                                                                                                        onChange={(e) => {
                                                                                                            const value = e.target.value;
                                                                                                            const regex = /^\d{0,4}(\.\d{0,2})?$/;
                                                                                                            if (regex.test(value)) {
                                                                                                                HandleQ36RepeaterFieldChange(e, itemParentidx);
                                                                                                            }
                                                                                                        }}
                                                                                                        onKeyDown={formatInput}
                                                                                                    />
                                                                                                    <label htmlFor={"cal_test_result_checked_" + itemParentidx}>
                                                                                                        <span className="">
                                                                                                            mg/dL
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="option-without-input option-fbox">
                                                                                                    <label className="option-fbox custom-spacing-lbl">
                                                                                                        <input
                                                                                                            type="radio"
                                                                                                            id={"cal_test_result_unk_" + itemParentidx}
                                                                                                            name={"cal_test_result_unk_" + itemParentidx}
                                                                                                            checked={itemParent.bloodTestDetails[0].cal_test_result_unk === Values.value99}
                                                                                                            value={99}
                                                                                                            onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                        />
                                                                                                        <span htmlFor={"cal_test_result_unk_" + itemParentidx}>
                                                                                                            Unknown
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </div>
                                                                                                <ErrorField
                                                                                                    show={itemParent.bloodTestDetails[0].q36T2DCalEmpty}
                                                                                                    message={TLSMonitoring_Msgs.Radio}
                                                                                                />
                                                                                                <ErrorField
                                                                                                    show={itemParent.bloodTestDetails[0].q36T2DCalResultEmpty}
                                                                                                    message={TLSMonitoring_Msgs.q36T2DResultEmptyMsg}
                                                                                                />
                                                                                                <ErrorField
                                                                                                    show={itemParent.bloodTestDetails[0].q36T2DCalAllUnknownEmpty}
                                                                                                    message={TLSMonitoring_Msgs.q36T2DAllUnknownEmptyMsg}
                                                                                                />
                                                                                                <ErrorField
                                                                                                    show={itemParent.bloodTestDetails[0].q36T2DCalResultRange}
                                                                                                    message={q36ResultCalOutOfRange}
                                                                                                />
                                                                                            </div>)}
                                                                                    </td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td className="modal-popup-table-cell">
                                                                                        Serum creatinine
                                                                                    </td>
                                                                                    <td className="modal-popup-table-cell">
                                                                                        <div className="option-without-input option-fbox">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={"sc_test_performed_1_" + itemParentidx}
                                                                                                name={"sc_test_performed_1_" + itemParentidx}
                                                                                                checked={itemParent.bloodTestDetails[0].sc_test_performed === Values.value1}
                                                                                                value={1}
                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                            />
                                                                                            <label htmlFor={"sc_test_performed_1_" + itemParentidx}>
                                                                                                <span className="">
                                                                                                    Yes
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="option-without-input option-fbox">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={"sc_test_performed_2_" + itemParentidx}
                                                                                                name={"sc_test_performed_2_" + itemParentidx}
                                                                                                checked={itemParent.bloodTestDetails[0].sc_test_performed === Values.value2}
                                                                                                value={2}
                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                            />
                                                                                            <label htmlFor={"sc_test_performed_2_" + itemParentidx}>
                                                                                                <span className="">
                                                                                                    Not tested
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="option-without-input option-fbox">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={"sc_test_performed_99_" + itemParentidx}
                                                                                                name={"sc_test_performed_99_" + itemParentidx}
                                                                                                checked={itemParent.bloodTestDetails[0].sc_test_performed ===Values.value99}
                                                                                                value={99}
                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                            />
                                                                                            <label htmlFor={"sc_test_performed_99_" + itemParentidx}>
                                                                                                <span className="">
                                                                                                    Unknown
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <ErrorField
                                                                                            show={itemParent.bloodTestDetails[0].q36T2BScEmpty}
                                                                                            message={TLSMonitoring_Msgs.Radio}
                                                                                        />
                                                                                        <ErrorField
                                                                                            show={itemParent.bloodTestDetails[0].q36T2BScAllUnkNotTestedEmpty}
                                                                                            message={TLSMonitoring_Msgs.q36T2BAllUnkNotTestedEmptyMsg}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="modal-popup-table-cell">
                                                                                        {itemParent.bloodTestDetails[0].sc_test_performed === Values.value1 && (
                                                                                            <div>NA</div>
                                                                                        )}
                                                                                    </td>
                                                                                    <td className="modal-popup-table-cell column-custom-width-150">
                                                                                        {itemParent.bloodTestDetails[0].sc_test_performed === Values.value1 && (
                                                                                            <div>
                                                                                                <div className="option-without-input">
                                                                                                    <input
                                                                                                        type="radio"
                                                                                                        id={"sc_test_result_checked_" + itemParentidx}
                                                                                                        name={"sc_test_result_checked_" + itemParentidx}
                                                                                                        checked={itemParent.bloodTestDetails[0].sc_test_result_checked === 1}
                                                                                                        value={1}
                                                                                                        onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                    />
                                                                                                    <input
                                                                                                        className="input-dash input_custome-space_sm"
                                                                                                        name={"sc_test_result_" + itemParentidx}
                                                                                                        type="text"
                                                                                                        maxLength={7}
                                                                                                        value={itemParent.bloodTestDetails[0].sc_test_result}
                                                                                                        onChange={(e) => {
                                                                                                            const value = e.target.value;
                                                                                                            const regex = /^\d{0,4}(\.\d{0,2})?$/;
                                                                                                            if (regex.test(value)) {
                                                                                                                HandleQ36RepeaterFieldChange(e, itemParentidx);
                                                                                                            }
                                                                                                        }}
                                                                                                        onKeyDown={formatInput}
                                                                                                    />
                                                                                                    <label htmlFor={"sc_test_result_checked_" + itemParentidx}>
                                                                                                        <span className="">
                                                                                                            mg/dL
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="option-without-input option-fbox">
                                                                                                    <label className="option-fbox custom-spacing-lbl">
                                                                                                        <input
                                                                                                            type="radio"
                                                                                                            id={"sc_test_result_unk_" + itemParentidx}
                                                                                                            name={"sc_test_result_unk_" + itemParentidx}
                                                                                                            checked={itemParent.bloodTestDetails[0].sc_test_result_unk ===Values.value99}
                                                                                                            value={99}
                                                                                                            onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                        />
                                                                                                        <span htmlFor={"sc_test_result_unk_" + itemParentidx}>
                                                                                                            Unknown
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </div>
                                                                                                <ErrorField
                                                                                                    show={itemParent.bloodTestDetails[0].q36T2DScEmpty}
                                                                                                    message={TLSMonitoring_Msgs.Radio}
                                                                                                />
                                                                                                <ErrorField
                                                                                                    show={itemParent.bloodTestDetails[0].q36T2DScResultEmpty}
                                                                                                    message={TLSMonitoring_Msgs.q36T2DResultEmptyMsg}
                                                                                                />
                                                                                                <ErrorField
                                                                                                    show={itemParent.bloodTestDetails[0].q36T2DScAllUnknownEmpty}
                                                                                                    message={TLSMonitoring_Msgs.q36T2DAllUnknownEmptyMsg}
                                                                                                />
                                                                                                <ErrorField
                                                                                                    show={itemParent.bloodTestDetails[0].q36T2DScResultRange}
                                                                                                    message={q36ResultScOutOfRange}
                                                                                                />
                                                                                            </div>)}
                                                                                    </td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td className="modal-popup-table-cell">
                                                                                        Creatinine clearance level (CrCl)
                                                                                    </td>
                                                                                    <td className="modal-popup-table-cell">
                                                                                        <div className="option-without-input option-fbox">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={"crcl_test_performed_1_" + itemParentidx}
                                                                                                name={"crcl_test_performed_1_" + itemParentidx}
                                                                                                checked={itemParent.bloodTestDetails[0].crcl_test_performed === 1}
                                                                                                value={1}
                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                            />
                                                                                            <label htmlFor={"crcl_test_performed_1_" + itemParentidx}>
                                                                                                <span className="">
                                                                                                    Yes
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="option-without-input option-fbox">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={"crcl_test_performed_2_" + itemParentidx}
                                                                                                name={"crcl_test_performed_2_" + itemParentidx}
                                                                                                checked={itemParent.bloodTestDetails[0].crcl_test_performed === Values.value2}
                                                                                                value={2}
                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                            />
                                                                                            <label htmlFor={"crcl_test_performed_2_" + itemParentidx}>
                                                                                                <span className="">
                                                                                                    Not tested
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="option-without-input option-fbox">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={"crcl_test_performed_99_" + itemParentidx}
                                                                                                name={"crcl_test_performed_99_" + itemParentidx}
                                                                                                checked={itemParent.bloodTestDetails[0].crcl_test_performed === Values.value99}
                                                                                                value={99}
                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                            />
                                                                                            <label htmlFor={"crcl_test_performed_99_" + itemParentidx}>
                                                                                                <span className="">
                                                                                                    Unknown
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <ErrorField
                                                                                            show={itemParent.bloodTestDetails[0].q36T2BCrclEmpty}
                                                                                            message={TLSMonitoring_Msgs.Radio}
                                                                                        />
                                                                                        <ErrorField
                                                                                            show={itemParent.bloodTestDetails[0].q36T2BCrclAllUnkNotTestedEmpty}
                                                                                            message={TLSMonitoring_Msgs.q36T2BAllUnkNotTestedEmptyMsg}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="modal-popup-table-cell">
                                                                                        {itemParent.bloodTestDetails[0].crcl_test_performed === Values.value1 && (
                                                                                            <div>
                                                                                                Formula type:
                                                                                                <div className="option-without-input option-fbox">
                                                                                                    <input
                                                                                                        type="radio"
                                                                                                        id={"crcl_formula_type_1_" + itemParentidx}
                                                                                                        name={"crcl_formula_type_1_" + itemParentidx}
                                                                                                        checked={itemParent.bloodTestDetails[0].crcl_formula_type === Values.value1}
                                                                                                        value={1}
                                                                                                        onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                    />
                                                                                                    <label htmlFor={"crcl_formula_type_1_" + itemParentidx}>
                                                                                                        <span className="">
                                                                                                            Cockcroft-Gault
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="option-without-input option-fbox">
                                                                                                    <input
                                                                                                        type="radio"
                                                                                                        id={"crcl_formula_type_2_" + itemParentidx}
                                                                                                        name={"crcl_formula_type_2_" + itemParentidx}
                                                                                                        checked={itemParent.bloodTestDetails[0].crcl_formula_type === Values.value2}
                                                                                                        value={2}
                                                                                                        onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                    />
                                                                                                    <label htmlFor={"crcl_formula_type_2_" + itemParentidx}>
                                                                                                        <span className="" style={{ textWrap: "nowrap" }}>
                                                                                                            Other, specify
                                                                                                        </span>
                                                                                                    </label>
                                                                                                    <input
                                                                                                        className="input-dash input_custome-space"
                                                                                                        name={"crcl_formula_type_oth_" + itemParentidx}
                                                                                                        type="text"
                                                                                                        maxLength={500}
                                                                                                        value={itemParent.bloodTestDetails[0].crcl_formula_type_oth}
                                                                                                        onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                        style={{ marginLeft: "-9px" }}
                                                                                                    />
                                                                                                </div>
                                                                                                <div className="option-without-input option-fbox">
                                                                                                    <input
                                                                                                        type="radio"
                                                                                                        id={"crcl_formula_type_99_" + itemParentidx}
                                                                                                        name={"crcl_formula_type_99_" + itemParentidx}
                                                                                                        checked={itemParent.bloodTestDetails[0].crcl_formula_type === Values.value99}
                                                                                                        value={99}
                                                                                                        onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                    />
                                                                                                    <label htmlFor={"crcl_formula_type_99_" + itemParentidx}>
                                                                                                        <span className="">
                                                                                                            Unknown
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </div>
                                                                                                <ErrorField
                                                                                                    show={itemParent.bloodTestDetails[0].q36T2CCrclEmpty}
                                                                                                    message={TLSMonitoring_Msgs.Radio}
                                                                                                />
                                                                                                <ErrorField
                                                                                                    show={itemParent.bloodTestDetails[0].q36T2CCrclOthTxtEmpty}
                                                                                                    message={TLSMonitoring_Msgs.q36T2COthTxtEmptyMsg}
                                                                                                />
                                                                                            </div>)}
                                                                                    </td>
                                                                                    <td className="modal-popup-table-cell column-custom-width-150">
                                                                                        {itemParent.bloodTestDetails[0].crcl_test_performed === 1 && (
                                                                                            <div>
                                                                                                <div className="option-without-input">
                                                                                                    <input
                                                                                                        type="radio"
                                                                                                        id={"crcl_test_result_checked_" + itemParentidx}
                                                                                                        name={"crcl_test_result_checked_" + itemParentidx}
                                                                                                        checked={itemParent.bloodTestDetails[0].crcl_test_result_checked === Values.value1}
                                                                                                        value={1}
                                                                                                        onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                    />
                                                                                                    <input
                                                                                                        className="input-dash input_custome-space_sm"
                                                                                                        name={"crcl_test_result_" + itemParentidx}
                                                                                                        type="text"
                                                                                                        maxLength={7}
                                                                                                        value={itemParent.bloodTestDetails[0].crcl_test_result}
                                                                                                        // onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                        onChange={(e) => {
                                                                                                            const value = e.target.value;
                                                                                                            const regex = /^\d{0,4}(\.\d{0,2})?$/;
                                                                                                            if (regex.test(value)) {
                                                                                                                HandleQ36RepeaterFieldChange(e, itemParentidx);
                                                                                                            }
                                                                                                        }}
                                                                                                        // onKeyDown={formatWholeNumbers}
                                                                                                        onKeyDown={formatInput}
                                                                                                    />
                                                                                                    <label htmlFor={"crcl_test_result_checked_" + itemParentidx}>
                                                                                                        <span className="">
                                                                                                            mL/min
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="option-without-input option-fbox">
                                                                                                    <label className="option-fbox custom-spacing-lbl">
                                                                                                        <input
                                                                                                            type="radio"
                                                                                                            id={"crcl_test_result_unk_" + itemParentidx}
                                                                                                            name={"crcl_test_result_unk_" + itemParentidx}
                                                                                                            checked={itemParent.bloodTestDetails[0].crcl_test_result_unk === Values.value99}
                                                                                                            value={99}
                                                                                                            onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                        />
                                                                                                        <span htmlFor={"crcl_test_result_unk_" + itemParentidx}>
                                                                                                            Unknown
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </div>
                                                                                                <ErrorField
                                                                                                    show={itemParent.bloodTestDetails[0].q36T2DCrclEmpty}
                                                                                                    message={TLSMonitoring_Msgs.Radio}
                                                                                                />
                                                                                                <ErrorField
                                                                                                    show={itemParent.bloodTestDetails[0].q36T2DCrclResultEmpty}
                                                                                                    message={TLSMonitoring_Msgs.q36T2DResultEmptyMsg}
                                                                                                />
                                                                                                <ErrorField
                                                                                                    show={itemParent.bloodTestDetails[0].q36T2DCrclAllUnknownEmpty}
                                                                                                    message={TLSMonitoring_Msgs.q36T2DAllUnknownEmptyMsg}
                                                                                                />
                                                                                                <ErrorField
                                                                                                    show={itemParent.bloodTestDetails[0].q36T2DCrclResultRange}
                                                                                                    message={q36ResultCrclOutOfRange}
                                                                                                />
                                                                                            </div>)}
                                                                                    </td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td className="modal-popup-table-cell">
                                                                                        Serum lactate dehydrogenase (LDH)
                                                                                    </td>
                                                                                    <td className="modal-popup-table-cell">
                                                                                        <div className="option-without-input option-fbox">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={"ldh_test_performed_1_" + itemParentidx}
                                                                                                name={"ldh_test_performed_1_" + itemParentidx}
                                                                                                checked={itemParent.bloodTestDetails[0].ldh_test_performed === 1}
                                                                                                value={1}
                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                            />
                                                                                            <label htmlFor={"ldh_test_performed_1_" + itemParentidx}>
                                                                                                <span className="">
                                                                                                    Yes
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="option-without-input option-fbox">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={"ldh_test_performed_2_" + itemParentidx}
                                                                                                name={"ldh_test_performed_2_" + itemParentidx}
                                                                                                checked={itemParent.bloodTestDetails[0].ldh_test_performed === Values.value2}
                                                                                                value={2}
                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                            />
                                                                                            <label htmlFor={"ldh_test_performed_2_" + itemParentidx}>
                                                                                                <span className="">
                                                                                                    Not tested
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="option-without-input option-fbox">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={"ldh_test_performed_99_" + itemParentidx}
                                                                                                name={"ldh_test_performed_99_" + itemParentidx}
                                                                                                checked={itemParent.bloodTestDetails[0].ldh_test_performed ===Values.value99}
                                                                                                value={99}
                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                            />
                                                                                            <label htmlFor={"ldh_test_performed_99_" + itemParentidx}>
                                                                                                <span className="">
                                                                                                    Unknown
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <ErrorField
                                                                                            show={itemParent.bloodTestDetails[0].q36T2BLdhEmpty}
                                                                                            message={TLSMonitoring_Msgs.Radio}
                                                                                        />
                                                                                        <ErrorField
                                                                                            show={itemParent.bloodTestDetails[0].q36T2BLdhAllUnkNotTestedEmpty}
                                                                                            message={TLSMonitoring_Msgs.q36T2BAllUnkNotTestedEmptyMsg}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="modal-popup-table-cell">
                                                                                        {itemParent.bloodTestDetails[0].ldh_test_performed === Values.value1 && (
                                                                                            <div>NA</div>
                                                                                        )}
                                                                                    </td>
                                                                                    <td className="modal-popup-table-cell column-custom-width-150">
                                                                                        {itemParent.bloodTestDetails[0].ldh_test_performed === Values.value1 && (
                                                                                            <div>
                                                                                                <div className="option-without-input">
                                                                                                    <input
                                                                                                        type="radio"
                                                                                                        id={"ldh_test_result_checked_" + itemParentidx}
                                                                                                        name={"ldh_test_result_checked_" + itemParentidx}
                                                                                                        checked={itemParent.bloodTestDetails[0].ldh_test_result_checked === Values.value1}
                                                                                                        value={1}
                                                                                                        onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                    />
                                                                                                    <input
                                                                                                        className="input-dash input_custome-space_sm"
                                                                                                        name={"ldh_test_result_" + itemParentidx}
                                                                                                        type="text"
                                                                                                        maxLength={7}
                                                                                                        value={itemParent.bloodTestDetails[0].ldh_test_result}
                                                                                                        // onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                        onChange={(e) => {
                                                                                                            const value = e.target.value;
                                                                                                            const regex = /^\d{0,4}(\.\d{0,2})?$/;
                                                                                                            if (regex.test(value)) {
                                                                                                                HandleQ36RepeaterFieldChange(e, itemParentidx);
                                                                                                            }
                                                                                                        }}
                                                                                                        // onKeyDown={formatWholeNumbers}
                                                                                                        onKeyDown={formatInput}
                                                                                                    />
                                                                                                    <label htmlFor={"ldh_test_result_checked_" + itemParentidx}>
                                                                                                        <span className="">
                                                                                                            U/L
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="option-without-input option-fbox">
                                                                                                    <label className="option-fbox custom-spacing-lbl">
                                                                                                        <input
                                                                                                            type="radio"
                                                                                                            id={"ldh_test_result_unk_" + itemParentidx}
                                                                                                            name={"ldh_test_result_unk_" + itemParentidx}
                                                                                                            checked={itemParent.bloodTestDetails[0].ldh_test_result_unk === Values.value99}
                                                                                                            value={99}
                                                                                                            onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                        />
                                                                                                        <span htmlFor="ldh_test_result_unk_">
                                                                                                            Unknown
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </div>
                                                                                                <ErrorField
                                                                                                    show={itemParent.bloodTestDetails[0].q36T2DLdhEmpty}
                                                                                                    message={TLSMonitoring_Msgs.Radio}
                                                                                                />
                                                                                                <ErrorField
                                                                                                    show={itemParent.bloodTestDetails[0].q36T2DLdhResultEmpty}
                                                                                                    message={TLSMonitoring_Msgs.q36T2DResultEmptyMsg}
                                                                                                />
                                                                                                <ErrorField
                                                                                                    show={itemParent.bloodTestDetails[0].q36T2DLdhAllUnknownEmpty}
                                                                                                    message={TLSMonitoring_Msgs.q36T2DAllUnknownEmptyMsg}
                                                                                                />
                                                                                                <ErrorField
                                                                                                    show={itemParent.bloodTestDetails[0].q36T2DLdhResultRange}
                                                                                                    message={q36ResultLdhOutOfRange}
                                                                                                />
                                                                                            </div>)}
                                                                                    </td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td className="modal-popup-table-cell">
                                                                                        Phosphorus
                                                                                    </td>
                                                                                    <td className="modal-popup-table-cell">
                                                                                        <div className="option-without-input option-fbox">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={"pho_test_performed_1_" + itemParentidx}
                                                                                                name={"pho_test_performed_1_" + itemParentidx}
                                                                                                checked={itemParent.bloodTestDetails[0].pho_test_performed === Values.value1}
                                                                                                value={1}
                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                            />
                                                                                            <label htmlFor={"pho_test_performed_1_" + itemParentidx}>
                                                                                                <span className="">
                                                                                                    Yes
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="option-without-input option-fbox">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={"pho_test_performed_2_" + itemParentidx}
                                                                                                name={"pho_test_performed_2_" + itemParentidx}
                                                                                                checked={itemParent.bloodTestDetails[0].pho_test_performed === Values.value2}
                                                                                                value={2}
                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                            />
                                                                                            <label htmlFor={"pho_test_performed_2_" + itemParentidx}>
                                                                                                <span className="">
                                                                                                    Not tested
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="option-without-input option-fbox">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={"pho_test_performed_99_" + itemParentidx}
                                                                                                name={"pho_test_performed_99_" + itemParentidx}
                                                                                                checked={itemParent.bloodTestDetails[0].pho_test_performed === Values.value99}
                                                                                                value={99}
                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                            />
                                                                                            <label htmlFor={"pho_test_performed_99_" + itemParentidx}>
                                                                                                <span className="">
                                                                                                    Unknown
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <ErrorField
                                                                                            show={itemParent.bloodTestDetails[0].q36T2BPhoEmpty}
                                                                                            message={TLSMonitoring_Msgs.Radio}
                                                                                        />
                                                                                        <ErrorField
                                                                                            show={itemParent.bloodTestDetails[0].q36T2BPhoAllUnkNotTestedEmpty}
                                                                                            message={TLSMonitoring_Msgs.q36T2BAllUnkNotTestedEmptyMsg}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="modal-popup-table-cell">
                                                                                        {itemParent.bloodTestDetails[0].pho_test_performed === Values.value1 && (
                                                                                            <div>NA</div>
                                                                                        )}
                                                                                    </td>
                                                                                    <td className="modal-popup-table-cell column-custom-width-150">

                                                                                        {itemParent.bloodTestDetails[0].pho_test_performed === Values.value1 && (
                                                                                            <div>
                                                                                                <div className="option-without-input">
                                                                                                    <input
                                                                                                        type="radio"
                                                                                                        id={"pho_test_result_checked_" + itemParentidx}
                                                                                                        name={"pho_test_result_checked_" + itemParentidx}
                                                                                                        checked={itemParent.bloodTestDetails[0].pho_test_result_checked === Values.value1}
                                                                                                        value={1}
                                                                                                        onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                    />
                                                                                                    <input
                                                                                                        className="input-dash input_custome-space_sm"
                                                                                                        name={"pho_test_result_" + itemParentidx}
                                                                                                        type="text"
                                                                                                        maxLength={7}
                                                                                                        value={itemParent.bloodTestDetails[0].pho_test_result}
                                                                                                        // onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                        onChange={(e) => {
                                                                                                            const value = e.target.value;
                                                                                                            const regex = /^\d{0,4}(\.\d{0,2})?$/;
                                                                                                            if (regex.test(value)) {
                                                                                                                HandleQ36RepeaterFieldChange(e, itemParentidx);
                                                                                                            }
                                                                                                        }}
                                                                                                        // onKeyDown={formatWholeNumbers}
                                                                                                        onKeyDown={formatInput}
                                                                                                    />
                                                                                                    <label htmlFor={"pho_test_result_checked_" + itemParentidx}>
                                                                                                        <span className="">
                                                                                                            mg/dL
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="option-without-input option-fbox">
                                                                                                    <label className="option-fbox custom-spacing-lbl">
                                                                                                        <input
                                                                                                            type="radio"
                                                                                                            id={"pho_test_result_unk_" + itemParentidx}
                                                                                                            name={"pho_test_result_unk_" + itemParentidx}
                                                                                                            checked={itemParent.bloodTestDetails[0].pho_test_result_unk === Values.value99}
                                                                                                            value={99}
                                                                                                            onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                        />
                                                                                                        <span htmlFor={"pho_test_result_unk_" + itemParentidx}>
                                                                                                            Unknown
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </div>
                                                                                                <ErrorField
                                                                                                    show={itemParent.bloodTestDetails[0].q36T2DPhoEmpty}
                                                                                                    message={TLSMonitoring_Msgs.Radio}
                                                                                                />
                                                                                                <ErrorField
                                                                                                    show={itemParent.bloodTestDetails[0].q36T2DPhoResultEmpty}
                                                                                                    message={TLSMonitoring_Msgs.q36T2DResultEmptyMsg}
                                                                                                />
                                                                                                <ErrorField
                                                                                                    show={itemParent.bloodTestDetails[0].q36T2DPhoAllUnknownEmpty}
                                                                                                    message={TLSMonitoring_Msgs.q36T2DAllUnknownEmptyMsg}
                                                                                                />
                                                                                                <ErrorField
                                                                                                    show={itemParent.bloodTestDetails[0].q36T2DPhoResultRange}
                                                                                                    message={q36ResultPhoOutOfRange}
                                                                                                />
                                                                                            </div>
                                                                                        )}
                                                                                    </td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td className="modal-popup-table-cell">
                                                                                        Potassium
                                                                                    </td>
                                                                                    <td className="modal-popup-table-cell">
                                                                                        <div className="option-without-input option-fbox">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={"pot_test_performed_1_" + itemParentidx}
                                                                                                name={"pot_test_performed_1_" + itemParentidx}
                                                                                                checked={itemParent.bloodTestDetails[0].pot_test_performed === Values.value1}
                                                                                                value={1}
                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                            />
                                                                                            <label htmlFor={"pot_test_performed_1_" + itemParentidx}>
                                                                                                <span className="">
                                                                                                    Yes
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="option-without-input option-fbox">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={"pot_test_performed_2_" + itemParentidx}
                                                                                                name={"pot_test_performed_2_" + itemParentidx}
                                                                                                checked={itemParent.bloodTestDetails[0].pot_test_performed === Values.value2}
                                                                                                value={2}
                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                            />
                                                                                            <label htmlFor={"pot_test_performed_2_" + itemParentidx}>
                                                                                                <span className="">
                                                                                                    Not tested
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="option-without-input option-fbox">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={"pot_test_performed_99_" + itemParentidx}
                                                                                                name={"pot_test_performed_99_" + itemParentidx}
                                                                                                checked={itemParent.bloodTestDetails[0].pot_test_performed === Values.value99}
                                                                                                value={99}
                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                            />
                                                                                            <label htmlFor={"pot_test_performed_99_" + itemParentidx}>
                                                                                                <span className="">
                                                                                                    Unknown
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <ErrorField
                                                                                            show={itemParent.bloodTestDetails[0].q36T2BPotEmpty}
                                                                                            message={TLSMonitoring_Msgs.Radio}
                                                                                        />
                                                                                        <ErrorField
                                                                                            show={itemParent.bloodTestDetails[0].q36T2BPotAllUnkNotTestedEmpty}
                                                                                            message={TLSMonitoring_Msgs.q36T2BAllUnkNotTestedEmptyMsg}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="modal-popup-table-cell">
                                                                                        {itemParent.bloodTestDetails[0].pot_test_performed === Values.value1 && (
                                                                                            <div>NA</div>
                                                                                        )}
                                                                                    </td>
                                                                                    <td className="modal-popup-table-cell column-custom-width-150">
                                                                                        {itemParent.bloodTestDetails[0].pot_test_performed === 1 && (
                                                                                            <div>
                                                                                                <div className="option-without-input">
                                                                                                    <input
                                                                                                        type="radio"
                                                                                                        id={"pot_test_result_checked_" + itemParentidx}
                                                                                                        name={"pot_test_result_checked_" + itemParentidx}
                                                                                                        checked={itemParent.bloodTestDetails[0].pot_test_result_checked === Values.value1}
                                                                                                        value={1}
                                                                                                        onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                    />
                                                                                                    <input
                                                                                                        className="input-dash input_custome-space_sm"
                                                                                                        name={"pot_test_result_" + itemParentidx}
                                                                                                        type="text"
                                                                                                        maxLength={7}
                                                                                                        value={itemParent.bloodTestDetails[0].pot_test_result}
                                                                                                        // onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                        onChange={(e) => {
                                                                                                            const value = e.target.value;
                                                                                                            const regex = /^\d{0,4}(\.\d{0,2})?$/;
                                                                                                            if (regex.test(value)) {
                                                                                                                HandleQ36RepeaterFieldChange(e, itemParentidx);
                                                                                                            }
                                                                                                        }}
                                                                                                        // onKeyDown={formatWholeNumbers}
                                                                                                        onKeyDown={formatInput}
                                                                                                    />
                                                                                                    <label htmlFor={"pot_test_result_checked_" + itemParentidx}>
                                                                                                        <span className="">
                                                                                                            mmol/L
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="option-without-input option-fbox">
                                                                                                    <label className="option-fbox custom-spacing-lbl">
                                                                                                        <input
                                                                                                            type="radio"
                                                                                                            id={"pot_test_result_unk_" + itemParentidx}
                                                                                                            name={"pot_test_result_unk_" + itemParentidx}
                                                                                                            checked={itemParent.bloodTestDetails[0].pot_test_result_unk === Values.value99}
                                                                                                            value={99}
                                                                                                            onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                        />
                                                                                                        <span htmlFor={"pot_test_result_unk_" + itemParentidx}>
                                                                                                            Unknown
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </div>
                                                                                                <ErrorField
                                                                                                    show={itemParent.bloodTestDetails[0].q36T2DPotEmpty}
                                                                                                    message={TLSMonitoring_Msgs.Radio}
                                                                                                />
                                                                                                <ErrorField
                                                                                                    show={itemParent.bloodTestDetails[0].q36T2DPotResultEmpty}
                                                                                                    message={TLSMonitoring_Msgs.q36T2DResultEmptyMsg}
                                                                                                />
                                                                                                <ErrorField
                                                                                                    show={itemParent.bloodTestDetails[0].q36T2DPotAllUnknownEmpty}
                                                                                                    message={TLSMonitoring_Msgs.q36T2DAllUnknownEmptyMsg}
                                                                                                />
                                                                                                <ErrorField
                                                                                                    show={itemParent.bloodTestDetails[0].q36T2DPotResultRange}
                                                                                                    message={q36ResultPotOutOfRange}
                                                                                                />
                                                                                            </div>)}
                                                                                    </td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td className="modal-popup-table-cell">
                                                                                        Uric acid
                                                                                    </td>
                                                                                    <td className="modal-popup-table-cell">
                                                                                        <div className="option-without-input option-fbox">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={"uric_test_performed_1_" + itemParentidx}
                                                                                                name={"uric_test_performed_1_" + itemParentidx}
                                                                                                checked={itemParent.bloodTestDetails[0].uric_test_performed === Values.value1}
                                                                                                value={1}
                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                            />
                                                                                            <label htmlFor={"uric_test_performed_1_" + itemParentidx}>
                                                                                                <span className="">
                                                                                                    Yes
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="option-without-input option-fbox">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={"uric_test_performed_2_" + itemParentidx}
                                                                                                name={"uric_test_performed_2_" + itemParentidx}
                                                                                                checked={itemParent.bloodTestDetails[0].uric_test_performed === Values.value2}
                                                                                                value = {2}
                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                            />
                                                                                            <label htmlFor={"uric_test_performed_2_" + itemParentidx}>
                                                                                                <span className="">
                                                                                                    Not tested
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="option-without-input option-fbox">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={"uric_test_performed_99_" + itemParentidx}
                                                                                                name={"uric_test_performed_99_" + itemParentidx}
                                                                                                checked={itemParent.bloodTestDetails[0].uric_test_performed === Values.value99}
                                                                                                value = {99}
                                                                                                onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                            />
                                                                                            <label htmlFor={"uric_test_performed_99_" + itemParentidx}>
                                                                                                <span className="">
                                                                                                    Unknown
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <ErrorField
                                                                                            show={itemParent.bloodTestDetails[0].q36T2BUricEmpty}
                                                                                            message={TLSMonitoring_Msgs.Radio}
                                                                                        />
                                                                                        <ErrorField
                                                                                            show={itemParent.bloodTestDetails[0].q36T2BUricAllUnkNotTestedEmpty}
                                                                                            message={TLSMonitoring_Msgs.q36T2BAllUnkNotTestedEmptyMsg}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="modal-popup-table-cell">
                                                                                        {itemParent.bloodTestDetails[0].uric_test_performed === Values.value1 && (
                                                                                            <div>NA</div>
                                                                                        )}
                                                                                    </td>
                                                                                    <td className="modal-popup-table-cell column-custom-width-150">
                                                                                        {itemParent.bloodTestDetails[0].uric_test_performed === Values.value1 && (
                                                                                            <div>
                                                                                                <div className="option-without-input">
                                                                                                    <input
                                                                                                        type="radio"
                                                                                                        id={"uric_test_result_checked_" + itemParentidx}
                                                                                                        name={"uric_test_result_checked_" + itemParentidx}
                                                                                                        checked={itemParent.bloodTestDetails[0].uric_test_result_checked === Values.value1}
                                                                                                        value={1}
                                                                                                        onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                    />
                                                                                                    <input
                                                                                                        className="input-dash input_custome-space_sm"
                                                                                                        id
                                                                                                        name={"uric_test_result_" + itemParentidx}
                                                                                                        type="text"
                                                                                                        maxLength={7}
                                                                                                        value={itemParent.bloodTestDetails[0].uric_test_result}
                                                                                                        // onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                        onChange={(e) => {
                                                                                                            const value = e.target.value;
                                                                                                            const regex = /^\d{0,4}(\.\d{0,2})?$/;
                                                                                                            if (regex.test(value)) {
                                                                                                                HandleQ36RepeaterFieldChange(e, itemParentidx);
                                                                                                            }
                                                                                                        }}
                                                                                                        // onKeyDown={formatWholeNumbers}
                                                                                                        onKeyDown={formatInput}
                                                                                                    />
                                                                                                    <label htmlFor={"uric_test_result_checked_" + itemParentidx}>
                                                                                                        <span className="">
                                                                                                            mg/dL
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="option-without-input option-fbox">
                                                                                                    <label className="option-fbox custom-spacing-lbl">
                                                                                                        <input
                                                                                                            type="radio"
                                                                                                            id={"uric_test_result_unk_" + itemParentidx}
                                                                                                            name={"uric_test_result_unk_" + itemParentidx}
                                                                                                            checked={itemParent.bloodTestDetails[0].uric_test_result_unk === Values.value99}
                                                                                                            value={99}
                                                                                                            onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx)}
                                                                                                        />
                                                                                                        <span htmlFor={"uric_test_result_unk_" + itemParentidx}>
                                                                                                            Unknown
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </div>
                                                                                                <ErrorField
                                                                                                    show={itemParent.bloodTestDetails[0].q36T2DUricEmpty}
                                                                                                    message={TLSMonitoring_Msgs.Radio}
                                                                                                />
                                                                                                <ErrorField
                                                                                                    show={itemParent.bloodTestDetails[0].q36T2DUricResultEmpty}
                                                                                                    message={TLSMonitoring_Msgs.q36T2DResultEmptyMsg}
                                                                                                />
                                                                                                <ErrorField
                                                                                                    show={itemParent.bloodTestDetails[0].q36T2DUricAllUnknownEmpty}
                                                                                                    message={TLSMonitoring_Msgs.q36T2DAllUnknownEmptyMsg}
                                                                                                />
                                                                                                <ErrorField
                                                                                                    show={itemParent.bloodTestDetails[0].q36T2DUricResultRange}
                                                                                                    message={q36ResultUricOutOfRange}
                                                                                                />
                                                                                            </div>)}
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                                <br />
                                                            </div>
                                                            //))}
                                                        )}
                                                        <br />

                                                    </div>
                                                ))}

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="survey-section-content-foot">
                                <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {showHideModalPopup && (
                <InfoPopUp
                    description={TLS_Cairo}
                    OkClick={() => setShowHideModalPopup(false)}
                />
            )}
            {showHideModalPopupLink && (
                <InfoPopUp
                    description={TLS_Table}
                    OkClick={() => setShowHideModalPopupLink(false)}
                />
            )}
        </>
    );
};

export default TlsLabTestMonitoring;

