import WebServiceWrapper from "./WebServiceWrapper";

export const OldCoreService = {
    GetCoreUSPatients,
    AddPatientFromOldCore,
};

function GetCoreUSPatients(centerId) {
    return WebServiceWrapper.Get(`/oldCore/GetCoreUSPatients?centerId=${centerId}`);
}

function AddPatientFromOldCore(corePatientUniqueId) {
    return WebServiceWrapper.Get(`/oldCore/AddPatientFromOldCore?corePatientUniqueId=${corePatientUniqueId}`);
}