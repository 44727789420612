import React, { useContext, useEffect, useState } from 'react';
import { SideMenuItems, SideMenuPageList, HTTPResponse } from '../Helpers/Enums';
import { useNavigate } from 'react-router';
import { AddLocalStorageData, GetLocalStorageData, GetLoggedInUserID } from '../Helpers/Utilities';
import { PatientService } from "../WebApiServices/Patient.service";
import { AppContext } from '../Contexts/AppContextProvider';


const SideBarMenu = (props) => {

    // const [incompletePageIds, setIncompletePageIds] = useState([]);
    const activePageId = props.activePageId;
    const patientID = GetLocalStorageData("patientId") ?? 0;
    const navigate = useNavigate();
    const { ToggleLoader, HandleSessionTimeout, appState, SetVenRampWeekCnt, SetTLSWeekCnt, SetTLSWeekTestCnt } = useContext(AppContext);
    //const { isSubmitted, formIncomplete } = useContext(AppContext);
    let patientData = GetLocalStorageData("patient");
    patientData = patientData && JSON.parse(patientData);

    // const submit = patientData?.submit ?? 0;
    const locked = patientData?.locked ?? false;
    // const pid = patientData?.patientId ?? 0;
    // const userId = GetLocalStorageData("userId") ?? 0;

    const [pageList, setPageList] = useState(SideMenuPageList);

    const loadConfig = GetLocalStorageData("loadConfig") ?? false;
    const [eligible, setEligible] = useState(false);
    const [loading, setLoading] = useState(true);//https://ideawharf.sifterapp.com/issues/11480


    useEffect(() => {
        const FetchData = async () => {

            try {
                AddLocalStorageData("loadConfig", false);

                //ToggleLoader(true);
                const response1 = await PatientService.IsEligiblePatient(patientID);
                const isEligible = response1?.data ?? false;
                setEligible(isEligible);
                SetVenRampWeekCnt(0);
                SetTLSWeekCnt(0);
                SetTLSWeekTestCnt(0);
                if (response1?.status === HTTPResponse.OK) {

                    const response2 = await PatientService.GetPatientConfigData(patientID);
                    if (response2?.status === HTTPResponse.OK) {
                        if (response2 != null) {
                            const { data } = response2;
                            if (data != null) {
                                const { item1, item2, item3, item4 } = data;
                                //item1 => patient config static pages data 
                                //item2 => ven ramp-up week config data

                                let pglistClone = [...SideMenuPageList];

                                let staticPgs = [];
                                staticPgs = pglistClone.filter(a => a.id !== SideMenuItems.VenetoclaxRampupWeek).map(obj => {
                                    // Find the corresponding object in item1 by id
                                    let matchObj = item1.find(a => a.pageId === obj.id);
                                    //update incomplete prop
                                    if (matchObj) {
                                        return {
                                            ...obj,
                                            incomplete: matchObj.incomplete
                                        };
                                    }
                                    //no match
                                    return obj;
                                });

                                staticPgs = pglistClone.filter(a => a.id !== SideMenuItems.TlsMonitoringWeek).map(obj => {
                                    // Find the corresponding object in item1 by id
                                    let matchObj = item1.find(a => a.pageId === obj.id);
                                    //update incomplete prop
                                    if (matchObj) {
                                        return {
                                            ...obj,
                                            incomplete: matchObj.incomplete
                                        };
                                    }
                                    //no match
                                    return obj;
                                });

                                // staticPgs = pglistClone.filter(a => a.id !== SideMenuItems.TlsMonitoringWeek).map(obj => {
                                //     // Find the corresponding object in item1 by id
                                //     let matchObj = item1.find(a => a.pageId === obj.id);
                                //     //update incomplete prop
                                //     if (matchObj) {
                                //         return {
                                //             ...obj,
                                //             incomplete: matchObj.incomplete
                                //         };
                                //     }
                                //     //no match
                                //     return obj;
                                // });



                                let listVenRampWeeks = [];
                                if (item2 != null && item2.length > 0) {
                                    SetVenRampWeekCnt(item2.length);
                                    SetTLSWeekCnt(item2.length);
                                    item2.map((a, i) => {
                                        const wkItm = {
                                            id: a.pageId,
                                            title: `Week ${i + 1}`,
                                            path: "/VenetoclaxRampUpWeek",
                                            weekNum: i + 1,
                                            hasParent: true,
                                            incomplete: a.incomplete,
                                            disabled: a.disabled
                                        };
                                        listVenRampWeeks.push(wkItm);
                                    });
                                }

                                let listTLSWeeksWithTests = [];

                                if (item3 && item3.length > 0 && item4.length === 0) {
                                    SetTLSWeekCnt(item3.length);
                                    // Map each week in item4, adding associated lab tests if they exist
                                    listTLSWeeksWithTests = item3.map((week) => {
                                        const labTests = week.tests && week.tests.length > 0
                                            ? week.tests.map((test) => ({
                                                id: test.pageId,
                                                title: `Test ${test.testNum}`,
                                                path: `/TlsLabTestMonitoring/${test.weeknum}/${test.testNum}`,
                                                incomplete: test.incomplete,
                                                disabled: test.disabled,
                                                testNum: test.testNum,
                                                weekNum: test.weeknum,
                                                hasParent: true,
                                                hasGrandParent: true
                                            }))
                                            : []; // Set as empty array if no tests

                                        return {
                                            id: week.pageId,
                                            title: `Week ${week.weeknum}`,
                                            path: "/TlsWeekMonitoring",
                                            weekNum: week.weeknum,
                                            hasParent: true,
                                            incomplete: week.incomplete,
                                            disabled: week.disabled,
                                            labTests // Attach lab tests (even if empty)
                                        };
                                    });
                                }

                                else if (item4 && item4.length > 0) {
                                    // Map each week in item4, adding associated lab tests if they exist
                                    listTLSWeeksWithTests = item4.map((week) => {
                                        const labTests = week.tests && week.tests.length > 0
                                            ? week.tests.map((test) => ({
                                                id: test.pageId,
                                                title: `Test ${test.testNum}`,
                                                path: `/TlsLabTestMonitoring`,
                                                incomplete: test.incomplete,
                                                disabled: test.disabled,
                                                testNum: test.testNum,
                                                weekNum: test.weeknum,
                                                hasParent: true,
                                                hasGrandParent: true
                                            }))
                                            : []; // Set as empty array if no tests

                                        return {
                                            id: week.pageId,
                                            title: `Week ${week.weeknum}`,
                                            path: "/TlsWeekMonitoring",
                                            weekNum: week.weeknum,
                                            hasParent: true,
                                            incomplete: week.incomplete,
                                            disabled: week.disabled,
                                            labTests // Attach lab tests (even if empty)
                                        };
                                    });
                                }


                                //SYJ - SetWeekTestCount of Last Week
                                if (listTLSWeeksWithTests.length > 0) {
                                    const lastRowLabTestsLength = listTLSWeeksWithTests[listTLSWeeksWithTests.length - 1].labTests.length;
                                    SetTLSWeekTestCnt(lastRowLabTestsLength);
                                }


                                // Flatten structure for final UI list, ensuring each week appears
                                const finalList = [
                                    ...staticPgs.filter((a) => a.id < SideMenuItems.VenetoclaxRampupWeek),
                                    ...listVenRampWeeks,
                                    ...staticPgs.filter((a) => a.id === SideMenuItems.TlsMonitoring),
                                    ...listTLSWeeksWithTests.flatMap((week) => [
                                        week, // Add each week
                                        ...week.labTests // Spread each week’s tests if available (empty array if none)
                                    ]),
                                    ...staticPgs.filter((a) => a.id > SideMenuItems.TlsMonitoringLabTest)
                                ];

                                setPageList(finalList);


                            }
                        }
                        //ToggleLoader(false);
                    }
                    else if (response1?.status === HTTPResponse.Unauthorized) {
                        HandleSessionTimeout();
                    }
                }
                else if (response1?.status === HTTPResponse.Unauthorized) {
                    HandleSessionTimeout();
                }
            } catch (e) {
                //ToggleLoader(false);
            }
            finally {
                setLoading(false);
            }
        };

        FetchData();
    }, [loadConfig, appState.showCartPage]);//, appState.showCartPage 


    /* <summary>
    date: 04-11-2023
    Name: AP
    description: Handle Navigation with Highlight on Validate/Submit Click.
      Edited the function. Removed highlighting of incomplete forms 
        inside a useEffect since it wasn't showing up on first click.
    <summary>*/
    const HandleNavigate = async (pageUrl, weekNum, pageId, testNum) => {
        try {
            if (pageId === SideMenuItems.ValidateSubmit) {
                const pid = GetLocalStorageData("patientId") ?? 0;
                const payload = {
                    pid: pid,
                    userId: GetLoggedInUserID(),
                };
                ToggleLoader(true);
                const response = await PatientService.SetPatientSubmitValue(payload);

                if (response?.status === HTTPResponse.OK) {
                    // Set Patient Submit Value in LS
                    const ptArr = { ...patientData };
                    ptArr.submit = response?.data;

                    AddLocalStorageData("patient", JSON.stringify(ptArr));
                }
            }

            if (patientID > 0) {
                //ToggleLoader(true);
                const response = await PatientService.IsEligiblePatient(patientID);
                const isEligible = response?.data ?? false;

                const ptArr = { ...patientData };
                AddLocalStorageData("patient", JSON.stringify(ptArr))

                if (isEligible) {
                    if (pageUrl === "/VenetoclaxRampUpWeek") {
                        navigate(pageUrl + "/" + weekNum);
                    }
                    else if (pageUrl === "/TlsWeekMonitoring") {
                        navigate(pageUrl + "/" + weekNum);
                    }
                    else if (pageUrl === "/TlsLabTestMonitoring") {
                        navigate(pageUrl + "/" + weekNum + "/" + testNum);
                    }
                    else {
                        navigate(pageUrl);
                    }
                }
                else {
                    //Below code later remove when side bar locks are added
                    // if (pageUrl === "/VenetoclaxRampUpWeek") {
                    //     navigate(pageUrl + "/" + weekNum)
                    // }
                    //if (pageUrl.trim().includes("PatientScreening") || pageUrl.trim().includes("EligibilityCriteria")) {
                    // else {
                    navigate(pageUrl);
                    // }//}
                }
                //ToggleLoader(false)
            }
            else {
                navigate(pageUrl);
            }

            ToggleLoader(false);
        } catch {

        }
    };

    return (
        <div className="col-md-3 col-pad-right">
            <div className="tab tab-custom">
                <div className="activity-link-tab">
                    {!loading && (
                        pageList?.map((item) => (
                            <button key={item.id}
                                onClick={() => { HandleNavigate(item.path, item.weekNum, item.id, item.testNum) }}
                                className={`tablinks ${activePageId == item.id && "active"}`}
                                style={{ color: item.incomplete ? "red " : "white" }}
                                //disabled={item.notClickable || (((patientID === 0 || !eligible) && item.id > 2))}
                                disabled={item.notClickable ||
                                    (
                                        item.disabled ||
                                        //New Patient lock all except 1st page
                                        (patientID === 0 && item.id > SideMenuItems.EligibilityCriteria)
                                        ||
                                        //patient is eligible(metINclusionCriteria)
                                        (!eligible && item.id >= SideMenuItems.Demographics && item.id <= SideMenuItems.ValidateSubmit)
                                    )}
                            >
                                <span className={`${item.hasParent && "child"} ${item.hasGrandParent && "grandchild"}`}
                                >
                                    {
                                        //Chk if its a clickable tab
                                        !item.notClickable && (
                                            item.disabled ||
                                            //New Patient lock all except 1st page
                                            (patientID === 0 && item.id > SideMenuItems.EligibilityCriteria)
                                            ||
                                            //patient is eligible(metINclusionCriteria)
                                            (!eligible && item.id >= SideMenuItems.Demographics && item.id <= SideMenuItems.ValidateSubmit)
                                        )
                                        && <img className="lock-icon" src="../Assets/images/locked.png" alt="" />
                                    }

                                    {item.id !== SideMenuItems.ValidateSubmit
                                        ? <span>{item.title}</span>
                                        : (<span >
                                            {locked ? "SUBMITTED"
                                                // : submit === 1 && incompletePageIds.length === 0
                                                //   ? "Submit Patient" : item.title
                                                : item.title
                                            }
                                        </span>)
                                    }
                                </span>
                            </button>
                        ))
                    )}
                </div>
            </div>
        </div >
    );
};

export default SideBarMenu;
