import React, { useContext, useEffect, useState } from 'react'
import { HTTPResponse, PageAction, ToastMessageType } from '../../Helpers/Enums';
import { Center_Msgs, CommonError_Msgs } from '../../Helpers/HelperText';
import { CenterService } from '../../WebApiServices/Center.service';
import { isStringEmpty } from '../../Helpers/Utilities';
import { AppContext } from '../../Contexts/AppContextProvider';
import { GetLoggedInUserID } from '../../Helpers/Utilities';
import ErrorField from '../../Components/ErrorField';

const CenterModal = ({ center, pageAction, CloseModal }) => {
    const { ShowToast, ToggleLoader, HandleSessionTimeout } = useContext(AppContext);

    const [nameErr, setNameErr] = useState(false);
    const [errorOnSave, setErrorOnSave] = useState("");
    const [nameExists, setNameExists] = useState(false);
    
    const [centerDetails, setCenterDetails] = useState({
        centerId: center?.centerId ?? 0,
        name: center?.name,
        isNew: !center.centerId || center?.centerId === 0
    })
    const CloseModalPopUp = (id) => {

        CloseModal({ ...centerDetails, centerId: id })
    }
    const HandleFieldChange = (e) => { 
        const { name } = e.target
        setCenterDetails((prev) => ({
            ...prev,
            [name]: e.target.value
        }));
        setNameErr(false);
        setNameExists(false);
    }

    const HandleSave = async () => {
        try {
            setNameExists(false);
            if (!ValidateForm()) {
                return;
            }
            
            const payload = {
                ...centerDetails,
                editedBy: GetLoggedInUserID(),
            }
            //if (centerDetails.centerId === 0) {
            ToggleLoader(true)
            const response = await CenterService.SaveCenter(payload);
            ToggleLoader(false)
            const { status } = response;
            
            if (status === HTTPResponse.OK) {
                ShowToast(centerDetails.centerId === 0 ? Center_Msgs.ADD_SUCCESS : Center_Msgs.EDIT_SUCCESS, ToastMessageType.Success);
                CloseModalPopUp(response?.data?.centerId);
            } 
            else if (status === HTTPResponse.Conflict) {
                setNameExists(true);
            }
            else {
                if(status === HTTPResponse.Unauthorized)
                    HandleSessionTimeout();
                else
                    setErrorOnSave(CommonError_Msgs.SAVE_ERR);
            }
            //}
            // else {
            //     const response = await CenterService.SaveCenter(payload);
            //     const { status } = response;
            //     if (status === HTTPResponse.OK) {
            //         ShowToast(Center_Msgs.EDIT_SUCCESS, ToastMessageType.Success);
            //         CloseModalPopUp();
            //     } else {
            //         setErrorOnSave(CommonError_Msgs.SAVE_ERR);
            //     }
            // }
        } catch (error) {
            setErrorOnSave(Center_Msgs.SAVE_CENTER)
        }
    }
    const ValidateForm = () => {
        setErrorOnSave("")
        setNameErr(isStringEmpty(centerDetails.name));
        return !isStringEmpty(centerDetails.name);
    }
    return (
        <>
            <section className="edit-modal">
                <div
                    className="modal"
                    id="AddCenterModal"
                    tabIndex={-1}
                    aria-labelledby="ModalFormLabel"
                    aria-hidden="true"
                    style={{ display: "block", paddingRight: "17px", backgroundColor: "#00000094" }}
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="container">
                                    <div className="row modal-headerr">
                                        <div className="col-10 col-md-10 col-lg-10">
                                            <label htmlFor="">
                                                {pageAction == PageAction.add ? "Add New Center" :
                                                    pageAction == PageAction.edit ? "Edit Center" : ""}
                                            </label>
                                        </div>
                                        <div className="col-2 col-md-2 col-lg-2 btn-align">
                                            <button
                                                type="button"
                                                className="btn-close btn-close-white"
                                                data-bs-dismiss="modal"
                                                aria-label="Close" onClick={()=>CloseModalPopUp(0)}
                                            />
                                        </div>
                                    </div>
                                    <div className="edit-modal-main">
                                        <div className="text-input-main">
                                            {/* <div class="edit-center-name-div">
                                    <label for="centerName"></label>
                                    <input id="centerName" class="form-control common-text-input" type="text" value="Vasco Clinic">
                                </div> */}
                                            <div className="input-details">
                                                <div className="row">
                                                    <ErrorField show={!isStringEmpty(errorOnSave)} message={errorOnSave} />
                                                    {pageAction === PageAction.edit && (

                                                        <div className="row edit-center-name-div">
                                                            <div className="col-md-4 col-lg-4">
                                                                <label htmlFor="centerId">Center ID: </label>
                                                            </div>
                                                            <div className="col-md-8 col-lg-8">
                                                                <input
                                                                    id="centerId"
                                                                    name="centerId"
                                                                    className="form-control common-text-input"
                                                                    type="text"
                                                                    readOnly={true}
                                                                    defaultValue={centerDetails.centerId}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}


                                                    <div className="row edit-center-name-div">
                                                        <div className="col-md-4 col-lg-4">
                                                            <label htmlFor="centerName">Center Name: </label>
                                                        </div>

                                                        <div className="col-md-8 col-lg-8">
                                                            <input
                                                                id="centerName"
                                                                name="name"
                                                                className="form-control common-text-input"
                                                                type="text"
                                                                placeholder="Center"
                                                                value={centerDetails.name}
                                                                onChange={HandleFieldChange}
                                                                maxLength={100}
                                                            />
                                                            <ErrorField show={nameErr} message={Center_Msgs.NAME} style={{paddingLeft: "-25px"}}/>
                                                            <ErrorField show={nameExists} message={Center_Msgs.NAME_EXISTS} style={{ paddingLeft: '0px' }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="save-btn-div">
                                            <button className="modal-save-btn" onClick={HandleSave}>Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>)
};

export default CenterModal