import WebServiceWrapper from "./WebServiceWrapper";

export const ClinicalCharacteristicsService = {
    GetClinicalCharacteristics,
    SaveClinicalCharacteristics,
};


function GetClinicalCharacteristics(pId) {
    return WebServiceWrapper.Get(`/clinicalCharacteristics/GetClinicalCharacteristicsData?patientId=${pId}`);
  }
  

function SaveClinicalCharacteristics(payload) {
    return WebServiceWrapper.PostWithHeader(`/clinicalCharacteristics/SaveClinicalCharacteristicsData`, payload);
}