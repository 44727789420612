import React, { useState } from 'react';
import ErrorField from './ErrorField';
import { GetPatientModal_Msgs } from '../Helpers/HelperText';

const AddPatientPopUp = ({ AllCoreUSPatients, HideClick, OnSubmit, TreatmentInterruption_Msgs }) => {
    const CloseModal = () => {
        HideClick();
    };

    const [selectedPatient, setSelectedPatient] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [hardErrList, setHardErrList] = useState({
        total_No_interruptionsEmpty: false // Error for empty search or no patient selected
    });

    const handleRadioChange = (event) => {
        setSelectedPatient(event.target.value);
        setHardErrList({ total_No_interruptionsEmpty: false }); // Clear error on radio change
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setHardErrList({ total_No_interruptionsEmpty: false }); // Clear error on search change
    };

    const handleSubmit = () => {
        const filteredPatients = AllCoreUSPatients.filter((patient) => {
            const patientId = patient.uniqueEncryptedPatientId || ''; // Handle null or undefined IDs
            return patientId.toLowerCase().includes(searchQuery.toLowerCase());
        });
    
        // Show error if no patient is selected, search is empty, or filteredPatients is empty
        if (!selectedPatient && (searchQuery.trim() === "" || filteredPatients.length === 0)) {
            setHardErrList({ total_No_interruptionsEmpty: true });
            return;
        }
    
        OnSubmit(selectedPatient); // Pass selected patient to parent component
        CloseModal(); // Close modal after submission
    };
    

    // Filter patients based on search query
    const filteredPatients = AllCoreUSPatients.filter((patient) => {
        const patientId = patient.uniqueEncryptedPatientId || ''; // Handle null or undefined IDs
        return patientId.toLowerCase().includes(searchQuery.toLowerCase());
    });

    return (
        <>
            <section className="delete-modal">
                <div
                    className="modal"
                    id="addPatientModal"
                    tabIndex={-1}
                    aria-labelledby="ModalFormLabel"
                    aria-hidden="true"
                    style={{
                        display: "block",
                        paddingRight: "17px",
                        backgroundColor: "#00000094",
                    }}
                >
                    <div
                        className="modal-dialog modal-dialog-centered"
                        style={{ maxWidth: "500px", width: "100%" }}
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5>Select Patient</h5>
                                <button
                                    type="button"
                                    className="close"
                                    onClick={CloseModal}
                                    style={{
                                        backgroundColor: "white",
                                        border: "none",
                                        color: "red",
                                        fontSize: "2rem",
                                        fontWeight: "bold",
                                        width: "30px",
                                        height: "30px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        position: "absolute",
                                        top: "1px",
                                        right: "10px",
                                        cursor: "pointer",
                                    }}
                                >
                                    &times;
                                </button>
                            </div>

                            <div className="modal-body">
                                <div className="container">
                                    <div className="delete-modal-main">
                                        {/* Search Bar */}
                                        <div className="searchBar" style={{ marginBottom: '10px' }}>
                                            <input
                                                className="searchQueryInput-class"
                                                id="searchQueryInput"
                                                type="text"
                                                name="searchQueryInput"
                                                placeholder="Patient ID"
                                                value={searchQuery}
                                                onChange={handleSearchChange}
                                            />
                                            <button id="searchQuerySubmit" type="submit" name="searchQuerySubmit">
                                                <img src="../Assets/images/Icon-feather-search.png" width="20px" alt="search icon" />
                                            </button>
                                        </div>

                                        {/* Error Field for empty search box or no patient selected */}
                                        <ErrorField 
                                            show={hardErrList.total_No_interruptionsEmpty} 
                                            message={GetPatientModal_Msgs.invalidPatient}
                                        />

                                        {/* List of patients */}
                                        <div style={{ maxHeight: '300px', overflowY: 'auto', padding: '10px', border: '1px solid #ccc', borderRadius: '5px' }}>
                                            {filteredPatients.length > 0 ? (
                                                <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                                                    {filteredPatients.map((patient) => (
                                                        <li 
                                                            key={patient.uniqueEncryptedPatientId} 
                                                            style={{ padding: '10px', display: 'flex', alignItems: 'center' }}
                                                        >
                                                            <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', width: '100%' }}>
                                                                <input
                                                                    type="radio"
                                                                    name="patient"
                                                                    value={patient.uniqueEncryptedPatientId}
                                                                    checked={selectedPatient === patient.uniqueEncryptedPatientId}
                                                                    onChange={handleRadioChange}
                                                                    style={{ marginRight: '15px', cursor: 'pointer' }}
                                                                /> 
                                                                <span>{patient.uniqueEncryptedPatientId}</span>
                                                            </label>
                                                        </li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <p style={{ textAlign: 'center', color: 'gray' }}>No patient data found.</p>
                                            )}
                                        </div>


                                        {/* Button */}
                                        <div className="confirm-btn-div get-patient-btn">
                                            <button className="modal-close-btn" onClick={handleSubmit}>
                                                Add Patient
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AddPatientPopUp;
