import { AppContextIntialState } from "../Helpers/Enums";
import { AppReducerActions } from "../ReducerActions/AppReducerActions";

const InitialState = {
  ...AppContextIntialState,
};

export const AppReducer = (state = InitialState, action) => {
  switch (action.type) {
    case AppReducerActions.PAGE_DATA_CHANGED: {
      const { pageDataChanged } = action;
      return { ...state, pageDataChanged };
    }

    case AppReducerActions.TOGGLE_LOADER: {
      const { value } = action;
      return { ...state, isLoading: value };
    }

    case AppReducerActions.SHOW_TOAST: {
      const { message, toastType } = action;
      return {
        ...state,
        showToast: !state.showToast,
        toastMessage: message,
        toastType,
      };
    }

    case AppReducerActions.SHOW_ACTIONPOPUP: {
      const { title, description, confirmButtonLabel, disableCancelBtn } =
        action;
      return {
        ...state,
        actionPopUp: {
          isOpen: true,
          title,
          description,
          confirmButtonLabel,
          disableCancelBtn,
        },
      };
    }
    case AppReducerActions.CLOSE_ACTIONPOPUP: {
      return {
        ...state,
        actionPopUp: {
          isOpen: false,
          ...AppContextIntialState.actionPopUp,
        },
      };
    }
    case AppReducerActions.SHOW_TOPMENU: {
      const { value } = action
      return {
        ...state,
        showTopMenu: value
      };
    }
    case AppReducerActions.SET_ACTIVETAB: {
      const { value } = action
      return {
        ...state,
        activeTab: value
      };
    }
    // case AppReducerActions.SHOW_ABSTRACTOR_POPUP: {
    //   const {value} = action
    //   return{
    //     ...state,
    //     abstractorPopUp: {
    //       ...state.abstractorPopUp,
    //       isOpen: value
    //     }
    //   }
    // }
    case AppReducerActions.SET_VEN_RAMP_UP_WEEK_CNT: {
      const {value} = action
      return{
        ...state,
        venRampUpWeekCnt : value
      }
    } 
    case AppReducerActions.SET_TMT_AFTER_EPCO_LOT_CNT: {
      const {value} = action
      return{
        ...state,
        tmtAfterEpcoLOTCnt: value
      }
    }     
    case AppReducerActions.SHOW_CART_PAGE: {
      const {value} = action
      return{
        ...state,
        showCartPage: value
      }
    } 
    case AppReducerActions.SET_TLS_WEEK_CNT: {
      const {value} = action
      return{
        ...state,
        tlsWeekCnt : value
      }
    } 
    case AppReducerActions.SET_TLS_WEEK_TEST_CNT: {
      const {value} = action
      return{
        ...state,
        tlsWeekTestCnt : value
      }
    } 
    default:
      return { ...state };
  }
};
