import WebServiceWrapper from "./WebServiceWrapper";

export const VenetoclaxRampUpDataService = {
  GetVenetoclaxRampUpData,
  SaveVenetoclaxRampUpData,
  GetVenRampUpWeekData,
  SaveVenRampUpWeekData
};

function GetVenetoclaxRampUpData(patientId) {
  return WebServiceWrapper.Get(`/venetoclaxRampUp/GetVenetoclaxRampUpData?patientId=${patientId}`
  );
}
function SaveVenetoclaxRampUpData(payload) {
  return WebServiceWrapper.Post("/venetoclaxRampUp/SaveVenetoclaxRampUpData", payload);
}
function GetVenRampUpWeekData(patientId, weekNum) {
  return WebServiceWrapper.Get(`/venetoclaxRampUp/GetVenRampUpWeekData?patientId=${patientId}&weekNum=${weekNum}`
  );
}
function SaveVenRampUpWeekData(payload) {
  return WebServiceWrapper.Post("/venetoclaxRampUp/SaveVenRampUpWeekData", payload);
}
