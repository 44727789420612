import React, { useContext, useEffect, useState } from "react";
import {
  HTTPResponse,
  SideMenuItems,
  ToastMessages,
  ToastMessageType,
  Values,
} from "../../Helpers/Enums";
import SideBarMenu from "../../Components/SideBarMenu";
import PatientDetails from "../../Components/PatientInformation";
import SaveAndNavigate from "../../Components/SaveAndNavigate";
import {
  GetLocalStorageData,
  GetLoggedInUserID,
} from "../../Helpers/Utilities";
import { AppContext } from "../../Contexts/AppContextProvider";
import { CommonError_Msgs } from "../../Helpers/HelperText";
import { DemographicService } from "../../WebApiServices/Demographic.service";
const Demographics = () => {
  const navigatePaths = {
    prevPage: "/EligibilityCriteria",
    currPage: "/Demographics",
    nextPage: "/ClinicalCharacteristics",
  };

  //ST 23/09/2024 demographics data
  const [ptDemographicsDetails, setPtDemographicsDetails] = useState({
    gender: null,
    patients_race_asian: false,
    patients_race_black: false,
    patients_race_caucasian: false,
    patients_race_native_american: false,
    patients_race_pacific_islander: false,
    patients_race_radio: null,
    patients_ethnicity: null,
  });

  const pid = GetLocalStorageData("patientId") ?? 0;
  const { ShowToast, ToggleLoader, HandleSessionTimeout } =
    useContext(AppContext);

  //ST 23/09/24 Save demographic data of the patient in table 'Table_Name'
  const SavePage = async () => {
    try {
      ToggleLoader(true);

      const demographicModel = {
        patientId: pid,
        gender: ptDemographicsDetails.gender,
        raceAsian: ptDemographicsDetails.patients_race_asian,
        raceBlack: ptDemographicsDetails.patients_race_black,
        raceCaucasian: ptDemographicsDetails.patients_race_caucasian,
        raceNativeAmerican: ptDemographicsDetails.patients_race_native_american,
        racePacificIslander:
          ptDemographicsDetails.patients_race_pacific_islander,
        patientRace: ptDemographicsDetails.patients_race_radio,
        ethnicity: ptDemographicsDetails.patients_ethnicity,
        loggedInUserId: GetLoggedInUserID(),
      };

      if (pid > 0) {
        const response = await DemographicService.SaveDemographicData(
          demographicModel
        );
        ToggleLoader(false);

        if (response.status !== HTTPResponse.OK) {
          if (response?.status === HTTPResponse.Unauthorized) {
            HandleSessionTimeout();
          } else {
            throw response.error;
          }
        } else {
          ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
        }
      }
      return true;
    } catch (error) {
      ToggleLoader(false);
      ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed);
    }
  };

  useEffect(() => {
    LoadData();
  }, []);

  //ST 23/09/24 Load demographic data of the patient in table 'Table_Name'
  const LoadData = async () => {
    try {
      ToggleLoader(true);
      if (pid > 0) {
        const response = await DemographicService.GetOldCoreDemographicData(
          pid
        );
        ToggleLoader(false);
        if (response?.status === HTTPResponse.OK) {
          const { data } = response;

          setPtDemographicsDetails( prev => ({
            ...prev,
            gender: data.gender,
            patients_race_asian: data.raceAsian,
            patients_race_black: data.raceBlack,
            patients_race_caucasian: data.raceCaucasian,
            patients_race_pacific_islander: data.racePacificIslander,
            patients_race_native_american: data.raceNativeAmerican,                        
            patients_race_radio: data.patientRace,
            patients_ethnicity: data.ethnicity,
          }));
        }

        if (response.status !== HTTPResponse.OK) {
          if (response?.status === HTTPResponse.Unauthorized) {
            HandleSessionTimeout();
          } else {
            throw response.error;
          }
        }
      }

      ToggleLoader(false);
      return true;
    } catch (error) {
      ToggleLoader(false);
      ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
    }
  };

  return (
    <>
      <section className="survey-patient-details-section">
        <div className="row">
          <PatientDetails />
          <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
            <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
          </div>
        </div>
      </section>

      <section className="main-survey-section">
        <div className="row">
          <SideBarMenu activePageId={SideMenuItems.Demographics} />
          <div className="col-md-9 col-pad-left">
            <div className="survey-section-content">
              <div className="survey-section-content-header">
                <h4>PATIENT DEMOGRAPHICS </h4>
              </div>

              {/* <summary>
              date: 20-09-2024
              Name: ST
              description: Demographics Design
              <summary> */}
              <div className="survey-question-section">
                <div className="survey-question-content">
                  <div className="question-answer">
                    {/* Q15. */}

                    <div className="readonly-div">
                      <div className="question question-weight">
                        <span>15.</span>
                        <span className="quest-text-pad">
                          What is the patient’s sex?{" "}
                          <i>
                            Please note that this response has been
                            pre-populated from the CORE database. If this
                            response is inconsistent with the patient chart,
                            please return to the CORE database to make the
                            correction.{" "}
                          </i>
                        </span>
                      </div>
                      <div className="double-dig-answer">
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="gender_male"
                              name="gender"
                              value={1}
                              checked={ptDemographicsDetails.gender === 1}
                              readOnly
                            />
                            <span className="radio-text-padding">Male</span>
                          </label>
                        </div>
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="gender_female"
                              name="gender"
                              value={2}
                              checked={
                                ptDemographicsDetails.gender === Values.value2
                              }
                              readOnly
                            />
                            <span className="radio-text-padding">Female</span>
                          </label>
                        </div>
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="gender_other"
                              name="gender"
                              value={3}
                              checked={
                                ptDemographicsDetails.gender === Values.value3
                              }
                              readOnly
                            />
                            <span className="radio-text-padding">Other</span>
                          </label>
                        </div>
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="gender_unk"
                              name="gender"
                              value={99}
                              checked={
                                ptDemographicsDetails.gender === Values.value99
                              }
                              readOnly
                            />
                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                      </div>
                    </div>

                    {/* Q16. */}
                    <div className="readonly-div">
                      <div className="question question-weight">
                        <span>16.</span>
                        <span className="quest-text-pad">
                          What is the patient’s race?{" "}
                          <i>
                            Please note that this response has been
                            pre-populated from the CORE database. If this
                            response is inconsistent with the patient chart,
                            please return to the CORE database to make the
                            correction.{" "}
                          </i>
                        </span>
                      </div>
                      <div className="double-dig-answer">
                        <div className="option-without-input">
                          <label>
                            <input
                              type="checkbox"
                              id="patients_race_asian_checkbox"
                              name="patients_race_asian"
                              checked={
                                ptDemographicsDetails.patients_race_asian
                              }
                              readOnly
                            />
                            <span className="radio-text-padding">Asian</span>
                          </label>
                        </div>
                        <div className="option-without-input">
                          <label>
                            <input
                              type="checkbox"
                              id="patients_race_black_checkbox"
                              name="patients_race_black"
                              checked={
                                ptDemographicsDetails.patients_race_black
                              }
                              readOnly
                            />
                            <span className="radio-text-padding">Black</span>
                          </label>
                        </div>
                        <div className="option-without-input">
                          <label>
                            <input
                              type="checkbox"
                              id="patients_race_caucasian_checkbox"
                              name="patients_race_caucasian"
                              checked={
                                ptDemographicsDetails.patients_race_caucasian
                              }
                              readOnly
                            />
                            <span className="radio-text-padding">
                              Caucasian
                            </span>
                          </label>
                        </div>
                        <div className="option-without-input">
                          <label>
                            <input
                              type="checkbox"
                              id="patients_race_native_american_checkbox"
                              name="patients_race_native_american"
                              checked={
                                ptDemographicsDetails.patients_race_native_american
                              }
                              readOnly
                            />
                            <span className="radio-text-padding">
                              Native American
                            </span>
                          </label>
                        </div>
                        <div className="option-without-input">
                          <label>
                            <input
                              type="checkbox"
                              id="patients_race_pacific_islander_checkbox"
                              name="patients_race_pacific_islander"
                              checked={
                                ptDemographicsDetails.patients_race_pacific_islander
                              }
                              readOnly
                            />
                            <span className="radio-text-padding">
                              Pacific Islander
                            </span>
                          </label>
                        </div>
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="patients_race_other"
                              name="patients_race_radio"
                              checked={
                                ptDemographicsDetails.patients_race_radio ===
                                Values.value1
                              }
                              value={1}
                              readOnly
                            />
                            <span className="radio-text-padding">Other</span>
                          </label>
                        </div>
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="patients_race_unk"
                              name="patients_race_radio"
                              checked={
                                ptDemographicsDetails.patients_race_radio ===
                                Values.value99
                              }
                              value={99}
                              readOnly
                            />
                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                      </div>
                    </div>

                    {/* Q17. */}
                    <div className="readonly-div">
                      <div className="question question-weight">
                        <span>17.</span>
                        <span className="quest-text-pad">
                          What is the patient's primary ethnicity?{" "}
                          <i>
                            Please note that this response has been
                            pre-populated from the CORE database. If this
                            response is inconsistent with the patient chart,
                            please return to the CORE database to make the
                            correction.{" "}
                          </i>
                        </span>
                      </div>
                      <div className="double-dig-answer">
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="patients_ethnicity_his"
                              name="patients_ethnicity"
                              value={1}
                              readOnly
                              checked={
                                ptDemographicsDetails.patients_ethnicity ===
                                Values.value1
                              }
                            />
                            <span className="radio-text-padding">
                              Hispanic or Latino{" "}
                            </span>
                          </label>
                        </div>
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="patients_ethnicity_not_his"
                              name="patients_ethnicity"
                              value={2}
                              readOnly
                              checked={
                                ptDemographicsDetails.patients_ethnicity ===
                                Values.value2
                              }
                            />
                            <span className="radio-text-padding">
                              Not Hispanic or Latino
                            </span>
                          </label>
                        </div>
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="patients_ethnicity_unk"
                              name="patients_ethnicity"
                              value={99}
                              readOnly
                              checked={
                                ptDemographicsDetails.patients_ethnicity ===
                                Values.value99
                              }
                            />
                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="survey-section-content-foot">
                <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Demographics;
