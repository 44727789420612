import WebServiceWrapper from "./WebServiceWrapper";

export const DemographicService = {
  GetOldCoreDemographicData,
  SaveDemographicData,
};

function GetOldCoreDemographicData(patientId) {
  return WebServiceWrapper.Get(
    `/oldCore/GetOldCoreDemographicData?patientId=${patientId}`
  );
}

function SaveDemographicData(payload) {
  return WebServiceWrapper.Post("/demographic/SaveDemographicData", payload);
}
