import React, {
  createContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from "react";
import { AppReducer } from "../Reducers/AppReducer";
import {
  AppContextIntialState,
  HTTPResponse,
  SideMenuPageList,
  ToastMessageType,
  ToastTimeout,
  TopMenuItems,
} from "../Helpers/Enums";
import Loader from "../Components/Loader";
import Toast from "../Components/Toast";
import { AppReducerActions } from "../ReducerActions/AppReducerActions";
import ActionPopUp from "../Components/ActionPopUp";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import TopMenu from "../Components/TopMenu";
import { CommonMsgs } from "../Helpers/HelperText";
import { GetLocalStorageData, GetLoggedInUserID, isStringEmpty } from "../Helpers/Utilities";
import { UserLoginService } from "../WebApiServices/UserLogin.service";
//import { TreatmentResponseService } from "../WebApiServices/TreatmentResponse.service";

export const AppContext = createContext();
const AppContextProvider = (props) => {
  const [appState, DispatchAppContextAction] = useReducer(AppReducer, {
    ...AppContextIntialState,
  });
  const navigate = useNavigate();
  const { pathname } = useLocation();

  //ref to resolve the confirm action
  const confirmResolveFunction = useRef();

  useEffect(() => {
    async function FetchData(patientId) {
      //const response = await TreatmentResponseService.IsCARTCellTherapySelected(patientId);
      // if (response?.status === HTTPResponse.OK) {
      //   const { data } = response;
      //   ShowCartPage(!data);
      // } else if (response?.status === HTTPResponse.Unauthorized) {
      //   HandleSessionTimeout();
      // }
    }

    let patientData = GetLocalStorageData("patient")
    patientData = patientData && JSON.parse(patientData);
    const patientId = patientData?.patientId ?? 0;
    if (!isNaN(patientId) && Number(patientId) !== 0) {
      FetchData(patientId);
    }
  }, [])
  /* <summary>
  date: 06-04-2024
  Name: AP
  description: Effect to hide toast after 4 seconds
  <summary>*/
  useEffect(() => {
    if (appState.showToast) {
      setTimeout(() => {
        DispatchAppContextAction({
          type: AppReducerActions.SHOW_TOAST,
          message: "",
        });
      }, ToastTimeout);
    }

    return () => { };
  }, [appState]);
  /* <summary>
  date: 06-04-2024
  Name: AP
  description: Scroll to top of page when it loads
  <summary>*/
  useEffect(() => {
    //Routes to consider when showing Top Menu
    const surveyRoutes = [...SideMenuPageList.map(a => a.path?.toLowerCase())];
    const includedRoutes = ["/managepatients", "/manageabstractors", "/managecenters", ...surveyRoutes];

    window.scrollTo(0, 0);

    // if (surveyRoutes) {
    //   IsSurveyPage(true);
    // } else {
    //   IsSurveyPage(false);
    // }


    if (!isStringEmpty(pathname) && includedRoutes.some(route => pathname.toLowerCase().startsWith(route))) {
      ShowTopMenu(true);

      if (pathname.toLowerCase().includes("/managecenters"))
        SetActiveTab(TopMenuItems.Centers);
      else if (pathname.toLowerCase().includes("/manageabstractors"))
        SetActiveTab(TopMenuItems.Abstractors);
      else SetActiveTab(TopMenuItems.Patients);
    } else {
      ShowTopMenu(false);
    }

    // Authenticate logged in user Token
    //   async function Authenticate() {
    //     const response = await UserLoginService.AuthenticateJWTToken();
    //     if (response?.status === HTTPResponse.Unauthorized) {
    //       await HandleSessionTimeout();
    //     }
    //   }
    //   GetLoggedInUserID() && Authenticate();
    // }, [pathname]);

    // Authenticate logged in user Token

    //SYJ 12/12/2024 - Added code used in emmpower to fix-> https://ideawharf.sifterapp.com/issues/12966
    async function Authenticate() {
      const response = await UserLoginService.AuthenticateJWTToken();
      if (response?.status === HTTPResponse.Unauthorized) {
        localStorage.clear();             
        const excludeRoutes = ["/", "/resetpassword", "/passwordrecovery"];
        if (!excludeRoutes.includes(pathname.toLowerCase())) {
          await HandleSessionTimeout();
        }
      }
    }
    GetLoggedInUserID() && Authenticate();
  }, [pathname]);

  /* <summary>
  date: 06-04-2024
  Name: AP
  description: Show message n Redirect to login page once session times out
  <summary>*/
  const HandleSessionTimeout = async () => {
    ToggleLoader(false);
    const choice = await ShowActionPopUp(
      "",
      CommonMsgs.SessionTimeout,
      "Ok",
      true
    );
    if (choice) {
      localStorage.clear();
      navigate("/");
    }
  };

  /* <summary>
  date: 06-04-2024
  Name: AP
  description: Toggle UI Loader
  <summary>*/
  function ToggleLoader(action) {
    DispatchAppContextAction({
      type: AppReducerActions.TOGGLE_LOADER,
      value: action,
    });
  }

  function ShowToast(message, toastType) {
    DispatchAppContextAction({
      type: AppReducerActions.SHOW_TOAST,
      message,
      toastType,
    });
  }
  function SetVenRampWeekCnt(action) {
    DispatchAppContextAction({
      type: AppReducerActions.SET_VEN_RAMP_UP_WEEK_CNT,
      value: action,
    });
  }
  function SetTmtAfterEpcoLOTCnt(action) {
    DispatchAppContextAction({
      type: AppReducerActions.SET_TMT_AFTER_EPCO_LOT_CNT,
      value: action,
    });
  }
  /* <summary>
  date: 02-07-2024
  Name: AP
  description: Show/Hide Intention To Treat with CAR T Cell Therapy page
  <summary>*/
  function ShowCartPage(action) {
    DispatchAppContextAction({
      type: AppReducerActions.SHOW_CART_PAGE,
      value: action,
    });
  }

  /* <summary>
  date: 06-04-2024
  Name: AP
  description: Method to show confirm modal and resolve the action
  <summary>*/
  const ShowActionPopUp = (
    title,
    description,
    confirmButtonLabel,
    disableCancelBtn
  ) => {
    return new Promise((resolve) => {
      DispatchAppContextAction({
        type: AppReducerActions.SHOW_ACTIONPOPUP,
        title,
        description,
        confirmButtonLabel,
        disableCancelBtn,
      });
      confirmResolveFunction.current = (choice) => {
        resolve(choice);
        DispatchAppContextAction({
          type: AppReducerActions.CLOSE_ACTIONPOPUP,
        });
      };
    });
  };

  const OnConfirmModalCancelClicked = () => {
    confirmResolveFunction.current(false);
  };

  const OnConfirmModalConfirmClicked = () => {
    confirmResolveFunction.current(true);
  };
  /* <summary>
  date: 06-04-2024
  Name: AP
  description: Toggle top menu
  <summary>*/
  const ShowTopMenu = (action) => {
    DispatchAppContextAction({
      type: AppReducerActions.SHOW_TOPMENU,
      value: action,
    });
  };
  /* <summary>
date: 06-04-2024
Name: AP
description: Set active tab to highlight in top menu
<summary>*/
  const SetActiveTab = (action) => {
    DispatchAppContextAction({
      type: AppReducerActions.SET_ACTIVETAB,
      value: action,
    });
  };


  function SetTLSWeekCnt(action) {
    DispatchAppContextAction({
      type: AppReducerActions.SET_TLS_WEEK_CNT,
      value: action,
    });
  }


  function SetTLSWeekTestCnt(action) {
    DispatchAppContextAction({
      type: AppReducerActions.SET_TLS_WEEK_TEST_CNT,
      value: action,
    });
  }

  const { isLoading, showToast, toastMessage, actionPopUp, showTopMenu } =
    appState;

  const value = useMemo(() => {
    return {
      appState,
      ToggleLoader,
      ShowToast,
      ShowActionPopUp,
      ShowTopMenu,
      SetActiveTab,
      HandleSessionTimeout,
      ShowCartPage,
      SetVenRampWeekCnt,
      SetTmtAfterEpcoLOTCnt,
      SetTLSWeekCnt,
      SetTLSWeekTestCnt
    };
  }, [appState]);
  return (
    <AppContext.Provider
      value={value}
    >
      <>
        {isLoading && <Loader />}
        {showToast && toastMessage && <Toast />}
        {actionPopUp.isOpen && (
          <ActionPopUp
            onCancel={OnConfirmModalCancelClicked}
            OnConfirmed={OnConfirmModalConfirmClicked}
          />
        )}
        <main style={pathname === "/" ? { overflowY: "hidden" } : {}}>
          <Header />
          {showTopMenu && <TopMenu />}
          {props.children}
          {<Footer isLoginPage={pathname.toLowerCase() === "/"} />}
        </main>
      </>
    </AppContext.Provider>
  );
};

export default AppContextProvider;
