import React from "react";

const Header = () => {
  return (

    <section className="header-section">
      <div className="container">
        <h5>
         Venetoclax Initiation Module in a Real-World Study in Chronic Lymphocytic Leukemia (CLL) in the United States
        </h5>
        <h5>An Extension of the Collaborative Study of Real-World Evidence (CORE)</h5>
        <h4>CASE REPORT FORM (CRF)</h4>
      </div>
    </section>
  );
};

export default Header;
