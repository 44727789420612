import React, { useContext, useEffect, useState } from "react";
import { HTTPResponse, PageDesignConstants, SideMenuItems, ToastMessages, ToastMessageType, Values } from "../../Helpers/Enums";
import SideBarMenu from "../../Components/SideBarMenu";
import PatientDetails from "../../Components/PatientInformation";
import SaveAndNavigate from "../../Components/SaveAndNavigate";
import DateControl from "../../Components/DateControl";
import { GetAlphabetSuffix, GetLocalStorageData, GetLoggedInUserID, GetOrdinal, IsDateBefore, IsDtValid } from "../../Helpers/Utilities";
import ErrorField from "../../Components/ErrorField";
import { AppContext } from "../../Contexts/AppContextProvider";
import { CommonError_Msgs, TreatmentInterruption_Msgs } from "../../Helpers/HelperText";
import dayjs from "dayjs";
import { TreatmentInterruptionService } from "../../WebApiServices/TreatmentInterruption.service";

const TreatmentInterruptions = () => {
    const {
        ShowToast,
        ToggleLoader,
        HandleSessionTimeout,
        appState
    } = useContext(AppContext);

    
    const navigatePaths = {
        prevPage: appState?.tlsWeekTestCnt ?? 0 > 0 ? `/TlsLabTestMonitoring/${appState?.tlsWeekCnt}/${appState?.tlsWeekTestCnt}` : (appState?.tlsWeekCnt ?? 0 > 0) && (appState?.tlsWeekTestCnt === 0 )? `/TlsWeekMonitoring/${appState?.tlsWeekCnt}` : "/TlsMonitoring",
        //prevPage: "/TlsMonitoring",
        currPage: "/TreatmentInterruptions",
        nextPage: "/HealthCareVisits",
    };

    const pid = GetLocalStorageData("patientId") ?? 0;
    const [showTopErrMsg, setShowTopErrMsg] = useState(false);

    const [treatmentInterruption, setTreatmentInterruption] = useState({
        total_No_interruptions: null,
        total_No_interruptions_txt: null,
        total_No_interruptions_txt_value: "",
        venetoclaxStartDate:"",
        venetoclaxEndDate:"",
        interventionGiven: null,
        yesDoseHold: false,
    });

    const [isValidLoad, setIsValidLoad] = useState(false);


    //Repeaters
    const [interruptionHoldBridge, setInterruptionHoldBridge] = useState([{
        interruptionBridgeId: 0,
        interruption_Date: null,
        interruption_Date_Value: "",
        veneto_Restarted_Date: null,
        veneto_Restarted_Date_Value: "",
        invalidDate: false,
        invalidVenetoDate: false,

        //ST: 04/10/2024: Hard erros for Q38. and Q39. empty check
        interruption_DateEmpty: false,
        interruption_Date_ValueEmpty: false,
        veneto_Restarted_DateEmpty: false,
        veneto_Restarted_Date_ValueEmpty: false,
        dateOutOfRange: false,
        venetoOutOfRange: false,
        venetoOutOfRangeUnk: false,
        intDatePrior: false
    }]);
    

    const [hardErrList, setHardErrList] = useState({
        total_No_interruptionsEmpty: false,
        total_No_interruptions_txtEmpty: false,
        total_No_interruptions_txt_valueEmpty: false,
      });

    const [softErrList, setSoftErrList] = useState({
        valueOutOfRange: false,
    });

    const [rangeInvalidErrorMsg, setRangeInvalidErrorMsg] = useState("");
    const [interruptoinsNoOrUnkErrorMsg, setInterruptoinsNoOrUnkErrorMsg] = useState("");
    const [dateInvalidRange, setDateInvalidRange] = useState("");
    const [venetoInvalidRange, setvenetoInvalidRange] = useState("");
    const [venetoInvalidRangeUnk, setvenetoInvalidRangeUnk] = useState("");
    const [intDatePrior, setIntDatePrior] = useState("");


    // ST: 03/10/2024,
    // State to store the holds value(No. of div's to repeat)
    // const [holds, setHolds] = useState();
    // ST: 03/10/2024,
    // Handle change in the input field for Q37.
    const HandleFieldChange = e => {
        const { name, value } = e.target;
        switch (name) {
          
          case "total_No_interruptions": {
            const valueInt = Number(value);
            
            setTreatmentInterruption(prev => ({
              ...prev,
              [name]: valueInt,
            }));
            if(valueInt == Values.value2 || valueInt == Values.value99){
                setTreatmentInterruption(prev => ({
                    ...prev,
                    [name]: valueInt,
                    total_No_interruptions_txt: null,
                    total_No_interruptions_txt_value: "",

                  }));
                //   setHolds(null);
                setInterruptionHoldBridge([]);
            }
            setHardErrList(prev => ({
                ...prev,
                total_No_interruptionsEmpty: false,
                total_No_interruptions_txtEmpty: false,
                total_No_interruptions_txt_valueEmpty: false,         
              }));

              if (treatmentInterruption.interventionGiven == Values.value1 && treatmentInterruption.yesDoseHold &&
                (valueInt == Values.value2 || valueInt == Values.value99)) {
                setInterruptoinsNoOrUnkErrorMsg(TreatmentInterruption_Msgs.interruptoinsNoOrUnkErrorMsg);
            } else {
                setInterruptoinsNoOrUnkErrorMsg("");
            }

            // setSoftErrList(prev=>({
            // ...prev,
            // valueOutOfRange: false,

            // }));
            setRangeInvalidErrorMsg("");
            break;
          }
          case "total_No_interruptions_txt": {
            const valueInt = Number(value);
            setTreatmentInterruption(prev => ({
              ...prev,
              [name]: valueInt,
              total_No_interruptions: 1,
            }));
            setHardErrList(prev => ({
                ...prev,
                total_No_interruptionsEmpty: false,
                total_No_interruptions_txtEmpty: false,
                total_No_interruptions_txt_valueEmpty: false,         
              }));

            // setSoftErrList(prev=>({
            // ...prev,
            // valueOutOfRange: false,

            // }));
            

            if(valueInt == Values.value99){
                setTreatmentInterruption(prev => ({
                    ...prev,
                    [name]: valueInt,
                    total_No_interruptions_txt_value: ""
                  }));
                //   setHolds(null);
                setInterruptionHoldBridge([]);
                
            }

            setRangeInvalidErrorMsg("");
            setInterruptoinsNoOrUnkErrorMsg("");
            break;
          }


          case "total_No_interruptions_txt_value": {
            const isInvalidRange = parseInt(value, 10) < 0 || parseInt(value, 10) > 10;
            // const holds = parseInt(value, 10) || "";
            // setHolds(holds);
            setIsValidLoad(true);
            setTreatmentInterruption(prev => ({
                ...prev,
                [name]: value,
                total_No_interruptions_txt: 1,
                total_No_interruptions: 1,
              }));
              setHardErrList(prev => ({
                ...prev,
                total_No_interruptionsEmpty: false,
                total_No_interruptions_txtEmpty: false,
                total_No_interruptions_txt_valueEmpty: false,         
              }));

            //   setSoftErrList(prev=>({
            //     ...prev,
            //     valueOutOfRange: isInvalidRange,

            //   }))

            if (isInvalidRange) {
                setRangeInvalidErrorMsg(
                    TreatmentInterruption_Msgs.rangeInvalidErrorMsgStart + value + TreatmentInterruption_Msgs.rangeInvalidErrorMsgEnd
                );
            } else {
                setRangeInvalidErrorMsg("");
            }
            setInterruptoinsNoOrUnkErrorMsg("");
            break;
          }


          
          default:
            break;
        }
    };


    // ST: 03/10/2024,
    // Handle Repeater Field Change Function for Q38. and Q39.
    const HandleRepeaterFieldChange = e => {
        const { name, value } = e.target;
        const lastIndex = name.lastIndexOf("_");
        const idx = name.slice(lastIndex + 1);
        const optionName = name;

        switch (optionName) {
            case "interruptionDate_" + idx:
                {
                    let Iarray = [...interruptionHoldBridge];
                    Iarray[idx].interruption_Date = parseInt(value, 10);
                    Iarray[idx].interruption_Date_Value = "";
                    Iarray[idx].interruption_DateEmpty = false;
                    Iarray[idx].interruption_Date_ValueEmpty = false;
                    Iarray[idx].dateOutOfRange = false;
                    Iarray[idx].venetoOutOfRange = false;
                    Iarray[idx].invalidDate = false;
                    Iarray[idx].intDatePrior = false;                    

                    const idx_int = Number(idx);
                    if(idx_int + 1 < Iarray.length) {
                        const nextDate = Iarray[idx_int + 1].interruption_Date_Value;
                        if(nextDate && IsDtValid(nextDate)) {
                            Iarray[idx_int + 1].intDatePrior = false;  
                        }
                    }   
                    setInterruptionHoldBridge(Iarray);


                }
                break;

            case "venetoRestartedDate_" + idx:
                {
                    let Varray = [...interruptionHoldBridge];
                    Varray[idx].veneto_Restarted_Date = parseInt(value, 10);
                    Varray[idx].veneto_Restarted_Date_Value = "";
                    Varray[idx].veneto_Restarted_DateEmpty = false;
                    Varray[idx].veneto_Restarted_Date_ValueEmpty = false;
                    Varray[idx].dateOutOfRange = false;
                    Varray[idx].venetoOutOfRange = false;
                    Varray[idx].invalidVenetoDate = false;


                    setInterruptionHoldBridge(Varray);
                }
                break;

            
            default:
                break;
        }
    };


    // ST: 04/10/2024,
    // Handle Repeater Date Change Function for Q38. and Q39.
    const HandleRepeaterDateChange = (name, date) => {
        const lastIndex = name.lastIndexOf("_");
        const idx =  parseInt(name.slice(lastIndex + 1), 10);
        let venetoStartDt = treatmentInterruption.venetoclaxStartDate 
        ? new Date(treatmentInterruption.venetoclaxStartDate) : null; // Venetoclax Start Date
        let venetoEndDt = treatmentInterruption.venetoclaxEndDate 
        ? new Date(treatmentInterruption.venetoclaxEndDate) : null; // Venetoclax End Date
        switch (name) {
          case "interruptionDateValue_" + idx: {
           
            let IDarray = [...interruptionHoldBridge];
            
            IDarray[idx].interruption_Date_Value = date;
            let currentDate = new Date(date);
            IDarray[idx].interruption_Date = 1;
            IDarray[idx].interruption_DateEmpty = false;
            IDarray[idx].interruption_Date_ValueEmpty = false;

            if (!IsDtValid(currentDate)) {
                IDarray[idx].invalidDate = true;
            }
            else 
            {
                IDarray[idx].invalidDate = false;
                if(venetoStartDt != null || venetoEndDt != null)
                {
                    if((venetoStartDt > currentDate)
                    ||(currentDate > venetoEndDt))
                    {
                        IDarray[idx].dateOutOfRange = true;
                        setDateInvalidRange(
                            <>The date of the {GetOrdinal(idx + 1)} treatment interruption/dose hold must be between {treatmentInterruption.venetoclaxStartDate} and {treatmentInterruption.venetoclaxEndDate}.</>
                        );
                    }
                    else{
                        IDarray[idx].dateOutOfRange = false;
                    }
                }
                if (idx > 0) {
                    const priorDate = IDarray[idx-1].interruption_Date_Value;
                    IDarray[idx].intDatePrior = false;    
                    if(priorDate && IsDtValid(priorDate)) {
                        if (IsDateBefore(currentDate, priorDate)) {   

                            setIntDatePrior(
                                <>
                                    The date of the {GetOrdinal(idx + 1)} treatment interruption/dose hold must be after the date of the {GetOrdinal(idx)} treatment interruption/dose hold. Please confirm or update your response.
                                </>
                            );
                            IDarray[idx].intDatePrior = true;                            
            }
                    }
                }
                const next_idx = idx + 1;
                if(next_idx < IDarray.length) {
                    const nextDate = IDarray[next_idx].interruption_Date_Value;
                    IDarray[next_idx].intDatePrior = false; 
                    if(nextDate && IsDtValid(nextDate)) {
                        if (IsDateBefore(nextDate, currentDate)) {                            
                            setIntDatePrior(
                                <>
                                    The date of the {GetOrdinal(next_idx + 1)} treatment interruption/dose hold must be after the date of the {GetOrdinal(next_idx)} treatment interruption/dose hold. Please confirm or update your response.
                                </>
                            );
                            IDarray[next_idx].intDatePrior = true;                            
                        }
                    }
                }
            }
            
           
        
            setInterruptionHoldBridge(IDarray);
        }
        
            break;

            case "venetoRestartedDateValue_" + idx:
                {
                    let IDarray = [...interruptionHoldBridge];
                    IDarray[idx].veneto_Restarted_Date_Value = date;
                    let currentDate = new Date(IDarray[idx].veneto_Restarted_Date_Value);
                    let intDate = new Date(IDarray[idx].interruption_Date_Value);
                    IDarray[idx].veneto_Restarted_Date = 1;
                    IDarray[idx].veneto_Restarted_DateEmpty = false;
                    IDarray[idx].veneto_Restarted_Date_ValueEmpty = false;

                    if (!IsDtValid(currentDate)) {
                        IDarray[idx].invalidVenetoDate = true;
                    }
                    else 
                    {
                        if(venetoStartDt != null || venetoEndDt != null)
                        {
                            if(IDarray[idx].interruption_Date_Value != ""
                                && (
                                    (intDate > currentDate)
                                ||  (currentDate > venetoEndDt))
                                ){
                                IDarray[idx].venetoOutOfRange = true;

                                setvenetoInvalidRange(
                                    <>The date in which venetoclax ramp-up was restarted must be between {IDarray[idx].interruption_Date_Value} and {treatmentInterruption.venetoclaxEndDate}.</>
                                );

                                }
                                else{
                                    IDarray[idx].venetoOutOfRange = false;
                                }
                        }

                        if(venetoStartDt != null || venetoEndDt != null)
                        {
                            if(IDarray[idx].interruption_Date == Values.value99
                                && (
                                    (currentDate > venetoEndDt)
                                ||  (currentDate < venetoStartDt )
                                    )
                            ){
                                IDarray[idx].venetoOutOfRangeUnk = true;
                                setvenetoInvalidRangeUnk(
                                    <>The date in which venetoclax ramp-up was restarted must be between {treatmentInterruption.venetoclaxStartDate} and {treatmentInterruption.venetoclaxEndDate}.</>
                                );
                            }
                            else{
                                    IDarray[idx].venetoOutOfRangeUnk = false;
                            }
                        }
                    }
                    
                    setInterruptionHoldBridge(IDarray);
                  
                }
            break;

            default:
                break;
        }

    };




    // ST: 04/10/2024, 
    // Save data in treatmentinterruption and interruption
    const SavePage = async validate => {
        try {
            ToggleLoader(true);
            setHardErrList(hardErrList);
            setSoftErrList(softErrList);

      
            let isValid = validate ? ValidateForm() : true;
            if (isValid) {
                setShowTopErrMsg(false);
                let treatmentDataToSave = {
                    treatmentInterruptionModel: {
                        PatientId: pid,
                        LoggedInUserID: GetLoggedInUserID(),
                        InterruptionExp: treatmentInterruption.total_No_interruptions,
                        InterruptionCountUnk: treatmentInterruption.total_No_interruptions_txt == Values.value1 ? false :
                                                treatmentInterruption.total_No_interruptions_txt == Values.value99 ? true : null,
                                                InterruptionCount: treatmentInterruption.total_No_interruptions_txt_value === "" 
                                                ? null 
                                                : (treatmentInterruption.total_No_interruptions_txt_value == 0 
                                                    ? 0 
                                                    : treatmentInterruption.total_No_interruptions_txt_value),
                                            
                        InterruptionHoldBridge: []
                    }
                };
                
                if (interruptionHoldBridge.length > 0) {
                    for (let i = 0; i < interruptionHoldBridge.length; i++) {
                        let holds = {
                            InterruptionHoldBridgeId: interruptionHoldBridge[i].interruptionBridgeId,
                            // TreatmentInterruptionId: i + 1,
                            InterruptionDateUnk: interruptionHoldBridge[i].interruption_Date == Values.value1 
                                                 && interruptionHoldBridge[i].interruption_Date_Value !== "Invalid Date"
                                                 ? false : interruptionHoldBridge[i].interruption_Date == Values.value99
                                                 ? true : null,
                            InterruptionDate: interruptionHoldBridge[i].interruption_Date_Value !== "Invalid Date"
                                ? interruptionHoldBridge[i].interruption_Date_Value : null,
                            RampupRestarted: interruptionHoldBridge[i].veneto_Restarted_Date==1 
                                             && interruptionHoldBridge[i].veneto_Restarted_Date_Value == "Invalid Date"
                                             ? null : interruptionHoldBridge[i].veneto_Restarted_Date,
                            RampupRestartedDate: interruptionHoldBridge[i].veneto_Restarted_Date_Value !== "Invalid Date"
                                ? interruptionHoldBridge[i].veneto_Restarted_Date_Value : null,
                        };
                        treatmentDataToSave.treatmentInterruptionModel.InterruptionHoldBridge.push(holds);
                    }
                }
                
      
                if (pid > 0) {
                    const response = await TreatmentInterruptionService.SaveTreatmentInterruptionData(treatmentDataToSave.treatmentInterruptionModel);
                    ToggleLoader(false);
      
                    if (response.status != HTTPResponse.OK) {
                        if (response?.status == HTTPResponse.Unauthorized) {
                            HandleSessionTimeout();
                        } else { 
                          throw response.error;
                        }
                    } else {
                        setHardErrList(prev => ({
                            ...prev,
                            total_No_interruptionsEmpty: false,
                            total_No_interruptions_txtEmpty: false,
                            total_No_interruptions_txt_valueEmpty: false,
                        }));
                        ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
                        await LoadData();
                    }
                }
                return true;
            }
            else {
                ToggleLoader(false);
                setShowTopErrMsg(true);
                return false;
            }
        }
        catch (error) {
            ToggleLoader(false);
            ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed);
        }
    };


    useEffect(() => {
        LoadData();
      }, []);
    
    // ST: 05/10/24, Load treatmnt interuption data of the treatmentinterruption and interruptionholdbridge tables.
    const LoadData = async () => {
        try {
            ToggleLoader(true);
            if (pid > 0) {
                const response = await TreatmentInterruptionService.GetTreatmentInterruptionData(pid);
                ToggleLoader(false);
          
                if (response?.status === HTTPResponse.OK) {
                    const { data } = response;
                    setIsValidLoad(false);

          
                    // Static Question Loading
                    setTreatmentInterruption(prev => ({
                        ...prev,
                        total_No_interruptions: data.interruptionExp,
                        total_No_interruptions_txt: data.interruptionCountUnk === true ? Values.value99 : 
                                                   data.interruptionCountUnk === false ? Values.value1 : null,
                                                   total_No_interruptions_txt_value: data.interruptionCount == null 
                                                   ? "" 
                                                   : (data.interruptionCount === 0 
                                                       ? 0 
                                                       : data.interruptionCount),
                                               
                        venetoclaxStartDate: data.venetoclaxStartDate,
                        venetoclaxEndDate: data.venetoclaxEndDate,
                        interventionGiven: data.interventionGiven,
                        yesDoseHold: data.yesDoseHold,
                        //once venetoclax page is complete, remove below 2 code and uncomment above 2 lines of code
                        // interventionGiven: 1,
                        // yesDoseHold: true,

                    }));
                    // Repeatable Loop (InterruptionHoldBridge)
                    if (data.interruptionHoldBridge && data.interruptionHoldBridge.length > 0) {
                        const bridgeData = data.interruptionHoldBridge.map(item => ({
                            interruptionBridgeId: item.interruptionHoldBridgeId,
                            interruption_Date: item.interruptionDateUnk === true ? Values.value99 
                                                : item.interruptionDateUnk === false &&  item.interruptionDate != "" ? Values.value1 : null,
                            interruption_Date_Value: item.interruptionDate,
                            veneto_Restarted_Date: item.rampupRestarted == 1 && item.rampupRestartedDate == "" ? null : item.rampupRestarted,
                            veneto_Restarted_Date_Value: item.rampupRestartedDate,
                            interruption_DateEmpty: false,
                            interruption_Date_ValueEmpty: false,
                            veneto_Restarted_DateEmpty: false,
                            veneto_Restarted_Date_ValueEmpty: false,
                            dateOutOfRange: false,
                            venetoOutOfRange: false,
                            venetoOutOfRangeUnk: false,
                            invalidDate: false,
                            invalidVenetoDate: false,
                            intDatePrior: false,
                        }));
                        
                        // setInterruptionHoldBridge([...bridgeData]);
                        setInterruptionHoldBridge(bridgeData); // Directly set the new data

                    }
                } else if (response?.status === HTTPResponse.Unauthorized) {
                    HandleSessionTimeout();
                } else {
                    throw response.error;
                }
            }
            ToggleLoader(false);
        } catch (error) {
            ToggleLoader(false);
            ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
        }
    };
    
    


    function formatInput(e) {
    const invalidChars = /[^0-9\s]/;
    const allowedKeys = ["Backspace", "Delete", " ", "ArrowLeft", "ArrowRight", "Tab"];

    if (e.key !== undefined) {
        if (allowedKeys.includes(e.key)) {
            return;
        }

        if (invalidChars.test(e.key)) {
            e.preventDefault();
        }
    } else if (e.keyCode !== undefined) {
        if (
            e.keyCode !== 8 &&  // Backspace
            e.keyCode !== 46 && // Delete
            e.keyCode !== 32 && // Space
            (e.keyCode < 48 || e.keyCode > 57) // Numbers 0-9
        ) {
            e.preventDefault();
        }
    }
    }
    
    const ValidateForm = () => {
        let isValid = true;

        if(treatmentInterruption.total_No_interruptions == null){
            setHardErrList(prev => ({
                ...prev,
                total_No_interruptionsEmpty: true,
              }));
              isValid = false;
        }else{
            setHardErrList(prev => ({
                ...prev,
                total_No_interruptionsEmpty: false,
              }));
        }

        if(treatmentInterruption.total_No_interruptions == 1){
        if(treatmentInterruption.total_No_interruptions_txt == null){
            setHardErrList(prev => ({
                ...prev,
                total_No_interruptions_txtEmpty: true,
              }));
              isValid = false;
        }else{
            setHardErrList(prev => ({
                ...prev,
                total_No_interruptions_txtEmpty: false,
              }));
        }}

        if(treatmentInterruption.total_No_interruptions_txt == Values.value1){
        if(treatmentInterruption.total_No_interruptions_txt_value === ""){
            setHardErrList(prev => ({
                ...prev,
                total_No_interruptions_txt_valueEmpty: true,
              }));
              isValid = false;
        }else{
            setHardErrList(prev => ({
                ...prev,
                total_No_interruptions_txt_valueEmpty: false,
              }));
        }}

        if(
            treatmentInterruption.total_No_interruptions_txt_value > Values.value0){
        //Repeater Validation
        // Map over the current state to check for errors
        const updatedInterruptionHoldBridge = interruptionHoldBridge.map((data) => {
            // Initialize validation flags for each entry
            // let hasErrors = false;

            if (!IsDtValid(data.interruption_Date_Value)) {
                data.invalidDate = true;
                isValid = false;
            }
            else {
                data.invalidDate = false;
            }

             if (!IsDtValid(data.veneto_Restarted_Date_Value)) {
                 data.invalidVenetoDate = true;
                 isValid = false;
             }
             else {
                 data.invalidVenetoDate = false;
             }




            // Validate "What was the date of the "Nth" treatment interruption/dose hold?" question
            if (!data.interruption_Date) {
                data.interruption_DateEmpty = true;
                // hasErrors = true;
                isValid = false;
            } else {
                data.interruption_DateEmpty = false;
            }

            if (!data.veneto_Restarted_Date) {
                data.veneto_Restarted_DateEmpty = true;
                // hasErrors = true;
                isValid = false;
            } else {
                data.veneto_Restarted_DateEmpty = false;
            }

            if (data.interruption_Date == Values.value1) {
            if (IsDtValid(data.interruption_Date_Value) && data.interruption_Date_Value == "") {
                data.interruption_Date_ValueEmpty = true;
                // hasErrors = true;
                isValid = false;
            } else {
                data.interruption_Date_ValueEmpty = false;
            }}

            if (data.veneto_Restarted_Date == Values.value1) {
                if (IsDtValid(data.veneto_Restarted_Date_Value) && data.veneto_Restarted_Date_Value == "") {
                    data.veneto_Restarted_Date_ValueEmpty = true;
                    // hasErrors = true;
                    isValid = false;
                } else {
                    data.veneto_Restarted_Date_ValueEmpty = false;
                }}

           
            // Return the updated data object with flags
            return { ...data };
        });

        // Update the state with any validation errors
        setInterruptionHoldBridge(updatedInterruptionHoldBridge);
    }

        return isValid;
    };




        // Use useEffect to update interruptionHoldBridge array based on total_No_interruptions_txt_value
        // useEffect(() => {
           
        //     if(isValidLoad){
        //     const holdsCount = parseInt(treatmentInterruption.total_No_interruptions_txt_value, 10) || 0;

        //     // Ensure the holds count doesn't exceed 10
        //     const limitedHoldsCount = Math.min(holdsCount, 10);


        //     // Create an array with the length based on the visitCount
        //     const updatedHolds = Array.from({ length: limitedHoldsCount }, (_, i) => ({
        //         userId: GetLoggedInUserID(),
        //         interruption_Date: null,
        //         interruption_Date_Value: "",
        //         veneto_Restarted_Date: null,
        //         veneto_Restarted_Date_Value: "",

        //         //Error msges states
        //         interruption_DateEmpty: false,
        //         interruption_Date_ValueEmpty: false,
        //         veneto_Restarted_DateEmpty: false,
        //         veneto_Restarted_Date_ValueEmpty: false,
        //     }));
        //     setInterruptionHoldBridge(updatedHolds);
        // }
        // }, [treatmentInterruption.total_No_interruptions_txt_value]);

        useEffect(() => {
            if (isValidLoad) {
                const holdsCount = parseInt(treatmentInterruption.total_No_interruptions_txt_value, 10) || 0;
        
                // Ensure the holds count doesn't exceed 10
                const limitedHoldsCount = Math.min(holdsCount, 10);
        
                setInterruptionHoldBridge(prevHolds => {
                    const currentCount = prevHolds.length;
        
                    // If limitedHoldsCount is less than current holds, slice the array
                    if (limitedHoldsCount < currentCount) {
                        return prevHolds.slice(0, limitedHoldsCount);
                    }
        
                    // Determine how many more holds to add (making sure total doesn't exceed 10)
                    const remainingToAdd = Math.min(limitedHoldsCount - currentCount, 10 - currentCount);
        
                    // If no more holds need to be added, return the current state
                    if (remainingToAdd <= 0) return prevHolds;
        
                    // Create the new holds array
                    const newHolds = Array.from({ length: remainingToAdd }, () => ({
                        userId: GetLoggedInUserID(),
                        interruptionBridgeId: 0,
                        interruption_Date: null,
                        interruption_Date_Value: "",
                        veneto_Restarted_Date: null,
                        veneto_Restarted_Date_Value: "",
        
                        // Error message states
                        interruption_DateEmpty: false,
                        interruption_Date_ValueEmpty: false,
                        veneto_Restarted_DateEmpty: false,
                        veneto_Restarted_Date_ValueEmpty: false,
                        dateOutOfRange: false,
                        venetoOutOfRange: false,
                        venetoOutOfRangeUnk: false,
                        invalidDate: false,
                        invalidVenetoDate: false,
                        intDatePrior: false,

                    }));
        
                    // Return the updated holds array with new holds appended
                    return [...prevHolds, ...newHolds];
                });
            }
        }, [treatmentInterruption.total_No_interruptions_txt_value, isValidLoad]);
        

    return (
        <>
            <section className="survey-patient-details-section">
                <div className="row">
                    <PatientDetails />
                    <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
                        <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
                    </div>
                </div>
            </section>

            <section className="main-survey-section">
                <div className="row">
                    <SideBarMenu activePageId={SideMenuItems.TreatmentInterruptions} />

                    <div className="col-md-9 col-pad-left">
                        <div className="survey-section-content">
                            <div className="survey-section-content-header">
                                <h4>TREATMENT INTERRUPTIONS & DOSE REDUCTIONS</h4>
                            </div>


                            {showTopErrMsg && (
                <div className="clean-error ">
                  <ErrorField
                    show={showTopErrMsg}
                    message={CommonError_Msgs.Hard_Empty}
                  />
                </div>
              )}



                            <div className="survey-question-section">
                                <div className="survey-question-content">
                                    <div className="question-answer">
                                        <div className="question-bot-sapce single-dig-quest">

                                            {/* Q37. */}
                                            <div className="question-bot-sapce">
                                                <div className="question question-weight">
                                                    <span>37.</span>
                                                    <span className="quest-text-pad">
                                                        Did the patient experience any treatment interruptions (stopped and restarted the same treatment) or holding of dose during venetoclax ramp-up as part of VO therapy? Please indicate the total number of treatment interruptions/dose holds between 
                                                        {" "}
                                                        <span style={{ color: PageDesignConstants.fontColor }}>
                                                            {treatmentInterruption.venetoclaxStartDate 
                                                                ? treatmentInterruption.venetoclaxStartDate 
                                                                : "[insert Venetoclax Start Date]"}
                                                        </span>{" "}
                                                        and
                                                        {" "}
                                                        <span style={{ color: PageDesignConstants.fontColor }}>
                                                            {treatmentInterruption.venetoclaxEndDate 
                                                                ? treatmentInterruption.venetoclaxEndDate 
                                                                : "[insert Venetoclax End Date]"}
                                                        </span>
                                                        .
                                                    </span>

                                                </div>
                                                <div className="double-dig-answer">

                                                    <div className="option-without-input">
                                                        <input
                                                            type="radio"
                                                            id="total_No_interruptions_1"
                                                            name="total_No_interruptions"
                                                            checked={treatmentInterruption.total_No_interruptions === Values.value1}
                                                            value={1}
                                                        onChange={HandleFieldChange}
                                                        />
                                                        <label htmlFor="total_No_interruptions_1">
                                                            <span className="radio-text-padding">Yes</span>
                                                        </label>
                                                    </div>

                                                    <div className="option-without-input question-20-c-sub-option-custom">
                                                        <div className="option-without-input">
                                                            <label>
                                                                <input
                                                                    type="radio"
                                                                    id="total_No_interruptions_txt_1"
                                                                    name="total_No_interruptions_txt"
                                                                    value={1}
                                                                checked={treatmentInterruption.total_No_interruptions_txt === Values.value1}
                                                                onChange={HandleFieldChange}
                                                                />
                                                                <input
                                                                    className="input-dash input_custome-space_sm"
                                                                    name="total_No_interruptions_txt_value"
                                                                    type="text"
                                                                    maxLength={100}
                                                                    value={treatmentInterruption.total_No_interruptions_txt_value}
                                                                    onChange={(e) => {
                                                                        const value = e.target.value;
                                                                        const regex = /^\d{0,2}$/;
                                                                        if (regex.test(value)) {
                                                                            HandleFieldChange(e);
                                                                        }
                                                                    }}
                                                                    onKeyDown={formatInput}
                                                                />
                                                                <span className="radio-text-padding">
                                                                    interruptions/dose holds{" "}
                                                                </span>
                                                            </label>
                                                        </div>
                                                        <div className="option-without-input">
                                                            <label className="option-fbox custom-spacing-lbl">
                                                                <input
                                                                    type="radio"
                                                                    id="total_No_interruptions_txt_99"
                                                                    name="total_No_interruptions_txt"
                                                                    value={99}
                                                                    checked={treatmentInterruption.total_No_interruptions_txt === Values.value99}
                                                                    onChange={HandleFieldChange}
                                                                />
                                                                <span>
                                                                    Number of interruptions/dose holds unknown
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="option-without-input">
                                                        <input
                                                            type="radio"
                                                            id="total_No_interruptions_2"
                                                            name="total_No_interruptions"
                                                            checked={treatmentInterruption.total_No_interruptions === Values.value2}
                                                            value={2}
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <label htmlFor="total_No_interruptions_2">
                                                            <span className="radio-text-padding">No</span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <input
                                                            type="radio"
                                                            id="total_No_interruptions_99"
                                                            name="total_No_interruptions"
                                                            checked={treatmentInterruption.total_No_interruptions === Values.value99}
                                                            value={99}
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <label htmlFor="total_No_interruptions_99">
                                                            <span className="radio-text-padding">Unknown</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <ErrorField show={hardErrList.total_No_interruptionsEmpty} message={TreatmentInterruption_Msgs.total_No_interruptionsEmpty} />
                                                <ErrorField show={hardErrList.total_No_interruptions_txtEmpty} message={TreatmentInterruption_Msgs.total_No_interruptions_txtEmpty} />
                                                <ErrorField show={hardErrList.total_No_interruptions_txt_valueEmpty} message={TreatmentInterruption_Msgs.total_No_interruptions_txt_valueEmpty} />
                                                <ErrorField message={rangeInvalidErrorMsg} />
                                                <ErrorField message={interruptoinsNoOrUnkErrorMsg} />

                                                




                                            </div>


                                            {/* Loop Start */}
                                            {treatmentInterruption.total_No_interruptions_txt_value !== "" 
                                            && treatmentInterruption.total_No_interruptions_txt_value > 0 
                                            && interruptionHoldBridge.map((data, index) => (
                                        <div className="dependent-section">
                        
                                            {/* <div className="">
                                                <span className="question-weight" style={{color:"red"}}>
                                                    {GetOrdinal(index + 1)}
                                                </span>
                                            </div> */}
                                            
                                            {/* Q38. */}
                                            <div className="sub-question-bot-sapce answer-pad-left">
                                                <div className="question question-weight">
                                                    <span>38{GetAlphabetSuffix(index + 1)}.</span>
                                                    <span className="quest-text-pad">
                                                        What was the date of the 
                                                        {/* N<sup>th</sup>  */} {" "} {GetOrdinal(index + 1)} {" "}
                                                        treatment interruption/dose hold?
                                                    </span>
                                                </div>
                                                <div className="double-dig-answer">
                                                    <div className="date-field-padding">
                                                        <input
                                                            type="radio"
                                                            id="interruption_Date_1"
                                                            name={"interruptionDate_" + index}
                                                            value={1}
                                                            checked={data.interruption_Date === Values.value1}
                                                            onChange={HandleRepeaterFieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            <DateControl
                                                                ctrlId={"interruptionDateValue_" + index}
                                                                HandleDateChange={HandleRepeaterDateChange}
                                                                date={data.interruption_Date_Value}
                                                                showUnkInfoTxt={false}
                                                                minDate={dayjs("01-01-1920")} 
                                                                maxDate={dayjs()}
                                                            />
                                                        </span>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <label >
                                                            <input
                                                                type="radio"
                                                                id="interruption_Date_99"
                                                                name={"interruptionDate_" + index}
                                                                value={99}
                                                                checked={data.interruption_Date === Values.value99}
                                                                onChange={HandleRepeaterFieldChange}
                                                            />

                                                            <span className="radio-text-padding">Unknown</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <ErrorField show={data.interruption_DateEmpty} 
                                                message={TreatmentInterruption_Msgs.interruption_DateEmpty} />
                                                <ErrorField show={data.interruption_Date_ValueEmpty} 
                                                message={TreatmentInterruption_Msgs.interruption_Date_ValueEmpty} />
                                                <ErrorField show={data.dateOutOfRange} 
                                                message={dateInvalidRange} />
                                                <ErrorField show={data.invalidDate} message={TreatmentInterruption_Msgs.invalidDate} />
                                                {/* <ErrorField message={venetoStartDateErrorMsg} /> */}
                                                <ErrorField show={data.intDatePrior} message={intDatePrior} />
                                            </div>

                                            {/* Q39. */}
                                            <div className="sub-question-bot-sapce answer-pad-left">
                                                <div className="question question-weight">
                                                    <span>39{GetAlphabetSuffix(index + 1)}.</span>
                                                    <span className="quest-text-pad">
                                                        Please indicate the date in which the venetoclax ramp-up was restarted.
                                                    </span>
                                                </div>
                                                <div className="double-dig-answer">
                                                    <div className="date-field-padding">
                                                        <input
                                                            type="radio"
                                                            id="veneto_Restarted_Date_1"
                                                            name={"venetoRestartedDate_" + index}
                                                            value={1}
                                                            checked={data.veneto_Restarted_Date === Values.value1}
                                                            onChange={HandleRepeaterFieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            <DateControl
                                                                ctrlId={"venetoRestartedDateValue_" + index}
                                                                HandleDateChange={HandleRepeaterDateChange}
                                                                date={data.veneto_Restarted_Date_Value }
                                                                showUnkInfoTxt={false}
                                                                minDate={dayjs("01-01-1920")} 
                                                                maxDate={dayjs()}
                                                            />
                                                        </span>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <label >
                                                            <input
                                                                type="radio"
                                                                id="veneto_Restarted_Date_2"
                                                                name={"venetoRestartedDate_" + index}
                                                                value={2}
                                                                checked={data.veneto_Restarted_Date === Values.value2}
                                                                onChange={HandleRepeaterFieldChange}
                                                            />

                                                            <span className="radio-text-padding">Not applicable, the venetoclax ramp-up was never restarted</span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <label >
                                                            <input
                                                                type="radio"
                                                                id="veneto_Restarted_Date_99"
                                                                name={"venetoRestartedDate_" + index}
                                                                value={99}
                                                                checked={data.veneto_Restarted_Date === Values.value99}
                                                                onChange={HandleRepeaterFieldChange}
                                                            />

                                                            <span className="radio-text-padding">Unknown</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <ErrorField show={data.veneto_Restarted_DateEmpty} message={TreatmentInterruption_Msgs.veneto_Restarted_DateEmpty} />
                                                <ErrorField show={data.veneto_Restarted_Date_ValueEmpty}  message={TreatmentInterruption_Msgs.veneto_Restarted_Date_ValueEmpty} />
                                                <ErrorField show={data.venetoOutOfRange} 
                                                message={venetoInvalidRange} />
                                                <ErrorField show={data.venetoOutOfRangeUnk} 
                                                message={venetoInvalidRangeUnk} />
                                                <ErrorField show={data.invalidVenetoDate} message={TreatmentInterruption_Msgs.invalidDate} />
                                            </div>
                        
                                        </div>))}


                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="survey-section-content-foot">
                                <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default TreatmentInterruptions;
