import React, { useContext, useEffect, useState } from "react";
import {
  HTTPResponse,
  PageDesignConstants,
  SideMenuItems,
  ToastMessages,
  ToastMessageType,
  Values,
} from "../../Helpers/Enums";
import SideBarMenu from "../../Components/SideBarMenu";
import PatientDetails from "../../Components/PatientInformation";
import SaveAndNavigate from "../../Components/SaveAndNavigate";
import DateControl from "../../Components/DateControl";
import dayjs from "dayjs";
import { AppContext } from "../../Contexts/AppContextProvider";
import { EligibilityService } from "../../WebApiServices/Eligibility.service";
import ErrorField from "../../Components/ErrorField";
import {
  GetLocalStorageData,
  GetLoggedInUserID,
  isStringEmpty,
  ParseDate,
  GetDifferenceInDays,
  IsDateBefore,
  IsDateBeforeMinus22Days,
  IsDateAfter,
  IsDtValid,
} from "../../Helpers/Utilities";
import {
  CommonError_Msgs,
  EligibilityCriteria_Msgs,
} from "../../Helpers/HelperText";
import { useNavigate } from "react-router-dom";

const EligibilityCriteria = () => {

  const [isEligible, setIsEligible] = useState(true);
  const [isAllDataFilled, setIsAllDataFilled] = useState(false);

  const [dOBErrorMsgAllo, setDOBErrorMsgAllo] = useState(null);
  const [q9StrDtErrorMsgAllo, setQ9StrDtErrorMsgAllo] = useState(null);
  const [q9StrDtLtEDtErrorMsgAllo, setQ9StrDtLtEDtErrorMsgAllo] = useState(null);
  const [q9MedicationErrorMsgAllo, setQ9MedicationErrorMsgAllo] = useState(null);
  const [dOBErrorMsgAnti, setDOBErrorMsgAnti] = useState(null);
  const [q9StrDtErrorMsgAnti, setQ9StrDtErrorMsgAnti] = useState(null);
  const [q9StrDtLtEDtErrorMsgAnti, setQ9StrDtLtEDtErrorMsgAnti] = useState(null);
  const [q9MedicationErrorMsgAnti, setQ9MedicationErrorMsgAnti] = useState(null);
  const [q7ErrorMsg, setQ7ErrorMsg] = useState(null);
  const [q3ErrorMsg, setQ3ErrorMsg] = useState(null);
  const [q10UnkErrorMsg, setQ10UnkErrorMsg] = useState(null);
  const [q10ErrorMsg, setQ10ErrorMsg] = useState(null);
  const [isTrue, setIsTrue] = useState(false);

  const [terminateMsg, setTerminateMsg] = useState("");
  const navigate = useNavigate();

  const [rangeInvalidLNErrorMsg, setRangeInvalidLNErrorMsg] = useState("");
  const [rangeInvalidALCErrorMsg, setRangeInvalidALCErrorMsg] = useState("");
  const [rangeInvalidCRCLErrorMsg, setRangeInvalidCRCLErrorMsg] = useState("");

  const navigatePaths = {
    prevPage: "/Introduction",
    currPage: "/EligibilityCriteria",
    nextPage: "/EligibilityCriteria",
  };

  const {
    ShowToast,
    ToggleLoader,
    HandleSessionTimeout,
    isSideMenuActive,
    setIsSideMenuActive,
  } = useContext(AppContext);

  useEffect(() => {
    async function FetchData() {
      if (!isNaN(pid) && Number(pid) !== 0) {
        ToggleLoader(true);
        await LoadData();
        ToggleLoader(false);
      }
    }
    FetchData();
  }, []);

  const pid = GetLocalStorageData("patientId") ?? 0;
  let patientData = GetLocalStorageData("patient");
  patientData = patientData && JSON.parse(patientData);
  const centerId = patientData?.ptCenterId ?? 0;
  let countTerminate = 0;

  const [getEligibityData, setGetEligibityData] = useState({
    userId: GetLoggedInUserID(),
    cllslldiagnosis: null,
    cll_dignosed_date: null,
    sll_dignosed_date: null,
    cll_year: null,
    sll_year: null,
    dob_checked: null,
    dob_date: null,
    dob_unk: null,
    initiatevo_therapy: null,
    obinutuzumab_date_yes: null,
    venetoclax_date_yes: null,
    obinutuzumab_date_no: null,
    venetoclax_date_no: null,
    received_agent: null,
    therapy_acalabrutinib: false,
    therapy_btkinhibitor: false,
    therapy_cerdulatinib: false,
    therapy_cudc13045: false,
    therapy_duvelisib: false,
    therapy_entospletinib: false,
    therapy_fenebrutinib: false,
    therapy_ibrutinib: false,
    therapy_idelalisib: false,
    therapy_incb040093: false,
    therapy_obatoclax: false,
    therapy_pirtobrutinib: false,
    therapy_rigosertib: false,
    therapy_spebrutinib: false,
    therapy_umbralisib: false,
    therapy_unspecified: false,
    therapy_vecabrutinib: false,
    therapy_venetoclax: false,
    therapy_zanubrutinib: false,
    therapy_oth: false,
    therapy_oth_txt: null,
    initiation_date: null,
    stop_date: null,
    vo_treated: null,
    received_other_cllagent: null,
    ven_rampup_checked: null,
    ven_rampup_date: null,
    ven_rampup_unknown: null,
    rampup_period: null,
    via_allopurinal: null,
    allopurinol_startdate_checked: null,
    allopurinol_startdate: null,
    allopurinol_startdate_unk: null,
    allopurinol_enddate_checked: null,
    allopurinol_enddate: null,
    allopurinol_enddate_unk: null,
    via_antihyperuricemics: null,
    antihyperuricemics_startdate_checked: null,
    antihyperuricemics_startdate: null,
    antihyperuricemics_startdate_unk: null,
    antihyperuricemics_enddate_checked: null,
    antihyperuricemics_enddate: null,
    antihyperuricemics_enddate_unk: null,
    no_antihyperuricemic: null,
    antihyperuricemic_unk: null,
    medication_clarithromycin: null,
    medication_itraconazole: null,
    medication_ketoconazole: null,
    medication_posaconazole: null,
    medication_ritonavir: null,
    medication_voriconazole: null,
    medication_other: null,
    antihyperuricemic_rasburicase: null,
    metInclusionCriteria: null,
    //PreScreening States
    tumor_burden: null,
    tumor_burden_ln_size: "",
    tumor_burden_alc_size: "",
    tumor_burden_crcl_value: "",
    patient_alive: null,
    patient_death_date: "", //date
    patient_first_treated: null,
    patient_first_treated_date: "", //date
    patient_last_seen: null,
    patient_last_seen_date: "", //date
  });

  const [terminateCount, setTerminateCount] = useState(0);
  const [showTopErrMsg, setShowTopErrMsg] = useState(false);
  const [showTopErrMsgTerminate, setShowTopErrMsgTerminate] = useState(false);
  const [incorrectResponse, setIncorrectResponse] = useState(false);

  async function LoadData() {
    try {
      if (pid > 0) {
        // Fetch data from the service
        const response =
          await EligibilityService.GetEligibilityData(pid);

        if (response?.status === HTTPResponse.OK) {
          const data = response.data;
          // Update the state with the fetched data
          setRangeInvalidLNErrorMsg("");
          setRangeInvalidALCErrorMsg("");
          setRangeInvalidCRCLErrorMsg("");

          setGetEligibityData((prev) => ({
            ...prev,
            cllslldiagnosis: data.cllslldiagnosis,
            cll_dignosed_date: data.clldiagnoseddate === "" ? null : data.clldiagnoseddate,
            sll_dignosed_date: data.slldiagnoseddate === "" ? null : data.slldiagnoseddate,
            cll_year: data.clldiagnosedyear,
            sll_year: data.slldiagnosedyear,
            dob_checked: data.dobDate === null ? null : Values.value1,
            dob_date: data.dobDate === "" ? null : data.dobDate,
            dob_unk: data.dobUnk ? Values.value99 : null,
            initiatevo_therapy: data.initiatevoTherapy,
            obinutuzumab_date_yes: data.initiatevoTherapy == Values.value1 ? (data.obinutuzumabDate === "" ? null : data.obinutuzumabDate) : null,
            venetoclax_date_yes: data.initiatevoTherapy == Values.value1 ? (data.venetoclaxDate === "" ? null : data.venetoclaxDate) : null,
            obinutuzumab_date_no: data.initiatevoTherapy == Values.value2 ? (data.obinutuzumabDate === "" ? null : data.obinutuzumabDate) : null,
            venetoclax_date_no: data.initiatevoTherapy == Values.value2 ? (data.venetoclaxDate === "" ? null : data.venetoclaxDate) : null,
            received_agent: data.initiatevoTherapy === Values.value2 ? data.receivedAgent : null,
            therapy_acalabrutinib: data.initiatevoTherapy === Values.value2 && data.therapyAcalabrutinib,
            therapy_btkinhibitor: data.initiatevoTherapy === Values.value2 && data.therapyBtkinhibitor,
            therapy_cerdulatinib: data.initiatevoTherapy === Values.value2 && data.therapyCerdulatinib,
            therapy_cudc13045: data.initiatevoTherapy === Values.value2 && data.therapyCudc13045,
            therapy_duvelisib: data.initiatevoTherapy === Values.value2 && data.therapyDuvelisib,
            therapy_entospletinib: data.initiatevoTherapy === Values.value2 && data.therapyEntospletinib,
            therapy_fenebrutinib: data.initiatevoTherapy === Values.value2 && data.therapyFenebrutinib,
            therapy_ibrutinib: data.initiatevoTherapy === Values.value2 && data.therapyIbrutinib,
            therapy_idelalisib: data.initiatevoTherapy === Values.value2 && data.therapyIdelalisib,
            therapy_incb040093: data.initiatevoTherapy === Values.value2 && data.therapyIncb040093,
            therapy_obatoclax: data.initiatevoTherapy === Values.value2 && data.therapyObatoclax,
            therapy_pirtobrutinib: data.initiatevoTherapy === Values.value2 && data.therapyPirtobrutinib,
            therapy_rigosertib: data.initiatevoTherapy === Values.value2 && data.therapyRigosertib,
            therapy_spebrutinib: data.initiatevoTherapy === Values.value2 && data.therapySpebrutinib,
            therapy_umbralisib: data.initiatevoTherapy === Values.value2 && data.therapyUmbralisib,
            therapy_unspecified: data.initiatevoTherapy === Values.value2 && data.therapyUnspecified,
            therapy_vecabrutinib: data.initiatevoTherapy === Values.value2 && data.therapyVecabrutinib,
            therapy_venetoclax: data.initiatevoTherapy === Values.value2 && data.therapyVenetoclax,
            therapy_zanubrutinib: data.initiatevoTherapy === Values.value2 && data.therapyZanubrutinib,
            therapy_oth: data.initiatevoTherapy === Values.value2 && data.therapyOth,
            therapy_oth_txt: data.therapyOthTxt,
            initiation_date: data.initiatevoTherapy === Values.value2 ? (data.initiationDate === "" ? null : data.initiationDate) : null,
            stop_date: data.initiatevoTherapy === Values.value2 ? (data.stopDate === "" ? null : data.stopDate) : null,
            vo_treated: data.voTreated,
            received_other_cllagent: data.receivedOtherCllagent,
            ven_rampup_checked: data.venRampupDate != "" && data.venRampupDate != null ? Values.value1 : null,
            ven_rampup_date: data.venRampupDate === "" ? null : data.venRampupDate,
            ven_rampup_unknown: data.venRampupUnknown,
            rampup_period: data.rampupPeriod,
            via_allopurinal: data.viaAllopurinal === Values.value1 ? true : false,
            allopurinol_startdate_checked: data.allopurinolStartdate != "" && data.allopurinolStartdate != null ? Values.value1 : null,
            allopurinol_startdate: data.allopurinolStartdate === "" ? null : data.allopurinolStartdate,
            allopurinol_startdate_unk: data.allopurinolStartdateUnk,
            allopurinol_enddate_checked: data.allopurinolEnddate != "" && data.allopurinolEnddate != null ? Values.value1 : null,
            allopurinol_enddate: data.allopurinolEnddate === "" ? null : data.allopurinolEnddate,
            allopurinol_enddate_unk: data.allopurinolEnddateUnk,
            via_antihyperuricemics: data.viaAntihyperuricemics === Values.value1 ? true : false,
            antihyperuricemics_startdate_checked: data.antihyperuricemicsStartdate != "" && data.antihyperuricemicsStartdate != null ? Values.value1 : null,
            antihyperuricemics_startdate: data.antihyperuricemicsStartdate === "" ? null : data.antihyperuricemicsStartdate,
            antihyperuricemics_startdate_unk: data.antihyperuricemicsStartdateUnk,
            antihyperuricemics_enddate_checked: data.antihyperuricemicsEnddate != "" && data.antihyperuricemicsEnddate != null ? Values.value1 : null,
            antihyperuricemics_enddate: data.antihyperuricemicsEnddate === "" ? null : data.antihyperuricemicsEnddate,
            antihyperuricemics_enddate_unk: data.antihyperuricemicsEnddateUnk,
            no_antihyperuricemic: data.noAntihyperuricemic ? Values.value1 : null,
            antihyperuricemic_unk: data.antihyperuricemicUnk ? Values.value99 : null,
            medication_clarithromycin: data.medicationClarithromycin,
            medication_itraconazole: data.medicationItraconazole,
            medication_ketoconazole: data.medicationKetoconazole,
            medication_posaconazole: data.medicationPosaconazole,
            medication_ritonavir: data.medicationRitonavir,
            medication_voriconazole: data.medicationVoriconazole,
            medication_other: data.medicationOther,
            antihyperuricemic_rasburicase: data.antihyperuricemicRasburicase,
            metInclusionCriteria: data.metInclusionCriteria,

            //PreScreening Data Load
            tumor_burden: data.tumorBurden,
            tumor_burden_ln_size: data.burdenLnSize ?? "",
            tumor_burden_alc_size: data.burdenAlcSize ?? "",
            tumor_burden_crcl_value: data.burdenCrclSize ?? "",
            patient_alive:
              data.patientAlive == true
                ? Values.value1
                : data.patientAlive == false
                  ? Values.value2
                  : null,
            patient_death_date: data.deathDate,
            patient_first_treated: data.firsttreatedDate != "" ? Values.value1 : null,
            patient_first_treated_date: data.firsttreatedDate,
            patient_last_seen: data.lastSeenDate != "" ? Values.value1 : null,
            patient_last_seen_date: data.lastSeenDate,
          }));
        } else if (response?.status === HTTPResponse.Unauthorized) {
          HandleSessionTimeout();
        }
      }
    } catch (error) {
      ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
    }
  }

  const ValidateForm = () => {

    const midmay2019 = ParseDate("05/15/2019");
    let isValid = true;
    let isEmpty = false;

    //Q3
    if (getEligibityData.initiatevo_therapy === null) {
      isEmpty = true;
      setHardErrList((prev) => ({
        ...prev,
        q3Empty: true,
      }));
    } else {
      setHardErrList((prev) => ({
        ...prev,
        q3Empty: false,
        q3ODtEmpty: false,
        q3VDtEmpty: false,
      }));
    }


    if (getEligibityData.initiatevo_therapy === Values.value1 && (isStringEmpty(getEligibityData.obinutuzumab_date_yes) || isStringEmpty(getEligibityData.venetoclax_date_yes))) {
      isEmpty = true;
      setHardErrList((prev) => ({
        ...prev,
        q3ODtEmpty: isStringEmpty(getEligibityData.obinutuzumab_date_yes),
        q3VDtEmpty: isStringEmpty(getEligibityData.venetoclax_date_yes),
      }));
    } else if (getEligibityData.initiatevo_therapy === Values.value2 && (isStringEmpty(getEligibityData.obinutuzumab_date_no) || isStringEmpty(getEligibityData.venetoclax_date_no))) {
      isEmpty = true;
      setHardErrList((prev) => ({
        ...prev,
        q3ODtEmpty: isStringEmpty(getEligibityData.obinutuzumab_date_no),
        q3VDtEmpty: isStringEmpty(getEligibityData.venetoclax_date_no),
      }));
    } else {
      if (getEligibityData.initiatevo_therapy === 1 && !isStringEmpty(getEligibityData.obinutuzumab_date_yes)) {
        const indLTMidMay = IsDateBefore(getEligibityData.obinutuzumab_date_yes, midmay2019);
        if (indLTMidMay) {
          isValid = false;
          setHardErrList((prev) => ({
            ...prev,
            q3IndxLTMidMay: true,
          }));
        }
        else {
          setHardErrList((prev) => ({
            ...prev,
            q3IndxLTMidMay: false,
          }));
        }
      }

      if (getEligibityData.initiatevo_therapy === Values.value2 && !isStringEmpty(getEligibityData.obinutuzumab_date_no)) {
        const indLTMidMay = IsDateBefore(getEligibityData.obinutuzumab_date_no, midmay2019);
        if (indLTMidMay) {
          isValid = false;
          setHardErrList((prev) => ({
            ...prev,
            q3IndxLTMidMay: true,
          }));
        }
        else {
          setHardErrList((prev) => ({
            ...prev,
            q3IndxLTMidMay: false,
          }));
        }
      }


      setHardErrList((prev) => ({
        ...prev,
        q3ODtEmpty: false,
        q3VDtEmpty: false,
      }));
    }

    //Q4
    if (getEligibityData.initiatevo_therapy === Values.value2) {
      if (getEligibityData.received_agent === null) {
        isEmpty = true;
        setHardErrList((prev) => ({
          ...prev,
          q4Empty: true,
        }));
      } else {
        setHardErrList((prev) => ({
          ...prev,
          q4Empty: false,
          q4IDtEmpty: false,
          q4SDtEmpty: false,
          q4OthTextEmpty: false,
        }));
      }

      if (getEligibityData.received_agent === Values.value1
        && (isStringEmpty(getEligibityData.initiation_date) || isStringEmpty(getEligibityData.stop_date) ||
          (getEligibityData.therapy_acalabrutinib == null || getEligibityData.therapy_acalabrutinib === false) &&
          (getEligibityData.therapy_btkinhibitor == null || getEligibityData.therapy_btkinhibitor === false) &&
          (getEligibityData.therapy_cerdulatinib == null || getEligibityData.therapy_cerdulatinib === false) &&
          (getEligibityData.therapy_cudc13045 == null || getEligibityData.therapy_cudc13045 === false) &&
          (getEligibityData.therapy_duvelisib == null || getEligibityData.therapy_duvelisib === false) &&
          (getEligibityData.therapy_entospletinib == null || getEligibityData.therapy_entospletinib === false) &&
          (getEligibityData.therapy_fenebrutinib == null || getEligibityData.therapy_fenebrutinib === false) &&
          (getEligibityData.therapy_ibrutinib == null || getEligibityData.therapy_ibrutinib === false) &&
          (getEligibityData.therapy_idelalisib == null || getEligibityData.therapy_idelalisib === false) &&
          (getEligibityData.therapy_incb040093 == null || getEligibityData.therapy_incb040093 === false) &&
          (getEligibityData.therapy_obatoclax == null || getEligibityData.therapy_obatoclax === false) &&
          (getEligibityData.therapy_pirtobrutinib == null || getEligibityData.therapy_pirtobrutinib === false) &&
          (getEligibityData.therapy_rigosertib == null || getEligibityData.therapy_rigosertib === false) &&
          (getEligibityData.therapy_spebrutinib == null || getEligibityData.therapy_spebrutinib === false) &&
          (getEligibityData.therapy_umbralisib == null || getEligibityData.therapy_umbralisib === false) &&
          (getEligibityData.therapy_unspecified == null || getEligibityData.therapy_unspecified === false) &&
          (getEligibityData.therapy_vecabrutinib == null || getEligibityData.therapy_vecabrutinib === false) &&
          (getEligibityData.therapy_zanubrutinib == null || getEligibityData.therapy_zanubrutinib === false) &&
          (getEligibityData.therapy_oth == null || getEligibityData.therapy_oth === false)
          // && (getEligibityData.therapy_oth_txt == null || getEligibityData.therapy_oth_txt === "")
        )) {
        isEmpty = true;
        isValid = false;
        setHardErrList((prev) => ({
          ...prev,
          q4IDtEmpty: isStringEmpty(getEligibityData.initiation_date),
          q4SDtEmpty: isStringEmpty(getEligibityData.stop_date),

          //ST: 09/10/2024 only mshowing eror if checkbox is not checked
          q4TEmpty: (
            (getEligibityData.therapy_acalabrutinib == null || getEligibityData.therapy_acalabrutinib === false) &&
            (getEligibityData.therapy_btkinhibitor == null || getEligibityData.therapy_btkinhibitor === false) &&
            (getEligibityData.therapy_cerdulatinib == null || getEligibityData.therapy_cerdulatinib === false) &&
            (getEligibityData.therapy_cudc13045 == null || getEligibityData.therapy_cudc13045 === false) &&
            (getEligibityData.therapy_duvelisib == null || getEligibityData.therapy_duvelisib === false) &&
            (getEligibityData.therapy_entospletinib == null || getEligibityData.therapy_entospletinib === false) &&
            (getEligibityData.therapy_fenebrutinib == null || getEligibityData.therapy_fenebrutinib === false) &&
            (getEligibityData.therapy_ibrutinib == null || getEligibityData.therapy_ibrutinib === false) &&
            (getEligibityData.therapy_idelalisib == null || getEligibityData.therapy_idelalisib === false) &&
            (getEligibityData.therapy_incb040093 == null || getEligibityData.therapy_incb040093 === false) &&
            (getEligibityData.therapy_obatoclax == null || getEligibityData.therapy_obatoclax === false) &&
            (getEligibityData.therapy_pirtobrutinib == null || getEligibityData.therapy_pirtobrutinib === false) &&
            (getEligibityData.therapy_rigosertib == null || getEligibityData.therapy_rigosertib === false) &&
            (getEligibityData.therapy_spebrutinib == null || getEligibityData.therapy_spebrutinib === false) &&
            (getEligibityData.therapy_umbralisib == null || getEligibityData.therapy_umbralisib === false) &&
            (getEligibityData.therapy_unspecified == null || getEligibityData.therapy_unspecified === false) &&
            (getEligibityData.therapy_vecabrutinib == null || getEligibityData.therapy_vecabrutinib === false) &&
            (getEligibityData.therapy_zanubrutinib == null || getEligibityData.therapy_zanubrutinib === false) &&
            (getEligibityData.therapy_oth == null || getEligibityData.therapy_oth === false)
          ),
        }));
      } else {

        if (!isStringEmpty(getEligibityData.initiation_date) && !isStringEmpty(getEligibityData.stop_date)) {
          const diffdays = GetDifferenceInDays(getEligibityData.stop_date, getEligibityData.initiation_date);
          if (diffdays > 30) {
            isValid = false;
            setHardErrList((prev) => ({
              ...prev,
              q4SSDtGT30: true,
            }));
          }
          else {
            setHardErrList((prev) => ({
              ...prev,
              q4SSDtGT30: false,
            }));
          }
        }
        //check if other text is empty for Q4.
        // if (getEligibityData.received_agent === 1 && getEligibityData.therapy_oth && (getEligibityData.therapy_oth_txt === null || getEligibityData.therapy_oth_txt === "")) {
        //   isValid = false;
        //   setHardErrList((prev) => ({
        //     ...prev,
        //     q4OthTextEmpty: true,
        //   }));

        // } else {
        //   setHardErrList((prev) => ({
        //     ...prev,
        //     q4OthTextEmpty: false,
        //   }));
        // }

        setHardErrList((prev) => ({
          ...prev,
          q4IDtEmpty: false,
          q4SDtEmpty: false,
          q4TEmpty: false,
        }));
      }
    }

    if (getEligibityData.received_agent === 1 && getEligibityData.therapy_oth && (getEligibityData.therapy_oth_txt === null || getEligibityData.therapy_oth_txt === "")) {
      isEmpty = true;
      setHardErrList((prev) => ({
        ...prev,
        q4OthTextEmpty: true,
      }));

    } else {
      setHardErrList((prev) => ({
        ...prev,
        q4OthTextEmpty: false,
      }));
    }


    //Q5
    if (getEligibityData.vo_treated === null) {
      isEmpty = true;
      setHardErrList((prev) => ({
        ...prev,
        q5Empty: true,
      }));
    } else {
      setHardErrList((prev) => ({
        ...prev,
        q5Empty: false,
      }));
    }

    //Q6
    if (getEligibityData.received_other_cllagent === null) {
      isEmpty = true;
      setHardErrList((prev) => ({
        ...prev,
        q6Empty: true,
      }));
    } else {
      setHardErrList((prev) => ({
        ...prev,
        q6Empty: false,
      }));
    }

    //Q7
    if (getEligibityData.ven_rampup_checked === null && getEligibityData.ven_rampup_unknown === null) {
      isEmpty = true;
      setHardErrList((prev) => ({
        ...prev,
        q7Empty: true,
      }));
    } else {
      setHardErrList((prev) => ({
        ...prev,
        q7Empty: false,
      }));
    }

    if (getEligibityData.ven_rampup_checked === 1 && isStringEmpty(getEligibityData.ven_rampup_date)) {
      isEmpty = true;
      setHardErrList((prev) => ({
        ...prev,
        q7DtEmpty: true,
      }));
    } else {
      setHardErrList((prev) => ({
        ...prev,
        q7DtEmpty: false,
      }));
    }

    //Q8
    if (getEligibityData.rampup_period === null) {
      isEmpty = true;
      setHardErrList((prev) => ({
        ...prev,
        q8Empty: true,
      }));
    } else {
      setHardErrList((prev) => ({
        ...prev,
        q8Empty: false,
      }));
    }

    //Q9
    if ((getEligibityData.via_allopurinal === null || getEligibityData.via_allopurinal === false) && (getEligibityData.via_antihyperuricemics === null || getEligibityData.via_antihyperuricemics === false) && getEligibityData.no_antihyperuricemic === null && getEligibityData.antihyperuricemic_unk === null) {
      isEmpty = true;
      setHardErrList((prev) => ({
        ...prev,
        q9Empty: true,
      }));
    } else {
      setHardErrList((prev) => ({
        ...prev,
        q9Empty: false,
        q9AllSDtEmpty: false,
        q9AllEDtEmpty: false,
        q9AHSDtEmpty: false,
        q9AHEDtEmpty: false,
      }));
    }

    if ((getEligibityData.via_allopurinal && ((isStringEmpty(getEligibityData.allopurinol_startdate) && getEligibityData.allopurinol_startdate_unk === null && getEligibityData.allopurinol_startdate_checked === null) || (isStringEmpty(getEligibityData.allopurinol_enddate) && getEligibityData.allopurinol_enddate_unk === null && getEligibityData.allopurinol_enddate_checked === null))) ||
      (getEligibityData.via_antihyperuricemics && ((isStringEmpty(getEligibityData.antihyperuricemics_startdate) && getEligibityData.antihyperuricemics_startdate_unk === null && getEligibityData.antihyperuricemics_startdate_checked === null) || (isStringEmpty(getEligibityData.antihyperuricemics_enddate) && getEligibityData.antihyperuricemics_enddate_unk === null && getEligibityData.antihyperuricemics_enddate_checked === null)))) {
      isEmpty = true;
      setHardErrList((prev) => ({
        ...prev,
        q9Empty: true,
      }));
    } else {
      if (getEligibityData.via_allopurinal && (isStringEmpty(getEligibityData.allopurinol_startdate) && getEligibityData.allopurinol_startdate_checked === 1) || (isStringEmpty(getEligibityData.allopurinol_enddate) && getEligibityData.allopurinol_enddate_checked === 1)) {
        isEmpty = true;
        setHardErrList((prev) => ({
          ...prev,
          q9AllSDtEmpty: isStringEmpty(getEligibityData.allopurinol_startdate) && getEligibityData.allopurinol_startdate_checked === 1,
          q9AllEDtEmpty: isStringEmpty(getEligibityData.allopurinol_enddate) && getEligibityData.allopurinol_enddate_checked === 1,
        }));
      }
      else {
        setHardErrList((prev) => ({
          ...prev,
          q9AllSDtEmpty: false,
          q9AllEDtEmpty: false,
        }));
      }
      if (getEligibityData.via_antihyperuricemics && (isStringEmpty(getEligibityData.antihyperuricemics_startdate) && getEligibityData.antihyperuricemics_startdate_checked === 1) || (isStringEmpty(getEligibityData.antihyperuricemics_enddate) && getEligibityData.antihyperuricemics_enddate_checked === 1)) {
        isEmpty = true;
        setHardErrList((prev) => ({
          ...prev,
          q9AHSDtEmpty: isStringEmpty(getEligibityData.antihyperuricemics_startdate) && getEligibityData.antihyperuricemics_startdate_checked === 1,
          q9AHEDtEmpty: isStringEmpty(getEligibityData.antihyperuricemics_enddate) && getEligibityData.antihyperuricemics_enddate_checked === 1,
        }));
      }
      else {
        setHardErrList((prev) => ({
          ...prev,
          q9AHSDtEmpty: false,
          q9AHEDtEmpty: false,
        }));
      }
    }

    //Q10
    if (getEligibityData.medication_clarithromycin === null ||
      getEligibityData.medication_itraconazole === null ||
      getEligibityData.medication_ketoconazole === null ||
      getEligibityData.medication_posaconazole === null ||
      getEligibityData.medication_ritonavir === null ||
      getEligibityData.medication_voriconazole === null ||
      getEligibityData.medication_other === null ||
      getEligibityData.antihyperuricemic_rasburicase === null) {
      isEmpty = true;
      setHardErrList((prev) => ({
        ...prev,
        q10Empty: true,
      }));
    } else {
      setHardErrList((prev) => ({
        ...prev,
        q10Empty: false,
      }));
    }

    //PreScreenig Validations
    // Q11 Radio.
    if (getEligibityData.tumor_burden == null) {
      setHardErrList(prev => ({
        ...prev,
        tumor_burdenEmpty: true,
      }));
      isEmpty = true;
    } else {
      setHardErrList(prev => ({
        ...prev,
        tumor_burdenEmpty: false,
      }));
    }

    // Q11 Other text area - validate only if tumor_burden is 5.
    if (getEligibityData.tumor_burden == Values.value5) {
      if (
        getEligibityData.tumor_burden_ln_size == "" ||
        getEligibityData.tumor_burden_alc_size == "" ||
        getEligibityData.tumor_burden_crcl_value == ""
      ) {
        setHardErrList(prev => ({
          ...prev,
          tumor_burden_other_textEmpty: true,
        }));
        isEmpty = true;
      } else {
        // // Alc value validation
        // const alcValue = getEligibityData.tumor_burden_alc_size;
        // // Validates numbers with max 1 decimal place, must start with a digit
        // if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(alcValue)) {
        //   setHardErrList(prev => ({
        //     ...prev,
        //     tumor_burden_alc_invalid: true,
        //   }));
        //   isValid = false;
        // } else {
        setHardErrList(prev => ({
          ...prev,
          // tumor_burden_alc_invalid: false,
          tumor_burden_other_textEmpty: false,
        }));
        // }
      }
    }

    //ST: 14/10/2024: Commenting this since client req. soft erros instead of hard erorr
    // if (getEligibityData.tumor_burden == Values.value5 

    //   &&(getEligibityData.tumor_burden_ln_size != "" ||
    //   getEligibityData.tumor_burden_alc_size != "" ||
    //   getEligibityData.tumor_burden_crcl_value != "") 

    //   && getEligibityData.tumor_burden_crcl_value > 120) {
    //   setHardErrList(prev => ({
    //     ...prev,
    //     tumor_burden_crcl_invalid: true,
    //   }));
    //   isValid = false;
    // } else {
    //   setHardErrList(prev => ({
    //     ...prev,
    //     tumor_burden_crcl_invalid: false,
    //   }));
    // }

    //ST: 11/10/2024
    // Q11 new req.

    // Q11 new req.
    // if (
    //   getEligibityData.tumor_burden == Values.value3 ||
    //   getEligibityData.tumor_burden == Values.value4 ||
    //   getEligibityData.tumor_burden == Values.value99
    // ) {
    //   // If tumor_burden is 3, 4, or 99, set terminateHard to true and mark form as invalid.
    //   setHardErrList(prev => ({
    //     ...prev,
    //     terminateHard: true,
    //   }));
    //   isValid = false;
    // } else if (
    //   getEligibityData.tumor_burden == Values.value5 &&
    //   getEligibityData.tumor_burden_ln_size != "" &&
    //   getEligibityData.tumor_burden_alc_size != "" &&
    //   getEligibityData.tumor_burden_crcl_value != ""
    // ) {
    //   // Extract values from state when tumor_burden is Values.value5
    //   const {
    //     tumor_burden_ln_size,
    //     tumor_burden_alc_size,
    //     tumor_burden_crcl_value,
    //   } = getEligibityData;


    //   // Validate the fields (ln_size, alc_size, crcl_value)
    //   const lnInvalid =
    //     tumor_burden_ln_size &&
    //     ((parseFloat(tumor_burden_ln_size) >= 5 &&
    //       parseFloat(tumor_burden_ln_size) < 10) ||
    //       parseFloat(tumor_burden_ln_size) >= 10);
    //   const alcInvalid =
    //     tumor_burden_alc_size && parseFloat(tumor_burden_alc_size) >= 25;
    //   const crclInvalid =
    //     tumor_burden_crcl_value && (parseFloat(tumor_burden_crcl_value) < 80 || parseFloat(tumor_burden_crcl_value) > 120);

    //   // If any of the fields are invalid, set terminateHard to true
    //   if (lnInvalid || alcInvalid || crclInvalid) {
    //     setHardErrList(prev => ({
    //       ...prev,
    //       terminateHard: true,
    //     }));
    //     isValid = false;
    //   } else {
    //     setHardErrList(prev => ({
    //       ...prev,
    //       terminateHard: false,
    //     }));
    //   }
    // } else {
    //   // For other cases where tumor_burden is not 3, 4, 99, or Values.value5
    //   setHardErrList(prev => ({
    //     ...prev,
    //     terminateHard: false,
    //   }));
    // }

    //Pending need to implement terminate logic, first click of submit butotn Hard erorr is shown and on second click need to terminate the patient and navigate user to manage paitents page
    // if (getEligibityData.metInclusionCriteria == false) {
    //   isValid=true;
    //   if (!isEligible) {
    //     navigate(navigatePaths.nextPage);  // Redirect to Demographics if eligible
    //   }
    // }


    // setIsAllDataFilled(isValid);




    //ST: 15/10/2024: Invalid Date Validation
    //Q3.
    if (!IsDtValid(getEligibityData.obinutuzumab_date_yes)) {
      setHardErrList(prev => ({
        ...prev,
        yesObiInitInvalid: true,
      }));
      isValid = false;
    }
    else {
      setHardErrList(prev => ({
        ...prev,
        yesObiInitInvalid: false,
      }));
    }

    if (!IsDtValid(getEligibityData.venetoclax_date_yes)) {
      setHardErrList(prev => ({
        ...prev,
        yesVenetoInitInvalid: true,
      }));
      isValid = false;
    }
    else {
      setHardErrList(prev => ({
        ...prev,
        yesVenetoInitInvalid: false,
      }));
    }

    if (!IsDtValid(getEligibityData.obinutuzumab_date_no)) {
      setHardErrList(prev => ({
        ...prev,
        noObiInitInvalid: true,
      }));
      isValid = false;
    }
    else {
      setHardErrList(prev => ({
        ...prev,
        noObiInitInvalid: false,
      }));
    }

    if (!IsDtValid(getEligibityData.venetoclax_date_no)) {
      setHardErrList(prev => ({
        ...prev,
        noVenetoInitInvalid: true,
      }));
      isValid = false;
    }
    else {
      setHardErrList(prev => ({
        ...prev,
        noVenetoInitInvalid: false,
      }));
    }

    //Q4.
    if (!IsDtValid(getEligibityData.initiation_date)) {
      setHardErrList(prev => ({
        ...prev,
        initDateInvalid: true,
      }));
      isValid = false;
    }
    else {
      setHardErrList(prev => ({
        ...prev,
        initDateInvalid: false,
      }));
    }

    if (!IsDtValid(getEligibityData.stop_date)) {
      setHardErrList(prev => ({
        ...prev,
        stopDateInvalid: true,
      }));
      isValid = false;
    }
    else {
      setHardErrList(prev => ({
        ...prev,
        stopDateInvalid: false,
      }));
    }

    //Q7.
    if (!IsDtValid(getEligibityData.ven_rampup_date)) {
      setHardErrList(prev => ({
        ...prev,
        venetoRampUpInvalid: true,
      }));
      isValid = false;
    }
    else {
      setHardErrList(prev => ({
        ...prev,
        venetoRampUpInvalid: false,
      }));
    }


    //Q9.
    if (!IsDtValid(getEligibityData.allopurinol_startdate)) {
      setHardErrList(prev => ({
        ...prev,
        startAlloInvalid: true,
      }));
      isValid = false;
    }
    else {
      setHardErrList(prev => ({
        ...prev,
        startAlloInvalid: false,
      }));
    }

    if (!IsDtValid(getEligibityData.allopurinol_enddate)) {
      setHardErrList(prev => ({
        ...prev,
        endAlloInvalid: true,
      }));
      isValid = false;
    }
    else {
      setHardErrList(prev => ({
        ...prev,
        endAlloInvalid: false,
      }));
    }

    if (!IsDtValid(getEligibityData.antihyperuricemics_startdate)) {
      setHardErrList(prev => ({
        ...prev,
        startHyperInvalid: true,
      }));
      isValid = false;
    }
    else {
      setHardErrList(prev => ({
        ...prev,
        startHyperInvalid: false,
      }));
    }

    if (!IsDtValid(getEligibityData.antihyperuricemics_enddate)) {
      setHardErrList(prev => ({
        ...prev,
        endHyperInvalid: true,
      }));
      isValid = false;
    }
    else {
      setHardErrList(prev => ({
        ...prev,
        endHyperInvalid: false,
      }));
    }
    if (!isEmpty && !isValid) {
      setIncorrectResponse(true);
    }
    return isValid && !isEmpty;
  };

  const ValidateTerminateForm = () => {
    let isValid = true;

    //Q3
    if (getEligibityData.initiatevo_therapy === Values.value3) {
      isValid = false;
      setHardErrList((prev) => ({
        ...prev,
        q3Terminate: true,
      }));
    } else {
      setHardErrList((prev) => ({
        ...prev,
        q3Terminate: false,
      }));
    }

    //Q4
    if (getEligibityData.received_agent === Values.value2 || getEligibityData.received_agent === Values.value99) {
      isValid = false;
      setHardErrList((prev) => ({
        ...prev,
        q4Terminate: true,
      }));
    } else {
      setHardErrList((prev) => ({
        ...prev,
        q4Terminate: false,
      }));
    }

    //Q5
    if (getEligibityData.vo_treated === 1 || getEligibityData.vo_treated === Values.value99) {
      isValid = false;
      setHardErrList((prev) => ({
        ...prev,
        q5Terminate: true,
      }));
    } else {
      setHardErrList((prev) => ({
        ...prev,
        q5Terminate: false,
      }));
    }

    //Q6
    if (getEligibityData.received_other_cllagent === 1 || getEligibityData.received_other_cllagent === Values.value99) {
      isValid = false;
      setHardErrList((prev) => ({
        ...prev,
        q6Terminate: true,
      }));
    } else {
      setHardErrList((prev) => ({
        ...prev,
        q6Terminate: false,
      }));
    }

    //Q7
    if (getEligibityData.ven_rampup_unknown === Values.value99) {
      isValid = false;
      setHardErrList((prev) => ({
        ...prev,
        q7Terminate: true,
      }));
    } else {
      setHardErrList((prev) => ({
        ...prev,
        q7Terminate: false,
      }));
    }

    //Q8
    if (getEligibityData.rampup_period === Values.value99) {
      isValid = false;
      setHardErrList((prev) => ({
        ...prev,
        q8Terminate: true,
      }));
    } else {
      setHardErrList((prev) => ({
        ...prev,
        q8Terminate: false,
      }));
    }

    //Q9
    if (getEligibityData.no_antihyperuricemic === Values.value1 || getEligibityData.antihyperuricemic_unk === Values.value99) {
      isValid = false;
      setHardErrList((prev) => ({
        ...prev,
        q9Terminate: true,
      }));
    } else {
      setHardErrList((prev) => ({
        ...prev,
        q9Terminate: false,
      }));
    }

    //Q10
    if (getEligibityData.medication_clarithromycin === Values.value1 ||
      getEligibityData.medication_itraconazole === Values.value1 ||
      getEligibityData.medication_ketoconazole === Values.value1 ||
      getEligibityData.medication_posaconazole === Values.value1 ||
      getEligibityData.medication_ritonavir === Values.value1 ||
      getEligibityData.medication_voriconazole === Values.value1 ||
      getEligibityData.medication_other === Values.value1 ||
      getEligibityData.antihyperuricemic_rasburicase === Values.value1) {
      isValid = false;
      const venetoclaxStartDate =
        !isStringEmpty(getEligibityData.venetoclax_date_yes)
          ? ParseDate(getEligibityData.venetoclax_date_yes)
          : !isStringEmpty(getEligibityData.venetoclax_date_no)
            ? ParseDate(getEligibityData.venetoclax_date_no)
            : "[Venetoclax Start Date]";
      let q10Msg = EligibilityCriteria_Msgs.q10TerminateIneligible.replace('[Venetoclax Start Date]', venetoclaxStartDate);
      setQ10ErrorMsg(q10Msg);
      setHardErrList((prev) => ({
        ...prev,
        q10Terminate: true,
      }));
    } else {
      setHardErrList((prev) => ({
        ...prev,
        q10Terminate: false,
      }));
    }
    if (getEligibityData.medication_clarithromycin === Values.value99 &&
      getEligibityData.medication_itraconazole === Values.value99 &&
      getEligibityData.medication_ketoconazole === Values.value99 &&
      getEligibityData.medication_posaconazole === Values.value99 &&
      getEligibityData.medication_ritonavir === Values.value99 &&
      getEligibityData.medication_voriconazole === Values.value99 &&
      getEligibityData.medication_other === Values.value99 &&
      getEligibityData.antihyperuricemic_rasburicase === Values.value99) {
      isValid = false;
      const venetoclaxStartDate =
        !isStringEmpty(getEligibityData.venetoclax_date_yes)
          ? ParseDate(getEligibityData.venetoclax_date_yes)
          : !isStringEmpty(getEligibityData.venetoclax_date_no)
            ? ParseDate(getEligibityData.venetoclax_date_no)
            : "[Venetoclax Start Date]";
      let q10UnkMsg = EligibilityCriteria_Msgs.q10TerminateIneligibleUnk.replace('[Venetoclax Start Date]', venetoclaxStartDate);
      setQ10UnkErrorMsg(q10UnkMsg);
      setHardErrList((prev) => ({
        ...prev,
        q10TerminateUnk: true,
      }));
    } else {
      setHardErrList((prev) => ({
        ...prev,
        q10TerminateUnk: false,
      }));
    }





    //ST: Prescreening terminate logic
    //Q11
    if (getEligibityData.tumor_burden == Values.value3 ||
      getEligibityData.tumor_burden == Values.value4 ||
      getEligibityData.tumor_burden == Values.value99) {
      isValid = false;
      setHardErrList((prev) => ({
        ...prev,
        terminateHard: true,
      }));
    }

    else if (
      getEligibityData.tumor_burden == Values.value5 &&
      getEligibityData.tumor_burden_ln_size != "" &&
      getEligibityData.tumor_burden_alc_size != "" &&
      getEligibityData.tumor_burden_crcl_value != ""
    ) {
      // Extract values from state when tumor_burden is Values.value5
      const {
        tumor_burden_ln_size,
        tumor_burden_alc_size,
        tumor_burden_crcl_value,
      } = getEligibityData;


      // Validate the fields (ln_size, alc_size, crcl_value)
      const lnInvalid =
        tumor_burden_ln_size &&
        ((parseFloat(tumor_burden_ln_size) >= 5 &&
          parseFloat(tumor_burden_ln_size) < 10) ||
          parseFloat(tumor_burden_ln_size) >= 10);
      const alcInvalid =
        tumor_burden_alc_size && parseFloat(tumor_burden_alc_size) >= 25;
      const crclInvalid =
        tumor_burden_crcl_value && (parseFloat(tumor_burden_crcl_value) < 80);

        const condition1 = tumor_burden_ln_size && tumor_burden_alc_size && tumor_burden_crcl_value &&  ((parseFloat(tumor_burden_ln_size) >= 5 && parseFloat(tumor_burden_ln_size) < 10) || (parseFloat(tumor_burden_alc_size) >= 25)) &&       
        (parseFloat(tumor_burden_crcl_value) < 80);
        const condition2 = tumor_burden_ln_size && parseFloat(tumor_burden_ln_size) >= 10;
        const condition3 = tumor_burden_ln_size && tumor_burden_alc_size && (parseFloat(tumor_burden_alc_size) >= 25) && (parseFloat(tumor_burden_ln_size) >= 5);

      // If any of the fields are invalid, set terminateHard to true
      if (condition1 || condition2 || condition3) {
        setHardErrList(prev => ({
          ...prev,
          terminateHard: true,
        }));
        isValid = false;
      } else {
        setHardErrList(prev => ({
          ...prev,
          terminateHard: false,
        }));
      }
    }


    else {
      setHardErrList((prev) => ({
        ...prev,
        terminateHard: false,
      }));
    }


    //In below code countTerminate gets set to 1, so user gets a chance to revise the options, 
    //If still user submits the form, then user will be terminated(we make metinclusion=false on second submit click)
    if (!isValid) {
      const updatedCount = terminateCount + 1;
      countTerminate = terminateCount + 1;
      setTerminateCount(updatedCount);
    }
    else
      if (terminateCount > 0) {
        const updatedCount = terminateCount - 1;
        countTerminate = terminateCount - 1;
        setTerminateCount(updatedCount);
      }

    setIsAllDataFilled(isValid);
    return isValid;
  };

  const SavePage = async () => {
    try {
      setHardErrList(InitialHardErrList);
      setSoftErrList(DatesSoftErrList);
      setIncorrectResponse(false);

      if (getEligibityData.metInclusionCriteria !== null && !getEligibityData.metInclusionCriteria) {
        navigate("/ManagePatients");
        return false;
      }

      ToggleLoader(true);

      if (!ValidateForm()) {
        setShowTopErrMsg(true);
        setShowTopErrMsgTerminate(false);
        ToggleLoader(false);
        return false;
      }
      if (!ValidateTerminateForm()) {
        setShowTopErrMsg(true);
        ToggleLoader(false);

        if (countTerminate === 1) {
          setShowTopErrMsg(false);
          setShowTopErrMsgTerminate(true);
          return false;
        }
      }
      setShowTopErrMsg(false);
      setShowTopErrMsgTerminate(false);

      const eligibilityCriteriaModel = {
        patientId: pid,
        centerId: centerId,
        loggedInUserID: GetLoggedInUserID(),
        cllslldiagnosis: getEligibityData.cllslldiagnosis,
        clldiagnoseddate: !isStringEmpty(getEligibityData.cll_dignosed_date) ? getEligibityData.cll_dignosed_date : null,
        slldiagnoseddate: !isStringEmpty(getEligibityData.sll_dignosed_date) ? getEligibityData.sll_dignosed_date : null,
        clldiagnosedyear: getEligibityData.cll_year,
        slldiagnosedyear: getEligibityData.sll_year,
        dobDate: !isStringEmpty(getEligibityData.dob_date) ? getEligibityData.dob_date : null,
        dobUnk: getEligibityData.dob_unk,
        initiatevoTherapy: getEligibityData.initiatevo_therapy,
        obinutuzumabDate: getEligibityData.initiatevo_therapy == 1 ? (!isStringEmpty(getEligibityData.obinutuzumab_date_yes) ? getEligibityData.obinutuzumab_date_yes : null) : getEligibityData.initiatevo_therapy == Values.value2 ? (!isStringEmpty(getEligibityData.obinutuzumab_date_no) ? getEligibityData.obinutuzumab_date_no : null) : null,
        venetoclaxDate: getEligibityData.initiatevo_therapy == 1 ? (!isStringEmpty(getEligibityData.venetoclax_date_yes) ? getEligibityData.venetoclax_date_yes : null) : getEligibityData.initiatevo_therapy == Values.value2 ? (!isStringEmpty(getEligibityData.venetoclax_date_no) ? getEligibityData.venetoclax_date_no : null) : null,
        receivedAgent: getEligibityData.initiatevo_therapy === Values.value2 ? getEligibityData.received_agent : null,
        therapyAcalabrutinib: getEligibityData.initiatevo_therapy === Values.value2 && getEligibityData.therapy_acalabrutinib,
        therapyBtkinhibitor: getEligibityData.initiatevo_therapy === Values.value2 && getEligibityData.therapy_btkinhibitor,
        therapyCerdulatinib: getEligibityData.initiatevo_therapy === Values.value2 && getEligibityData.therapy_cerdulatinib,
        therapyCudc13045: getEligibityData.initiatevo_therapy === Values.value2 && getEligibityData.therapy_cudc13045,
        therapyDuvelisib: getEligibityData.initiatevo_therapy === Values.value2 && getEligibityData.therapy_duvelisib,
        therapyEntospletinib: getEligibityData.initiatevo_therapy === Values.value2 && getEligibityData.therapy_entospletinib,
        therapyFenebrutinib: getEligibityData.initiatevo_therapy === Values.value2 && getEligibityData.therapy_fenebrutinib,
        therapyIbrutinib: getEligibityData.initiatevo_therapy === Values.value2 && getEligibityData.therapy_ibrutinib,
        therapyIdelalisib: getEligibityData.initiatevo_therapy === Values.value2 && getEligibityData.therapy_idelalisib,
        therapyIncb040093: getEligibityData.initiatevo_therapy === Values.value2 && getEligibityData.therapy_incb040093,
        therapyObatoclax: getEligibityData.initiatevo_therapy === Values.value2 && getEligibityData.therapy_obatoclax,
        therapyPirtobrutinib: getEligibityData.initiatevo_therapy === Values.value2 && getEligibityData.therapy_pirtobrutinib,
        therapyRigosertib: getEligibityData.initiatevo_therapy === Values.value2 && getEligibityData.therapy_rigosertib,
        therapySpebrutinib: getEligibityData.initiatevo_therapy === Values.value2 && getEligibityData.therapy_spebrutinib,
        therapyUmbralisib: getEligibityData.initiatevo_therapy === Values.value2 && getEligibityData.therapy_umbralisib,
        therapyUnspecified: getEligibityData.initiatevo_therapy === Values.value2 && getEligibityData.therapy_unspecified,
        therapyVecabrutinib: getEligibityData.initiatevo_therapy === Values.value2 && getEligibityData.therapy_vecabrutinib,
        therapyZanubrutinib: getEligibityData.initiatevo_therapy === Values.value2 && getEligibityData.therapy_zanubrutinib,
        therapyOth: getEligibityData.initiatevo_therapy === Values.value2 && getEligibityData.therapy_oth,
        therapyOthTxt: getEligibityData.therapy_oth_txt,
        initiationDate: getEligibityData.initiatevo_therapy === Values.value2 ? (!isStringEmpty(getEligibityData.initiation_date) ? getEligibityData.initiation_date : null) : null,
        stopDate: getEligibityData.initiatevo_therapy === Values.value2 ? (!isStringEmpty(getEligibityData.stop_date) ? getEligibityData.stop_date : null) : null,
        voTreated: getEligibityData.vo_treated,
        receivedOtherCllagent: getEligibityData.received_other_cllagent,
        venRampupDate: !isStringEmpty(getEligibityData.ven_rampup_date) ? getEligibityData.ven_rampup_date : null,
        venRampupUnknown: getEligibityData.ven_rampup_unknown,
        rampupPeriod: getEligibityData.rampup_period,
        viaAllopurinal: getEligibityData.via_allopurinal ? 1 : null,
        allopurinolStartdate: getEligibityData.allopurinol_startdate,
        allopurinolStartdateUnk: getEligibityData.allopurinol_startdate_unk,
        allopurinolEnddate: getEligibityData.allopurinol_enddate,
        allopurinolEnddateUnk: getEligibityData.allopurinol_enddate_unk,
        viaAntihyperuricemics: getEligibityData.via_antihyperuricemics ? 1 : null,
        antihyperuricemicsStartdate: getEligibityData.antihyperuricemics_startdate,
        antihyperuricemicsStartdateUnk: getEligibityData.antihyperuricemics_startdate_unk,
        antihyperuricemicsEnddate: getEligibityData.antihyperuricemics_enddate,
        antihyperuricemicsEnddateUnk: getEligibityData.antihyperuricemics_enddate_unk,
        noAntihyperuricemic: getEligibityData.no_antihyperuricemic,
        antihyperuricemicUnk: getEligibityData.antihyperuricemic_unk,
        medicationClarithromycin: getEligibityData.medication_clarithromycin,
        medicationItraconazole: getEligibityData.medication_itraconazole,
        medicationKetoconazole: getEligibityData.medication_ketoconazole,
        medicationPosaconazole: getEligibityData.medication_posaconazole,
        medicationRitonavir: getEligibityData.medication_ritonavir,
        medicationVoriconazole: getEligibityData.medication_voriconazole,
        medicationOther: getEligibityData.medication_other,
        antihyperuricemicRasburicase: getEligibityData.antihyperuricemic_rasburicase,
        // metInclusionCriteria: terminateCount >= 2 ? false : true,
        metInclusionCriteria: countTerminate >= Values.value2 ? false : true,


        //PreScreening Save
        tumorBurden: getEligibityData.tumor_burden,
        burdenLnSize: getEligibityData.tumor_burden_ln_size,
        burdenAlcSize: getEligibityData.tumor_burden_alc_size,
        burdenCrclSize: getEligibityData.tumor_burden_crcl_value,
        patientAlive: getEligibityData.patient_alive,
        deathDate: getEligibityData.patient_death_date,
        firsttreatedDate: getEligibityData.patient_first_treated_date,
        lastSeenDate: getEligibityData.patient_last_seen_date,
      };
      setIsEligible(eligibilityCriteriaModel.metInclusionCriteria);

      if (pid > 0) {
        const response =
          await EligibilityService.SaveEligibilityData(
            eligibilityCriteriaModel
          );
        ToggleLoader(false);
        if (response.status !== HTTPResponse.OK) {
          if (response?.status === HTTPResponse.Unauthorized) {
            HandleSessionTimeout();
          } else throw response.error;
        } else {
          ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
          await LoadData();
          setRangeInvalidLNErrorMsg("");
          setRangeInvalidALCErrorMsg("");
          setRangeInvalidCRCLErrorMsg("");

          if (eligibilityCriteriaModel.metInclusionCriteria)
            navigatePaths.nextPage = "/Demographics";
        }
      }
      return true;
    } catch (error) {
      ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed);
    }
  };

  const InitialHardErrList = {
    q3Empty: false,
    q3ODtEmpty: false,
    q3VDtEmpty: false,
    q4Empty: false,
    q4OthTextEmpty: false,
    q4TEmpty: false,
    q4IDtEmpty: false,
    q4SDtEmpty: false,
    q5Empty: false,
    q6Empty: false,
    q7Empty: false,
    q7DtEmpty: false,
    q8Empty: false,
    q9Empty: false,
    q9AllSDtEmpty: false,
    q9AllEDtEmpty: false,
    q9AHSDtEmpty: false,
    q9AHEDtEmpty: false,
    q10Empty: false,
    q3Terminate: false,
    q4Terminate: false,
    q5Terminate: false,
    q6Terminate: false,
    q7Terminate: false,
    q8Terminate: false,
    q9Terminate: false,
    q10Terminate: false,
    q10TerminateUnk: false,
    q3IndxLTMidMay: false,
    q4SSDtGT30: false,

    //ST: 10/10/2024: PreScreening Hard Erorr States
    tumor_burdenEmpty: false,
    tumor_burden_other_textEmpty: false,
    tumor_burden_alc_invalid: false,
    tumor_burden_terminate: false,
    terminateHard: false,
    tumor_burden_crcl_invalid: false,

    //ST: 15/10/2024: Invalid Date Check for Date Fields
    //Q3.
    yesObiInitInvalid: false,
    yesVenetoInitInvalid: false,
    noObiInitInvalid: false,
    noVenetoInitInvalid: false,

    //Q4.
    initDateInvalid: false,
    stopDateInvalid: false,

    //Q7.
    venetoRampUpInvalid: false,

    //Q9.
    startAlloInvalid: false,
    endAlloInvalid: false,
    startHyperInvalid: false,
    endHyperInvalid: false,

  };
  const [hardErrList, setHardErrList] = useState(InitialHardErrList);


  const DatesSoftErrList = {
    q3IndxLTDiag: false,
    q3VenLTIndx: false,
    q3IndxLTVenMTT: false,

    q7VSEDtLT35: false,

    q9SDtLTEDtAllo: false,
    q9SDtGTVSDtAllo: false,
    q9SDtLTVEDtAllo: false,
    q9SDtLTDobAllo: false,

    q9SDtLTEDtAnti: false,
    q9SDtGTVSDtAnti: false,
    q9SDtLTVEDtAnti: false,
    q9SDtLTDobAnti: false,
  };
  const [softErrList, setSoftErrList] = useState(DatesSoftErrList);

  const handleLabelClick = () => { window.open('/Appendix', '_blank', 'noopener,noreferrer'); };

  const HandleDateChange = (name, date) => {
    if (getEligibityData.metInclusionCriteria != null && !getEligibityData.metInclusionCriteria)
      return;

    const parsedDate = ParseDate(date);
    const diagnosedDate = getEligibityData.cll_dignosed_date != null ? ParseDate(getEligibityData.cll_dignosed_date) : getEligibityData.sll_dignosed_date != null ? ParseDate(getEligibityData.sll_dignosed_date) : (getEligibityData.cll_year != null) ? ParseDate("01/01/" + getEligibityData.cll_year) : (getEligibityData.sll_year != null) ? ParseDate("01/01/" + getEligibityData.sll_year) : null;

    const indexDate_yes = getEligibityData.obinutuzumab_date_yes != null ? ParseDate(getEligibityData.obinutuzumab_date_yes) : null;
    const venetoclaxStartDate_yes = getEligibityData.venetoclax_date_yes != null ? ParseDate(getEligibityData.venetoclax_date_yes) : null;
    const indexDate_no = getEligibityData.obinutuzumab_date_no != null ? ParseDate(getEligibityData.obinutuzumab_date_no) : null;
    const venetoclaxStartDate_no = getEligibityData.venetoclax_date_no != null ? ParseDate(getEligibityData.venetoclax_date_no) : null;


    const venetoclaxEndDate = getEligibityData.ven_rampup_date != null ? ParseDate(getEligibityData.ven_rampup_date) : null;
    const dob = getEligibityData.dob_date != null ? ParseDate(getEligibityData.dob_date) : null;

    let q3IndxLTMidMayError = false;
    const midmay2019 = ParseDate("05/15/2019");
    switch (name) {
      case `obinutuzumab_date_yes`: {
        setGetEligibityData((prev) => ({
          ...prev,
          [name]: date, //value,
          initiatevo_therapy: 1,
          obinutuzumab_date_no: null,
          venetoclax_date_no: null,
        }));

        if (diagnosedDate != null) {
          const indLTDiag = IsDateBefore(parsedDate, diagnosedDate);
          if (indLTDiag) {
            let diagDtMessage = EligibilityCriteria_Msgs.q3IndxLTDiagMsg.replace('[Diagnosis Date]', diagnosedDate);
            setQ3ErrorMsg(diagDtMessage);
            setSoftErrList((prev) => ({
              ...prev,
              q3IndxLTDiag: true,
            }));
          }
          else {
            setSoftErrList((prev) => ({
              ...prev,
              q3IndxLTDiag: false,
            }));
          }
        }

        if (venetoclaxStartDate_yes != null) {
          const venLTInd = IsDateBefore(venetoclaxStartDate_yes, parsedDate);
          if (venLTInd) {
            setSoftErrList((prev) => ({
              ...prev,
              q3VenLTIndx: true,
            }));
          }
          else {
            setSoftErrList((prev) => ({
              ...prev,
              q3VenLTIndx: false,
            }));
          }

          const indLTvenM22 = IsDateBeforeMinus22Days(parsedDate, venetoclaxStartDate_yes);
          if (indLTvenM22) {
            setSoftErrList((prev) => ({
              ...prev,
              q3IndxLTVenMTT: true,
            }));
          }
          else {
            setSoftErrList((prev) => ({
              ...prev,
              q3IndxLTVenMTT: false,
            }));
          }
        }
        else {
          setSoftErrList((prev) => ({
            ...prev,
            q3VenLTIndx: false,
            q3IndxLTVenMTT: false,
          }));
        }
        if (getEligibityData.initiatevo_therapy === 1 && getEligibityData.obinutuzumab_date_yes != null) {
          const indLTMidMay = IsDateBefore(date, midmay2019);
          if (indLTMidMay)
            q3IndxLTMidMayError = true;
          else
            q3IndxLTMidMayError = false;

        }
        setHardErrList((prev) => ({
          ...prev,
          q3IndxLTMidMay: q3IndxLTMidMayError,
          q3Empty: false,
          q3ODtEmpty: false,
          // q3VDtEmpty: false,
          q3Terminate: false,
          yesObiInitInvalid: false,
          noObiInitInvalid: false,
          noVenetoInitInvalid: false,
        }));
      }
        break;
      case `venetoclax_date_yes`: {
        setGetEligibityData((prev) => ({
          ...prev,
          [name]: date, //value,
          initiatevo_therapy: 1,
          obinutuzumab_date_no: null,
          venetoclax_date_no: null,
        }));

        if (indexDate_yes != null) {
          const venLTInd = IsDateBefore(parsedDate, indexDate_yes);
          if (venLTInd) {
            setSoftErrList((prev) => ({
              ...prev,
              q3VenLTIndx: true,
            }));
          }
          else {
            setSoftErrList((prev) => ({
              ...prev,
              q3VenLTIndx: false,
            }));
          }

          const indLTvenM22 = IsDateBeforeMinus22Days(indexDate_yes, parsedDate);
          if (indLTvenM22) {
            setSoftErrList((prev) => ({
              ...prev,
              q3IndxLTVenMTT: true,
            }));
          }
          else {
            setSoftErrList((prev) => ({
              ...prev,
              q3IndxLTVenMTT: false,
            }));
          }
        }
        else {
          setSoftErrList((prev) => ({
            ...prev,
            q3IndxLTVenMTT: false,
            q3VenLTIndx: false,
          }));
        }

        setHardErrList((prev) => ({
          ...prev,
          //q3IndxLTMidMay: false,
          q3Empty: false,
          // q3ODtEmpty: false,
          q3VDtEmpty: false,
          q3Terminate: false,
          yesVenetoInitInvalid: false,
          noObiInitInvalid: false,
          noVenetoInitInvalid: false,
        }));

      }
        break;
      case `obinutuzumab_date_no`: {
        setGetEligibityData((prev) => ({
          ...prev,
          [name]: date, //value,
          initiatevo_therapy: 2,
          obinutuzumab_date_yes: null,
          venetoclax_date_yes: null,
        }));

        if (diagnosedDate != null) {
          const indLTDiag = IsDateBefore(parsedDate, diagnosedDate);
          if (indLTDiag) {
            let diagDtMessage = EligibilityCriteria_Msgs.q3IndxLTDiagMsg.replace('[Diagnosis Date]', diagnosedDate);
            setQ3ErrorMsg(diagDtMessage);
            setSoftErrList((prev) => ({
              ...prev,
              q3IndxLTDiag: true,
            }));
          }
          else {
            setSoftErrList((prev) => ({
              ...prev,
              q3IndxLTDiag: false,
            }));
          }
        }
        if (venetoclaxStartDate_no != null) {
          const venLTInd = IsDateBefore(venetoclaxStartDate_no, parsedDate);
          if (venLTInd) {
            setSoftErrList((prev) => ({
              ...prev,
              q3VenLTIndx: true,
            }));
          }
          else {
            setSoftErrList((prev) => ({
              ...prev,
              q3VenLTIndx: false,
            }));
          }

          const indLTvenM22 = IsDateBeforeMinus22Days(parsedDate, venetoclaxStartDate_no);

          if (indLTvenM22) {

            setSoftErrList((prev) => ({
              ...prev,
              q3IndxLTVenMTT: true,
            }));
          }
          else {

            setSoftErrList((prev) => ({
              ...prev,
              q3IndxLTVenMTT: false,
            }));
          }
        }
        else {

          setSoftErrList((prev) => ({
            ...prev,
            q3VenLTIndx: false,
            q3IndxLTVenMTT: false,
          }));
        }
        if (getEligibityData.initiatevo_therapy === Values.value2 && getEligibityData.obinutuzumab_date_no != null) {
          const indLTMidMay = IsDateBefore(date, midmay2019);
          if (indLTMidMay)
            q3IndxLTMidMayError = true;
          else
            q3IndxLTMidMayError = false;
        }
        setHardErrList((prev) => ({
          ...prev,
          q3IndxLTMidMay: q3IndxLTMidMayError,
          q3Empty: false,
          q3ODtEmpty: false,
          // q3VDtEmpty: false,
          q3Terminate: false,
          noObiInitInvalid: false,
          yesObiInitInvalid: false,
          yesVenetoInitInvalid: false,
        }));
      }
        break;
      case `venetoclax_date_no`: {
        setGetEligibityData((prev) => ({
          ...prev,
          [name]: date, //value,
          initiatevo_therapy: 2,
          obinutuzumab_date_yes: null,
          venetoclax_date_yes: null,
        }));


        if (indexDate_no != null) {
          const venLTInd = IsDateBefore(parsedDate, indexDate_no);
          if (venLTInd) {
            setSoftErrList((prev) => ({
              ...prev,
              q3VenLTIndx: true,
            }));
          }
          else {
            setSoftErrList((prev) => ({
              ...prev,
              q3VenLTIndx: false,
            }));
          }


          const indLTvenM22 = IsDateBeforeMinus22Days(indexDate_no, parsedDate);
          if (indLTvenM22) {

            setSoftErrList((prev) => ({
              ...prev,
              q3IndxLTVenMTT: true,
            }));
          }
          else {

            setSoftErrList((prev) => ({
              ...prev,
              q3IndxLTVenMTT: false,
            }));
          }
        } else {
          setSoftErrList((prev) => ({
            ...prev,
            q3IndxLTVenMTT: false,
            q3VenLTIndx: false,
          }));
        }

        setHardErrList((prev) => ({
          ...prev,
          //q3IndxLTMidMay: false,
          q3Empty: false,
          // q3ODtEmpty: false,
          q3VDtEmpty: false,
          q3Terminate: false,
          noVenetoInitInvalid: false,
          yesObiInitInvalid: false,
          yesVenetoInitInvalid: false,

        }));
      }
        break;
      case `initiation_date`: {
        setGetEligibityData((prev) => ({
          ...prev,
          [name]: date, //value,
          received_agent: 1
        }));
        let q4SSDtGT30 = false;
        if (date != null && getEligibityData.stop_date != null) {
          const diffdays = GetDifferenceInDays(getEligibityData.stop_date, date);
          if (diffdays > 30) {
            q4SSDtGT30 = true;
          }
        }
        setHardErrList((prev) => ({
          ...prev,
          q4SSDtGT30,
          q4Empty: false,
          // q4TEmpty: false,
          q4IDtEmpty: false,
          // q4SDtEmpty: false,
          q4Terminate: false,
          // q4OthTextEmpty: false,
          initDateInvalid: false,
        }));
      }
        break;
      case `stop_date`: {
        setGetEligibityData((prev) => ({
          ...prev,
          [name]: date, //value,
          received_agent: 1
        }));

        let q4SSDtGT30 = false;
        if (getEligibityData.initiation_date != null && date != null) {
          const diffdays = GetDifferenceInDays(date, getEligibityData.initiation_date);
          if (diffdays > 30) {
            q4SSDtGT30 = true;
          }
        }
        setHardErrList((prev) => ({
          ...prev,
          q4SSDtGT30,
          q4Empty: false,
          q4TEmpty: false,
          // q4IDtEmpty: false,
          q4SDtEmpty: false,
          q4Terminate: false,
          // q4OthTextEmpty: false,
          stopDateInvalid: false,
        }));
      }
        break;
      case `ven_rampup_date`: {
        setGetEligibityData((prev) => ({
          ...prev,
          [name]: date, //value,
          ven_rampup_checked: 1,
          ven_rampup_unknown: null,
        }));

        if (parsedDate != null && (getEligibityData.venetoclax_date_yes != null || getEligibityData.venetoclax_date_no != null)) {
          const venDate = getEligibityData.venetoclax_date_yes != null ? getEligibityData.venetoclax_date_yes : getEligibityData.venetoclax_date_no;
          const diffdays = GetDifferenceInDays(parsedDate, venDate);
          if (diffdays < 35) {
            let rampupMessage = EligibilityCriteria_Msgs.q7VSEDtLT35Msg.replace('[Ramp-up Period]', venDate + " - " + parsedDate);
            setQ7ErrorMsg(rampupMessage);
            setSoftErrList((prev) => ({
              ...prev,
              q7VSEDtLT35: true,
            }));
          }
          else {
            setSoftErrList((prev) => ({
              ...prev,
              q7VSEDtLT35: false,
            }));
          }
        }
        setHardErrList((prev) => ({
          ...prev,
          q7Empty: false,
          q7DtEmpty: false,
          q7Terminate: false,
          venetoRampUpInvalid: false,
        }));
      }
        break;
      case `allopurinol_startdate`: {
        setGetEligibityData((prev) => ({
          ...prev,
          [name]: date, //value,
          via_allopurinal: true,
          allopurinol_startdate_checked: 1,
          allopurinol_startdate_unk: null,
          no_antihyperuricemic: null,
          antihyperuricemic_unk: null,
        }));

        if (dob != null) {
          const sDtLTDob = IsDateBefore(parsedDate, dob);
          if (sDtLTDob) {
            let dobMessage = EligibilityCriteria_Msgs.q9SDtLTDobMsg.replace('[Date of BirthDiagnosis Date]', dob);
            dobMessage = dobMessage.replace('[Medication received]', 'allopurinol');
            setDOBErrorMsgAllo(dobMessage);
            setSoftErrList((prev) => ({
              ...prev,
              q9SDtLTDobAllo: true,
            }));
          }
          else {
            setSoftErrList((prev) => ({
              ...prev,
              q9SDtLTDobAllo: false,
            }));
          }
        }

        const venetoclaxStartDate = getEligibityData.venetoclax_date_yes != null ? ParseDate(getEligibityData.venetoclax_date_yes) : getEligibityData.venetoclax_date_no != null ? ParseDate(getEligibityData.venetoclax_date_no) : null;

        if (venetoclaxStartDate != null) {
          const sDtGTven = IsDateAfter(parsedDate, venetoclaxStartDate);
          if (sDtGTven) {
            let q9Message = EligibilityCriteria_Msgs.q9SDtGTVSDtMsg.replace('[Venetoclax Start Date]', venetoclaxStartDate);
            q9Message = q9Message.replace('[Medication received]', 'allopurinol');
            setQ9StrDtErrorMsgAllo(q9Message);
            setSoftErrList((prev) => ({
              ...prev,
              q9SDtGTVSDtAllo: true,
            }));
          }
          else {
            setSoftErrList((prev) => ({
              ...prev,
              q9SDtGTVSDtAllo: false,
            }));
          }
        }

        if (parsedDate != null && getEligibityData.allopurinol_enddate != null) {
          const sDtLTEDt = IsDateBefore(getEligibityData.allopurinol_enddate, parsedDate);
          if (sDtLTEDt) {
            let q9Message = EligibilityCriteria_Msgs.q9SDtLTEDtMsg.replace('[Medication received]', 'allopurinol');
            q9Message = q9Message.replace('[Medication received]', 'allopurinol');
            setQ9StrDtLtEDtErrorMsgAllo(q9Message);
            setSoftErrList((prev) => ({
              ...prev,
              q9SDtLTEDtAllo: true,
            }));
          }
          else {
            setSoftErrList((prev) => ({
              ...prev,
              q9SDtLTEDtAllo: false,
            }));
          }
        }
        setHardErrList((prev) => ({
          ...prev,
          q9Empty: false,
          q9AllSDtEmpty: false,
          // q9AllEDtEmpty: false,
          q9Terminate: false,
          startAlloInvalid: false,
          startHyperInvalid: false,
          endHyperInvalid: false,
        }));
      }
        break;
      case `allopurinol_enddate`: {
        setGetEligibityData((prev) => ({
          ...prev,
          [name]: date, //value,
          via_allopurinal: true,
          allopurinol_enddate_checked: 1,
          allopurinol_enddate_unk: null,
          no_antihyperuricemic: null,
          antihyperuricemic_unk: null,
        }));

        if (venetoclaxEndDate != null) {
          let q9MedMessage = EligibilityCriteria_Msgs.q9SDtLTVEDtMsg.replace('[Medication received]', 'allopurinol');
          q9MedMessage = q9MedMessage.replace('[Venetoclax End Date]', venetoclaxEndDate);
          setQ9MedicationErrorMsgAllo(q9MedMessage);
          const eDtLTVEDt = IsDateBefore(parsedDate, venetoclaxEndDate);
          if (eDtLTVEDt) {
            setSoftErrList((prev) => ({
              ...prev,
              q9SDtLTVEDtAllo: true,
            }));
          }
          else {
            setSoftErrList((prev) => ({
              ...prev,
              q9SDtLTVEDtAllo: false,
            }));
          }
        }

        if (getEligibityData.allopurinol_startdate != null && parsedDate != null) {
          const sDtLTEDt = IsDateBefore(parsedDate, getEligibityData.allopurinol_startdate);
          if (sDtLTEDt) {
            let q9Message = EligibilityCriteria_Msgs.q9SDtLTEDtMsg.replace('[Medication received]', 'allopurinol');
            q9Message = q9Message.replace('[Medication received]', 'allopurinol');
            setQ9StrDtLtEDtErrorMsgAllo(q9Message);
            setSoftErrList((prev) => ({
              ...prev,
              q9SDtLTEDtAllo: true,
            }));
          }
          else {
            setSoftErrList((prev) => ({
              ...prev,
              q9SDtLTEDtAllo: false,
            }));
          }
        }
        setHardErrList((prev) => ({
          ...prev,
          q9Empty: false,
          // q9AllSDtEmpty: false,
          q9AllEDtEmpty: false,
          q9Terminate: false,
          endAlloInvalid: false,
          startHyperInvalid: false,
          endHyperInvalid: false,
        }));
      }
        break;
      case `antihyperuricemics_startdate`: {
        setGetEligibityData((prev) => ({
          ...prev,
          [name]: date, //value,
          via_antihyperuricemics: true,
          antihyperuricemics_startdate_checked: 1,
          antihyperuricemics_startdate_unk: null,
          no_antihyperuricemic: null,
          antihyperuricemic_unk: null,
        }));

        if (dob != null) {
          const sDtLTDob = IsDateBefore(parsedDate, dob);
          if (sDtLTDob) {
            let dobMessage = EligibilityCriteria_Msgs.q9SDtLTDobMsg.replace('[Date of BirthDiagnosis Date]', dob);
            dobMessage = dobMessage.replace('[Medication received]', 'antihyperuricemics');
            setDOBErrorMsgAnti(dobMessage);
            setSoftErrList((prev) => ({
              ...prev,
              q9SDtLTDobAnti: true,
            }));
          }
          else {
            setSoftErrList((prev) => ({
              ...prev,
              q9SDtLTDobAnti: false,
            }));
          }
        }

        const venetoclaxStartDate = getEligibityData.venetoclax_date_yes != null ? ParseDate(getEligibityData.venetoclax_date_yes) : getEligibityData.venetoclax_date_no != null ? ParseDate(getEligibityData.venetoclax_date_no) : null;

        if (venetoclaxStartDate != null) {
          const sDtGTven = IsDateAfter(parsedDate, venetoclaxStartDate);
          if (sDtGTven) {
            let q9Message = EligibilityCriteria_Msgs.q9SDtGTVSDtMsg.replace('[Venetoclax Start Date]', venetoclaxStartDate);
            q9Message = q9Message.replace('[Medication received]', 'antihyperuricemics');
            setQ9StrDtErrorMsgAnti(q9Message);
            setSoftErrList((prev) => ({
              ...prev,
              q9SDtGTVSDtAnti: true,
            }));
          }
          else {
            setSoftErrList((prev) => ({
              ...prev,
              q9SDtGTVSDtAnti: false,
            }));
          }
        }

        if (parsedDate != null && getEligibityData.antihyperuricemics_enddate != null) {
          const sDtLTEDt = IsDateBefore(getEligibityData.antihyperuricemics_enddate, parsedDate);
          if (sDtLTEDt) {
            let q9Message = EligibilityCriteria_Msgs.q9SDtLTEDtMsg.replace('[Medication received]', 'antihyperuricemics');
            q9Message = q9Message.replace('[Medication received]', 'antihyperuricemics');
            setQ9StrDtLtEDtErrorMsgAnti(q9Message);
            setSoftErrList((prev) => ({
              ...prev,
              q9SDtLTEDtAnti: true,
            }));
          }
          else {
            setSoftErrList((prev) => ({
              ...prev,
              q9SDtLTEDtAnti: false,
            }));
          }
        }
        setHardErrList((prev) => ({
          ...prev,
          q9Empty: false,
          q9AHSDtEmpty: false,
          // q9AHEDtEmpty: false,
          q9Terminate: false,
          startHyperInvalid: false,
          startAlloInvalid: false,
          endAlloInvalid: false,
        }));
      }
        break;
      case `antihyperuricemics_enddate`: {
        setGetEligibityData((prev) => ({
          ...prev,
          [name]: date, //value,
          via_antihyperuricemics: true,
          antihyperuricemics_enddate_checked: 1,
          antihyperuricemics_enddate_unk: null,
          no_antihyperuricemic: null,
          antihyperuricemic_unk: null,
        }));

        if (getEligibityData.antihyperuricemics_startdate != null && parsedDate != null) {
          const sDtLTEDt = IsDateBefore(parsedDate, getEligibityData.antihyperuricemics_startdate);
          if (sDtLTEDt) {
            let q9Message = EligibilityCriteria_Msgs.q9SDtLTEDtMsg.replace('[Medication received]', 'antihyperuricemics');
            q9Message = q9Message.replace('[Medication received]', 'antihyperuricemics');
            setQ9StrDtLtEDtErrorMsgAnti(q9Message);
            setSoftErrList((prev) => ({
              ...prev,
              q9SDtLTEDtAnti: true,
            }));
          }
          else {
            setSoftErrList((prev) => ({
              ...prev,
              q9SDtLTEDtAnti: false,
            }));
          }
        }

        if (venetoclaxEndDate != null) {
          let q9MedMessage = EligibilityCriteria_Msgs.q9SDtLTVEDtMsg.replace('[Medication received]', 'antihyperuricemics');
          q9MedMessage = q9MedMessage.replace('[Venetoclax End Date]', venetoclaxEndDate);
          setQ9MedicationErrorMsgAnti(q9MedMessage);
          const eDtLTVEDt = IsDateBefore(parsedDate, venetoclaxEndDate);
          if (eDtLTVEDt) {
            setSoftErrList((prev) => ({
              ...prev,
              q9SDtLTVEDtAnti: true,
            }));
          }
          else {
            setSoftErrList((prev) => ({
              ...prev,
              q9SDtLTVEDtAnti: false,
            }));
          }
        }
        setHardErrList((prev) => ({
          ...prev,
          q9Empty: false,
          // q9AHSDtEmpty: false,
          q9AHEDtEmpty: false,
          q9Terminate: false,
          endHyperInvalid: false,
          startAlloInvalid: false,
          endAlloInvalid: false,
        }));
      }
        break;
      default:
        break;
    }
  };

  var wholeNum = /^\d*$/; // only whole number allowed to enter
  var decimalUpto1Place = /^\d*\.?\d{0,1}$/; // allows only 1 place after decimal
  // var wholeNumUpto2 =   /^\d{0,2}(\.\d{0,1})?$/; //Whole numbers from 0 to 99.
  // Numbers with up to one decimal place, where the whole number part has at most two digits.
  // Optionally, allows a decimal point and one digit after the decimal.

  var wholeNumUpto9999 = /^(?:\d{1,4})(?:\.\d{0,1})?$/;

  var wholeNum3 = /^\d{0,4}$/; //whole num maxlength 4, so max value is 9999 which can be entered

  const HandleFieldChange = (e) => {
    if (getEligibityData.metInclusionCriteria != null && !getEligibityData.metInclusionCriteria)
      return;

    const { name, id, value } = e.target;

    // Helper function to validate all three text fields and update error state
    const validateAllFields = (lnValue, alcValue, crclValue, name) => {
      // Units mapping
      const units = {
        ln: "cm",
        alc: "x 10^9/L",
        crcl: "mL/min",
      };

      // Validation logic for each field
      const lnInvalid = lnValue && (parseFloat(lnValue) > 15) && lnValue !== "";
      const alcInvalid = alcValue && (parseFloat(alcValue) > 50) && alcValue !== "";
      const crclInvalid = crclValue && (parseFloat(crclValue) > 120) && crclValue !== "";




      //ST: 08/11/2024: https://ideawharf.sifterapp.com/issues/12746 for Q11.
 const lnInvalidTerminate    =  ((parseFloat(lnValue) >= 5 &&
                                      parseFloat(lnValue) < 10) ||
                                      parseFloat(lnValue) >= 10);
      const alcInvalidTerminate   =   parseFloat(alcValue) >= 25;
      const crclInvalidTerminate  =   parseFloat(crclValue) < 80;

      const condition1 = ((parseFloat(lnValue) >= 5 && parseFloat(lnValue) < 10) || (parseFloat(alcValue) >= 25)) && (parseFloat(crclValue) < 80);
      const condition2 = parseFloat(lnValue) >= 10;
      const condition3 = (parseFloat(alcValue) >= 25) && (parseFloat(lnValue) >= 5);



      // // Initialize the error tracking
      // let errorValue = "";
      // let errorField = "";
      let errorValueLN = "";
      let errorFieldLN = "";
      let errorValueALC = "";
      let errorFieldALC = "";
      let errorValueCRCL = "";
      let errorFieldCRCL = "";

      // Check each field for validity and set the latest invalid field
      if (crclInvalid) {
        errorValueCRCL = crclValue;
        errorFieldCRCL = "crcl";
      }
      if (alcInvalid) {
        errorValueALC = alcValue; // Overwrite with alc value if invalid
        errorFieldALC = "alc";
      }
      if (lnInvalid) {
        errorValueLN = lnValue; // Overwrite with ln value if invalid
        errorFieldLN = "ln";
      }

      // Update the error state
      setHardErrList(prev => ({
        ...prev,
        tumor_burdenEmpty: false,
        tumor_burden_other_textEmpty: false,
        // terminate: !!errorValue, // true if there's an invalid value
        terminateHard: (condition1 || condition2 || condition3),
        tumor_burden_crcl_invalid: false,
      }));


      if (errorValueLN) {
        setRangeInvalidLNErrorMsg(
          `${EligibilityCriteria_Msgs.rangeInvalidErrorMsgStart} ${errorValueLN} ${units[errorFieldLN]}. ${EligibilityCriteria_Msgs.rangeInvalidErrorMsgEnd}`
        );
      } else {
        setRangeInvalidLNErrorMsg("");
      }


      if (errorValueALC) {
        setRangeInvalidALCErrorMsg(
          <div>
            {`${EligibilityCriteria_Msgs.rangeInvalidErrorMsgStart} ${errorValueALC} x 10`}<sup>9</sup>{`/L. ${EligibilityCriteria_Msgs.rangeInvalidErrorMsgEnd}`}
          </div>

        );
      } else {
        setRangeInvalidALCErrorMsg(""); // Clear the error message if all values are valid
      }


      if (errorValueCRCL) {
        setRangeInvalidCRCLErrorMsg(
          `${EligibilityCriteria_Msgs.rangeInvalidErrorMsgStart} ${errorValueCRCL} ${units[errorFieldCRCL]}. ${EligibilityCriteria_Msgs.rangeInvalidErrorMsgEnd}`
        );
      } else {
        setRangeInvalidCRCLErrorMsg(""); // Clear the error message if all values are valid
      }
    };





    switch (name) {
      case "initiatevo_therapy": {
        const value_int = parseInt(value, 10);
        setGetEligibityData((prev) => ({
          ...prev,
          [name]: parseInt(value, 10), //value,
          obinutuzumab_date_yes: null,
          venetoclax_date_yes: null,
          obinutuzumab_date_no: null,
          venetoclax_date_no: null,
        }));
        setHardErrList((prev) => ({
          ...prev,
          q3Empty: false,
          q3ODtEmpty: false,
          q3VDtEmpty: false,
          q3IndxLTMidMay: false,
          q3Terminate: value_int === Values.value3,
          //ST: Invalid Date Error States
          yesObiInitInvalid: false,
          yesVenetoInitInvalid: false,
          noObiInitInvalid: false,
          noVenetoInitInvalid: false,
        }));

        setSoftErrList((prev) => ({
          ...prev,
          q3VenLTIndx: false,
          q3IndxLTDiag: false,
          q3IndxLTVenMTT: false,
        }));

        //ST: 09/10/2024: added below code to clear Q4. data and error msgs on yes and unk options for Q3.
        if (value_int == Values.value2 || value_int == Values.value99) {
          setGetEligibityData((prev) => ({
            ...prev,
            received_agent: null,
            therapy_acalabrutinib: null,
            therapy_btkinhibitor: null,
            therapy_cerdulatinib: null,
            therapy_cudc13045: null,
            therapy_duvelisib: null,
            therapy_entospletinib: null,
            therapy_fenebrutinib: null,
            therapy_ibrutinib: null,
            therapy_idelalisib: null,
            therapy_incb040093: null,
            therapy_obatoclax: null,
            therapy_pirtobrutinib: null,
            therapy_rigosertib: null,
            therapy_spebrutinib: null,
            therapy_umbralisib: null,
            therapy_unspecified: null,
            therapy_vecabrutinib: null,
            therapy_venetoclax: null,
            therapy_zanubrutinib: null,
            therapy_oth: null,
            therapy_oth_txt: null,
            initiation_date: null,
            stop_date: null,
          }));

          setHardErrList((prev) => ({
            ...prev,
            q4TEmpty: false,
            q4IDtEmpty: false,
            q4SDtEmpty: false,
            q4SSDtGT30: false,
            q4Terminate: false,
            q4OthTextEmpty: false,

          }));

        }

        break;
      }
      case "received_agent": {
        setGetEligibityData((prev) => ({
          ...prev,
          [name]: parseInt(value, 10), //value,
          therapy_acalabrutinib: false,
          therapy_btkinhibitor: false,
          therapy_cerdulatinib: false,
          therapy_cudc13045: false,
          therapy_duvelisib: false,
          therapy_entospletinib: false,
          therapy_fenebrutinib: false,
          therapy_ibrutinib: false,
          therapy_idelalisib: false,
          therapy_incb040093: false,
          therapy_obatoclax: false,
          therapy_pirtobrutinib: false,
          therapy_rigosertib: false,
          therapy_spebrutinib: false,
          therapy_umbralisib: false,
          therapy_unspecified: false,
          therapy_vecabrutinib: false,
          therapy_venetoclax: false,
          therapy_zanubrutinib: false,
          therapy_oth: false,
          therapy_oth_txt: "",
          initiation_date: null,
          stop_date: null,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q4Empty: false,
          q4TEmpty: false,
          q4IDtEmpty: false,
          q4SDtEmpty: false,
          q4SSDtGT30: false,
          q4Terminate: Number(value) === Values.value2 || Number(value) === Values.value99,
          q4OthTextEmpty: false,
          initDateInvalid: false,
          stopDateInvalid: false,
        }));

        break;
      }
      case "therapy_acalabrutinib":
      case "therapy_btkinhibitor":
      case "therapy_cerdulatinib":
      case "therapy_cudc13045":
      case "therapy_duvelisib":
      case "therapy_entospletinib":
      case "therapy_fenebrutinib":
      case "therapy_ibrutinib":
      case "therapy_idelalisib":
      case "therapy_incb040093":
      case "therapy_obatoclax":
      case "therapy_pirtobrutinib":
      case "therapy_rigosertib":
      case "therapy_spebrutinib":
      case "therapy_umbralisib":
      case "therapy_unspecified":
      case "therapy_vecabrutinib":
      case "therapy_venetoclax":
      case "therapy_zanubrutinib":
      case "therapy_oth": {
        setGetEligibityData((prev) => ({
          ...prev,
          [name]: !prev[name],
          received_agent: 1,
        }));

        if (getEligibityData.therapy_oth == null || getEligibityData.therapy_oth == true) {
          setGetEligibityData((prev) => ({
            ...prev,
            therapy_oth_txt: "",
          }));
        }

        //ST: 09/10/2024: added below code to clear hard error msgs
        setHardErrList((prev) => ({
          ...prev,
          q4Empty: false,
          q4TEmpty: false,
          q4Terminate: false,
          // q4IDtEmpty: false,
          // q4SDtEmpty: false,
          q4OthTextEmpty: false,
          // initDateInvalid: false,
          // stopDateInvalid: false,
        }));

        break;
      }
      case "therapy_oth_txt": {
        setGetEligibityData((prev) => ({
          ...prev,
          [name]: value,
          therapy_oth: 1,
          received_agent: 1,
        }));

        //ST: 09/10/2024: added below code to clear hard error msgs
        setHardErrList((prev) => ({
          ...prev,
          q4Empty: false,
          q4TEmpty: false,
          // q4IDtEmpty: false,
          // q4SDtEmpty: false,
          q4OthTextEmpty: false,
          // initDateInvalid: false,
          // stopDateInvalid: false,
        }));

        break;
      }
      case "vo_treated": {
        setGetEligibityData((prev) => ({
          ...prev,
          [name]: parseInt(value, 10), //value,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q5Empty: false,
          q5Terminate: Number(value) === Values.value1 || Number(value) === Values.value99
        }));

        break;
      }
      case "received_other_cllagent": {
        setGetEligibityData((prev) => ({
          ...prev,
          [name]: parseInt(value, 10), //value,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q6Empty: false,
          q6Terminate: Number(value) === Values.value1 || Number(value) === Values.value99,
        }));

        break;
      }
      case "ven_rampup_checked":
      case "ven_rampup_unknown": {
        setGetEligibityData((prev) => ({
          ...prev,
          [name]: parseInt(value, 10),
          ven_rampup_checked: parseInt(value, 10) === Values.value1 ? Values.value1 : null,
          ven_rampup_unknown: parseInt(value, 10) === Values.value99 ? Values.value99 : null,
          ven_rampup_date: parseInt(value, 10) === Values.value99 ? null : prev.ven_rampup_date,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q7Empty: false,
          q7DtEmpty: false,
          q7Terminate: Number(value, 10) === Values.value99,
          venetoRampUpInvalid: false
        }));

        break;
      }
      case "rampup_period": {
        setGetEligibityData((prev) => ({
          ...prev,
          [name]: parseInt(value, 10), //value,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q8Empty: false,
          q8Terminate: Number(value, 10) === Values.value99,
        }));

        break;
      }
      case "no_antihyperuricemic":
      case "antihyperuricemic_unk": {
        setGetEligibityData((prev) => ({
          ...prev,
          [name]: parseInt(value, 10), //value,
          no_antihyperuricemic: parseInt(value, 10) === Values.value1 ? Values.value1 : null,
          antihyperuricemic_unk: parseInt(value, 10) === Values.value99 ? Values.value99 : null,
          via_allopurinal: null,
          allopurinol_startdate_checked: null,
          allopurinol_startdate: null,
          allopurinol_startdate_unk: null,
          allopurinol_enddate_checked: null,
          allopurinol_enddate: null,
          allopurinol_enddate_unk: null,
          via_antihyperuricemics: null,
          antihyperuricemics_startdate_checked: null,
          antihyperuricemics_startdate: null,
          antihyperuricemics_startdate_unk: null,
          antihyperuricemics_enddate_checked: null,
          antihyperuricemics_enddate: null,
          antihyperuricemics_enddate_unk: null,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q9Empty: false,
          q9AllSDtEmpty: false,
          q9AllEDtEmpty: false,
          q9AHSDtEmpty: false,
          q9AHEDtEmpty: false,
          q9Terminate: Number(value) === Values.value1 || Number(value) === Values.value99,
          startAlloInvalid: false,
          endAlloInvalid: false,
          startHyperInvalid: false,
          endHyperInvalid: false,
        }));

        setSoftErrList(prev => ({
          ...prev,
          q9SDtLTEDtAllo: false,
          q9SDtGTVSDtAllo: false,
          q9SDtLTVEDtAllo: false,
          q9SDtLTDobAllo: false,
          q9SDtLTEDtAnti: false,
          q9SDtGTVSDtAnti: false,
          q9SDtLTVEDtAnti: false,
          q9SDtLTDobAnti: false,
        }));

        break;
      }
      case "via_allopurinal":
      case "via_antihyperuricemics": {
        setGetEligibityData((prev) => ({
          ...prev,
          [name]: !prev[name],
          no_antihyperuricemic: null,
          antihyperuricemic_unk: null,
          allopurinol_startdate_checked: (name === "via_allopurinal" && prev[name] === true) ? null : prev.allopurinol_startdate_checked,
          allopurinol_startdate: (name === "via_allopurinal" && prev[name] === true) ? null : prev.allopurinol_startdate,
          allopurinol_startdate_unk: (name === "via_allopurinal" && prev[name] === true) ? null : prev.allopurinol_startdate_unk,
          allopurinol_enddate_checked: (name === "via_allopurinal" && prev[name] === true) ? null : prev.allopurinol_enddate_checked,
          allopurinol_enddate: (name === "via_allopurinal" && prev[name] === true) ? null : prev.allopurinol_enddate,
          allopurinol_enddate_unk: (name === "via_allopurinal" && prev[name] === true) ? null : prev.allopurinol_enddate_unk,
          antihyperuricemics_startdate_checked: (name === "via_antihyperuricemics" && prev[name] === true) ? null : prev.antihyperuricemics_startdate_checked,
          antihyperuricemics_startdate: (name === "via_antihyperuricemics" && prev[name] === true) ? null : prev.antihyperuricemics_startdate,
          antihyperuricemics_startdate_unk: (name === "via_antihyperuricemics" && prev[name] === true) ? null : prev.antihyperuricemics_startdate_unk,
          antihyperuricemics_enddate_checked: (name === "via_antihyperuricemics" && prev[name] === true) ? null : prev.antihyperuricemics_enddate_checked,
          antihyperuricemics_enddate: (name === "via_antihyperuricemics" && prev[name] === true) ? null : prev.antihyperuricemics_enddate,
          antihyperuricemics_enddate_unk: (name === "via_antihyperuricemics" && prev[name] === true) ? null : prev.antihyperuricemics_enddate_unk,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q9Empty: false,
          // q9AllSDtEmpty: false,
          // q9AllEDtEmpty: false,
          // q9AHSDtEmpty: false,
          // q9AHEDtEmpty: false,
          q9Terminate: false,
          startAlloInvalid: false,
          endAlloInvalid: false,
          startHyperInvalid: false,
          endHyperInvalid: false,
        }));

        if (name === "via_allopurinal") {
          setHardErrList((prev) => ({
            ...prev,

            q9AllSDtEmpty: false,
            q9AllEDtEmpty: false,

          }));
          setSoftErrList(prev => ({
            ...prev,

            q9SDtLTDobAllo: false,

          }));

        }
        else if (name === "via_antihyperuricemics") {
          setHardErrList((prev) => ({
            ...prev,

            q9AHSDtEmpty: false,
            q9AHEDtEmpty: false,

          }));
          setSoftErrList(prev => ({
            ...prev,

            q9SDtLTDobAnti: false,
          }));

        }

        setSoftErrList(prev => ({
          ...prev,
          q9SDtLTEDtAllo: false,
          q9SDtGTVSDtAllo: false,
          q9SDtLTVEDtAllo: false,
          // q9SDtLTDobAllo: false,
          q9SDtLTEDtAnti: false,
          q9SDtGTVSDtAnti: false,
          q9SDtLTVEDtAnti: false,
          // q9SDtLTDobAnti: false,
        }));

        break;
      }
      case "allopurinol_startdate_checked":
      case "allopurinol_startdate_unk": {
        setGetEligibityData((prev) => ({
          ...prev,
          [name]: parseInt(value, 10),
          via_allopurinal: true,
          no_antihyperuricemic: null,
          antihyperuricemic_unk: null,
          allopurinol_startdate_checked: parseInt(value, 10) === Values.value1 ? Values.value1 : null,
          allopurinol_startdate_unk: parseInt(value, 10) === Values.value99 ? Values.value99 : null,
          allopurinol_startdate: parseInt(value, 10) === Values.value99 ? null : prev.allopurinol_startdate,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q9Empty: false,
          q9AllSDtEmpty: false,
          // q9AllEDtEmpty: false,
          // q9AHSDtEmpty: false,
          // q9AHEDtEmpty: false,
          q9Terminate: false,
          startAlloInvalid: false,

        }));

        setSoftErrList(prev => ({
          ...prev,
          q9SDtLTEDtAllo: false,
          q9SDtGTVSDtAllo: false,
          q9SDtLTVEDtAllo: false,
          q9SDtLTDobAllo: false,
        }));

        break;
      }
      case "allopurinol_enddate_checked":
      case "allopurinol_enddate_unk": {
        setGetEligibityData((prev) => ({
          ...prev,
          [name]: parseInt(value, 10),
          via_allopurinal: true,
          no_antihyperuricemic: null,
          antihyperuricemic_unk: null,
          allopurinol_enddate_checked: parseInt(value, 10) === Values.value1 ? Values.value1 : null,
          allopurinol_enddate_unk: parseInt(value, 10) === Values.value99 ? Values.value99 : null,
          allopurinol_enddate: parseInt(value, 10) === Values.value99 ? null : prev.allopurinol_enddate,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q9Empty: false,
          // q9AllSDtEmpty: false,
          q9AllEDtEmpty: false,
          // q9AHSDtEmpty: false,
          // q9AHEDtEmpty: false,
          q9Terminate: false,
          endAlloInvalid: false,

        }));

        setSoftErrList(prev => ({
          ...prev,
          q9SDtLTEDtAllo: false,
          q9SDtGTVSDtAllo: false,
          q9SDtLTVEDtAllo: false,
          q9SDtLTDobAllo: false,
        }));

        break;
      }
      case "antihyperuricemics_startdate_checked":
      case "antihyperuricemics_startdate_unk": {
        setGetEligibityData((prev) => ({
          ...prev,
          [name]: parseInt(value, 10),
          via_antihyperuricemics: true,
          no_antihyperuricemic: null,
          antihyperuricemic_unk: null,
          antihyperuricemics_startdate_checked: parseInt(value, 10) === Values.value1 ? Values.value1 : null,
          antihyperuricemics_startdate_unk: parseInt(value, 10) === Values.value99 ? Values.value99 : null,
          antihyperuricemics_startdate: parseInt(value, 10) === Values.value99 ? null : prev.antihyperuricemics_startdate,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q9Empty: false,
          // q9AllSDtEmpty: false,
          // q9AllEDtEmpty: false,
          q9AHSDtEmpty: false,
          // q9AHEDtEmpty: false,
          q9Terminate: false,
          startHyperInvalid: false,

        }));

        setSoftErrList(prev => ({
          ...prev,
          q9SDtLTEDtAnti: false,
          q9SDtGTVSDtAnti: false,
          q9SDtLTVEDtAnti: false,
          q9SDtLTDobAnti: false,
        }));

        break;
      }
      case "antihyperuricemics_enddate_checked":
      case "antihyperuricemics_enddate_unk": {
        setGetEligibityData((prev) => ({
          ...prev,
          [name]: parseInt(value, 10),
          via_antihyperuricemics: true,
          no_antihyperuricemic: null,
          antihyperuricemic_unk: null,
          antihyperuricemics_enddate_checked: parseInt(value, 10) === Values.value1 ? Values.value1 : null,
          antihyperuricemics_enddate_unk: parseInt(value, 10) === Values.value99 ? Values.value99 : null,
          antihyperuricemics_enddate: parseInt(value, 10) === Values.value99 ? null : prev.antihyperuricemics_enddate,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q9Empty: false,
          // q9AllSDtEmpty: false,
          // q9AllEDtEmpty: false,
          // q9AHSDtEmpty: false,
          q9AHEDtEmpty: false,
          q9Terminate: false,
          endHyperInvalid: false,

        }));

        setSoftErrList(prev => ({
          ...prev,
          q9SDtLTEDtAnti: false,
          q9SDtGTVSDtAnti: false,
          q9SDtLTVEDtAnti: false,
          q9SDtLTDobAnti: false,
        }));

        break;
      }
      case "medication_clarithromycin":
      case "medication_itraconazole":
      case "medication_ketoconazole":
      case "medication_posaconazole":
      case "medication_ritonavir":
      case "medication_voriconazole":
      case "medication_other":
      case "antihyperuricemic_rasburicase": {
        setGetEligibityData((prev) => ({
          ...prev,
          [name]: parseInt(value, 10), //value,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q10Empty: false,
          q10Terminate: false,
          q10TerminateUnk: false,
        }));
        SetQ10SoftError(name, Number(value));

        break;
      }


      //PreScreening Handle Field Change
      case "tumor_burden_ln_size":
      case "tumor_burden_crcl_value":
      case "tumor_burden_alc_size": {
        const isValueValid =
          name === "tumor_burden_ln_size"
            // ? /^\d*$/.test(value)
            // : /^\d*\.?\d{0,1}$/.test(value);
            ? wholeNum.test(value)
            : decimalUpto1Place.test(value);

        if (isValueValid && value.length <= 5) {
          setGetEligibityData(prev => ({
            ...prev,
            [name]: value,
            tumor_burden: Values.value5,
          }));

          // Get current values from state and validate all fields
          const {
            tumor_burden_ln_size,
            tumor_burden_alc_size,
            tumor_burden_crcl_value,
          } = getEligibityData;
          const lnValue =
            name === "tumor_burden_ln_size" ? value : tumor_burden_ln_size;
          const alcValue =
            name === "tumor_burden_alc_size" ? value : tumor_burden_alc_size;
          const crclValue =
            name === "tumor_burden_crcl_value"
              ? value
              : tumor_burden_crcl_value;

          validateAllFields(lnValue, alcValue, crclValue, name);


        }
        break;
      }

      case "tumor_burden": {
        const valueInt = Number(value);
        const isInvalidOption = false;
        // [Values.value3, Values.value4, Values.value99].includes(valueInt);

        setGetEligibityData(prev => ({
          ...prev,
          [name]: valueInt,
          tumor_burden_ln_size:
            valueInt !== Values.value5 ? "" : prev.tumor_burden_ln_size,
          tumor_burden_alc_size:
            valueInt !== Values.value5 ? "" : prev.tumor_burden_alc_size,
          tumor_burden_crcl_value:
            valueInt !== Values.value5 ? "" : prev.tumor_burden_crcl_value,
        }));

        setHardErrList(prev => ({
          ...prev,
          tumor_burdenEmpty: false,
          tumor_burden_other_textEmpty: false,
          terminate: isInvalidOption,
          terminateHard: (valueInt==Values.value3 || valueInt==Values.value4 || valueInt==Values.value99),
          tumor_burden_crcl_invalid: false,
        }));

        // setTerminateMsg(isInvalidOption ? EligibilityCriteria_Msgs.terminate : "");
        setRangeInvalidLNErrorMsg("");
        setRangeInvalidALCErrorMsg("");
        setRangeInvalidCRCLErrorMsg("");

        break;
      }
      default:
        break;
    }
  };
  const SetQ10SoftError = (name, value) => {
    let { medication_clarithromycin, medication_itraconazole, medication_ketoconazole, medication_posaconazole,
      medication_ritonavir, medication_voriconazole, medication_other, antihyperuricemic_rasburicase,
      venetoclax_date_yes, venetoclax_date_no } = getEligibityData;
    if (name === "medication_clarithromycin") {
      medication_clarithromycin = value;
    }
    else if (name === "medication_itraconazole") {
      medication_itraconazole = value;
    }
    else if (name === "medication_ketoconazole") {
      medication_ketoconazole = value;
    }
    else if (name === "medication_posaconazole") {
      medication_posaconazole = value;
    }
    else if (name === "medication_ritonavir") {
      medication_ritonavir = value;
    }
    else if (name === "medication_voriconazole") {
      medication_voriconazole = value;
    }
    else if (name === "medication_other") {
      medication_other = value;
    }
    else if (name === "antihyperuricemic_rasburicase") {
      antihyperuricemic_rasburicase = value;
    }
    //any YES
    if (medication_clarithromycin === Values.value1 ||
      medication_itraconazole === Values.value1 ||
      medication_ketoconazole === Values.value1 ||
      medication_posaconazole === Values.value1 ||
      medication_ritonavir === Values.value1 ||
      medication_voriconazole === Values.value1 ||
      medication_other === Values.value1 ||
      antihyperuricemic_rasburicase === Values.value1) {
      const venetoclaxStartDate =
        venetoclax_date_yes != null
          ? ParseDate(venetoclax_date_yes)
          : venetoclax_date_no != null
            ? ParseDate(venetoclax_date_no)
            : "[Venetoclax Start Date]";
      let q10Msg = EligibilityCriteria_Msgs.q10TerminateIneligible.replace('[Venetoclax Start Date]', venetoclaxStartDate);
      setQ10ErrorMsg(q10Msg);
      setHardErrList((prev) => ({
        ...prev,
        q10Terminate: true,
      }));
    }
    // ALL unk 
    else if (medication_clarithromycin === Values.value99 &&
      medication_itraconazole === Values.value99 &&
      medication_ketoconazole === Values.value99 &&
      medication_posaconazole === Values.value99 &&
      medication_ritonavir === Values.value99 &&
      medication_voriconazole === Values.value99 &&
      medication_other === Values.value99 &&
      antihyperuricemic_rasburicase === Values.value99) {
      const venetoclaxStartDate =
        getEligibityData.venetoclax_date_yes != null
          ? ParseDate(getEligibityData.venetoclax_date_yes)
          : getEligibityData.venetoclax_date_no != null
            ? ParseDate(getEligibityData.venetoclax_date_no)
            : "[Venetoclax Start Date]";
      let q10UnkMsg = EligibilityCriteria_Msgs.q10TerminateIneligibleUnk.replace('[Venetoclax Start Date]', venetoclaxStartDate);
      setQ10UnkErrorMsg(q10UnkMsg);
      setHardErrList((prev) => ({
        ...prev,
        q10TerminateUnk: true,
      }));
    }
  };
  return (
    <>
      <section className="survey-patient-details-section">
        <div className="row">
          <PatientDetails />
          <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
            <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
          </div>
        </div>
      </section>

      <section className="main-survey-section">
        <div className="row">
          <SideBarMenu activePageId={SideMenuItems.EligibilityCriteria} />
          <div className="col-md-9 col-pad-left eligibiltylocked">
            <div className="survey-section-content">
              <div className="survey-section-content-header">
                <h4>PATIENT ELIGIBILITY CRITERIA </h4>
              </div>

              {!showTopErrMsg && !showTopErrMsgTerminate && pid != 0 && getEligibityData.metInclusionCriteria != null && (
                (getEligibityData.metInclusionCriteria && !showTopErrMsgTerminate && terminateCount === 0) ?
                  (<div className="survey-section-content-message">
                    <p>
                      {EligibilityCriteria_Msgs.EligiblePatient}
                    </p>
                  </div>)
                  : (getEligibityData.metInclusionCriteria == false) ?
                    (<div className="clean-error">
                      <p>
                        {EligibilityCriteria_Msgs.IneligiblePatient}
                      </p>
                    </div>)
                    : (<></>)
              )
              }

              {/* {showTopErrMsg && terminateCount === 1 && (
                <div className="clean-error ">
                  <ErrorField
                    show={showTopErrMsg}
                    message={EligibilityCriteria_Msgs.INELIGIBLE}
                  />
                </div>
              )} */}

              {showTopErrMsgTerminate && terminateCount === 1 && (
                <div className="clean-error ">
                  <ErrorField
                    show={showTopErrMsgTerminate}
                    message={EligibilityCriteria_Msgs.INELIGIBLE}
                  />
                </div>
              )}

              {showTopErrMsg && !showTopErrMsgTerminate && (
                <div className="clean-error ">
                  <ErrorField
                    show={showTopErrMsg}
                    message={incorrectResponse ? CommonError_Msgs.IncorrectResponse : CommonError_Msgs.Hard_Empty}
                  />
                </div>
              )}
              {/* <summary>
              date: 18-09-2024
              Name: ST
              description: Eligibility Design
              <summary> */}
              <div className="survey-question-section" disabled={getEligibityData.metInclusionCriteria != null && !getEligibityData.metInclusionCriteria}>
                <div className="survey-question-content">
                  <div className="question-answer">
                    {/* Q1. */}
                    <div className="readonly-div">
                      <div className="question question-weight">
                        <span>1.</span>
                        <span className="quest-text-pad">
                          When was CLL/SLL first diagnosed for this patient?{" "}
                          <i>
                            Please note that this response has been
                            pre-populated from the CORE database. If this
                            response is inconsistent with the patient chart,
                            please return to the CORE database to make the
                            correction.
                          </i>
                        </span>
                      </div>
                      <div className="double-dig-answer">
                        <div className="option-without-input date-bottom">
                          <label>
                            <input
                              type="radio"
                              id="first_dignosed_yes"
                              name="first_dignosed"
                              value={1}
                              readOnly
                              checked={getEligibityData.cllslldiagnosis === 1}
                            />

                            <span className="radio-text-padding">
                              CLL was diagnosed on &nbsp;
                              <DateControl
                                ctrlId="cll_dignosed_date"
                                optionId={1}
                                minDate={dayjs("01-01-1920")}
                                maxDate={dayjs()}
                                locale="en" // current it is mm/dd/yyyy but for dd/mm/yyyy use "en-gb"
                                date={getEligibityData.cll_dignosed_date}
                                readOnly
                              />
                            </span>
                          </label>
                        </div>
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="first_dignosed_yes"
                              name="first_dignosed"
                              value={2}
                              readOnly
                              checked={getEligibityData.cllslldiagnosis === Values.value2}
                            />
                            <span className="radio-text-padding">
                              SLL was diagnosed on &nbsp;
                              <DateControl
                                ctrlId="sll_dignosed_date"
                                optionId={1}
                                minDate={dayjs("01-01-1920")}
                                maxDate={dayjs()}
                                locale="en" // current it is mm/dd/yyyy but for dd/mm/yyyy use "en-gb"
                                date={getEligibityData.sll_dignosed_date}
                                readOnly
                              />
                            </span>
                          </label>
                        </div>
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="first_dignosed_only_year_cll"
                              name="first_dignosed"
                              value={3}
                              readOnly
                              checked={getEligibityData.cllslldiagnosis === Values.value3}
                            />
                            <span className="radio-text-padding">
                              CLL diagnosis date is unknown, but the patient was
                              diagnosed in year{" "}
                            </span>
                            <input
                              id="first_dignosed_only_year_text_cll"
                              type="text"
                              className="input-dash input_custome-space_sm"
                              value={getEligibityData.cll_year}
                              placeholder=""
                              maxLength={100}
                              style={{
                                border: "none",
                                borderBottom: "1px solid #000", // Adds a bottom border with a solid line
                                outline: "none", // Removes the default outline on focus
                              }}
                              readOnly
                            />
                          </label>
                        </div>
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="first_dignosed_only_year_sll"
                              name="first_dignosed"
                              value={4}
                              readOnly
                              checked={getEligibityData.cllslldiagnosis === Values.value4}
                            />
                            <span className="radio-text-padding">
                              SLL diagnosis date is unknown, but the patient was
                              diagnosed in year{" "}
                            </span>
                            <input
                              id="first_dignosed_only_year_text_sll"
                              type="text"
                              className="input-dash input_custome-space_sm"
                              value={getEligibityData.sll_year}
                              placeholder=""
                              maxLength={100}
                              style={{
                                border: "none",
                                borderBottom: "1px solid #000", // Adds a bottom border with a solid line
                                outline: "none", // Removes the default outline on focus
                              }}
                              readOnly
                            />
                          </label>
                        </div>

                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="first_dignosed_no"
                              name="first_dignosed"
                              value={5}
                              readOnly
                              checked={getEligibityData.cllslldiagnosis === Values.value5}
                            />
                            <span className="radio-text-padding">
                              Patient was not diagnosed with CLL
                            </span>
                          </label>
                        </div>
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="first_dignosed_unk"
                              name="first_dignosed"
                              value={99}
                              readOnly
                              checked={getEligibityData.cllslldiagnosis === Values.value99}
                            />
                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                      </div>
                    </div>

                    {/* Q2. */}
                    <div className="readonly-div">
                      <div className="question question-weight">
                        <span>2.</span>
                        <span className="quest-text-pad">
                          What is the patient’s date of birth?{" "}
                          <i>
                            Please note that this response has been
                            pre-populated from the CORE database. If this
                            response is inconsistent with the patient chart,
                            please return to the CORE database to make the
                            correction.{" "}
                          </i>
                        </span>
                      </div>
                      <div className="double-dig-answer">
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="dob_known"
                              name="dob"
                              value={1}
                              readOnly
                              checked={getEligibityData.dob_checked === Values.value1}
                            />
                            <span className="radio-text-padding">
                              <DateControl
                                ctrlId="dob_date"
                                optionId={1}
                                minDate={dayjs("01-01-1920")}
                                maxDate={dayjs()}
                                locale="en" // current it is mm/dd/yyyy but for dd/mm/yyyy use "en-gb"
                                date={getEligibityData.dob_date}
                                readOnly
                              />
                            </span>
                          </label>
                        </div>
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="dob_unk"
                              name="dob"
                              value={99}
                              readOnly
                              checked={getEligibityData.dob_unk === Values.value99}
                            />
                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                      </div>
                    </div>

                    {/* Q3. */}
                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>3.</span>
                        <span className="quest-text-pad">
                          Did the patient initiate venetoclax + obinutuzumab
                          (VO) therapy as a <u>first-line therapy</u>?
                        </span>
                      </div>
                      <div className="double-dig-answer">
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="initiate_therapy_yes"
                              name="initiatevo_therapy"
                              value={1}
                              checked={getEligibityData.initiatevo_therapy === Values.value1}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">
                              Yes, received as a first-line therapy
                            </span>
                          </label>

                          <ul className="bullet-big">
                            <li>
                              <label>
                                Obinutuzumab initiated on
                                <span className="radio-text-padding">
                                  <DateControl
                                    ctrlId="obinutuzumab_date_yes"
                                    // selectedDate={selectedDate}
                                    //onDateChange={(newDate) => setSelectedDate(newDate)}
                                    HandleDateChange={HandleDateChange}
                                    optionId={1}
                                    minDate={dayjs("01-01-1920")}
                                    maxDate={dayjs()}
                                    locale="en" // current it is mm/dd/yyyy but for dd/mm/yyyy use "en-gb"
                                    date={getEligibityData.initiatevo_therapy === Values.value1 ? getEligibityData.obinutuzumab_date_yes : null}
                                  />
                                </span>
                              </label>
                            </li>
                            <li>
                              <label>
                                Venetoclax initiated on
                                <span className="radio-text-padding">
                                  <DateControl
                                    ctrlId="venetoclax_date_yes"
                                    // selectedDate={selectedDate}
                                    //onDateChange={(newDate) => setSelectedDate(newDate)}
                                    HandleDateChange={HandleDateChange}
                                    optionId={1}
                                    minDate={dayjs("01-01-1920")}
                                    maxDate={dayjs()}
                                    locale="en" // current it is mm/dd/yyyy but for dd/mm/yyyy use "en-gb"
                                    date={getEligibityData.initiatevo_therapy === Values.value1 ? getEligibityData.venetoclax_date_yes : null}
                                  />
                                </span>
                              </label>
                            </li>
                          </ul>
                        </div>
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="initiate_therapy_no_vo"
                              name="initiatevo_therapy"
                              value={2}
                              checked={getEligibityData.initiatevo_therapy === Values.value2}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">
                              No, received as a second-line therapy
                            </span>
                          </label>
                          <ul className="bullet-big">
                            <li>
                              <label>
                                Obinutuzumab initiated on
                                <span className="radio-text-padding">
                                  <DateControl
                                    ctrlId="obinutuzumab_date_no"
                                    // selectedDate={selectedDate}
                                    // onDateChange={(newDate) => setSelectedDate(newDate)}
                                    HandleDateChange={HandleDateChange}
                                    optionId={1}
                                    minDate={dayjs("01-01-1920")}
                                    maxDate={dayjs()}
                                    locale="en" // current it is mm/dd/yyyy but for dd/mm/yyyy use "en-gb"
                                    date={getEligibityData.initiatevo_therapy === Values.value2 ? getEligibityData.obinutuzumab_date_no : null}
                                  />
                                </span>
                              </label>
                            </li>
                            <li>
                              <label>
                                Venetoclax initiated on
                                <span className="radio-text-padding">
                                  <DateControl
                                    ctrlId="venetoclax_date_no"
                                    // selectedDate={selectedDate}
                                    //onDateChange={(newDate) => setSelectedDate(newDate)}
                                    HandleDateChange={HandleDateChange}
                                    optionId={1}
                                    minDate={dayjs("01-01-1920")}
                                    maxDate={dayjs()}
                                    locale="en" // current it is mm/dd/yyyy but for dd/mm/yyyy use "en-gb"
                                    date={getEligibityData.initiatevo_therapy === Values.value2 ? getEligibityData.venetoclax_date_no : null}
                                  />
                                </span>
                              </label>
                            </li>
                          </ul>
                        </div>
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="initiate_therapy_no"
                              name="initiatevo_therapy"
                              value={3}
                              checked={getEligibityData.initiatevo_therapy === Values.value3}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">
                              No, never received
                            </span>
                          </label>
                        </div>
                      </div>
                      <ErrorField
                        show={hardErrList.q3Empty}
                        message={EligibilityCriteria_Msgs.Radio}
                      />
                      <ErrorField
                        show={hardErrList.q3ODtEmpty}
                        message={EligibilityCriteria_Msgs.q3ODtEmptyMsg}
                      />
                      <ErrorField
                        show={hardErrList.q3VDtEmpty}
                        message={EligibilityCriteria_Msgs.q3VDtEmptyMsg}
                      />
                      <ErrorField
                        show={softErrList.q3IndxLTDiag}
                        message={q3ErrorMsg}
                      />
                      <ErrorField
                        show={hardErrList.q3IndxLTMidMay}
                        message={EligibilityCriteria_Msgs.q3IndxLTMidMayMsg}
                      />
                      <ErrorField
                        show={softErrList.q3VenLTIndx}
                        message={EligibilityCriteria_Msgs.q3VenLTIndxMsg}
                      />
                      <ErrorField
                        show={softErrList.q3IndxLTVenMTT}
                        message={EligibilityCriteria_Msgs.q3IndxLTVenMTTMsg}
                      />
                      <ErrorField
                        show={hardErrList.q3Terminate}
                        message={EligibilityCriteria_Msgs.q3TerminateIneligible}
                      />
                      {/* Invalid Date Error Field */}
                      <ErrorField show={hardErrList.yesObiInitInvalid} message={EligibilityCriteria_Msgs.invalidDate + " Obinutuzumab initiation date"} />
                      <ErrorField show={hardErrList.yesVenetoInitInvalid} message={EligibilityCriteria_Msgs.invalidDate + " Venetoclax initiation date"} />
                      <ErrorField show={hardErrList.noObiInitInvalid} message={EligibilityCriteria_Msgs.invalidDate + " Obinutuzumab initiation date"} />
                      <ErrorField show={hardErrList.noVenetoInitInvalid} message={EligibilityCriteria_Msgs.invalidDate + " Venetoclax initiation date"} />
                    </div>

                    {/* Q4. */}
                    {getEligibityData.initiatevo_therapy === Values.value2 && (
                      <div className="question-bot-sapce">
                        <div className="question question-weight">
                          <span>4.</span>
                          <span className="quest-text-pad">
                            Did the patient’s first-line therapy consist of a
                            targeted agent which was discontinued in less than 30
                            days? Please specify the agents received and the date
                            of initiation and stop of the prior therapy received.
                          </span>
                        </div>
                        <div className="double-dig-answer">
                          <div className="option-without-input">
                            <label>
                              <input
                                type="radio"
                                id="targeted_agent_yes"
                                name="received_agent"
                                value={1}
                                checked={getEligibityData.received_agent === Values.value1}
                                onChange={HandleFieldChange}
                              />
                              <span className="radio-text-padding">
                                Yes, the patient received the following agent(s)
                                in the first line (please select all that apply)
                              </span>
                            </label>

                            <ul className="bullet-big">
                              <li>
                                Targeted therapies
                                <div className="option-without-input">
                                  <label>
                                    <input
                                      type="checkbox"
                                      id="targeted_therapies_acalabrutinib"
                                      name="therapy_acalabrutinib"
                                      checked={getEligibityData.therapy_acalabrutinib}
                                      onChange={HandleFieldChange}
                                    />
                                    <span className="radio-text-padding">
                                      Acalabrutinib
                                    </span>
                                  </label>
                                </div>
                                <div className="option-without-input">
                                  <label>
                                    <input
                                      type="checkbox"
                                      id="targeted_therapies_btk_inhibitor"
                                      name="therapy_btkinhibitor"
                                      checked={getEligibityData.therapy_btkinhibitor}
                                      onChange={HandleFieldChange}
                                    />
                                    <span className="radio-text-padding">
                                      BTK inhibitor
                                    </span>
                                  </label>
                                </div>
                                <div className="option-without-input">
                                  <label>
                                    <input
                                      type="checkbox"
                                      id="targeted_therapies_cerdulatinib"
                                      name="therapy_cerdulatinib"
                                      checked={getEligibityData.therapy_cerdulatinib}
                                      onChange={HandleFieldChange}
                                    />
                                    <span className="radio-text-padding">
                                      Cerdulatinib
                                    </span>
                                  </label>
                                </div>
                                <div className="option-without-input">
                                  <label>
                                    <input
                                      type="checkbox"
                                      id="targeted_therapies_cudc_13_045"
                                      name="therapy_cudc13045"
                                      checked={getEligibityData.therapy_cudc13045}
                                      onChange={HandleFieldChange}
                                    />
                                    <span className="radio-text-padding">
                                      CUDC-13-045
                                    </span>
                                  </label>
                                </div>
                                <div className="option-without-input">
                                  <label>
                                    <input
                                      type="checkbox"
                                      id="targeted_therapies_duvelisib"
                                      name="therapy_duvelisib"
                                      checked={getEligibityData.therapy_duvelisib}
                                      onChange={HandleFieldChange}
                                    />
                                    <span className="radio-text-padding">
                                      Duvelisib
                                    </span>
                                  </label>
                                </div>
                                <div className="option-without-input">
                                  <label>
                                    <input
                                      type="checkbox"
                                      id="targeted_therapies_entospletinib"
                                      name="therapy_entospletinib"
                                      checked={getEligibityData.therapy_entospletinib}
                                      onChange={HandleFieldChange}
                                    />
                                    <span className="radio-text-padding">
                                      Entospletinib
                                    </span>
                                  </label>
                                </div>
                                <div className="option-without-input">
                                  <label>
                                    <input
                                      type="checkbox"
                                      id="targeted_therapies_fenebrutinib"
                                      name="therapy_fenebrutinib"
                                      checked={getEligibityData.therapy_fenebrutinib}
                                      onChange={HandleFieldChange}
                                    />
                                    <span className="radio-text-padding">
                                      Fenebrutinib
                                    </span>
                                  </label>
                                </div>
                                <div className="option-without-input">
                                  <label>
                                    <input
                                      type="checkbox"
                                      id="targeted_therapies_ibrutinib"
                                      name="therapy_ibrutinib"
                                      checked={getEligibityData.therapy_ibrutinib}
                                      onChange={HandleFieldChange}
                                    />
                                    <span className="radio-text-padding">
                                      Ibrutinib
                                    </span>
                                  </label>
                                </div>
                                <div className="option-without-input">
                                  <label>
                                    <input
                                      type="checkbox"
                                      id="targeted_therapies_idelalisib"
                                      name="therapy_idelalisib"
                                      checked={getEligibityData.therapy_idelalisib}
                                      onChange={HandleFieldChange}
                                    />
                                    <span className="radio-text-padding">
                                      Idelalisib
                                    </span>
                                  </label>
                                </div>
                                <div className="option-without-input">
                                  <label>
                                    <input
                                      type="checkbox"
                                      id="targeted_therapies_incb040093"
                                      name="therapy_incb040093"
                                      checked={getEligibityData.therapy_incb040093}
                                      onChange={HandleFieldChange}
                                    />
                                    <span className="radio-text-padding">
                                      INCB040093
                                    </span>
                                  </label>
                                </div>
                                <div className="option-without-input">
                                  <label>
                                    <input
                                      type="checkbox"
                                      id="targeted_therapies_obatoclax"
                                      name="therapy_obatoclax"
                                      checked={getEligibityData.therapy_obatoclax}
                                      onChange={HandleFieldChange}
                                    />
                                    <span className="radio-text-padding">
                                      Obatoclax
                                    </span>
                                  </label>
                                </div>
                                <div className="option-without-input">
                                  <label>
                                    <input
                                      type="checkbox"
                                      id="targeted_therapies_pirtobrutinib"
                                      name="therapy_pirtobrutinib"
                                      checked={getEligibityData.therapy_pirtobrutinib}
                                      onChange={HandleFieldChange}
                                    />
                                    <span className="radio-text-padding">
                                      Pirtobrutinib
                                    </span>
                                  </label>
                                </div>
                                <div className="option-without-input">
                                  <label>
                                    <input
                                      type="checkbox"
                                      id="targeted_therapies_rigosertib"
                                      name="therapy_rigosertib"
                                      checked={getEligibityData.therapy_rigosertib}
                                      onChange={HandleFieldChange}
                                    />
                                    <span className="radio-text-padding">
                                      Rigosertib
                                    </span>
                                  </label>
                                </div>
                                <div className="option-without-input">
                                  <label>
                                    <input
                                      type="checkbox"
                                      id="targeted_therapies_spebrutinib"
                                      name="therapy_spebrutinib"
                                      checked={getEligibityData.therapy_spebrutinib}
                                      onChange={HandleFieldChange}
                                    />
                                    <span className="radio-text-padding">
                                      Spebrutinib
                                    </span>
                                  </label>
                                </div>
                                <div className="option-without-input">
                                  <label>
                                    <input
                                      type="checkbox"
                                      id="targeted_therapies_umbralisib"
                                      name="therapy_umbralisib"
                                      checked={getEligibityData.therapy_umbralisib}
                                      onChange={HandleFieldChange}
                                    />
                                    <span className="radio-text-padding">
                                      Umbralisib
                                    </span>
                                  </label>
                                </div>
                                <div className="option-without-input">
                                  <label>
                                    <input
                                      type="checkbox"
                                      id="targeted_therapies_unspecified_bcl2i"
                                      name="therapy_unspecified"
                                      checked={getEligibityData.therapy_unspecified}
                                      onChange={HandleFieldChange}
                                    />
                                    <span className="radio-text-padding">
                                      Unspecified BCL2i
                                    </span>
                                  </label>
                                </div>
                                <div className="option-without-input">
                                  <label>
                                    <input
                                      type="checkbox"
                                      id="targeted_therapies_vecabrutinib"
                                      name="therapy_vecabrutinib"
                                      checked={getEligibityData.therapy_vecabrutinib}
                                      onChange={HandleFieldChange}
                                    />
                                    <span className="radio-text-padding">
                                      Vecabrutinib
                                    </span>
                                  </label>
                                </div>
                                <div className="option-without-input">
                                  <label>
                                    <input
                                      type="checkbox"
                                      id="targeted_therapies_venetoclax"
                                      name="therapy_venetoclax"
                                      checked={getEligibityData.therapy_venetoclax}
                                      onChange={HandleFieldChange}
                                    />
                                    <span className="radio-text-padding">
                                      Venetoclax
                                    </span>
                                  </label>
                                </div>
                                <div className="option-without-input">
                                  <label>
                                    <input
                                      type="checkbox"
                                      id="targeted_therapies_zanubrutinib"
                                      name="therapy_zanubrutinib"
                                      checked={getEligibityData.therapy_zanubrutinib}
                                      onChange={HandleFieldChange}
                                    />
                                    <span className="radio-text-padding">
                                      Zanubrutinib
                                    </span>
                                  </label>
                                </div>
                                <div className="option-without-input">
                                  <label>
                                    <input
                                      type="checkbox"
                                      id="targeted_therapies_other_specify"
                                      name="therapy_oth"
                                      checked={getEligibityData.therapy_oth}
                                      onChange={HandleFieldChange}
                                    />
                                    <span className="radio-text-padding">
                                      Other, specify:{" "}
                                    </span>
                                    <input
                                      id="targeted_therapies_other_specify_text"
                                      name="therapy_oth_txt"
                                      type="text"
                                      className="targeted_therapies_other_specify_text"
                                      value={getEligibityData.therapy_oth_txt}
                                      onChange={HandleFieldChange}
                                      placeholder=""
                                      maxLength={500}
                                      style={{
                                        border: "none",
                                        borderBottom: "1px solid #000", // Adds a bottom border with a solid line
                                        outline: "none", // Removes the default outline on focus
                                      }}
                                    />
                                  </label>
                                </div>
                              </li>
                              <br />
                              <li>
                                <label>
                                  Date of initiation
                                  <span className="radio-text-padding">
                                    <DateControl
                                      ctrlId="initiation_date"
                                      // selectedDate={selectedDate}
                                      //onDateChange={(newDate) => setSelectedDate(newDate)}
                                      HandleDateChange={HandleDateChange}
                                      optionId={1}
                                      minDate={dayjs("01-01-1920")}
                                      maxDate={dayjs()}
                                      locale="en" // current it is mm/dd/yyyy but for dd/mm/yyyy use "en-gb"
                                      date={getEligibityData.initiation_date}
                                    />
                                  </span>
                                </label>
                              </li>
                              <li>
                                <label>
                                  Date of stop
                                  <span className="radio-text-padding">
                                    <DateControl
                                      ctrlId="stop_date"
                                      // selectedDate={selectedDate}
                                      //onDateChange={(newDate) => setSelectedDate(newDate)}
                                      HandleDateChange={HandleDateChange}
                                      optionId={1}
                                      minDate={dayjs("01-01-1920")}
                                      maxDate={dayjs()}
                                      locale="en" // current it is mm/dd/yyyy but for dd/mm/yyyy use "en-gb"
                                      date={getEligibityData.stop_date}
                                    />
                                  </span>
                                </label>
                              </li>
                            </ul>
                          </div>
                          <div className="option-without-input">
                            <label>
                              <input
                                type="radio"
                                id="targeted_agent_no"
                                name="received_agent"
                                value={2}
                                checked={getEligibityData.received_agent === Values.value2}
                                onChange={HandleFieldChange}
                              />
                              <span className="radio-text-padding">No</span>
                            </label>
                          </div>
                          <div className="option-without-input">
                            <label>
                              <input
                                type="radio"
                                id="targeted_agent_unk"
                                name="received_agent"
                                value={99}
                                checked={getEligibityData.received_agent === Values.value99}
                                onChange={HandleFieldChange}
                              />
                              <span className="radio-text-padding">Unknown</span>
                            </label>
                          </div>
                        </div>
                        <ErrorField
                          show={hardErrList.q4Empty}
                          message={EligibilityCriteria_Msgs.Radio}
                        />
                        <ErrorField
                          show={hardErrList.q4OthTextEmpty}
                          message={EligibilityCriteria_Msgs.q4OthTxtEmptyMsg}
                        />
                        <ErrorField
                          show={hardErrList.q4TEmpty}
                          message={EligibilityCriteria_Msgs.q4TEmptyMsg}
                        />
                        <ErrorField
                          show={hardErrList.q4IDtEmpty}
                          message={EligibilityCriteria_Msgs.q4IDtEmptyMsg}
                        />
                        <ErrorField
                          show={hardErrList.q4SDtEmpty}
                          message={EligibilityCriteria_Msgs.q4SDtEmptyMsg}
                        />
                        <ErrorField
                          show={hardErrList.q4SSDtGT30}
                          message={EligibilityCriteria_Msgs.q4SSDtGT30Msg}
                        />
                        <ErrorField
                          show={hardErrList.q4Terminate}
                          message={EligibilityCriteria_Msgs.q4TerminateIneligible}
                        />
                        {/* Invalid Date Error Field */}
                        <ErrorField show={hardErrList.initDateInvalid} message={EligibilityCriteria_Msgs.invalidDate + " date of initiation"} />
                        <ErrorField show={hardErrList.stopDateInvalid} message={EligibilityCriteria_Msgs.invalidDate + " date of stop"} />

                      </div>)}

                    {/* Q5. */}
                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>5.</span>
                        <span className="quest-text-pad">
                          Was patient treated with the VO therapy as part of a
                          clinical trial?
                        </span>
                      </div>
                      <div className="double-dig-answer">
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="vo_treated_yes"
                              name="vo_treated"
                              value={1}
                              checked={getEligibityData.vo_treated === Values.value1}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">Yes</span>
                          </label>
                        </div>
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="vo_treated_no"
                              name="vo_treated"
                              value={2}
                              checked={getEligibityData.vo_treated === Values.value2}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">No</span>
                          </label>
                        </div>
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="vo_treated_unk"
                              name="vo_treated"
                              value={99}
                              checked={getEligibityData.vo_treated === Values.value99}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                      </div>
                      <ErrorField
                        show={hardErrList.q5Empty}
                        message={EligibilityCriteria_Msgs.Radio}
                      />
                      <ErrorField
                        show={hardErrList.q5Terminate}
                        message={EligibilityCriteria_Msgs.q5TerminateIneligible}
                      />
                    </div>

                    {/* Q6. */}
                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>6.</span>
                        <span className="quest-text-pad">
                          Did the patient receive any other CLL agent(s) besides
                          venetoclax or obinutuzumab as part of their VO
                          therapy?
                        </span>
                      </div>
                      <div className="double-dig-answer">
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="oth_cll_yes"
                              name="received_other_cllagent"
                              value={1}
                              checked={getEligibityData.received_other_cllagent === Values.value1}
                              onChange={HandleFieldChange}
                            />

                            <span className="radio-text-padding">Yes</span>
                          </label>
                        </div>
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="oth_cll_no"
                              name="received_other_cllagent"
                              value={2}
                              checked={getEligibityData.received_other_cllagent === Values.value2}
                              onChange={HandleFieldChange}
                            />

                            <span className="radio-text-padding">No</span>
                          </label>
                        </div>
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="oth_cll_unk"
                              name="received_other_cllagent"
                              value={99}
                              checked={getEligibityData.received_other_cllagent === Values.value99}
                              onChange={HandleFieldChange}
                            />

                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                      </div>
                      <ErrorField
                        show={hardErrList.q6Empty}
                        message={EligibilityCriteria_Msgs.Radio}
                      />
                      <ErrorField
                        show={hardErrList.q6Terminate}
                        message={EligibilityCriteria_Msgs.q6TerminateIneligible}
                      />
                    </div>

                    {/* Q7. */}
                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>7.</span>
                        <span className="quest-text-pad">
                          What was the date of completion of venetoclax ramp-up
                          (i.e., the last day before receiving their first
                          maintenance dose of venetoclax)?
                        </span>
                      </div>
                      <div className="double-dig-answer">
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="ven_ramp_known"
                              name="ven_rampup_checked"
                              value={1}
                              checked={getEligibityData.ven_rampup_checked === Values.value1}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">
                              <DateControl
                                ctrlId="ven_rampup_date"
                                // selectedDate={selectedDate}
                                //onDateChange={(newDate) => setSelectedDate(newDate)}
                                HandleDateChange={HandleDateChange}
                                optionId={1}
                                minDate={dayjs("01-01-1920")}
                                maxDate={dayjs()}
                                locale="en" // current it is mm/dd/yyyy but for dd/mm/yyyy use "en-gb"
                                date={getEligibityData.ven_rampup_date}
                              />
                            </span>
                          </label>
                        </div>
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="ven_ramp_unk"
                              name="ven_rampup_unknown"
                              value={99}
                              checked={getEligibityData.ven_rampup_unknown === Values.value99}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                      </div>
                      <ErrorField
                        show={hardErrList.q7Empty}
                        message={EligibilityCriteria_Msgs.Radio}
                      />
                      <ErrorField
                        show={hardErrList.q7DtEmpty}
                        message={EligibilityCriteria_Msgs.q7DtEmptyMsg}
                      />
                      <ErrorField
                        show={softErrList.q7VSEDtLT35}
                        message={q7ErrorMsg}
                      />
                      <ErrorField
                        show={hardErrList.q7Terminate}
                        message={EligibilityCriteria_Msgs.q7TerminateIneligible}
                      />
                      {/* Invalid Date Error Field */}
                      <ErrorField show={hardErrList.venetoRampUpInvalid} message={EligibilityCriteria_Msgs.invalidDate + " date of completion of venetoclax ramp-up"} />

                    </div>

                    {/* Q8. */}
                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>8.</span>
                        <span className="quest-text-pad">
                          Did the patient have a ramp-up period as{" "}
                          <span
                            className="modal-popup"
                            data-tooltip-id="encounterTip"
                            onClick={handleLabelClick}
                          >
                            per FDA label
                          </span>?
                        </span>
                      </div>
                      <div className="double-dig-answer">
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="ramp_period_yes"
                              name="rampup_period"
                              value={1}
                              checked={getEligibityData.rampup_period === Values.value1}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">Yes</span>
                          </label>
                        </div>
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="ramp_period_no"
                              name="rampup_period"
                              value={2}
                              checked={getEligibityData.rampup_period === Values.value2}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">No</span>
                          </label>
                        </div>
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="ramp_period_unk"
                              name="rampup_period"
                              value={99}
                              checked={getEligibityData.rampup_period === Values.value99}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                      </div>
                      <ErrorField
                        show={hardErrList.q8Empty}
                        message={EligibilityCriteria_Msgs.Radio}
                      />
                      <ErrorField
                        show={hardErrList.q8Terminate}
                        message={EligibilityCriteria_Msgs.q8TerminateIneligible}
                      />
                    </div>

                    {/* Q9. */}
                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>9.</span>
                        <span className="quest-text-pad">
                          Did the patient have a record for antihyperuricemics{" "}
                          <span
                            className="modal-popup"
                            data-tooltip-id="encounterTip"
                            onClick={handleLabelClick}
                          >
                            per-label
                          </span>{" "}
                          (i.e., allopurinol or other xanthine oxidase
                          inhibitors) prior to the initiation of venetoclax?
                        </span>
                      </div>
                      <div className="double-dig-answer">
                        <div className="option-without-input">
                          <label>
                            <input
                              type="checkbox"
                              id="anti_hyper_record_allo"
                              name="via_allopurinal"
                              checked={getEligibityData.via_allopurinal}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">
                              Yes, via allopurinol{" "}
                            </span>
                          </label>

                          <ul className="bullet-big">
                            <li>
                              <label>
                                Start date
                              </label>

                              <div className="option-without-input ">
                                <label>
                                  <input
                                    type="radio"
                                    id="allo_startdate_yes"
                                    value={1}
                                    name="allopurinol_startdate_checked"
                                    checked={getEligibityData.allopurinol_startdate_checked}
                                    onChange={HandleFieldChange}
                                  />
                                  <span className="radio-text-padding">
                                    Allopurinol initiated on &nbsp;
                                    <DateControl
                                      ctrlId="allopurinol_startdate"
                                      // selectedDate={selectedDate}
                                      //onDateChange={(newDate) => setSelectedDate(newDate)}
                                      HandleDateChange={HandleDateChange}
                                      optionId={1}
                                      minDate={dayjs("01-01-1920")}
                                      maxDate={dayjs()}
                                      locale="en" // current it is mm/dd/yyyy but for dd/mm/yyyy use "en-gb"
                                      date={getEligibityData.allopurinol_startdate}
                                    />
                                  </span>
                                </label>
                              </div>
                              <div className="option-without-input">
                                <label>
                                  <input
                                    type="radio"
                                    id="allo_startdate_unk"
                                    name="allopurinol_startdate_unk"
                                    value={99}
                                    checked={getEligibityData.allopurinol_startdate_unk === Values.value99}
                                    onChange={HandleFieldChange}
                                  />
                                  <span className="radio-text-padding">
                                    Allopurinol initiation date unknown
                                  </span>
                                </label>
                              </div>
                            </li>
                            <li>
                              <label>
                                End date
                                <div className="option-without-input ">
                                  <label>
                                    <input
                                      type="radio"
                                      id="allo_enddate_radio_yes"
                                      value={1}
                                      name="allopurinol_enddate_checked"
                                      checked={getEligibityData.allopurinol_enddate_checked}
                                      onChange={HandleFieldChange}
                                    />
                                    <span className="radio-text-padding">
                                      Allopurinol ended on &nbsp;
                                      <DateControl
                                        ctrlId="allopurinol_enddate"
                                        // selectedDate={selectedDate}
                                        //onDateChange={(newDate) => setSelectedDate(newDate)}
                                        HandleDateChange={HandleDateChange}
                                        optionId={1}
                                        minDate={dayjs("01-01-1920")}
                                        maxDate={dayjs()}
                                        locale="en" // current it is mm/dd/yyyy but for dd/mm/yyyy use "en-gb"
                                        date={getEligibityData.allopurinol_enddate}
                                      />
                                    </span>
                                  </label>
                                </div>
                                <div className="option-without-input">
                                  <label>
                                    <input
                                      type="radio"
                                      id="allo_enddate_unk"
                                      name="allopurinol_enddate_unk"
                                      value={99}
                                      checked={getEligibityData.allopurinol_enddate_unk === Values.value99}
                                      onChange={HandleFieldChange}
                                    />
                                    <span className="radio-text-padding">
                                      Allopurinol end date unknown
                                    </span>
                                  </label>
                                </div>
                              </label>
                            </li>
                          </ul>
                        </div>
                        <div className="option-without-input">
                          <label>
                            <input
                              type="checkbox"
                              id="anti_hyper_record_other"
                              name="via_antihyperuricemics"
                              checked={getEligibityData.via_antihyperuricemics}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">
                              Yes, via other antihyperuricemics{" "}
                            </span>
                          </label>

                          <ul className="bullet-big">
                            <li>
                              <label>
                                Start date
                              </label>

                              <div className="option-without-input ">
                                <label>
                                  <input
                                    type="radio"
                                    id="other_startdate_yes"
                                    value={1}
                                    name="antihyperuricemics_startdate_checked"
                                    checked={getEligibityData.antihyperuricemics_startdate_checked === Values.value1}
                                    onChange={HandleFieldChange}
                                  />
                                  <span className="radio-text-padding">
                                    Other antihyperuricemics initiated on &nbsp;
                                    <DateControl
                                      ctrlId="antihyperuricemics_startdate"
                                      // selectedDate={selectedDate}
                                      //onDateChange={(newDate) => setSelectedDate(newDate)}
                                      HandleDateChange={HandleDateChange}
                                      optionId={1}
                                      minDate={dayjs("01-01-1920")}
                                      maxDate={dayjs()}
                                      locale="en" // current it is mm/dd/yyyy but for dd/mm/yyyy use "en-gb"
                                      date={getEligibityData.antihyperuricemics_startdate}
                                    />
                                  </span>
                                </label>
                              </div>
                              <div className="option-without-input">
                                <label>
                                  <input
                                    type="radio"
                                    id="other_startdate_unk"
                                    name="antihyperuricemics_startdate_unk"
                                    value={99}
                                    checked={getEligibityData.antihyperuricemics_startdate_unk === Values.value99}
                                    onChange={HandleFieldChange}
                                  />
                                  <span className="radio-text-padding">
                                    Other antihyperuricemics initiation date unknown
                                  </span>
                                </label>
                              </div>
                            </li>
                            <li>
                              <label>
                                End date
                                <div className="option-without-input ">
                                  <label>
                                    <input
                                      type="radio"
                                      id="other_enddate_radio_yes"
                                      value={1}
                                      name="antihyperuricemics_enddate_checked"
                                      checked={getEligibityData.antihyperuricemics_enddate_checked === Values.value1}
                                      onChange={HandleFieldChange}
                                    />
                                    <span className="radio-text-padding">
                                      Other antihyperuricemics ended on &nbsp;
                                      <DateControl
                                        ctrlId="antihyperuricemics_enddate"
                                        // selectedDate={selectedDate}
                                        //onDateChange={(newDate) => setSelectedDate(newDate)}
                                        HandleDateChange={HandleDateChange}
                                        optionId={1}
                                        minDate={dayjs("01-01-1920")}
                                        maxDate={dayjs()}
                                        locale="en" // current it is mm/dd/yyyy but for dd/mm/yyyy use "en-gb"
                                        date={getEligibityData.antihyperuricemics_enddate}
                                      />
                                    </span>
                                  </label>
                                </div>
                                <div className="option-without-input">
                                  <label>
                                    <input
                                      type="radio"
                                      id="other_enddate_unk"
                                      name="antihyperuricemics_enddate_unk"
                                      value={99}
                                      checked={getEligibityData.antihyperuricemics_enddate_unk === Values.value99}
                                      onChange={HandleFieldChange}
                                    />
                                    <span className="radio-text-padding">
                                      Other antihyperuricemics end date unknown
                                    </span>
                                  </label>
                                </div>
                              </label>
                            </li>
                          </ul>
                        </div>
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="anti_hyper_record_no"
                              name="no_antihyperuricemic"
                              value={1}
                              checked={getEligibityData.no_antihyperuricemic === Values.value1}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">No</span>
                          </label>
                        </div>

                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="anti_hyper_record_unk"
                              name="antihyperuricemic_unk"
                              value={99}
                              checked={getEligibityData.antihyperuricemic_unk === Values.value99}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                      </div>
                      <ErrorField
                        show={hardErrList.q9Empty}
                        message={EligibilityCriteria_Msgs.Checkbox}
                      />
                      <ErrorField
                        show={hardErrList.q9AllSDtEmpty}
                        message={EligibilityCriteria_Msgs.q9AllSDtEmpty}
                      />
                      <ErrorField
                        show={hardErrList.q9AllEDtEmpty}
                        message={EligibilityCriteria_Msgs.q9AllEDtEmpty}
                      />
                      <ErrorField
                        show={hardErrList.q9AHSDtEmpty}
                        message={EligibilityCriteria_Msgs.q9AHSDtEmpty}
                      />
                      <ErrorField
                        show={hardErrList.q9AHEDtEmpty}
                        message={EligibilityCriteria_Msgs.q9AHEDtEmpty}
                      />
                      <ErrorField
                        show={softErrList.q9SDtLTEDtAllo}
                        message={q9StrDtLtEDtErrorMsgAllo}
                      />
                      <ErrorField
                        show={softErrList.q9SDtGTVSDtAllo}
                        message={q9StrDtErrorMsgAllo}
                      />
                      <ErrorField
                        show={softErrList.q9SDtLTVEDtAllo}
                        message={q9MedicationErrorMsgAllo}
                      />
                      <ErrorField
                        show={softErrList.q9SDtLTDobAllo}
                        message={dOBErrorMsgAllo}
                      />
                      <ErrorField
                        show={softErrList.q9SDtLTEDtAnti}
                        message={q9StrDtLtEDtErrorMsgAnti}
                      />
                      <ErrorField
                        show={softErrList.q9SDtGTVSDtAnti}
                        message={q9StrDtErrorMsgAnti}
                      />
                      <ErrorField
                        show={softErrList.q9SDtLTVEDtAnti}
                        message={q9MedicationErrorMsgAnti}
                      />
                      <ErrorField
                        show={softErrList.q9SDtLTDobAnti}
                        message={dOBErrorMsgAnti}
                      />
                      <ErrorField
                        show={hardErrList.q9Terminate}
                        message={EligibilityCriteria_Msgs.q9TerminateIneligible}
                      />
                      {/* Invalid Date Error Field */}
                      <ErrorField show={hardErrList.startAlloInvalid} message={EligibilityCriteria_Msgs.invalidDate + " Allopurinol initiation date"} />
                      <ErrorField show={hardErrList.endAlloInvalid} message={EligibilityCriteria_Msgs.invalidDate + " Allopurinol end date"} />
                      <ErrorField show={hardErrList.startHyperInvalid} message={EligibilityCriteria_Msgs.invalidDate + " Other antihyperuricemics initiation date"} />
                      <ErrorField show={hardErrList.endHyperInvalid} message={EligibilityCriteria_Msgs.invalidDate + " Other antihyperuricemics end date"} />
                    </div>

                    {/* Q10. */}
                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>10.</span>
                        <span className="quest-text-pad">
                          Did the patient receive any of the following
                          medications during the 3 days prior to the initiation
                          of venetoclax until the end of ramp-up?
                        </span>
                      </div>
                      <div className=" double-dig-answer">
                        <table
                          className="styled-table"
                          style={{ width: "60%" }}
                        >
                          <tbody>
                            <tr>
                              <td>
                                <b>Agent</b>
                              </td>
                              <td>
                                <b>Yes</b>
                              </td>
                              <td>
                                <b>No</b>
                              </td>
                              <td>
                                <b>Unknown</b>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={4}>
                                <b>Strong CYP3A inhibitor</b>
                              </td>
                            </tr>
                            <tr>
                              <td>Clarithromycin</td>
                              <td>
                                <input
                                  type="radio"
                                  name="medication_clarithromycin"
                                  id="clarithromycin_yes"
                                  value={1}
                                  checked={getEligibityData.medication_clarithromycin === Values.value1}
                                  onChange={HandleFieldChange}
                                />
                              </td>
                              <td>
                                <input
                                  type="radio"
                                  name="medication_clarithromycin"
                                  id="clarithromycin_no"
                                  value={2}
                                  checked={getEligibityData.medication_clarithromycin === Values.value2}
                                  onChange={HandleFieldChange}
                                />
                              </td>
                              <td>
                                <input
                                  type="radio"
                                  name="medication_clarithromycin"
                                  id="clarithromycin_unk"
                                  value={99}
                                  checked={getEligibityData.medication_clarithromycin === Values.value99}
                                  onChange={HandleFieldChange}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Itraconazole</td>
                              <td>
                                <input
                                  type="radio"
                                  name="medication_itraconazole"
                                  id="itraconazoleyes"
                                  value={1}
                                  checked={getEligibityData.medication_itraconazole === Values.value1}
                                  onChange={HandleFieldChange}
                                />
                              </td>
                              <td>
                                <input
                                  type="radio"
                                  name="medication_itraconazole"
                                  id="itraconazole_no"
                                  value={2}
                                  checked={getEligibityData.medication_itraconazole === Values.value2}
                                  onChange={HandleFieldChange}
                                />
                              </td>
                              <td>
                                <input
                                  type="radio"
                                  name="medication_itraconazole"
                                  id="itraconazole_unk"
                                  value={99}
                                  checked={getEligibityData.medication_itraconazole === Values.value99}
                                  onChange={HandleFieldChange}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Ketoconazole</td>
                              <td>
                                <input
                                  type="radio"
                                  name="medication_ketoconazole"
                                  id="ketoconazole_yes"
                                  value={1}
                                  checked={getEligibityData.medication_ketoconazole === Values.value1}
                                  onChange={HandleFieldChange}
                                />
                              </td>
                              <td>
                                <input
                                  type="radio"
                                  name="medication_ketoconazole"
                                  id="ketoconazole_no"
                                  value={2}
                                  checked={getEligibityData.medication_ketoconazole === Values.value2}
                                  onChange={HandleFieldChange}
                                />
                              </td>
                              <td>
                                <input
                                  type="radio"
                                  name="medication_ketoconazole"
                                  id="ketoconazole_unk"
                                  value={99}
                                  checked={getEligibityData.medication_ketoconazole === Values.value99}
                                  onChange={HandleFieldChange}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Posaconazole</td>
                              <td>
                                <input
                                  type="radio"
                                  name="medication_posaconazole"
                                  id="posaconazole_yes"
                                  value={1}
                                  checked={getEligibityData.medication_posaconazole === Values.value1}
                                  onChange={HandleFieldChange}
                                />
                              </td>
                              <td>
                                <input
                                  type="radio"
                                  name="medication_posaconazole"
                                  id="posaconazole_no"
                                  value={2}
                                  checked={getEligibityData.medication_posaconazole === Values.value2}
                                  onChange={HandleFieldChange}
                                />
                              </td>
                              <td>
                                <input
                                  type="radio"
                                  name="medication_posaconazole"
                                  id="posaconazole_unk"
                                  value={99}
                                  checked={getEligibityData.medication_posaconazole === Values.value99}
                                  onChange={HandleFieldChange}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Ritonavir</td>
                              <td>
                                <input
                                  type="radio"
                                  name="medication_ritonavir"
                                  id="ritonavir_yes"
                                  value={1}
                                  checked={getEligibityData.medication_ritonavir === Values.value1}
                                  onChange={HandleFieldChange}
                                />
                              </td>
                              <td>
                                <input
                                  type="radio"
                                  name="medication_ritonavir"
                                  id="ritonavir_no"
                                  value={2}
                                  checked={getEligibityData.medication_ritonavir === Values.value2}
                                  onChange={HandleFieldChange}
                                />
                              </td>
                              <td>
                                <input
                                  type="radio"
                                  name="medication_ritonavir"
                                  id="ritonavir_unk"
                                  value={99}
                                  checked={getEligibityData.medication_ritonavir === Values.value99}
                                  onChange={HandleFieldChange}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Voriconazole</td>
                              <td>
                                <input
                                  type="radio"
                                  name="medication_voriconazole"
                                  id="voriconazole_yes"
                                  value={1}
                                  checked={getEligibityData.medication_voriconazole === Values.value1}
                                  onChange={HandleFieldChange}
                                />
                              </td>
                              <td>
                                <input
                                  type="radio"
                                  name="medication_voriconazole"
                                  id="voriconazole_no"
                                  value={2}
                                  checked={getEligibityData.medication_voriconazole === Values.value2}
                                  onChange={HandleFieldChange}
                                />
                              </td>
                              <td>
                                <input
                                  type="radio"
                                  name="medication_voriconazole"
                                  id="voriconazole_unk"
                                  value={99}
                                  checked={getEligibityData.medication_voriconazole === Values.value99}
                                  onChange={HandleFieldChange}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Other strong CYP3A inhibitors</td>
                              <td>
                                <input
                                  type="radio"
                                  name="medication_other"
                                  id="other_strong_yes"
                                  value={1}
                                  checked={getEligibityData.medication_other === Values.value1}
                                  onChange={HandleFieldChange}
                                />
                              </td>
                              <td>
                                <input
                                  type="radio"
                                  name="medication_other"
                                  id="other_strong_no"
                                  value={2}
                                  checked={getEligibityData.medication_other === Values.value2}
                                  onChange={HandleFieldChange}
                                />
                              </td>
                              <td>
                                <input
                                  type="radio"
                                  name="medication_other"
                                  id="other_strong_unk"
                                  value={99}
                                  checked={getEligibityData.medication_other === Values.value99}
                                  onChange={HandleFieldChange}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={4}>
                                <b>
                                  Antihyperuricemics other than allopurinol or
                                  xanthine oxidase inhibitor
                                </b>
                              </td>
                            </tr>
                            <tr>
                              <td>Rasburicase</td>
                              <td>
                                <input
                                  type="radio"
                                  name="antihyperuricemic_rasburicase"
                                  id="rasburicase_yes"
                                  value={1}
                                  checked={getEligibityData.antihyperuricemic_rasburicase === Values.value1}
                                  onChange={HandleFieldChange}
                                />
                              </td>
                              <td>
                                <input
                                  type="radio"
                                  name="antihyperuricemic_rasburicase"
                                  id="rasburicase_no"
                                  value={2}
                                  checked={getEligibityData.antihyperuricemic_rasburicase === Values.value2}
                                  onChange={HandleFieldChange}
                                />
                              </td>
                              <td>
                                <input
                                  type="radio"
                                  name="antihyperuricemic_rasburicase"
                                  id="rasburicase_unk"
                                  value={99}
                                  checked={getEligibityData.antihyperuricemic_rasburicase === Values.value99}
                                  onChange={HandleFieldChange}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <ErrorField
                        show={hardErrList.q10Empty}
                        message={EligibilityCriteria_Msgs.Radio}
                      />
                      <ErrorField
                        show={hardErrList.q10Terminate}
                        message={q10ErrorMsg}
                      />
                      <ErrorField
                        show={hardErrList.q10TerminateUnk}
                        message={q10UnkErrorMsg}
                      />
                    </div>

                    {/* PreScreening Questions */}
                    {/* Q11. */}
                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>11.</span>
                        <span className="quest-text-pad">
                          What was the patient’s latest tumor burden between
                          {/* [insert date of initiation of obinutuzumab therapy] */}
                          <span style={{ color: PageDesignConstants.fontColor }}>
                            {" "}
                            {getEligibityData.obinutuzumab_date_yes
                              ? getEligibityData.obinutuzumab_date_yes
                              : getEligibityData.obinutuzumab_date_no
                                ? getEligibityData.obinutuzumab_date_no
                                : "[insert date of initiation of obinutuzumab therapy]"}

                            {" "}
                          </span>
                          and
                          {/* [insert date of initiation of venetoclax therapy] */}
                          <span style={{ color: PageDesignConstants.fontColor }}>
                            {" "}
                            {getEligibityData.venetoclax_date_yes
                              ? getEligibityData.venetoclax_date_yes
                              : getEligibityData.venetoclax_date_no
                                ? getEligibityData.venetoclax_date_no
                                : "[insert date of initiation of venetoclax therapy]"}
                          </span>
                          ?{" "}
                          <i>
                            Please refer to the latest available assessments for
                            ALC and CrCl that led to the determination of
                            venetoclax start dose.
                          </i>
                        </span>
                      </div>
                      <div className="double-dig-answer">
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="tumor_burden_low"
                              name="tumor_burden"
                              value={1}
                              onChange={HandleFieldChange}
                              checked={getEligibityData.tumor_burden == Values.value1}
                            />
                            <span className="radio-text-padding">
                              Low (all lymph node [LN] &lt; 5 cm and absolute
                              lymphocyte count [ALC] &lt; 25 x 10<sup>9</sup>/L)
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="double-dig-answer">
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="tumor_burden_medium"
                              name="tumor_burden"
                              value={2}
                              onChange={HandleFieldChange}
                              checked={getEligibityData.tumor_burden == Values.value2}
                            />
                            <span className="radio-text-padding">
                              Medium (any LN from 5 cm to &lt; 10 cm or ALC &ge;
                              25 x 10<sup>9</sup>/L), with CrCl of &ge; 80
                              ml/min
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="double-dig-answer">
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="tumor_burden_medium_terminate"
                              name="tumor_burden"
                              value={3}
                              onChange={HandleFieldChange}
                              checked={getEligibityData.tumor_burden == Values.value3}
                            />
                            <span className="radio-text-padding">
                              Medium (any LN from 5 cm to &lt; 10 cm or ALC &ge;
                              25 x 10<sup>9</sup>/L), with CrCl of &lt; 80
                              ml/min
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="double-dig-answer">
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="tumor_burden_high"
                              name="tumor_burden"
                              value={4}
                              onChange={HandleFieldChange}
                              checked={getEligibityData.tumor_burden == Values.value4}
                            />
                            <span className="radio-text-padding">
                              High (any LN &ge; 10 cm, or ALC &ge; 25 x 10
                              <sup>9</sup>/L and LN &ge; 5 cm)
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="double-dig-answer">
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="tumor_burden_other"
                              name="tumor_burden"
                              value={5}
                              onChange={HandleFieldChange}
                              checked={getEligibityData.tumor_burden == Values.value5}
                            />
                            <span className="radio-text-padding">
                              Other, specify
                            </span>
                          </label>
                          <ul className="bullet-big">
                            <li>
                              <label>
                                <span className="radio-text-padding">
                                  LN size:
                                </span>
                                <input
                                  name="tumor_burden_ln_size"
                                  type="text"
                                  className="input-dash input_custome-space_sm"
                                  value={getEligibityData.tumor_burden_ln_size}
                                  onChange={e => {
                                    const value = e.target.value;

                                    // Allow only digits, check if the value is between 0 and 15
                                    if (
                                      wholeNum.test(value) &&
                                      value !== ""
                                      // &&
                                      // parseInt(value) <= 15
                                    ) {
                                      HandleFieldChange(e); // Process the valid value
                                    } else if (value === "") {
                                      HandleFieldChange(e); // Allow clearing the input
                                    }
                                  }}
                                  maxLength={4} // Limit to 2 digits
                                  // min={0}
                                  // max={15}
                                  style={{
                                    border: "none",
                                    borderBottom: "1px solid #000",
                                    outline: "none",
                                  }}
                                />
                                cm
                              </label>
                            </li>
                            <li>
                              <label>
                                <span className="radio-text-padding">
                                  ALC size:
                                </span>
                                <input
                                  name="tumor_burden_alc_size"
                                  type="text"
                                  className="input-dash input_custome-space_sm"
                                  value={getEligibityData.tumor_burden_alc_size}
                                  onChange={e => {
                                    const value = e.target.value;

                                    // Allow numbers with max 1 decimal place, and restrict the range between 0 and 50
                                    if (
                                      // /^\d{0,2}(\.\d{0,1})?$/.test(value)
                                      wholeNumUpto9999.test(value)
                                      // &&
                                      // parseFloat(value) <= 50
                                    ) {
                                      HandleFieldChange(e); // Process the valid value
                                    } else if (value === "") {
                                      HandleFieldChange(e); // Allow clearing the input
                                    }
                                  }}
                                  maxLength={4} // Limit to 5 characters (including the decimal point)
                                  // min={0}
                                  // max={50}
                                  style={{
                                    border: "none",
                                    borderBottom: "1px solid #000",
                                    outline: "none",
                                  }}
                                />
                                x 10<sup>9</sup>/L
                              </label>
                            </li>
                            <li>
                              <label>
                                <span className="radio-text-padding">
                                  CrCl value:
                                </span>
                                <input
                                  name="tumor_burden_crcl_value"
                                  type="text"
                                  className="input-dash input_custome-space_sm"
                                  value={getEligibityData.tumor_burden_crcl_value}
                                  onChange={e => {
                                    const value = e.target.value;

                                    // Allow whole numbers only, and restrict the range between 0 and 120
                                    if (
                                      // /^\d{0,3}$/.test(value)
                                      wholeNum3.test(value)
                                      // &&
                                      // parseInt(value, 10) <= 120
                                    ) {
                                      HandleFieldChange(e); // Process the valid value
                                    } else if (value === "") {
                                      HandleFieldChange(e); // Allow clearing the input
                                    }
                                  }}
                                  maxLength={4} // Limit input to 3 digits for whole numbers (since max is 120)
                                  // min={0}
                                  // max={120}
                                  style={{
                                    border: "none",
                                    borderBottom: "1px solid #000",
                                    outline: "none",
                                  }}
                                />
                                mL/min
                              </label>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="double-dig-answer">
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="tumor_burden_unk"
                              name="tumor_burden"
                              value={99}
                              onChange={HandleFieldChange}
                              checked={getEligibityData.tumor_burden == Values.value99}
                            />
                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                      </div>
                      <ErrorField
                        show={hardErrList.tumor_burdenEmpty}
                        message={EligibilityCriteria_Msgs.radioEmpty}
                      />
                      <ErrorField
                        show={hardErrList.tumor_burden_other_textEmpty}
                        message={EligibilityCriteria_Msgs.textEmpty}
                      />
                      <ErrorField
                        show={hardErrList.tumor_burden_alc_invalid}
                        message={EligibilityCriteria_Msgs.alcInvalid}
                      />
                      <ErrorField
                        show={hardErrList.terminateHard}
                        message={EligibilityCriteria_Msgs.terminate}
                      />
                      {/* <ErrorField
                        show={hardErrList.terminate}
                        message={terminateMsg}
                      /> */}
                      <ErrorField
                        show={hardErrList.tumor_burden_crcl_invalid}
                        message={EligibilityCriteria_Msgs.crclInvalid}
                      />
                    </div>
                    {/* Below is erorr message for Out of range values for LN, ALC and CrCl */}
                    <ErrorField message={rangeInvalidLNErrorMsg} />
                    <ErrorField message={rangeInvalidALCErrorMsg} />
                    <ErrorField message={rangeInvalidCRCLErrorMsg} />

                    {/* Q12. */}
                    <div className="readonly-div">
                      <div className="question question-weight">
                        <span>12.</span>
                        <span className="quest-text-pad">
                          Is the patient alive?{" "}
                          <i>
                            Please note that this response has been
                            pre-populated from the CORE database. If this
                            response is inconsistent with the patient chart,
                            please return to the CORE database to make the
                            correction.{" "}
                          </i>
                        </span>
                      </div>
                      <div className="double-dig-answer">
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="patient_alive_yes"
                              name="patient_alive"
                              value={1}
                              readOnly
                              checked={getEligibityData.patient_alive == Values.value1}
                            />
                            <span className="radio-text-padding">
                              To the best of my knowledge, the patient is still
                              alive
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="double-dig-answer">
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="patient_alive_no"
                              name="patient_alive"
                              value={2}
                              readOnly
                              checked={getEligibityData.patient_alive == Values.value2}
                            />
                            <span className="radio-text-padding">
                              Patient died on
                            </span>
                            <ul className="bullet-big">
                              <li>
                                <span className="radio-text-padding">
                                  <DateControl
                                    ctrlId="patient_death_date"
                                    // selectedDate={selectedDate}
                                    //onDateChange={(newDate) => setSelectedDate(newDate)}
                                    // HandleDateChange={HandleDateChange}
                                    optionId={1}
                                    minDate={dayjs("01-01-1920")}
                                    maxDate={dayjs()}
                                    locale="en" // current it is mm/dd/yyyy but for dd/mm/yyyy use "en-gb"
                                    date={getEligibityData.patient_death_date}
                                    readOnly
                                  />
                                </span>
                              </li>
                            </ul>
                          </label>
                        </div>
                      </div>
                    </div>

                    {/* Q13. */}
                    <div className="readonly-div">
                      <div className="question question-weight">
                        <span>13.</span>
                        <span className="quest-text-pad">
                          When was the patient <u>first</u> treated at your
                          institution?{" "}
                          <i>
                            Please note that this response has been
                            pre-populated from the CORE database. If this
                            response is inconsistent with the patient chart,
                            please return to the CORE database to make the
                            correction.{" "}
                          </i>
                        </span>
                      </div>

                      <div className="double-dig-answer">
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="patient_first_treated"
                              name="patient_first_treated"
                              value={1}
                              readOnly
                              // onChange={""}
                              checked={getEligibityData.patient_first_treated}
                            />
                            <span className="radio-text-padding"></span>

                            <span className="radio-text-padding">
                              <DateControl
                                ctrlId="patient_first_treated_date"
                                // selectedDate={selectedDate}
                                //onDateChange={(newDate) => setSelectedDate(newDate)}
                                // HandleDateChange={HandleDateChange}
                                optionId={1}
                                minDate={dayjs("01-01-1920")}
                                maxDate={dayjs()}
                                locale="en" // current it is mm/dd/yyyy but for dd/mm/yyyy use "en-gb"
                                date={getEligibityData.patient_first_treated_date}
                                readOnly
                              />
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>

                    {/* Q14. */}
                    <div className="readonly-div">
                      <div className="question question-weight">
                        <span>14.</span>
                        <span className="quest-text-pad">
                          When was the patient last seen at your institution?{" "}
                          <i>
                            Please note that this response has been
                            pre-populated from the CORE database. If this
                            response is inconsistent with the patient chart,
                            please return to the CORE database to make the
                            correction.{" "}
                          </i>
                        </span>
                      </div>

                      <div className="double-dig-answer">
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="patient_last_seen"
                              name="patient_last_seen"
                              value={1}
                              readOnly
                              // onChange={""}
                              checked={getEligibityData.patient_last_seen}
                            />
                            <span className="radio-text-padding"></span>

                            <span className="radio-text-padding">
                              <DateControl
                                ctrlId="patient_last_seen_date"
                                // selectedDate={selectedDate}
                                //onDateChange={(newDate) => setSelectedDate(newDate)}
                                // HandleDateChange={HandleDateChange}
                                optionId={1}
                                minDate={dayjs("01-01-1920")}
                                maxDate={dayjs()}
                                locale="en" // current it is mm/dd/yyyy but for dd/mm/yyyy use "en-gb"
                                date={getEligibityData.patient_last_seen_date}
                                readOnly
                              />
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className="survey-section-content-foot">
                <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EligibilityCriteria;
