import React from 'react'

const VenetoclaxDosingPopUp = ({ OkClick }) => {
    const OnOkClick = () => {
        OkClick()
    }

    const closeModal = () => {
        OkClick();
    };

    return (
        <>
            <section className="delete-modal">
                <div
                    className="modal"
                    id="infoModal"
                    tabIndex={-1}
                    aria-labelledby="ModalFormLabel"
                    aria-hidden="true"
                    style={{
                        display: "block",
                        paddingRight: "17px",
                        backgroundColor: "#00000094",
                    }}
                >
                    <div
                        className="modal-dialog modal-dialog-centered"
                        style={{ maxWidth: "850px", width: "100%" }}
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    type="button"
                                    className="close"
                                    onClick={closeModal}
                                    style={{
                                        backgroundColor: "white",
                                        border: "none",
                                        color: "red",
                                        fontSize: "2rem",
                                        fontWeight: "bold",
                                        width: "30px",
                                        height: "30px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        position: "absolute",
                                        top: "1px",
                                        right: "10px",
                                        cursor: "pointer",
                                    }}
                                >
                                    &times;
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="container">
                                    <div className="delete-modal-main">
                                        <div className="text-input-main">                                            
                                            <div className="modal-body">
                                                <p className="modal-popup-title">
                                                    <b>Dosing schedule for 5-week ramp-up phase for patients with CLL/SLL</b>
                                                </p>
                                                <table className="modal-popup-table-xlarge">
                                                    <thead>
                                                        <tr>
                                                            <th rowSpan="2" className="modal-popup-table-header-cell" style={{textAlign: "center", fontWeight: "normal"}}>Week</th>
                                                            <th colSpan="2" className="modal-popup-table-header-cell" style={{textAlign: "center", fontWeight: "normal"}}>Oral Daily Dose (mg)</th>
                                                        </tr>
                                                        <tr>
                                                            <th className="modal-popup-table-header-cell" style={{textAlign: "center", fontWeight: "normal"}}>Regular</th>
                                                            <th className="modal-popup-table-header-cell" style={{textAlign: "center", fontWeight: "normal"}}>Modified*</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="modal-popup-table-cell" style={{textAlign: "center" }}>1</td>
                                                            <td className="modal-popup-table-cell" style={{textAlign: "center" }}>20</td>
                                                            <td className="modal-popup-table-cell" style={{textAlign: "center" }}>10</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="modal-popup-table-cell" style={{textAlign: "center" }}>2</td>
                                                            <td className="modal-popup-table-cell" style={{textAlign: "center" }}>50</td>
                                                            <td className="modal-popup-table-cell" style={{textAlign: "center" }}>20</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="modal-popup-table-cell" style={{textAlign: "center" }}>3</td>
                                                            <td className="modal-popup-table-cell" style={{textAlign: "center" }}>100</td>
                                                            <td className="modal-popup-table-cell" style={{textAlign: "center" }}>50</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="modal-popup-table-cell" style={{textAlign: "center" }}>4</td>
                                                            <td className="modal-popup-table-cell" style={{textAlign: "center" }}>200</td>
                                                            <td className="modal-popup-table-cell" style={{textAlign: "center" }}>100</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="modal-popup-table-cell" style={{textAlign: "center" }}>5</td>
                                                            <td className="modal-popup-table-cell" style={{textAlign: "center" }}>400</td>
                                                            <td className="modal-popup-table-cell" style={{textAlign: "center" }}>200</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <p className="modal-para">*Please note that modified daily dosing schedule is required per label when venetoclax is co-administered with a moderate CYP3A inhibitor or P-gp inhibitor</p>

                                            </div>
                                            <div className="confirm-btn-div">
                                                <button className="modal-close-btn" onClick={OnOkClick}>
                                                    Ok
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default VenetoclaxDosingPopUp;
