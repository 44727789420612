import WebServiceWrapper from "./WebServiceWrapper";

export const HealthCareVisitsService = {
    GetHealthCareVisit,
    SaveHealthCareVisit,
};


function GetHealthCareVisit(pId) {
    return WebServiceWrapper.Get(`/healthCareVisit/GetHealthCareVisitData?patientId=${pId}`);
  }
  

function SaveHealthCareVisit(payload) {
    return WebServiceWrapper.PostWithHeader("/healthCareVisit/SaveHealthCareVisitData", payload);
}