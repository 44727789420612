import WebServiceWrapper from "./WebServiceWrapper";

export const AbstractorService = {
  GetAllAbstractors, AddUpdateAbstractor,DeleteAbstractor
};

function GetAllAbstractors() {
  return WebServiceWrapper.Get(`/abstractor/GetAllAbstractors`);
}
function AddUpdateAbstractor(payload) {
  return WebServiceWrapper.Post("/abstractor/AddUpdateAbstractor",payload);
}
function DeleteAbstractor(abstractorId) {
  return WebServiceWrapper.Get(`/abstractor/DeleteAbstractor?abstractorId=${abstractorId}`);
}


