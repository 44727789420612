import WebServiceWrapper from "./WebServiceWrapper";

export const TreatmentInterruptionService = {
  GetTreatmentInterruptionData,
  SaveTreatmentInterruptionData,
};

function GetTreatmentInterruptionData(patientId) {
  return WebServiceWrapper.Get(`/treatmentInterruption/GetTreatmentInterruptionData?patientId=${patientId}`);
}

function SaveTreatmentInterruptionData(payload) {
  return WebServiceWrapper.Post("/treatmentInterruption/SaveTreatmentInterruptionData", payload);
}
