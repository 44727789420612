import React, { useRef, useContext, useEffect, useState } from 'react'
import PatientDetails from '../../Components/PatientInformation'
import { CenterService } from '../../WebApiServices/Center.service';
import { AppContext } from '../../Contexts/AppContextProvider';
import { DefaultAGGridProps, ErrorMessages, HTTPResponse, PageAction, ToastMessageType } from '../../Helpers/Enums';
import CenterModal from './CenterModal';
import ConfirmPopUp from '../../Components/ConfirmPopUp';
import { Center_Msgs } from '../../Helpers/HelperText';
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { RemoveLocalStorageData } from '../../Helpers/Utilities';



export const ManageCenters = () => {

  const [loading, setLoading] = useState(true);
  const { ShowToast, ToggleLoader, ShowActionPopUp, HandleSessionTimeout } = useContext(AppContext);

  const [allCenters, setAllCenters] = useState([]);
  const [filteredCenters, setFilteredCenters] = useState([]);
  const [showCenterModal, setShowCenterModal] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const searchRef = useRef("");

  const [center, setCenter] = useState([]);
  const [pageAction, setPageAction] = useState();
  const [deleteCenterId, setDeleteCenterId] = useState("");
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)

  const iconStyle = {
    width: 25,
    margin: 10,
    cursor: "pointer",
  };


  useEffect(() => {
    RemoveLocalStorageData("patientId");
    //RemoveLocalStorageData("uniqueEncryptedPatientId");
    //RemoveLocalStorageData("surveyDate");

    RemoveLocalStorageData("patient");
    const FetchData = async () => {
      ToggleLoader(true)
      await GetAllCenters();
      ToggleLoader(false)
    }
    FetchData();
  }, []);

  useEffect(() => { 
    setFilteredCenters(allCenters?.filter((c) =>
      c.name.replace(/\s/g, '').toLowerCase().includes(searchQuery.replace(/\s/g, '').toLowerCase())
    )
      .sort(c => c.centerId));
  }, [searchQuery, allCenters])





  const GetAllCenters = async () => {
    try {
      const response = await CenterService.GetAllCenters();
      if (response?.status === HTTPResponse.Unauthorized) {
        HandleSessionTimeout();
      }
      else if (response?.status === HTTPResponse.OK) {
        const { data } = response;
        setAllCenters(data);
      }
      else{
        throw response.error;
      }
      setLoading(false);
    } catch {
      setLoading(false);
      ShowToast(ErrorMessages.LOAD_ERROR, ToastMessageType.Failed);
    }
  };
  const AddNew = () => {
    setShowCenterModal(true);
    setPageAction(PageAction.add);
    setCenter([]);
  };
  const Edit = (center) => {
    setShowCenterModal(true);
    setPageAction(PageAction.edit);
    setCenter(center);
  };
  const Delete = async (id, canDelete, canDeleteAbstractor) => {
    if (!canDelete) {
      const ShowAlertBox = async () => {
        const choice = await ShowActionPopUp(
          "Alert",
          "This center cannot be deleted as there are patients associated to it.",
          "Ok",
          true
        );
      };
      ShowAlertBox()
    } else if (!canDeleteAbstractor) {
      const ShowAlertBox = async () => {
        const choice = await ShowActionPopUp(
          "Alert",
          "This center cannot be deleted as there are abstractors associated to it.",
          "Ok",
          true
        );
      };
      ShowAlertBox()
    } else {
      setShowDeleteConfirm(true);
      setDeleteCenterId(id)
    }
  }
  const HandleDelete = async (id) => {
    try {
      ToggleLoader(true)
      const response = await CenterService.DeleteCenter(id);
      ToggleLoader(false)
      if (response?.status === HTTPResponse.OK) {
        setFilteredCenters((prevState) => prevState?.filter(a => a.centerId !== id));
        setAllCenters((prevState) => prevState?.filter(a => a.centerId !== id));
        ShowToast(Center_Msgs.DELETE_SUCCESS, ToastMessageType.Success);
      }
      else {
        if(response?.status === HTTPResponse.Unauthorized)
          HandleSessionTimeout();
        else
          ShowToast(Center_Msgs.DELETE_FAIL, ToastMessageType.Failed);
      }
    } catch {
      ShowToast(Center_Msgs.DELETE_FAIL, ToastMessageType.Failed);
    }
  }
  const HandleSearch = () => {
    setSearchQuery(searchRef.current.value);
  }



  // GRID
  const columnDefs = [
    {
      headerName: "Center ID",
      field: "centerId",
      width: 155,
      cellRenderer: (params) => <div> {params.data.centerId} </div>,
    },
    {
      headerName: "Center Name",
      field: "name",
      //minWidth: 150,
      flex: 2,
      cellRenderer: (params) => <div> {params.data.name}</div>,
    },

    // EDIT canEdit
    {
      width: 100,
      cellRenderer: (params) => (
        <img
          src="../Assets/images/Icon-awesome-edit.png"
          alt="edit"
          style={iconStyle}
          onClick={() => Edit(params.data)}
          title="Edit Center"
        />
      ),
      cellClass: "td",
    },

    // DELETE   canDelete
    {
      width: 100,
      cellRenderer: (params) => (
        //params.data.canDelete && 
        params.data.centerId !== 1 &&
        <img
          src="../Assets/images/Icon-material-delete.png"
          alt="delete"
          style={{ ...iconStyle, width: "17px" }}
          onClick={() => Delete(params.data.centerId, params.data.canDelete, params.data.canDeleteAbstractor)}
          title="Delete Center"
        />
      ),
      cellClass: "td",
      resizable: true,
    },
  ];
  const defaultColDef = {
    resizable: true,
    sortable: true,
    // flex: 1,
    cellClass: "td no-border",
    //headerClass: 'ag-cell-header'
  };
  // CSS class for highlighting edited and new rows
  const getRowClass = (params) => {
    return params.data.isEdited ? 'edited-row' : '';
  };
  const gridOptions = {
    columnDefs,
    defaultColDef,
    rowHeight: DefaultAGGridProps.ROW_HEIGHT,
    pagination: true,
    paginationPageSize: DefaultAGGridProps.ROWS_PER_PAGE,
    getRowClass
  };
  const agGridLocaleText = {
    noRowsToShow: DefaultAGGridProps.NOT_FOUND,
  };
  const GetTableHeight = () => {
    return 100 + (filteredCenters?.length < 4
      ? 50 * 4
      : filteredCenters?.length < 11
        ? 50 * filteredCenters?.length
        : 50 * 10);
  };

  // CLOSE FORM
  const CloseModal = (center) => {
    setShowCenterModal(false);

    if (center.centerId !== undefined && center.centerId !== 0) {
      let updatedList = [];
      if (center.isNew) {
        //reset isEdited of existing centers to false
        const allCenters1 = allCenters.map((center) => ({
          ...center,
          isEdited: false,
        }));

        updatedList = [...allCenters1, { ...center, isEdited: true, canDelete: true, canDeleteAbstractor: true}]
      }
      else {
        updatedList = allCenters.map((row) => {

          if (row.centerId == center.centerId) {
            row.name = center.name;
            return { ...row, isEdited: true };
          }
          return { ...row, isEdited: false };
        })
          .sort(c => c.centerId)
      }
      setAllCenters(updatedList)
    }
  }
  //DELETE CONFIRM
  const YesClick = (centerId) => {
    HandleDelete(centerId)
    setShowDeleteConfirm(false)

  }
  const NoClick = () => {
    setShowDeleteConfirm(false)
  }
  return (
    <>
      <section className="patient-details-section">
        <PatientDetails />
      </section>
      <section className="main-common-page-section main-common-page-section-min-ht">
        <div className="container">
          <div className="row search-add-btn">
            <div className="col-6 col-sm-5 col-md-5 col-lg-4">
              <div className="searchBar">
                <input
                  className="searchQueryInput-class"
                  id="searchQueryInput"
                  type="text"
                  name="searchQueryInput"
                  placeholder="Center Name"
                  ref={searchRef}
                  onChange={HandleSearch}
                />
                <button
                  id="searchQuerySubmit"
                  type="submit"
                  name="searchQuerySubmit"
                >
                  <img src="../Assets/images/Icon-feather-search.png" width="20px" />
                </button>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4">
              <div className="">
                <button
                  // data-bs-toggle="modal"
                  // data-bs-target="#AddCenterModal"
                  className="btn btn-outline-light btn-lg add-new-patient"
                  type="submit"
                  onClick={AddNew}
                >
                  + Add New Center
                </button>
              </div>
            </div>
          </div>
          <div className="user-tab">
            {loading ? (
              // <Loader/>
              <></>
            )
              :
              (<div
                className="ag-theme-alpine "
                style={{
                  height: filteredCenters?.length === 0 ? 322 : GetTableHeight(), //DefaultAGGridProps.TABLE_HEIGHT,
                  width: "100%",
                }}
              >
                <AgGridReact
                  rowData={filteredCenters}
                  gridOptions={{ ...gridOptions, localeText: agGridLocaleText }}
                  onPaginationChanged={() => {
                    window.scrollTo(0, 0);
                  }}
                />
              </div>
              )
              //   <h2
              //   style={{
              //     height: 322,
              //     display: "flex",
              //     alignItems: "center",
              //     justifyContent: "center",
              //   }}
              // >
              // </h2>
            }
          </div>
        </div>
      </section>




      {showCenterModal && (
        <CenterModal
          pageAction={pageAction}
          center={center}
          CloseModal={CloseModal}
        //centers={centers}
        //
        />
      )}
      {showDeleteConfirm && <ConfirmPopUp id={deleteCenterId} YesClick={YesClick} NoClick={NoClick} />}


    </>


  )
};
