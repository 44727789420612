import React, { useContext, useEffect, useState } from "react";
import { HTTPResponse, SideMenuItems, ToastMessages, ToastMessageType, Values } from "../../Helpers/Enums";
import SideBarMenu from "../../Components/SideBarMenu";
import PatientDetails from "../../Components/PatientInformation";
import SaveAndNavigate from "../../Components/SaveAndNavigate";
import {
    GetLocalStorageData,
    GetLoggedInUserID,
} from "../../Helpers/Utilities";
import { CommonError_Msgs, ClinicalCharacteristics_Msgs } from "../../Helpers/HelperText";
import ErrorField from "../../Components/ErrorField";
import { AppContext } from "../../Contexts/AppContextProvider";
import { ClinicalCharacteristicsService } from "../../WebApiServices/ClinicalCharacteristics.service";


let pid = 0;
const ClinicalCharacteristics = () => {
    const navigatePaths = {
        prevPage: "/Demographics",
        currPage: "/ClinicalCharacteristics",
        nextPage: "/VenetoclaxRamUp",
    };

    const {
        ShowToast,
        ToggleLoader,
        HandleSessionTimeout,
    } = useContext(AppContext);

    const [ptClinicalCharacteristics, setPtClinicalCharacteristics] = useState({
        userId: GetLoggedInUserID(),
        patientWeight: null,
        patientWeight_txt: "",
        performance_ECOG: null,
        a_Rai_Stage: null,
        b_Binet_Stage: null,
        a_IGVH: null,
        b_Immo: null,
        c_BBMarrow: null,
        c_Sub_chk_MYB: false,
        c_Sub_chk_Atm11q: false,
        c_Sub_chk_Trisomy: false,
        c_Sub_chk_LAMP1: false,
        c_Sub_chk_TP53: false,
        c_Sub_chk_CCND1: false,
        c_Sub_chk_Karyotype: false,
        c_Sub_chk_Other: false,
        d_BBMarrow: null,
        d_Sub_chk_Atm: false,
        d_Sub_chk_BIRC3: false,
        d_Sub_chk_BTK: false,
        d_Sub_chk_MYD88: false,
        d_Sub_chk_NOTCH1: false,
        d_Sub_chk_SF3B1: false,
        d_Sub_chk_TP53: false,
        d_Sub_chk_Other: false,
        d_Sub_chk_Other_txt: "",
        d_Sub_Inconclusive: false,
        conditions: false,
        cLL_chk: false,
        sub_chk_Bladder: false,
        sub_chk_Blood: false,
        sub_chk_Brain: false,
        sub_chk_Breast: false,
        sub_chk_Cervical: false,
        sub_chk_Colorectal: false,
        sub_chk_Kidney: false,
        sub_chk_Lung: false,
        sub_chk_Pancreatic: false,
        sub_chk_Prostate: false,
        sub_chk_Skin: false,
        sub_chk_Testicular: false,
        sub_chk_Thyroid: false,
        sub_chk_Uterine: false,
        sub_chk_Other: false,
        cardio_chk: false,
        sub_Cardio_chk_Hypertension: false,
        sub_Cardio_chk_Atrial: false,
        sub_Cardio_chk_Ventricular: false,
        sub_Cardio_chk_CHF: false,
        sub_Cardio_chk_Other: false,
        renal_chk: false,
        hepatic_chk: false,
        respiratory_chk: false,
        endocrine_chk: false,
        psychiatric_chk: false,
        neurologic_chk: false,
        musculoskeletal_chk: false,
        gastrointestinal_chk: false,
        condition_other_chk: false,

    });

    const [showTopErrMsg, setShowTopErrMsg] = useState(false);
    const [weightInvalidErrorMsg, setWeigntInvalidErrorMsg] = useState("");

    const [hardErrList, setHardErrList] = useState({
        q18Empty: false,
        q18WeightEmpty: false,
        q18InvalidRange: false,
    });

    function formatInput(e) {
        const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab", "Enter"];
        const invalidChars = /[^0-9.]/; // Only allow numbers and the decimal point

        if (allowedKeys.includes(e.key)) {
            return;
        }
        if (e.key === "." && e.target.value.includes(".") || invalidChars.test(e.key)) {
            // Prevent multiple decimal points or invalid characters
            e.preventDefault();
        }
    }

    //SYJ 26/09/2024 handle Input Changes
    const HandleFieldChange = (e) => {
        const { name, id, value } = e.target;
        switch (name) {
            case "patientWeight": {
                if (id === "patientWeight_99") {
                    setPtClinicalCharacteristics((prev) => ({
                        ...prev,
                        [name]: parseInt(value, 10),
                        patientWeight_txt: "",
                    }));
                    setHardErrList((prev) => ({
                        ...prev,
                        q18Empty: false,
                        q18WeightEmpty: false,
                        q18InvalidRange: false,
                    }));
                }
                else {
                    setPtClinicalCharacteristics((prev) => ({
                        ...prev,
                        [name]: parseInt(value, 10),
                    }));
                    setHardErrList((prev) => ({
                        ...prev,
                        q18Empty: false,                        
                        q18InvalidRange: false,
                    }));
                }
                break;
            }
            case "patientWeight_txt": {
                const isInvalidRange = parseFloat(value) < 50 || parseFloat(value) > 400;
                setPtClinicalCharacteristics((prev) => ({
                    ...prev,
                    [name]: value,
                    patientWeight: 1,
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q18Empty: false,
                    q18WeightEmpty: false,
                    q18InvalidRange: isInvalidRange
                }));

                if (isInvalidRange) {
                    let msg = ClinicalCharacteristics_Msgs.q18WeightRange.replace('[value]', value);
                    msg = msg.replace('[value units]', 'lbs');

                    setWeigntInvalidErrorMsg(msg);
                } else {
                    setWeigntInvalidErrorMsg("");
                }


                break;
            }

            default:
                break;
        }
    };

    //SYJ 26/09/2024 Validate page before saving
    const ValidateForm = () => {
        let result = true;
        const { patientWeight, patientWeight_txt } = ptClinicalCharacteristics;

        const isWeightNull = patientWeight == null;
        const isWeightEmpty = parseInt(patientWeight, 10) === 1 && (patientWeight_txt === "" || patientWeight_txt === null);

        setHardErrList((prev) => ({
            ...prev,
            q18Empty: isWeightNull,
            q18WeightEmpty: isWeightEmpty,
            // q18InvalidRange: isInvalidRange
        }));

        if (isWeightNull || isWeightEmpty) {
            result = false;
        }

        return result;
    };


    //SYJ 27/09/2024 call this on page load
    useEffect(() => {
        pid = GetLocalStorageData("patientId");
        GetClinicalCharacteristics();
    }, []);


    //SYJ 27/09/2024 get all ClinicalCharacteristics data from oldCore and new CoreVen
    const GetClinicalCharacteristics = async () => {
        try {
            ToggleLoader(true);
            if (pid > 0) {
                const response = await ClinicalCharacteristicsService.GetClinicalCharacteristics(pid);
                ToggleLoader(false);
                if (response?.status === HTTPResponse.OK) {
                    const { data } = response;
                    const patientWeight = getPatientWeight(data);

                    setPtClinicalCharacteristics((prev) => ({
                        ...prev,
                        patientWeight: patientWeight,
                        //patientWeight: data.weightUnk === true ? 99 : data.weight !== "" && data.weight !== null ? 1 : null,
                        patientWeight_txt: data.weight,
                        performance_ECOG: data.ecogScale,
                        a_Rai_Stage: data.raiStage,
                        b_Binet_Stage: data.binetStage,
                        a_IGVH: data.ighvVariableStatus,
                        b_Immo: data.vVariableStatus,
                        c_BBMarrow: data.chromosomeDetected,
                        c_Sub_chk_MYB: data.chromosome6q,
                        c_Sub_chk_Atm11q: data.chromosome11q,
                        c_Sub_chk_Trisomy: data.chromosome12,
                        c_Sub_chk_LAMP1: data.chromosome13q,
                        c_Sub_chk_TP53: data.chromosome17p,
                        c_Sub_chk_CCND1: data.chromosomeTranslocation,
                        c_Sub_chk_Karyotype: data.chromosomeComplex,
                        c_Sub_chk_Other: data.chromosomeOth,
                        d_BBMarrow: data.mutationsDetected,
                        d_Sub_chk_Atm: data.mutationAtm,
                        d_Sub_chk_BIRC3: data.mutationBirc,
                        d_Sub_chk_BTK: data.mutationBtk,
                        d_Sub_chk_MYD88: data.mutationMyd,
                        d_Sub_chk_NOTCH1: data.mutationNotch,
                        d_Sub_chk_SF3B1: data.mutationSf3b1,
                        d_Sub_chk_TP53: data.mutationTp53,
                        d_Sub_chk_Other: data.mutationOth,
                        d_Sub_chk_Other_txt: data.mutationOthTxt,
                        d_Sub_Inconclusive: data.mutationInconclusive,
                        conditions: data.nocomborbidities,
                        cLL_chk: data.activeMalignancy,
                        sub_chk_Bladder: data.maligBladder,
                        sub_chk_Blood: data.maligBlood,
                        sub_chk_Brain: data.maligBrain,
                        sub_chk_Breast: data.maligBreast,
                        sub_chk_Cervical: data.maligCervical,
                        sub_chk_Colorectal: data.maligColorectal,
                        sub_chk_Kidney: data.maligKidney,
                        sub_chk_Lung: data.maligLung,
                        sub_chk_Pancreatic: data.maligPancreatic,
                        sub_chk_Prostate: data.maligProstate,
                        sub_chk_Skin: data.maligSkin,
                        sub_chk_Testicular: data.maligTesticular,
                        sub_chk_Thyroid: data.maligThyroid,
                        sub_chk_Uterine: data.maligUterine,
                        sub_chk_Other: data.maligOth,
                        cardio_chk: data.condCardio,
                        sub_Cardio_chk_Hypertension: data.cardioHypertension,
                        sub_Cardio_chk_Atrial: data.cardioAtrial,
                        sub_Cardio_chk_Ventricular: data.cardioVentricular,
                        sub_Cardio_chk_CHF: data.cardioChf,
                        sub_Cardio_chk_Other: data.cardioOth,
                        renal_chk: data.condRenal,
                        hepatic_chk: data.condHepatic,
                        respiratory_chk: data.condRespiratory,
                        endocrine_chk: data.condEndocrine,
                        psychiatric_chk: data.condPsychiatric,
                        neurologic_chk: data.condNeurologic,
                        musculoskeletal_chk: data.condMusculoskeletal,
                        gastrointestinal_chk: data.condGastrointestinal,
                        condition_other_chk: data.condOth,
                    }));
                }

                if (response.status !== HTTPResponse.OK) {
                    if (response?.status === HTTPResponse.Unauthorized) {
                        HandleSessionTimeout();
                    } else throw response.error;
                }
            }

            ToggleLoader(false);
            return true;
        }
        catch (error) {
            ToggleLoader(false);
            ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
        }


    };

    //SYJ 27/09/2024 Sonarcube fix
    function getPatientWeight(data) {
        if (data.weightUnk === true) {
            return 99;
        }
        if (data.weight !== "" && data.weight !== null) {
            return 1;
        }
        return null;
    }

    //SYJ 27/09/2024 Save ClinicalCharacteristics data to new CoreVen
    const SavePage = async (validate) => {
        try {
            ToggleLoader(true);
            setHardErrList(hardErrList);

            let isValid = validate ? ValidateForm() : true;
            if (isValid) {
                setShowTopErrMsg(false);
                let clinicalCharacteristicsModel = {
                    patientId: pid,
                    weight: parseFloat(ptClinicalCharacteristics.patientWeight_txt),
                    weightUnk: (ptClinicalCharacteristics.patientWeight_txt === null || ptClinicalCharacteristics.patientWeight_txt === "") && ptClinicalCharacteristics.patientWeight !== 1 ? ptClinicalCharacteristics.patientWeight : null,
                    ecogScale: ptClinicalCharacteristics.performance_ECOG,
                    raiStage: ptClinicalCharacteristics.a_Rai_Stage,
                    binetStage: ptClinicalCharacteristics.b_Binet_Stage,
                    ighvVariableStatus: ptClinicalCharacteristics.a_IGVH,
                    vVariableStatus: ptClinicalCharacteristics.b_Immo,
                    chromosomeDetected: ptClinicalCharacteristics.c_BBMarrow,
                    chromosome6q: ptClinicalCharacteristics.c_Sub_chk_MYB,
                    chromosome11q: ptClinicalCharacteristics.c_Sub_chk_Atm11q,
                    chromosome12: ptClinicalCharacteristics.c_Sub_chk_Trisomy,
                    chromosome13q: ptClinicalCharacteristics.c_Sub_chk_LAMP1,
                    chromosome17p: ptClinicalCharacteristics.c_Sub_chk_TP53,
                    chromosomeTranslocation: ptClinicalCharacteristics.c_Sub_chk_CCND1,
                    chromosomeComplex: ptClinicalCharacteristics.c_Sub_chk_Karyotype,
                    chromosomeOth: ptClinicalCharacteristics.c_Sub_chk_Other,

                    // adnormalities_oth_txt: ptClinicalCharacteristics.performance_ECOG,

                    mutationsDetected: ptClinicalCharacteristics.d_BBMarrow,
                    mutationAtm: ptClinicalCharacteristics.d_Sub_chk_Atm,
                    mutationBirc: ptClinicalCharacteristics.d_Sub_chk_BIRC3,
                    mutationBtk: ptClinicalCharacteristics.d_Sub_chk_BTK,
                    mutationMyd: ptClinicalCharacteristics.d_Sub_chk_MYD88,
                    mutationNotch: ptClinicalCharacteristics.d_Sub_chk_NOTCH1,
                    mutationSf3b1: ptClinicalCharacteristics.d_Sub_chk_SF3B1,
                    mutationTp53: ptClinicalCharacteristics.d_Sub_chk_TP53,
                    mutationOth: ptClinicalCharacteristics.d_Sub_chk_Other,
                    mutationOthTxt: ptClinicalCharacteristics.d_Sub_chk_Other_txt,
                    mutationInconclusive: ptClinicalCharacteristics.d_Sub_Inconclusive,

                    nocomborbidities: ptClinicalCharacteristics.conditions,
                    activeMalignancy: ptClinicalCharacteristics.cLL_chk,
                    condCardio: ptClinicalCharacteristics.cardio_chk,
                    condRenal: ptClinicalCharacteristics.renal_chk,
                    condHepatic: ptClinicalCharacteristics.hepatic_chk,
                    condRespiratory: ptClinicalCharacteristics.respiratory_chk,
                    condEndocrine: ptClinicalCharacteristics.endocrine_chk,
                    condPsychiatric: ptClinicalCharacteristics.psychiatric_chk,
                    condNeurologic: ptClinicalCharacteristics.neurologic_chk,
                    condMusculoskeletal: ptClinicalCharacteristics.musculoskeletal_chk,
                    condGastrointestinal: ptClinicalCharacteristics.gastrointestinal_chk,
                    condOth: ptClinicalCharacteristics.condition_other_chk,

                    maligBladder: ptClinicalCharacteristics.sub_chk_Bladder,
                    maligBlood: ptClinicalCharacteristics.sub_chk_Blood,
                    maligBrain: ptClinicalCharacteristics.sub_chk_Brain,
                    maligBreast: ptClinicalCharacteristics.sub_chk_Breast,
                    maligCervical: ptClinicalCharacteristics.sub_chk_Cervical,
                    maligColorectal: ptClinicalCharacteristics.sub_chk_Colorectal,
                    maligKidney: ptClinicalCharacteristics.sub_chk_Kidney,
                    maligLung: ptClinicalCharacteristics.sub_chk_Lung,
                    maligPancreatic: ptClinicalCharacteristics.sub_chk_Pancreatic,
                    maligProstate: ptClinicalCharacteristics.sub_chk_Prostate,
                    maligSkin: ptClinicalCharacteristics.sub_chk_Skin,
                    maligTesticular: ptClinicalCharacteristics.sub_chk_Testicular,
                    maligThyroid: ptClinicalCharacteristics.sub_chk_Thyroid,
                    maligUterine: ptClinicalCharacteristics.sub_chk_Uterine,
                    maligOth: ptClinicalCharacteristics.sub_chk_Other,
                    cardioHypertension: ptClinicalCharacteristics.sub_Cardio_chk_Hypertension,
                    cardioAtrial: ptClinicalCharacteristics.sub_Cardio_chk_Atrial,
                    cardioVentricular: ptClinicalCharacteristics.sub_Cardio_chk_Ventricular,
                    cardioChf: ptClinicalCharacteristics.sub_Cardio_chk_CHF,
                    cardioOth: ptClinicalCharacteristics.sub_Cardio_chk_Other,
                    // createdBy: GetLoggedInUserID(),
                    // editedBy: GetLoggedInUserID(),
                    loggedInUserID: GetLoggedInUserID(),
                };

                if (pid > 0) {
                    const response = await ClinicalCharacteristicsService.SaveClinicalCharacteristics(
                        clinicalCharacteristicsModel
                    );
                    ToggleLoader(false);

                    if (response.status !== HTTPResponse.OK) {
                        if (response?.status === HTTPResponse.Unauthorized) {
                            HandleSessionTimeout();
                        } else throw response.error;
                    } else {
                        setHardErrList((prev) => ({
                            ...prev,
                            q18Empty: false,
                            q18WeightEmpty: false,
                            q18InvalidRange: false
                        }));
                        ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
                    }
                }
                return true;
            }
            else {
                ToggleLoader(false);
                setShowTopErrMsg(true);
                return false;
            }
        }
        catch (error) {
            ToggleLoader(false);
            ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed);
        }
    };

    return (
        <>
            <section className="survey-patient-details-section">
                <div className="row">
                    <PatientDetails />
                    <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
                        <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
                    </div>
                </div>
            </section>

            <section className="main-survey-section">
                <div className="row">
                    <SideBarMenu activePageId={SideMenuItems.ClinicalCharacteristics} />

                    <div className="col-md-9 col-pad-left">
                        <div className="survey-section-content">
                            <div className="survey-section-content-header">
                                <h4>PATIENT CLINICAL CHARACTERISTICS</h4>
                            </div>
                            {showTopErrMsg && <div className="clean-error ">
                                <ErrorField show={showTopErrMsg} message={CommonError_Msgs.Hard_Empty} />
                            </div>}
                            <div className="survey-question-section">
                                <div className="survey-question-content">
                                    <div className="question-answer">
                                        <div className="question-bot-sapce single-dig-quest">

                                            <div className="question-bot-sapce">
                                                <div className="question question-weight">
                                                    <span>18.</span>
                                                    <span className="quest-text-pad">
                                                        What is the patient's weight?
                                                    </span>
                                                </div>
                                                <div className="double-dig-answer">
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="patientWeight_1"
                                                                name="patientWeight"
                                                                value={1}
                                                                checked={
                                                                    ptClinicalCharacteristics.patientWeight === Values.value1
                                                                }
                                                                onChange={HandleFieldChange}
                                                            />
                                                            <input
                                                                className="input-dash input_custome-space_sm"
                                                                name="patientWeight_txt"
                                                                type="text"
                                                                maxLength={6}
                                                                min={50}
                                                                max={400}
                                                                value={ptClinicalCharacteristics.patientWeight_txt}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    const regex = /^\d{0,4}(\.\d?)?$/;
                                                                    if (regex.test(value)) {
                                                                        HandleFieldChange(e);
                                                                    }
                                                                }}
                                                                onKeyDown={formatInput}
                                                            />
                                                            <span className="radio-text-padding">
                                                                lbs{" "}
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <label className="option-fbox custom-spacing-lbl">
                                                            <input
                                                                type="radio"
                                                                id="patientWeight_99"
                                                                name="patientWeight"
                                                                value={99}
                                                                checked={
                                                                    ptClinicalCharacteristics.patientWeight === Values.value99
                                                                }
                                                                onChange={HandleFieldChange}
                                                            />
                                                            <span>
                                                                Unknown{" "}
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <ErrorField show={hardErrList.q18Empty} message={ClinicalCharacteristics_Msgs.weightRequired} />
                                                <ErrorField show={hardErrList.q18WeightEmpty} message={ClinicalCharacteristics_Msgs.q18WeightRangeEmpty} />
                                                <ErrorField show={hardErrList.q18InvalidRange} message={weightInvalidErrorMsg} />
                                            </div>

                                            <div className="readonly-div">
                                                <div className="question question-weight">
                                                    <span>19.</span>
                                                    <span className="quest-text-pad">
                                                        Please provide your best estimate of the patient’s performance status at the time of initiation of VO therapy, based on the ECOG (Eastern Cooperative Oncology Group) scale.  <i>Please note that this response has been pre-populated from the CORE database. If this response is inconsistent with the patient chart, please return to the CORE database to make the correction.</i>
                                                    </span>
                                                </div>
                                                <div className="double-dig-answer">
                                                    <div className="option-without-input">
                                                        <input
                                                            type="radio"
                                                            id="performance_ECOG_1"
                                                            name="performance_ECOG"
                                                            value={1}
                                                            checked={ptClinicalCharacteristics.performance_ECOG === Values.value1}
                                                            //onChange={HandleFieldChange}
                                                            readOnly
                                                        />
                                                        <label htmlFor="performance_ECOG_1">
                                                            <span className="radio-text-padding">Grade 0</span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <input
                                                            type="radio"
                                                            id="performance_ECOG_2"
                                                            name="performance_ECOG"
                                                            checked={ptClinicalCharacteristics.performance_ECOG === Values.value2}
                                                            value={2}
                                                            readOnly
                                                        //onChange={HandleFieldChange}
                                                        />
                                                        <label htmlFor="performance_ECOG_2">
                                                            <span className="radio-text-padding">Grade 1</span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <input
                                                            type="radio"
                                                            id="performance_ECOG_3"
                                                            name="performance_ECOG"
                                                            checked={ptClinicalCharacteristics.performance_ECOG === Values.value3}
                                                            value={3}
                                                            readOnly
                                                        //onChange={HandleFieldChange}
                                                        />
                                                        <label htmlFor="performance_ECOG_3">
                                                            <span className="radio-text-padding">Grade 2</span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <input
                                                            type="radio"
                                                            id="performance_ECOG_4"
                                                            name="performance_ECOG"
                                                            checked={ptClinicalCharacteristics.performance_ECOG === Values.value4}
                                                            value={4}
                                                            readOnly
                                                        //onChange={HandleFieldChange}
                                                        />
                                                        <label htmlFor="performance_ECOG_4">
                                                            <span className="radio-text-padding">Grade 3</span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <input
                                                            type="radio"
                                                            id="performance_ECOG_5"
                                                            name="performance_ECOG"
                                                            checked={ptClinicalCharacteristics.performance_ECOG === Values.value5}
                                                            value={5}
                                                            readOnly
                                                        //onChange={HandleFieldChange}
                                                        />
                                                        <label htmlFor="performance_ECOG_5">
                                                            <span className="radio-text-padding">Grade 4</span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <input
                                                            type="radio"
                                                            id="performance_ECOG_6"
                                                            name="performance_ECOG"
                                                            checked={ptClinicalCharacteristics.performance_ECOG === Values.value6}
                                                            value={6}
                                                            readOnly
                                                        //onChange={HandleFieldChange}
                                                        />
                                                        <label htmlFor="performance_ECOG_6">
                                                            <span className="radio-text-padding">Not measured based on the ECOG scale</span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <input
                                                            type="radio"
                                                            id="performance_ECOG_99"
                                                            name="performance_ECOG"
                                                            checked={ptClinicalCharacteristics.performance_ECOG === Values.value99}
                                                            value={99}
                                                            readOnly
                                                        //onChange={HandleFieldChange}
                                                        />
                                                        <label htmlFor="performance_ECOG_99">
                                                            <span className="radio-text-padding">Unknown</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="readonly-div">
                                                <div className="question question-weight">
                                                    <span>20.</span>
                                                    <span className="quest-text-pad">
                                                        What was the clinical stage of the patient’s CLL/SLL disease at the time of initiation of VO therapy?
                                                    </span>
                                                </div>
                                                <br />
                                                <div className="question question-weight question-19-a-custom">
                                                    <span>a.</span>
                                                    <span className="quest-text-pad">
                                                        Based on the Rai staging system? <i>Please note that this response has been pre-populated from the CORE database. If this response is inconsistent with the patient chart, please return to the CORE database to make the correction.</i>
                                                    </span>
                                                </div>

                                                <div className="answer-pad-left question-19-a-sub-option-custom">
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="a_Rai_Stage_1"
                                                                name="a_Rai_Stage"
                                                                value={1}
                                                                checked={ptClinicalCharacteristics.a_Rai_Stage === Values.value1}
                                                                //onChange={HandleFieldChange}
                                                                readOnly
                                                            />
                                                            <span className="radio-text-padding">{"Stage 0 (Low risk, Lymphocytosis, lymphocytes in blood > 5 X109/L clonal B-cells and > 40% lymphocytes in the bone marrow)"}</span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="a_Rai_Stage_2"
                                                                name="a_Rai_Stage"
                                                                value={2}
                                                                checked={ptClinicalCharacteristics.a_Rai_Stage === Values.value2}
                                                                //onChange={HandleFieldChange}
                                                                readOnly
                                                            />
                                                            <span className="radio-text-padding">{"Stage I (Intermediate risk, Stage 0 with enlarged node[s] )"}</span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="a_Rai_Stage_3"
                                                                name="a_Rai_Stage"
                                                                value={3}
                                                                checked={ptClinicalCharacteristics.a_Rai_Stage === Values.value3}
                                                                //onChange={HandleFieldChange}
                                                                readOnly
                                                            />
                                                            <span className="radio-text-padding">{"Stage II (Intermediate risk, Stage 0-1 with splenomegaly, hepatomegaly, or both)"}</span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="a_Rai_Stage_4"
                                                                name="a_Rai_Stage"
                                                                value={4}
                                                                checked={ptClinicalCharacteristics.a_Rai_Stage === Values.value4}
                                                                //onChange={HandleFieldChange}
                                                                readOnly
                                                            />
                                                            <span className="radio-text-padding">{"Stage III (High risk, Stage 0-II with hemoglobin < 11.0 g/Dl or hematocrit < 33%)"}</span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="a_Rai_Stage_5"
                                                                name="a_Rai_Stage"
                                                                value={5}
                                                                checked={ptClinicalCharacteristics.a_Rai_Stage === Values.value5}
                                                                //onChange={HandleFieldChange}
                                                                readOnly
                                                            />
                                                            <span className="radio-text-padding">{"Stage IV (high risk, Stage 0-III with platelets < 100,000/mcL)"}</span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="a_Rai_Stage_6"
                                                                name="a_Rai_Stage"
                                                                value={6}
                                                                checked={ptClinicalCharacteristics.a_Rai_Stage === Values.value6}
                                                                //onChange={HandleFieldChange}
                                                                readOnly
                                                            />
                                                            <span className="radio-text-padding">{"Not measured based on the Rai staging system"}</span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="a_Rai_Stage_99"
                                                                name="a_Rai_Stage"
                                                                value={99}
                                                                checked={ptClinicalCharacteristics.a_Rai_Stage === Values.value99}
                                                                //onChange={HandleFieldChange}
                                                                readOnly
                                                            />
                                                            <span className="radio-text-padding">{"Unknown"}</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <br />
                                                <div className="question question-weight question-19-a-custom">
                                                    <span>b.</span>
                                                    <span className="quest-text-pad">
                                                        Based on the Binet staging system? <i> Please note that this response has been pre-populated from the CORE database. If this response is inconsistent with the patient chart, please return to the CORE database to make the correction.</i>
                                                    </span>
                                                </div>
                                                <div className="answer-pad-left question-19-a-sub-option-custom">
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="b_Rai_Stage_1"
                                                                name="b_Binet_Stage"
                                                                value={1}
                                                                checked={ptClinicalCharacteristics.b_Binet_Stage === Values.value1}
                                                                //onChange={HandleFieldChange}
                                                                readOnly
                                                            />
                                                            <span className="radio-text-padding">{"Stage A (Hemoglobin ≥10 g/dL and platelets ≥100,000/mm3 and < 3 enlarged areas)"}</span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="b_Rai_Stage_2"
                                                                name="b_Binet_Stage"
                                                                value={2}
                                                                checked={ptClinicalCharacteristics.b_Binet_Stage === Values.value2}
                                                                //onChange={HandleFieldChange}
                                                                readOnly
                                                            />
                                                            <span className="radio-text-padding">{"Stage B (Hemoglobin ≥10 g/dL and platelets ≥100,000/mm3 and ≥ 3 enlarged areas)"}</span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="b_Rai_Stage_3"
                                                                name="b_Binet_Stage"
                                                                value={3}
                                                                checked={ptClinicalCharacteristics.b_Binet_Stage === Values.value3}
                                                                //onChange={HandleFieldChange}
                                                                readOnly
                                                            />
                                                            <span className="radio-text-padding">{"Stage C (Hemoglobin < 10 g/dL and/or platelets < 100,000/mm3 and any number of enlarged areas)"}</span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="b_Rai_Stage_4"
                                                                name="b_Binet_Stage"
                                                                value={4}
                                                                checked={ptClinicalCharacteristics.b_Binet_Stage === Values.value4}
                                                                //onChange={HandleFieldChange}
                                                                readOnly
                                                            />
                                                            <span className="radio-text-padding">{"Not measured based on the Binet staging system"}</span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="b_Rai_Stage_99"
                                                                name="b_Binet_Stage"
                                                                value={99}
                                                                checked={ptClinicalCharacteristics.b_Binet_Stage === Values.value99}
                                                                //onChange={HandleFieldChange}
                                                                readOnly
                                                            />
                                                            <span className="radio-text-padding">{"Unknown"}</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="readonly-div">
                                                <div className="question question-weight">
                                                    <span>21.</span>
                                                    <span className="quest-text-pad">
                                                        {/*  SYJ- https://ideawharf.sifterapp.com/issues/12490 ->  Please complete the table  */}                                                        
                                                        Please respond to the questions below for information regarding chromosome abnormalities and genetic mutations just before or at the time of initiation of VO therapy.
                                                    </span>
                                                </div>
                                                <br />
                                                <div className="question question-weight question-19-a-custom">
                                                    <span>a.</span>
                                                    <span className="quest-text-pad">
                                                        What was the immunoglobulin heavy-chain variable (IGHV) region mutation status? <i>Please note that this response has been pre-populated from the CORE database. If this response is inconsistent with the patient chart, please return to the CORE database to make the correction. </i>
                                                    </span>
                                                </div>

                                                <div className="answer-pad-left question-19-a-sub-option-custom">
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="a_IGVH_1"
                                                                name="a_IGVH"
                                                                value={1}
                                                                checked={ptClinicalCharacteristics.a_IGVH === Values.value1}
                                                                //onChange={HandleFieldChange}
                                                                readOnly
                                                            />
                                                            <span className="radio-text-padding">{"Mutated (M-CLL)"}</span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="a_IGVH_2"
                                                                name="a_IGVH"
                                                                value={2}
                                                                checked={ptClinicalCharacteristics.a_IGVH === Values.value2}
                                                                //onChange={HandleFieldChange}
                                                                readOnly
                                                            />
                                                            <span className="radio-text-padding">{"Unmutated (U-CLL)"}</span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="a_IGVH_3"
                                                                name="a_IGVH"
                                                                value={3}
                                                                checked={ptClinicalCharacteristics.a_IGVH === Values.value3}
                                                                //onChange={HandleFieldChange}
                                                                readOnly
                                                            />
                                                            <span className="radio-text-padding">{"IGHV mutation test was not performed"}</span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="a_IGVH_99"
                                                                name="a_IGVH"
                                                                value={99}
                                                                checked={ptClinicalCharacteristics.a_IGVH === Values.value99}
                                                                //onChange={HandleFieldChange}
                                                                readOnly
                                                            />
                                                            <span className="radio-text-padding">{"Unknown"}</span>
                                                        </label>
                                                    </div>
                                                </div>

                                                {/*SYJ- https://ideawharf.sifterapp.com/issues/12500 */}
                                                 {ptClinicalCharacteristics.a_IGVH !== 3 && ptClinicalCharacteristics.a_IGVH !== Values.value99 && <><br /> 
                                                <div className="question question-weight question-19-a-custom">
                                                    <span>b.</span>
                                                    <span className="quest-text-pad">
                                                        What was the variable (V) immunoglobulin gene identified? <i> Please note that this response has been pre-populated from the CORE database. If this response is inconsistent with the patient chart, please return to the CORE database to make the correction. </i>
                                                    </span>
                                                </div>


                                                <div className="answer-pad-left question-19-a-sub-option-custom">
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="b_Immo_1"
                                                                name="b_Immo"
                                                                value={1}
                                                                checked={ptClinicalCharacteristics.b_Immo === Values.value1}
                                                                //onChange={HandleFieldChange}
                                                                readOnly
                                                            />
                                                            <span className="radio-text-padding">Clan I gene (IGHV<b>1-2</b>, IGHV<b>1-3</b>, IGHV<b>1-8</b>, IGHV<b>1-18</b>, IGHV<b>5-10-1</b>, IGHV<b>5-51</b>, IGHV<b>7-4-1</b>)</span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="b_Immo_2"
                                                                name="b_Immo"
                                                                value={2}
                                                                checked={ptClinicalCharacteristics.b_Immo === Values.value2}
                                                                //onChange={HandleFieldChange}
                                                                readOnly
                                                            />
                                                            <span className="radio-text-padding">IGHV<b>1-69</b></span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="b_Immo_3"
                                                                name="b_Immo"
                                                                value={3}
                                                                checked={ptClinicalCharacteristics.b_Immo === Values.value3}
                                                                //onChange={HandleFieldChange}
                                                                readOnly
                                                            />
                                                            <span className="radio-text-padding">IGHV<b>3-21</b></span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="b_Immo_4"
                                                                name="b_Immo"
                                                                value={4}
                                                                checked={ptClinicalCharacteristics.b_Immo === Values.value4}
                                                                //onChange={HandleFieldChange}
                                                                readOnly
                                                            />
                                                            <span className="radio-text-padding">IGHV<b>4-34</b></span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="b_Immo_5"
                                                                name="b_Immo"
                                                                value={5}
                                                                checked={ptClinicalCharacteristics.b_Immo === Values.value5}
                                                                //onChange={HandleFieldChange}
                                                                readOnly
                                                            />
                                                            <span className="radio-text-padding">IGHV<b>4-39</b></span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="b_Immo_6"
                                                                name="b_Immo"
                                                                value={6}
                                                                checked={ptClinicalCharacteristics.b_Immo === Values.value6}
                                                                //onChange={HandleFieldChange}
                                                                readOnly
                                                            />
                                                            <span className="radio-text-padding">Other</span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="b_Immo_99"
                                                                name="b_Immo"
                                                                value={99}
                                                                checked={ptClinicalCharacteristics.b_Immo === Values.value99}
                                                                //onChange={HandleFieldChange}
                                                                readOnly
                                                            />
                                                            <span className="radio-text-padding">Unknown</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                 </>} 
                                                <br />

                                                <div className="question question-weight question-19-a-custom">
                                                    <span>c.</span>
                                                    <span className="quest-text-pad">
                                                        Please select all chromosome abnormalities detected in the patient’s blood or bone marrow. <i> Please note that this response has been pre-populated from the CORE database. If this response is inconsistent with the patient chart, please return to the CORE database to make the correction.</i>
                                                    </span>
                                                </div>
                                                <div className="answer-pad-left question-19-a-sub-option-custom">
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="c_BBMarrow_1"
                                                                name="c_BBMarrow"
                                                                value={1}
                                                                checked={ptClinicalCharacteristics.c_BBMarrow === Values.value1}
                                                                //onChange={HandleFieldChange}
                                                                readOnly
                                                            />
                                                            <span className="radio-text-padding">Testing was performed and the following chromosome abnormalities were detected</span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input question-20-c-sub-option-custom">
                                                        <div className="option-without-input">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    id="c_Sub_chk_MYB"
                                                                    name="c_Sub_chk_MYB"
                                                                    checked={ptClinicalCharacteristics.c_Sub_chk_MYB}
                                                                    //onChange={HandleFieldChange}
                                                                    readOnly
                                                                />
                                                                <span className="radio-text-padding">6q- (6q deletion) or MYB mutation IGHV3-21</span>
                                                            </label>
                                                        </div>
                                                        <div className="option-without-input">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    id="c_Sub_chk_Atm11q"
                                                                    name="c_Sub_chk_Atm11q"
                                                                    checked={ptClinicalCharacteristics.c_Sub_chk_Atm11q}
                                                                    //onChange={HandleFieldChange}
                                                                    readOnly
                                                                />
                                                                <span className="radio-text-padding">11q- (11q deletion) or ATM mutation</span>
                                                            </label>
                                                        </div>
                                                        <div className="option-without-input">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    id="c_Sub_chk_Trisomy"
                                                                    name="c_Sub_chk_Trisomy"
                                                                    checked={ptClinicalCharacteristics.c_Sub_chk_Trisomy}
                                                                    //onChange={HandleFieldChange}
                                                                    readOnly
                                                                />
                                                                <span className="radio-text-padding">+12 (trisomy 12)</span>
                                                            </label>
                                                        </div>
                                                        <div className="option-without-input">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    id="c_Sub_chk_LAMP1"
                                                                    name="c_Sub_chk_LAMP1"
                                                                    checked={ptClinicalCharacteristics.c_Sub_chk_LAMP1}
                                                                    //onChange={HandleFieldChange}
                                                                    readOnly
                                                                />
                                                                <span className="radio-text-padding">13q- (13q deletion) or LAMP1 mutation</span>
                                                            </label>
                                                        </div>
                                                        <div className="option-without-input">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    id="c_Sub_chk_TP53"
                                                                    name="c_Sub_chk_TP53"
                                                                    checked={ptClinicalCharacteristics.c_Sub_chk_TP53}
                                                                    //onChange={HandleFieldChange}
                                                                    readOnly
                                                                />
                                                                <span className="radio-text-padding">17p- (17p deletion) or TP53 mutation</span>
                                                            </label>
                                                        </div>
                                                        <div className="option-without-input">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    id="c_Sub_chk_CCND1"
                                                                    name="c_Sub_chk_CCND1"
                                                                    checked={ptClinicalCharacteristics.c_Sub_chk_CCND1}
                                                                    //onChange={HandleFieldChange}
                                                                    readOnly
                                                                />
                                                                <span className="radio-text-padding">Translocation t(11;14) or CCND1/IGH rearrangement</span>
                                                            </label>
                                                        </div>
                                                        <div className="option-without-input">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    id="c_Sub_chk_Karyotype"
                                                                    name="c_Sub_chk_Karyotype"
                                                                    checked={ptClinicalCharacteristics.c_Sub_chk_Karyotype}
                                                                    //onChange={HandleFieldChange}
                                                                    readOnly
                                                                />
                                                                <span className="radio-text-padding">Complex karyotype (defined as three or more abnormalities present)</span>
                                                            </label>
                                                        </div>
                                                        <div className="option-without-input">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    id="c_Sub_chk_Other"
                                                                    name="c_Sub_chk_Other"
                                                                    checked={ptClinicalCharacteristics.c_Sub_chk_Other}
                                                                    //onChange={HandleFieldChange}
                                                                    readOnly
                                                                />
                                                                <span className="radio-text-padding">Other</span>
                                                            </label>

                                                        </div>
                                                    </div>

                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="c_BBMarrow_2"
                                                                name="c_BBMarrow"
                                                                value={2}
                                                                checked={ptClinicalCharacteristics.c_BBMarrow === Values.value2}
                                                                //onChange={HandleFieldChange}
                                                                readOnly
                                                            />
                                                            <span className="radio-text-padding">No chromosome abnormalities detected</span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="c_BBMarrow_3"
                                                                name="c_BBMarrow"
                                                                value={3}
                                                                checked={ptClinicalCharacteristics.c_BBMarrow === Values.value3}
                                                                //onChange={HandleFieldChange}
                                                                readOnly
                                                            />
                                                            <span className="radio-text-padding">Chromosome abnormality testing was NOT performed</span>
                                                        </label>
                                                    </div>
                                                </div>

                                                <br />
                                                <div className="question question-weight question-19-a-custom">
                                                    <span>d.</span>
                                                    <span className="quest-text-pad">
                                                        Please select all genetic mutations detected in the patient's blood or bone marrow. <i>Please note that this response has been pre-populated from the CORE database. If this response is inconsistent with the patient chart, please return to the CORE database to make the correction.</i>
                                                    </span>
                                                </div>
                                                <div className="answer-pad-left question-19-a-sub-option-custom">
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="d_BBMarrow_1"
                                                                name="d_BBMarrow"
                                                                value={1}
                                                                checked={ptClinicalCharacteristics.d_BBMarrow === Values.value1}
                                                                //onChange={HandleFieldChange}
                                                                readOnly
                                                            />
                                                            <span className="radio-text-padding">Testing was performed and the following genetic mutations were detected</span>
                                                        </label>
                                                    </div>

                                                    <div className="option-without-input question-20-c-sub-option-custom">

                                                        <div className="option-without-input">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    id="d_Sub_chk_Atm"
                                                                    name="d_Sub_chk_Atm"
                                                                    checked={ptClinicalCharacteristics.d_Sub_chk_Atm}
                                                                    //onChange={HandleFieldChange}
                                                                    readOnly
                                                                />
                                                                <span className="radio-text-padding">ATM</span>
                                                            </label>
                                                        </div>
                                                        <div className="option-without-input">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    id="d_Sub_chk_BIRC3"
                                                                    name="d_Sub_chk_BIRC3"
                                                                    checked={ptClinicalCharacteristics.d_Sub_chk_BIRC3}
                                                                    //onChange={HandleFieldChange}
                                                                    readOnly
                                                                />
                                                                <span className="radio-text-padding">BIRC3</span>
                                                            </label>
                                                        </div>
                                                        <div className="option-without-input">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    id="d_Sub_chk_BTK"
                                                                    name="d_Sub_chk_BTK"
                                                                    checked={ptClinicalCharacteristics.d_Sub_chk_BTK}
                                                                    //onChange={HandleFieldChange}
                                                                    readOnly
                                                                />
                                                                <span className="radio-text-padding">BTK/PLCG2 (ibrutinib resistance)</span>
                                                            </label>
                                                        </div>
                                                        <div className="option-without-input">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    id="d_Sub_chk_MYD88"
                                                                    name="d_Sub_chk_MYD88"
                                                                    checked={ptClinicalCharacteristics.d_Sub_chk_MYD88}
                                                                    //onChange={HandleFieldChange}
                                                                    readOnly
                                                                />
                                                                <span className="radio-text-padding">MYD88</span>
                                                            </label>
                                                        </div>
                                                        <div className="option-without-input">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    id="d_Sub_chk_NOTCH1"
                                                                    name="d_Sub_chk_NOTCH1"
                                                                    checked={ptClinicalCharacteristics.d_Sub_chk_NOTCH1}
                                                                    //onChange={HandleFieldChange}
                                                                    readOnly
                                                                />
                                                                <span className="radio-text-padding">NOTCH1</span>
                                                            </label>
                                                        </div>
                                                        <div className="option-without-input">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    id="d_Sub_chk_SF3B1"
                                                                    name="d_Sub_chk_SF3B1"
                                                                    checked={ptClinicalCharacteristics.d_Sub_chk_SF3B1}
                                                                    //onChange={HandleFieldChange}
                                                                    readOnly
                                                                />
                                                                <span className="radio-text-padding">SF3B1</span>
                                                            </label>
                                                        </div>
                                                        <div className="option-without-input">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    id="d_Sub_chk_TP53"
                                                                    name="d_Sub_chk_TP53"
                                                                    checked={ptClinicalCharacteristics.d_Sub_chk_TP53}
                                                                    //onChange={HandleFieldChange}
                                                                    readOnly
                                                                />
                                                                <span className="radio-text-padding">TP53</span>
                                                            </label>
                                                        </div>
                                                        <div className="option-without-input">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    id="d_Sub_chk_Other"
                                                                    name="d_Sub_chk_Other"
                                                                    checked={ptClinicalCharacteristics.d_Sub_chk_Other}
                                                                    //onChange={HandleFieldChange}
                                                                    readOnly
                                                                />
                                                                <span className="radio-text-padding">Other, specify
                                                                    <input
                                                                        className="input-dash input_custome-space"
                                                                        name="d_Sub_chk_Other_txt"
                                                                        type="text"
                                                                        maxLength={100}
                                                                        value={ptClinicalCharacteristics.d_Sub_chk_Other_txt}
                                                                        //onChange={HandleFieldChange}
                                                                        readOnly
                                                                    />
                                                                </span>
                                                            </label>
                                                        </div>
                                                        <div className="option-without-input">
                                                            <label>
                                                                <input
                                                                    type="radio"
                                                                    id="d_BBMarrow_1"
                                                                    name="d_Sub_Inconclusive"
                                                                    // value={1}
                                                                    checked={ptClinicalCharacteristics.d_Sub_Inconclusive}
                                                                    //onChange={HandleFieldChange}
                                                                    readOnly
                                                                />
                                                                <span className="radio-text-padding">Inconclusive</span>
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="d_BBMarrow_2"
                                                                name="d_BBMarrow"
                                                                value={3}
                                                                checked={ptClinicalCharacteristics.d_BBMarrow === Values.value2}
                                                                //onChange={HandleFieldChange}
                                                                readOnly
                                                            />
                                                            <span className="radio-text-padding">No genetic mutations detected</span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="d_BBMarrow_3"
                                                                name="d_BBMarrow"
                                                                value={4}
                                                                checked={ptClinicalCharacteristics.d_BBMarrow === Values.value3}
                                                                //onChange={HandleFieldChange}
                                                                readOnly
                                                            />
                                                            <span className="radio-text-padding">Genetic mutations testing was NOT performed</span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="d_BBMarrow_99"
                                                                name="d_BBMarrow"
                                                                value={99}
                                                                checked={ptClinicalCharacteristics.d_BBMarrow === Values.value99}
                                                                //onChange={HandleFieldChange}
                                                                readOnly
                                                            />
                                                            <span className="radio-text-padding">Unknown</span>
                                                        </label>
                                                    </div>


                                                </div>
                                            </div>

                                            <div className="readonly-div">
                                                <div className="question question-weight">
                                                    <span>22.</span>
                                                    <span className="quest-text-pad">
                                                        Did the patient have any of the following conditions at the time of initiation of VO therapy? <i>Please note that this response has been pre-populated from the CORE database. If this response is inconsistent with the patient chart, please return to the CORE database to make the correction.</i>
                                                    </span>
                                                </div>
                                                <div className="double-dig-answer">
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="Conditions_1"
                                                                name="conditions"
                                                                // value={1}
                                                                checked={ptClinicalCharacteristics.conditions}
                                                                //onChange={HandleFieldChange}
                                                                readOnly
                                                            />
                                                            <span className="radio-text-padding">No comorbidities</span>
                                                        </label>
                                                    </div>

                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                id="cLL_chk"
                                                                name="cLL_chk"
                                                                checked={ptClinicalCharacteristics.cLL_chk}
                                                                //onChange={HandleFieldChange}
                                                                readOnly
                                                            />
                                                            <span className="radio-text-padding">Active malignancy other than CLL/SLL</span>
                                                        </label>
                                                    </div>

                                                    <div className="option-without-input question-20-c-sub-option-custom">
                                                        <div className="option-without-input">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    id="sub_chk_Bladder"
                                                                    name="sub_chk_Bladder"
                                                                    checked={ptClinicalCharacteristics.sub_chk_Bladder}
                                                                    //onChange={HandleFieldChange}
                                                                    readOnly
                                                                />
                                                                <span className="radio-text-padding">Bladder</span>
                                                            </label>
                                                        </div>
                                                        <div className="option-without-input">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    id="sub_chk_Blood"
                                                                    name="sub_chk_Blood"
                                                                    checked={ptClinicalCharacteristics.sub_chk_Blood}
                                                                    //onChange={HandleFieldChange}
                                                                    readOnly
                                                                />
                                                                <span className="radio-text-padding">Blood</span>
                                                            </label>
                                                        </div>
                                                        <div className="option-without-input">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    id="sub_chk_Brain"
                                                                    name="sub_chk_Brain"
                                                                    checked={ptClinicalCharacteristics.sub_chk_Brain}
                                                                    //onChange={HandleFieldChange}
                                                                    readOnly
                                                                />
                                                                <span className="radio-text-padding">Brain and spinal cord</span>
                                                            </label>
                                                        </div>
                                                        <div className="option-without-input">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    id="sub_chk_Breast"
                                                                    name="sub_chk_Breast"
                                                                    checked={ptClinicalCharacteristics.sub_chk_Breast}
                                                                    //onChange={HandleFieldChange}
                                                                    readOnly
                                                                />
                                                                <span className="radio-text-padding">Breast</span>
                                                            </label>
                                                        </div>
                                                        <div className="option-without-input">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    id="sub_chk_Cervical"
                                                                    name="sub_chk_Cervical"
                                                                    checked={ptClinicalCharacteristics.sub_chk_Cervical}
                                                                    //onChange={HandleFieldChange}
                                                                    readOnly
                                                                />
                                                                <span className="radio-text-padding">Cervical</span>
                                                            </label>
                                                        </div>
                                                        <div className="option-without-input">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    id="sub_chk_Colorectal"
                                                                    name="sub_chk_Colorectal"
                                                                    checked={ptClinicalCharacteristics.sub_chk_Colorectal}
                                                                    //onChange={HandleFieldChange}
                                                                    readOnly
                                                                />
                                                                <span className="radio-text-padding">Colorectal</span>
                                                            </label>
                                                        </div>
                                                        <div className="option-without-input">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    id="sub_chk_Kidney"
                                                                    name="sub_chk_Kidney"
                                                                    checked={ptClinicalCharacteristics.sub_chk_Kidney}
                                                                    //onChange={HandleFieldChange}
                                                                    readOnly
                                                                />
                                                                <span className="radio-text-padding">Kidney</span>
                                                            </label>
                                                        </div>
                                                        <div className="option-without-input">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    id="sub_chk_Lung"
                                                                    name="sub_chk_Lung"
                                                                    checked={ptClinicalCharacteristics.sub_chk_Lung}
                                                                    //onChange={HandleFieldChange}
                                                                    readOnly
                                                                />
                                                                <span className="radio-text-padding">Lung</span>
                                                            </label>

                                                        </div>
                                                        <div className="option-without-input">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    id="sub_chk_Pancreatic"
                                                                    name="sub_chk_Pancreatic"
                                                                    checked={ptClinicalCharacteristics.sub_chk_Pancreatic}
                                                                    //onChange={HandleFieldChange}
                                                                    readOnly
                                                                />
                                                                <span className="radio-text-padding">Pancreatic</span>
                                                            </label>

                                                        </div>
                                                        <div className="option-without-input">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    id="sub_chk_Prostate"
                                                                    name="sub_chk_Prostate"
                                                                    checked={ptClinicalCharacteristics.sub_chk_Prostate}
                                                                    //onChange={HandleFieldChange}
                                                                    readOnly
                                                                />
                                                                <span className="radio-text-padding">Prostate</span>
                                                            </label>

                                                        </div>
                                                        <div className="option-without-input">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    id="sub_chk_Skin"
                                                                    name="sub_chk_Skin"
                                                                    checked={ptClinicalCharacteristics.sub_chk_Skin}
                                                                    //onChange={HandleFieldChange}
                                                                    readOnly
                                                                />
                                                                <span className="radio-text-padding">Skin</span>
                                                            </label>

                                                        </div>
                                                        <div className="option-without-input">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    id="sub_chk_Testicular"
                                                                    name="sub_chk_Testicular"
                                                                    checked={ptClinicalCharacteristics.sub_chk_Testicular}
                                                                    //onChange={HandleFieldChange}
                                                                    readOnly
                                                                />
                                                                <span className="radio-text-padding">Testicular</span>
                                                            </label>

                                                        </div>
                                                        <div className="option-without-input">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    id="sub_chk_Thyroid"
                                                                    name="sub_chk_Thyroid"
                                                                    checked={ptClinicalCharacteristics.sub_chk_Thyroid}
                                                                    //onChange={HandleFieldChange}
                                                                    readOnly
                                                                />
                                                                <span className="radio-text-padding">Thyroid</span>
                                                            </label>

                                                        </div>
                                                        <div className="option-without-input">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    id="sub_chk_Uterine"
                                                                    name="sub_chk_Uterine"
                                                                    checked={ptClinicalCharacteristics.sub_chk_Uterine}
                                                                    //onChange={HandleFieldChange}
                                                                    readOnly
                                                                />
                                                                <span className="radio-text-padding">Uterine</span>
                                                            </label>

                                                        </div>
                                                        <div className="option-without-input">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    id="sub_chk_Other"
                                                                    name="sub_chk_Other"
                                                                    checked={ptClinicalCharacteristics.sub_chk_Other}
                                                                    //onChange={HandleFieldChange}
                                                                    readOnly
                                                                />
                                                                <span className="radio-text-padding">Other</span>
                                                            </label>

                                                        </div>
                                                    </div>

                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                id="cardio_chk"
                                                                name="cardio_chk"
                                                                checked={ptClinicalCharacteristics.cardio_chk}
                                                                //onChange={HandleFieldChange}
                                                                readOnly
                                                            />
                                                            <span className="radio-text-padding">Cardiovascular condition</span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input question-20-c-sub-option-custom">
                                                        <div className="option-without-input">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    id="sub_Cardio_chk_Hypertension"
                                                                    name="sub_Cardio_chk_Hypertension"
                                                                    checked={ptClinicalCharacteristics.sub_Cardio_chk_Hypertension}
                                                                    //onChange={HandleFieldChange}
                                                                    readOnly
                                                                />
                                                                <span className="radio-text-padding">Hypertension</span>
                                                            </label>

                                                        </div>
                                                        <div className="option-without-input">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    id="sub_Cardio_chk_Atrial"
                                                                    name="sub_Cardio_chk_Atrial"
                                                                    checked={ptClinicalCharacteristics.sub_Cardio_chk_Atrial}
                                                                    //onChange={HandleFieldChange}
                                                                    readOnly
                                                                />
                                                                <span className="radio-text-padding">Atrial fibrillation</span>
                                                            </label>

                                                        </div>
                                                        <div className="option-without-input">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    id="sub_Cardio_chk_Ventricular"
                                                                    name="sub_Cardio_chk_Ventricular"
                                                                    checked={ptClinicalCharacteristics.sub_Cardio_chk_Ventricular}
                                                                    //onChange={HandleFieldChange}
                                                                    readOnly
                                                                />
                                                                <span className="radio-text-padding">Ventricular track arrythmias</span>
                                                            </label>

                                                        </div>
                                                        <div className="option-without-input">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    id="sub_Cardio_chk_CHF"
                                                                    name="sub_Cardio_chk_CHF"
                                                                    checked={ptClinicalCharacteristics.sub_Cardio_chk_CHF}
                                                                    //onChange={HandleFieldChange}
                                                                    readOnly
                                                                />
                                                                <span className="radio-text-padding">CHF (congestive heart failure)</span>
                                                            </label>

                                                        </div>
                                                        <div className="option-without-input">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    id="sub_Cardio_chk_Other"
                                                                    name="sub_Cardio_chk_Other"
                                                                    checked={ptClinicalCharacteristics.sub_Cardio_chk_Other}
                                                                    //onChange={HandleFieldChange}
                                                                    readOnly
                                                                />
                                                                <span className="radio-text-padding">Other</span>
                                                            </label>

                                                        </div>
                                                    </div>

                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                id="renal_chk"
                                                                name="renal_chk"
                                                                checked={ptClinicalCharacteristics.renal_chk}
                                                                //onChange={HandleFieldChange}
                                                                readOnly
                                                            />
                                                            <span className="radio-text-padding">Renal condition</span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                id="hepatic_chk"
                                                                name="hepatic_chk"
                                                                checked={ptClinicalCharacteristics.hepatic_chk}
                                                                //onChange={HandleFieldChange}
                                                                readOnly
                                                            />
                                                            <span className="radio-text-padding">Hepatic condition</span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                id="respiratory_chk"
                                                                name="respiratory_chk"
                                                                checked={ptClinicalCharacteristics.respiratory_chk}
                                                                //onChange={HandleFieldChange}
                                                                readOnly
                                                            />
                                                            <span className="radio-text-padding">Respiratory condition</span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                id="endocrine_chk"
                                                                name="endocrine_chk"
                                                                checked={ptClinicalCharacteristics.endocrine_chk}
                                                                //onChange={HandleFieldChange}
                                                                readOnly
                                                            />
                                                            <span className="radio-text-padding">Endocrine/metabolic condition</span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                id="psychiatric_chk"
                                                                name="psychiatric_chk"
                                                                checked={ptClinicalCharacteristics.psychiatric_chk}
                                                                //onChange={HandleFieldChange}
                                                                readOnly
                                                            />
                                                            <span className="radio-text-padding">Psychiatric condition</span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                id="neurologic_chk"
                                                                name="neurologic_chk"
                                                                checked={ptClinicalCharacteristics.neurologic_chk}
                                                                //onChange={HandleFieldChange}
                                                                readOnly
                                                            />
                                                            <span className="radio-text-padding">Neurologic condition</span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                id="musculoskeletal_chk"
                                                                name="musculoskeletal_chk"
                                                                checked={ptClinicalCharacteristics.musculoskeletal_chk}
                                                                //onChange={HandleFieldChange}
                                                                readOnly
                                                            />
                                                            <span className="radio-text-padding">Musculoskeletal condition</span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                id="gastrointestinal_chk"
                                                                name="gastrointestinal_chk"
                                                                checked={ptClinicalCharacteristics.gastrointestinal_chk}
                                                                //onChange={HandleFieldChange}
                                                                readOnly
                                                            />
                                                            <span className="radio-text-padding">Gastrointestinal (GI) condition</span>
                                                        </label>
                                                    </div>
                                                    <div className="option-without-input">
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                id="condition_other_chk"
                                                                name="condition_other_chk"
                                                                checked={ptClinicalCharacteristics.condition_other_chk}
                                                                //onChange={HandleFieldChange}
                                                                readOnly
                                                            />
                                                            <span className="radio-text-padding">Other clinically significant condition</span>
                                                        </label>
                                                    </div>
                                                    {/* <div className="option-without-input">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="conditions_99"
                                                            name="conditions"
                                                            value={99}
                                                        // checked={ptDetails.s3_conf_lbcl === 99}
                                                        // //onChange={HandleFieldChange}
                                                        />
                                                        <span className="radio-text-padding">Unknown</span>
                                                    </label>
                                                </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="survey-section-content-foot">
                                <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ClinicalCharacteristics;
