export const AppReducerActions = {
  TOGGLE_LOADER: "TOGGLE_LOADER",
  SHOW_TOAST: "SHOW_TOAST",
  SHOW_ACTIONPOPUP: "SHOW_ACTIONPOPUP",
  CLOSE_ACTIONPOPUP: "CLOSE_ACTIONPOPUP",
  PAGE_DATA_CHANGED: "PAGE_DATA_CHANGED",
  SHOW_TOPMENU: "SHOW_TOPMENU",
  SET_ACTIVETAB: "SET_ACTIVETAB",
  SHOW_TMT_PRIOR_EPCO: "SHOW_TMT_PRIOR_EPCO",//not reqd
  SET_VEN_RAMP_UP_WEEK_CNT: "SET_VEN_RAMP_UP_WEEK_CNT", 
  SET_TMT_AFTER_EPCO_LOT_CNT: "SET_TMT_AFTER_EPCO_LOT_CNT",
  SHOW_CART_PAGE: "SHOW_CART_PAGE" ,
  SET_TLS_WEEK_CNT: "SET_TLS_WEEK_CNT",
  SET_TLS_WEEK_TEST_CNT: "SET_TLS_WEEK_TEST_CNT"
};
