const ErrorField = ({
    show = true,
    message,
    style,
}) => {
    if (!show) {
        return <></>;
    }

    const defaultStyle = { color: "red", fontSize: "16px", paddingLeft: "25px", display: 'block'};
    const style1 = style ? { ...defaultStyle, ...style } : defaultStyle;

    return ( 
        <div className="error-input">
            <span style={style1}>{message} </span>
        </div>
    );
};
export default ErrorField;

